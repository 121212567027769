import { Typography } from "@material-ui/core";
import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng
} from "react-places-autocomplete";

class PlaceAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: typeof props.address !== 'undefined' ? props.address.length > 0 ? props.address : "" : "", latLng: null, formatted_address: "",
            latLng: "",
        };

    }

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => this.setState({ ...this.state, latLng: latLng }))
            .catch((error) => console.error("Error", error));
        geocodeByAddress(address).then((results) => {
            geocodeByPlaceId(results[0].place_id)
                .then(results => console.log(''))
                .catch(error => console.error(error));
            results.forEach((element) => {

                let stateNew = { ...this.state };

                // // stateNew.formatted_address = element.formatted_address;
                let city = "";
                let state = "";
                let country = "";
                let _address = "";
                let pincode = "";
                let state_code = "";
                let country_code = "";
                let city_name = '';
                element.address_components.forEach((item) => {
                    item.types.forEach((ele) => {

                        if (ele === "administrative_area_level_2") {
                            if (item.long_name) {
                                city_name = item.long_name
                                city = item.long_name;
                            } else {
                                city = "";
                            }
                        } else if (ele === "administrative_area_level_1") {
                            if (item.long_name) {
                                state = item.long_name;
                                state_code = item.short_name;
                            } else {
                                state = "";
                            }
                        } else if (ele === "country") {
                            country = item.long_name;
                            country_code = item.short_name;
                        } else if (ele === 'postal_code') {
                            pincode = item.long_name;
                        }
                    });
                });
                stateNew.address = address.split(`, ${city === '' ? state : city}`)[0];

                _address = city + state + country + pincode;
                this.setState(stateNew);
                console.log(address.split(`, ${city === '' ? state : city}`)[0], city_name, stateNew.address.indexOf(city), '========', _address);
                let _childData = {
                    city: city,
                    state: state,
                    pincode: pincode,
                    state_code: state_code,
                    country_code: country_code,
                    address: address.split(`, ${city === '' ? state : city}`)[0]
                };
                this.props.getLocationFromPlace(_childData);
            })
        }
        );
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={
                    {
                        location: new window.google.maps.LatLng(this.state.latLng.lat, this.state.latLng.lng),
                        radius: 50000,
                        types: ["address"]
                    }
                }
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{}}>
                        <input
                            {...getInputProps({
                                placeholder: this.props.t('addressForm.searchplace'),
                                className: "location-search-input",
                            })}
                            style={{
                                marginTop: this.props.isApplied ? this.props.margin : 0,
                                padding: '10px',
                                height: ' 55px',
                                backgroundColor: '#597593',
                                color: "#ffffff",
                                borderBottom: this.props.serverError ? '2px solid #f44336' : '2px solid #80808070'
                            }}
                            value={this.state.address}
                            type="text"
                            id="place-autocomplete"
                            variant="filled"
                            className={`bg-loginDark text-fontWhite `}
                        />
                        {
                            suggestions.length > 0 && (
                                <React.Fragment>
                                    <div className="autocomplete-dropdown-container ">
                                        {loading && <div className="text-button">Loading...</div>}
                                        {suggestions.map((suggestion, index) => {
                                            const className = suggestion.active
                                                ? "suggestion-item--active"
                                                : "suggestion-item";
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: "#e8e8e8", cursor: "pointer" }
                                                : { backgroundColor: "#e8e8e8", cursor: "pointer" };

                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                    className='p-2'
                                                >

                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            )
                        }
                        {this.props.serverError && <Typography className='text-red-500 '>{this.props.serverError}</Typography>}
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default PlaceAutoComplete;
