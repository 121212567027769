import React from "react";
import AccountMenu from "./AccountMenu";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { useEffect } from "react";
import apiService from "../../../services/apiService/apiService";
import { useState } from "react";
import TablePaginationDemo from "../../../components/common/Pagination";
import moment from "moment";

const Commissions = () => {
  const { t } = useTranslation();
  const [commission, setCommission] = useState();
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowperPage] = useState({ id: 20, name: "20" });
  const [lastPage, setLastPage] = useState();
  const type = [
    { id: "Pending", name: "Pending" },
    { id: "Processing", name: "Processing" },
    { id: "Cancelled", name: "Cancelled" },
    { id: "Delivered", name: "Delivered" },
  ];

  const [statusValue, setStatusvalue] = useState();

  const onPropsReceiveChange = (event, value) => {
    
    setStatusvalue(value);
    
  };

  useEffect(() => {
    const _commission = new FormData();
    _commission.append("page", 1);
    if(statusValue){
      _commission.append("type", statusValue?.id || null);
    }
    apiService.affiliateCommission(_commission).then((response) => {
      setCommission(response.data.data);
      setLastPage(response.data.data.last_page);
    });
  }, [page, statusValue]);

  const handleChangeRowsPerPage = (event) => {
    setRowperPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="ml-32 mt-10 p-5">
        <div className="text-3xl font-semibold text-black">
          {t("affiliateDashboard.commission")}
        </div>
      </div>
      <div className="md:w-2/3 w-11/12 my-8 py-5 mx-auto">
        <AccountMenu />
        <div className="my-10 ">
          <div className="text-2xl font-semibold text-black">
            {t("affiliateDashboard.commission")}
          </div>
        </div>

        <Filter
          statusValue={statusValue}
          onPropsReceiveChange={onPropsReceiveChange}
          type={type}
          setRowperPage={setRowperPage}
          rowPerPage={rowPerPage}
        />
        <div className="relative  overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border h-16 text-gray-700 bg-white bg-transparent py-5 uppercase  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className=" ">
                  <div className="w-full text-center bg-blue-100 py-2 ">
                    Date
                  </div>
                </th>
                <th scope="col" className=" ">
                  <div className="w-full text-center bg-blue-100 py-2 ">
                    Product
                  </div>
                </th>
                <th scope="col" className=" ">
                  <div className="w-full text-center bg-blue-100 py-2 ">
                    Amount
                  </div>
                </th>
                <th scope="col" className=" ">
                  <div className="w-full text-center bg-blue-100 py-2 ">
                    Rate
                  </div>
                </th>
                <th scope="col" className="">
                  <div className="w-full text-center bg-blue-100 py-4"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {commission?.data.length > 0 ? (
                commission?.data.map((item) => {
                  const {affiliate_commission_total ,status,get_item, created_at,affiliate_percentage} = item
                  return (
                    <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-3 border-r py-4 font-medium whitespace-nowrap dark:text-white"
                      >
                      {moment(created_at).format('ll')}

                      </th>
                      <td className="pl-6 border-r py-4">
                        {get_item[0].product_detail[0].product_name}
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="flex justify-between">
                          <div className="my-auto border-r">{affiliate_commission_total}</div>
                        </div>
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="flex justify-between">
                          <div className="my-auto border-r">{affiliate_percentage}</div>
                        </div>
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="border-l-1 mx-2 px-8 py-2 text-fontWhite bg-red-600">
                          {status}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                  <td colspan="5" className="pl-6 rounded-sm border-r py-4 ">
                    <div className=" rounded py-2  text-center">
                      Sorry! No item Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <TablePaginationDemo
            lastPage={lastPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            setPage={setPage}
            rowPerPage={rowPerPage}
          />
        </div>
        {/* <TablePaginationDemo  page={page} setPage={setPage}/> */}
      </div>
    </div>
  );
};

export default Commissions;
