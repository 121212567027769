import React, { useEffect, useState } from "react";
import AccountMenu from "./AccountMenu";
import { useTranslation } from "react-i18next";
import BankDetails from "../../BankDetails";
import ApiService from "../../../services/apiService";
import { Button } from "@mui/material";
import TextFieldFormsy from "../../../core-components/TextFieldFormsy";
import { isEmpty } from "lodash";

const Settings = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    account_type: "",
    branch_code: "",
    account: "",
    confirmaccount: "",
  });
  const [error, setError] = useState();
  const getBankDetails = () => {
    ApiService.getBankDetails().then((result) => {
      if (result.code === 200) {
        if (result?.data?.data.length === 0) {
          setForm({
            name: "",
            branch_code: "",
            account_type: "",
            account: "",
            confirmaccount: "",
          });
        } else {
          setForm({
            name: result?.data?.data[0]?.name,
            branch_code: result?.data?.data[0]?.ifsc,
            account: result?.data?.data[0]?.account,
            account_type: result?.data?.data[0]?.account_type,
            confirmaccount: "",
          });
        }
      }
    });
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  const addBankDetails = () => {
    let errorData = {};
    let formData = new FormData();
    for (const key in form) {
      if (form[key] === "") {
        errorData = { ...errorData, [key]: `Please Fill the ${key} value` };
      }
    }
    setError(errorData);
    if (isEmpty(errorData)) {
      const _bankDetails = new FormData();
      _bankDetails.append("account", form.account);
      _bankDetails.append("confirmaccount", form.confirmaccount);
      _bankDetails.append("name", form.name);
      _bankDetails.append("ifsc", form.branch_code);
      _bankDetails.append("account_type", form.account_type);
      ApiService.addBankDetails(_bankDetails)
        .then((res) => {
          if (res.code === 200) {
            getBankDetails();
            setForm({
              name: "",
              branch_code: "",
              account_type: "",
              account: "",
              confirmaccount: "",
            });
          } else {
          }
        })
        .catch((e) => {
          console.log(e.errors);
        });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="ml-32 mt-10 p-5">
        <div className="text-3xl font-semibold text-black">
          {t("affiliateDashboard.settings")}
        </div>
      </div>
      <div className="md:w-2/3 w-11/12 mx-auto">
        <AccountMenu />

        <div className=" my-10 py-5">
          <div className="text-3xl font-semibold text-black">
            {t("affiliateDashboard.directBank")}
          </div>
        </div>
        <div className="flex flex-col mx-auto gap-4">
          <div className="gap-4">
            <TextFieldFormsy
              label={t("bankDetails.ifsc")}
              name="branch_code"
              value={form?.branch_code}
              onChange={handleChange}
              className="md:w-3/5 w-11/12 md:mx-2 mx-auto my-2 text-fontWhite bg-whiteBackground"
              variant="filled"
            />
        
            <TextFieldFormsy
              label={t("bankDetails.accountType")}
              name="account_type"
              value={form?.account_type}
              onChange={handleChange}
              className="md:w-3/5 w-11/12 md:mx-2 mx-auto my-2 text-fontWhite bg-whiteBackground"
              variant="filled"
            />
        
            <TextFieldFormsy
              label={t("bankDetails.accountNumber")}
              name="account"
              onChange={handleChange}
              className="md:w-3/5 w-11/12 md:mx-2 mx-auto my-2 text-fontWhite bg-whiteBackground"
              value={form?.account}
              variant="filled"
            />
          
            <TextFieldFormsy
              label={t("bankDetails.confirmAccountNumber")}
              name="confirmaccount"
              onChange={handleChange}
              value={form?.confirmaccount}
              className="md:w-3/5 w-11/12 md:mx-2 mx-auto my-2 text-fontWhite bg-whiteBackground"
              variant="filled"
            />
          
            <TextFieldFormsy
              label={t("bankDetails.accountHolderName")}
              name="name"
              className="md:w-3/5 w-11/12 md:mx-2 mx-auto my-2 text-fontWhite bg-whiteBackground"
              variant="filled"
              onChange={handleChange}
              value={form?.name}
            />
          </div>

          <div className="mt-4 justify-center flex md:w-1/2 mb-6">
            <Button
              variant="contained"
              className="text-base bg-primary text-white dark:bg-button font-normal uppercase"
              onClick={addBankDetails}
              // style={{padding:"6px 24px"}}
            >
              {t("bankDetails.submit")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
