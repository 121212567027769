import { AppBar, DialogContentText, Grid, Toolbar, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../core-components/AlertComponent";
import ApiService from "../services/apiService";
export default function FormDialog({
  show,
  setShow,
  modalRow,
  addToCart,
  color,
  size,
  openAlertModal,
  alertContent,
  setOpenAlertModal,
}) {
  const [price, setPrice] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [optionKey, setOptionKey] = useState([]);
  const { t } = useTranslation();
  const [firstIndexArray, setFirstIndexArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState();
  const [data, setData] = useState();
  React.useEffect(() => { }, [color, size]);

  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
    setShow(false);
  };
  const SetproductPrice = (currentValue, index) => {
    console.log(currentValue, "currentValue***");
    // firstIndexArray is out main array where all the object of different attributes user has selected will be inserted and initially it has all object with 0 index value
    const filteredArray = firstIndexArray.filter((firstIndexArray) => {
      return firstIndexArray.attribute !== currentValue.attribute;
    });
    // for changing the product price as user clicked on different attribute we will do the same thing as we did in useEffect
    // but we have to replace the existing object in firstIndexArray by our current object
    // for deciding which object we will remove from firstIndexArray we will just match current object attribute type with firstIndexArray's object type
    // because it will always have objects with unique attribute
    // ek attribute ki ek hi object hogi
    // if user clicked on size object then object with size attribute in firstIndexArray will get replaced by current object

    filteredArray.push(currentValue);

    let initialPrice = [];
    let initialOfferPrice = [];
    filteredArray.map((filteredArray) => {
      initialPrice.push(filteredArray.price ? filteredArray.price : "0", filteredArray?.commission);
      initialOfferPrice.push(filteredArray.offer_price ? filteredArray.offer_price : "0", filteredArray?.commission);
    });

    var result = initialPrice.map(function (x) {
      return parseInt(x, 10);
    })

    const totalScores = result.reduce(
      (previousScore, currentScore, index) => previousScore + currentScore,
      0);

    let selectPrice = totalScores + Number(data?.offer_price)
    setSelectedPrice(selectPrice)

    const maximumPrice = initialPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });
    const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });

    setFirstIndexArray(filteredArray);
    setPrice(maximumPrice);

    if (
      maximumOfferPrice == 0 ||
      maximumOfferPrice == null ||
      maximumOfferPrice == "null"
    ) {
      setOfferPrice(maximumPrice);
    } else {
      setOfferPrice(maximumOfferPrice);
    }
  };
  React.useEffect(() => {
    ApiService.getProductById(modalRow.id).then((res) => {
      if (res.code === 200) {
        setLoading(false)
        setData(res.data.data.product);
        setOptionKey(Object.entries(res.data.data.product.OptionKey));
        if (Array.isArray(res.data.data.product.OptionKey)) {
          setPrice(res.data.data.product.price);
          if (res.data.data.product.offer_price !== null) {
            setOfferPrice(res.data.data.product.offer_price);
          } else {
            setOfferPrice(res.data.data.product.price);
          }
        } else {
          // now we have one array which is firstIndexArray and this array will help us getting price and offer price for the current attributes selected

          // current attributes objects will look like this
          //     size[:{price:"100",offerPrice:"50"},
          //     {price:"70",offerPrice:"40"},
          // ]

          //     color[:{price:'20',offerPrice:"10"}
          //     color{price:'10',offerPrice:"8"}
          // ]
          Object.entries(res.data.data.product.OptionKey).map((optionkey) => {
            firstIndexArray.push(optionkey[1][0]);
            // initially we will push all first index attribute object in our firstIndex Array it will look like this
            // size:{price:"100",offerPrice:"50"},
            // color:{price:'20',offerPrice:"10"}
          });
          let initialPrice = [];
          let initialOfferPrice = [];
          firstIndexArray.map((firstIndexArray) => {
            // now we will push the price and offer price of  both the object in initialPrice array and intialOfferPrice Array respectively
            initialPrice.push(firstIndexArray.price ? firstIndexArray.price : "0", firstIndexArray?.commission);
            initialOfferPrice.push(firstIndexArray.offer_price ? firstIndexArray.offer_price : "0", firstIndexArray?.commission);
          });
          // both array will look like this
          // initialPrice=[100,20]
          // initialOfferPrice=[40,8]

          // ]  now we will take out the maximum price
          var result = initialPrice.map(function (x) {
            return parseInt(x, 10);
          })

          const totalScores = result.reduce(
            (previousScore, currentScore, index) => previousScore + currentScore,
            0);

          let selectPrice = totalScores + Number(res.data.data.product.offer_price)
          setSelectedPrice(selectPrice)

          const maximumPrice = initialPrice.reduce(function (a, b) {
            return Math.max(a, b);
          });
          // maximum price wil be 100 as 100 is greater than 20

          // ]  now we will take out the maximum offerPrice
          const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
            return Math.max(a, b);
          });
          // maximumOfferPrice will be 40

          // now setting the price and offerPrice in our main state
          setPrice(maximumPrice);

          if (
            maximumOfferPrice == 0 ||
            maximumOfferPrice == null ||
            maximumOfferPrice == "null"
          ) {
            // sometimes if offerPrice is null so we will set original price in price
            // offerPrice will be same as price
            setOfferPrice(maximumPrice);
          } else {
            setOfferPrice(maximumOfferPrice);
          }
        }
      }
    });
  }, [modalRow]);
  const handleClickOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return !modalRow ? (
    <div></div>
  ) : (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        className="h-full overflow-y-scroll "
      >
        <AppBar position="static" elevation={1} className='font-bold bg-button dark:text-fontWhite  text-primary'>
          <Toolbar className="flex w-full">
            <Typography component={'span'} variant={'body2'} className='font-bold bg-button dark:text-fontWhite  text-white'>
              {t("attributeModal.chooseOptions")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className="overflow-hidden font-bold dark:bg-lightDarkBackground  text-primary">
          {loading ?
            <div className="text-center">
              <CircularProgress style={{
                width: '50px',
                height: '50px',
                margin: '100px',
                color: '#597593'
              }} />
            </div>
            : <React.Fragment>

              <DialogContentText id="alert-dialog-slide-description">
                <Typography className="font-bold dark:text-fontWhite text-primary">{modalRow.product_name}</Typography>

                <p className="text-base dark:text-fontWhite ">
                  {t("attributeModal.price")}:<strike className=" text-danger line-through px-1"> {modalRow.currency_data.symbol} {data?.price}{" "}</strike>
                  {modalRow.currency_data.symbol} {selectedPrice}

                </p>

                {optionKey.length > 0 &&
                  optionKey.map((optionKey, key) => (
                    <>
                      <h2 className="mt-8 mb-2 font-bold dark:text-fontWhite   ">{optionKey[0]} </h2>
                      <Grid spacing={2} className="mt-2.5 dark:bg-lightDarkBackground " container>
                        {optionKey[1].map((product_option, key) => (
                          <Grid item lg="auto" md={3}>
                            <Button
                              className={`${firstIndexArray.find(
                                (o) =>
                                  o.attribute === product_option.attribute &&
                                  o.price === product_option.price &&
                                  o.values === product_option.values
                              )
                                ? " bg-button text-white  border-1 border-primary dark:text-fontWhite"
                                : " bg-whiteBackground dark:bg-lightDarkBackground text-dark dark:text-fontWhite"
                                }`}
                              onClick={() => {
                                SetproductPrice(product_option, key);
                              }}
                              variant="contained"
                            // color="primary"
                            >
                              {product_option.values}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ))}
              </DialogContentText>
            </React.Fragment>}

        </DialogContent>
        <DialogActions className="dark:bg-lightDarkBackground">
          <Button
            className="bg-button"
            variant="contained"
            onClick={() => {
              addToCart(modalRow, price, selectedPrice, firstIndexArray);
            }}
          >
            {t("common.addToCart")}
          </Button>

          {/* alert component will get render if you have products from another seller in your cart and you are trying to add product of different seller */}
          <AlertComponent
            productData={modalRow}
            setShow={setShow}
            price={price}
            offerPrice={offerPrice}
            openAlertModal={openAlertModal}
            alertContent={alertContent}
            handleAlertModalClose={handleAlertModalClose}
            firstIndexArray={firstIndexArray}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
