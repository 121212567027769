import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import _ from "../utils/@lodash";
const useStyles = makeStyles((theme) => ({
  customTextField: {
    "& input": {
      color: "black",
    },
  },
  label: {
    color: "white",
  },
}));

// this Component will be a replacement for using textField in all other component
// instead of using textField we will use TextFieldFormsy
function AutoCompleteFormsy(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const importedProps = _.pick(props, [
    "onChange",
    "placeholder",
    "required",
    "options",
    "errormessage",
    "variant",
  ]);
  const { errormessage } = props;

  // onChange function

  async function optionchangeValue(event, value, selectedOptions) {
    props.onSelectValueChange(props.name, value);
  }
  useEffect(() => {
    if (props.options) {
      setData(props.options);
    }
  }, [props.options]);

  return (
    <FormControl
      error={Boolean((!props.isPristine && props.showRequired) || errormessage)}
      className={clsx(
        props.className,
        "z-10",
        props.showRequired ? "required" : "",
        props.showError ? "error" : null
      )}
      variant={importedProps.variant}
    >
      {typeof props.options === "undefined" ? (
        <Autocomplete
          {...importedProps}
          id={props.name}
          // freeSolo
          value={props.value}
          clearOnBlur={true}
          name={props.name}
          onChange={(event, value) => {
            props.onSelectValueChange(props.name, value);
          }}
          getOptionLabel={(data) => (data ? data.name : "")}
          options={data}
          renderInput={(params) => (
            <TextField
              error={Boolean(
                (!props.isPristine && props.showRequired) || props.serverError
              )}
              {...params}
              helperText={props.serverError}
              label={props.value ? "" : props.label}
              classes={{ root: classes.customTextField }}
              variant={props.variant}
              InputLabelProps={{
                className: classes.label,
              }}
            />
          )}
          autoComplete={false}
        />
      ) : (
        <Autocomplete
          {...importedProps}
          id={props.name}
          freeSolo
          value={props.value}
          clearOnBlur={true}
          name={props.name}
          onChange={(event, value) => {
            optionchangeValue(event, value);
          }}
          getOptionLabel={(option) => (option ? option.name : "")}
          options={data}
          renderInput={(params) => (
            <TextField
              error={Boolean(
                (!props.isPristine && props.showRequired) || props.serverError
              )}
              {...params}
              label={props.value ? "" : props.label}
              classes={{ root: classes.customTextField }}
              helperText={props.serverError}
              placeholder={props.placeholder}
              variant={props.variant}
              onChange={(e) => { props.onSearchChange(e.target.value) }}
              InputLabelProps={{
                className: classes.label,
              }}
            />
          )}
          autoComplete={false}
        />
      )}
      {Boolean(errormessage) && <FormHelperText>{errormessage}</FormHelperText>}
    </FormControl>
  );
}
export default React.memo(AutoCompleteFormsy);
