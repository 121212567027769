// import { setPermissionsData } from "app/auth/store/permissionSlice";
// import store from "app/store";
// import { showMessage } from "app/store/fuse/messageSlice";
//import ExampleConfig from 'app/main/example/ExampleConfig';
import axios from "axios";
import jwtDecode from "jwt-decode";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import AppConfig from "../../appConfig";
import FuseUtils from "../../utils/FuseUtils";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterseptorRequest = () => {
    axios.interceptors.request.use((req) => {
      let accessToken = this.getAccessToken();
      // req.headers.authorization = 'my secret token';
      req.baseURL = AppConfig.apiUrl;
      req.headers.accept = "application/json";
      req.headers["Content-Type"] = "application/json";
      // req.headers.authorization = accessToken ? 'Bearer ' + accessToken : 'Bearer ';
      req.headers.Authorization = accessToken
        ? "Bearer " + accessToken
        : "Bearer ";

      return req;
    });
  };

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (access_token === "null") {
      this.emit("onAutoLogout", "access_token expired");
      return;
    }
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithToken = () => {
    this.setInterseptorRequest();
    return new Promise((resolve, reject) => {
      axios
        .get("user/auth/access-token-user")
        .then((response) => {
          if (response.data) {
            // this.setSession(data.data.access_token);
            // let _shortcuts = data.data?.user?.theme_shortcut;
            // // store.dispatch(
            // //   // setPermissionsData(data.data?.user?.role?.permissions)
            // // );
            // let user = data.data;
            // data.data["user"] = user;
            store.dispatch({
              type: ActionTypes.ADD_USER_DATA,
              payload: response.data,
            });
            resolve(response.data); //response.data.user
          } else {
            // showMessage({
            //   message: "Failed to login with token.",
            //   variant: "error",
            // });
            this.logout();
            this.removeUserData();
            reject(new Error("Failed to login with token."));

            // commonUtils.redirectionPage(`${process.env.PUBLIC_URL}/logout`);
          }
        })
        .catch((error) => {
          // reject(
          //   new Error("You don't have permission to access this website.")
          // );

          this.logout();
          this.removeUserData();

          // commonUtils.redirectionPage(`${process.env.PUBLIC_URL}/logout`);

          //
        });
    });
  };

  updateUserData = (user) => {
    return axios.post("admin-theme", {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      sessionStorage.setItem(AppConfig.localAppKey, access_token);
      sessionStorage.setItem(AppConfig.localDomainName, process.env.PUBLIC_URL);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      sessionStorage.removeItem(AppConfig.localAppKey);
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    let formData = new FormData();
    formData.append("token", sessionStorage.getItem("pushToken"));
    formData.append(
      "topic",
      AppConfig.apiUrl.search("backend_staging") > 0
        ? "T" + "_User_ID_" + sessionStorage.getItem(AppConfig.localUserIdkey)
        : "L" + "_User_ID_" + sessionStorage.getItem(AppConfig.localUserIdkey)
    );
    // apiService.unsubscribeFirebaseUser(formData);
    this.setSession(null);
  };

  removeUserData = (user) => {
    sessionStorage.removeItem(AppConfig.localRoleKey);
    sessionStorage.removeItem(AppConfig.localContactIdKey);
    sessionStorage.removeItem(AppConfig.localUserIdkey);
    sessionStorage.removeItem(AppConfig.localDomainName);

    let _firebaseUser = {
      id: user?.data.id,
      name: user?.data.displayName,
      status: "offline",
      mood: "This is user status!",
      avatar: user?.data.photoURL
        ? user?.data.photoURL
        : "https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png",
    };

    // this.logoutFirebase(_firebaseUser);
  };

  // logoutFirebase = async (user) => {
  //   if (typeof user.id !== "undefined") {
  //     let response = await firebaseApiService.addFirebaseUser(user);
  //     if (response === 200) {
  //       setTimeout(() => {
  //         commonUtils.redirectionPage(`${process.env.PUBLIC_URL}/logout`);
  //       }, 500);
  //     }
  //   }
  // };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem(AppConfig.localAppKey);
  };
}

const instance = new JwtService();

export default instance;
