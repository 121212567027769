import React, { useEffect, useState } from 'react'
import ApiService from '../services/apiService';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import HtmlParser from 'react-html-parser';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Faq() {
    const [faqData, setFaqData] = useState([])
    const [expanded, setExpanded] = React.useState();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        ApiService.getFaqData().then((res) => {
            setFaqData(res.data.data.faq.data)
        });
    }, [])

    return (
        <div>
            <div className='text-4xl font-semibold text-primary text-center py-8'>Frequently Asked Questions</div>

            <div className='px-20 pb-10 pt-5'>
                {faqData.map((item, index) => {
                    return (
                        <Accordion expanded={expanded === item?.id} onChange={handleChange(item?.id)} key={item?.id}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography className='text-primary'>{item?.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className='text-sm'>{HtmlParser(item?.answer)}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>
        </div>
    )
}

export default Faq