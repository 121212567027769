const AppConfig = {
  color: {
    primary: "#d8ba25",
    secondary: "#597593",
    font: "#333333",
    background: "#fafafa",
    button: "#ffffff",
  },
  breakpoints: {
    mobile: 640,
    tablet: 767,
    desktop: 1300,
  },
  gatewayUrl: "",
  // stripePaymentKey:
  //   "pk_test_51HkiATHwzng6Sb2CuVJoHZ9IE2cvxLuvdJKjoq6kyqOkwP3DqxP8quEQq7sAFsPndKCN3iHL7Qa6noyK9Wtpydg000hkS9Ll2P",
  pusher: {
    PUSHER_APP_ID: 1413910,
    PUSHER_APP_KEY: "87909e984abafa89a22c",
    PUSHER_APP_CLUSTER: "ap2",
  },
  // apiUrl: "https://fashion-leo.servepratham.com/api/v2",
  apiUrl: "https://admin.fashionleo.in/api/v2",
  playStoreUrl: "https://play.google.com/store/apps/details?id=com.fashionleo",
  apiUrlCCAvenue: "https://admin.fashionleo.in",
  appName: "ecommerce",
  currencyType: "R$",
  currencyFormat: "usd",
  localAppKey: "access_token",
  localReferalKey: "affilite_id",
  localSessionIdKey: "session_id",
  localUserIdkey: "",
  localIdKey: "user_id",
  walletAmount: "wallet",
  copyright: "© 2020 WebSenor Infotech.",
  // echoURL: "https://nily.com.br",
  routes: {
    shippingPolicy: "/shipping-policy",
    faq: '/faq',
    signUpSeller: "/signUpSeller",
    home: "/home",
    login: "/login",
    footer: "/footer",
    slider1: "/homeslider1",
    shop: "/shop/:type/:slug/:id",
    shopByCategories: "/shop/:type/:categoryId/:id",
    cart: "/cart-details",
    checkout: "/checkOut",
    productDetails: "/product-details/:productName/:productId",
    myOrders: "/my-orders",
    wishList: "/wishlist",
    myChats: "/my-chats",
    cancelOrder: "/cancel-order/:type/:ordernumber",
    returnOrder: "/return-order/:type/:ordernumber",
    profile: "/my-profile",
    aboutus: "/about-us",
    myfollowing: "/seller-store/:slug/:id",
    allbrands: '/brands/:type',
    vendordetails: "/details/:type",
    allreviews: "/all-reviews/:type",
    viewOrder: "/my-orders/:orderId",
    Cms: "/cms/:type",
    ViewAll: "/view-all/:type",
    manageAddress: "/manage-addresses",
    bankDetails: "/bank-details",
    myEarnings: "/my-earnings",
    myReviews: "/my-reviews",
    loginEmail: "/loginEmail",
    signUp: "/signUp",
    affiliateDashboard: "/affiliate-dashboard/",
    routingCommission: "/affiliate-dashboard/commission",
    routingClicks: "/affiliate-dashboard/clicks",
    routingpayment: "/affiliate-dashboard/payments",
    // routingGenerateLink:"/affiliate-dashboard/generate-link",
    routingSettings: "/affiliate-dashboard/settings",
  },
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCCsyytFgGKyW5_H8J6iLmaDJaeaK7nf3I",
//   authDomain: "nily-app.firebaseapp.com",
//   projectId: "nily-app",
//   storageBucket: "nily-app.appspot.com",
//   messagingSenderId: "350370615986",
//   appId: "1:350370615986:web:b2397481958b0b9a632fab",
//   measurementId: "G-S8XCVGN5X0",
// };

export default AppConfig;
