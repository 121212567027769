const tl = {
    translation: {
        alertComponent: {
            otherSellerProductsinCart: "మీ కార్ట్ ఇతర విక్రేత నుండి ఉత్పత్తులను కలిగి ఉంది, దయచేసి కార్ట్‌ను తరలించండి",
            alert: "హెచ్చరిక",
            removeOtherAddThis: "ఇతర అంశాలను తీసివేసి, దీన్ని జోడించండి",
            cancel: "రద్దు చేయండి",
        },
        productcard: {
            addtowishlist: "కోరిక జాబితాకి జోడించండి",
            removefromwishlist: "కోరికల జాబితా నుండి తీసివేయి",
        },
        header: {
            search: "వెతకండి...",
            language: "భాష",
            categories: "కేటగిరీలు",
            home: "ఇల్లు",
            alert: "హెచ్చరిక",
            stockNotAvailable: "స్టాక్ అందుబాటులో లేదు....",
            ok: "అలాగే",
            profile: "ప్రొఫైల్",
            myChats: "నా చాట్‌లు",
            myFollowing: "నా ఫాలోయింగ్",
            logout: "లాగ్ అవుట్",
            myCart: "నా బండి",
            myOrders: "నా ఆదేశాలు",
            wishlist: "కోరికల జాబితా",
            notifications: "నోటిఫికేషన్లు",
            cart: "బండి",
            login: "ప్రవేశించండి",
            account: "ఖాతా",
        },
        paymentForm: {
            paymentOptions: "డెబిట్ కార్డ్, క్రెడిట్ కార్డ్, నెట్ బ్యాంకింగ్, UPI",
            cashOnDelivery: "వస్తువులు అందిన తరువాత నగదు చెల్లించడం",
            flashSaleNote: "గమనిక: ఫ్లాష్ సేల్ ఉత్పత్తులపై ఆన్‌లైన్ చెల్లింపు మాత్రమే అందుబాటులో ఉంటుంది"
        },
        checkOutForm: {
            payNow: "ఇప్పుడు చెల్లించండి",
        },
        addressForm: {
            city: "నగరం",
            close: 'దగ్గరగా',
            document: 'CPF',
            state: "రాష్ట్రం",
            locality: "స్థానికత",
            shippingAddress: "షిప్పింగ్ చిరునామా",
            address: "చిరునామా",
            addAddress: "కొత్త చిరునామాను జోడించు",
            saveAddress: 'చిరునామాను సేవ్ చేయి',
            editAddress: 'చిరునామాను సవరించు',
            name: "పేరు",
            mobile: "మొబైల్",
            landmark: "ల్యాండ్‌మార్క్",
            pinCode: "పిన్ కోడ్",
            addressType: "చిరునామా రకం",
            searchplace: 'స్థలాలను వెతకండి...',
            home: "ఇల్లు",
            office: "కార్యాలయం",
            country: "దేశం",
            NearByLocation: "స్థానం ద్వారా సమీపంలో",
            statecode: "స్టేట్ కోడ్",
            countrycode: "దేశం కోడ్",
            confirmation: "ధృవీకరణ",
            confirmationMessage: "మీ వ్యాపార చిరునామా దేశం కోడ్ మార్చబడినందున, డిఫాల్ట్ కరెన్సీ మరియు గతంలో జోడించిన ఉత్పత్తులు నిష్క్రియంగా సెట్ చేయబడతాయి. దయచేసి అభ్యర్థనను నిర్ధారించండి లేదా రద్దు చేయండి."
        },
        noFound: {},
        order: {
            shippingAddress: "షిప్పింగ్ చిరునామా",
            mobile: "మొబైల్",
            deliveryAt: "డెలివరీ వద్ద",
            orderDetails: "ఆర్డర్ వివరాలు",
            qty: "Qty",
            status: "స్థితి",
            paymentMethod: "చెల్లింపు పద్ధతి",
            orderedOn: "ఆర్డర్ చేయబడింది",
            customerDetails: "వినియోగదారుని వివరాలు",
            trackOrder: "ట్రాక్ ఆర్డర్",
            backToOrder: "బ్యాక్ టు ఆర్డర్",
            orderAmount: "ఆర్డర్ మొత్తం",
            orderId: 'ఆర్డర్ గుర్తింపు సంఖ్యా',
            more: 'మరింత',
            discountAmount: 'ఆర్డర్ తగ్గింపు మొత్తం',
            rating: 'రేటింగ్ ఇవ్వండి',
        },

        cancelOrder: {
            reasonForCancel: "రద్దుకు కారణం",
            reasonForReturn: "తిరిగి రావడానికి కారణం",
            orderCancellation: "ఆర్డర్ రద్దు",
            orderCancel: "ఆర్డర్ రద్దు",
            returnCancel: "ఆర్డర్ రిటర్న్",
            cancelHeading: "రిటర్న్ అభ్యర్థనను రద్దు చేయాలా?",
            returnRequestCancel: "అవును, నేను రద్దు చేయాలనుకుంటున్నాను",
            continue: "కొనసాగించు",
            itemInfo: "వస్తు సమాచారం",
            qty: "Qty",
            deliveryFee: "డెలివరీ రుసుము",
            orderDiscount: "ఆర్డర్ డిస్కౌంట్",
            productAmount: "ఉత్పత్తి మొత్తం",
            price: "ధర",
            comments: "వ్యాఖ్యలు",
            confirmMsg: "మీరు ఖచ్చితంగా ఈ ఆర్డర్ కోసం రిటర్న్ అభ్యర్థనను రద్దు చేయాలనుకుంటున్నారా?",
            feedback: "మీ అభిప్రాయాన్ని తెలియజేయండి",
            feedbackMsg: "మీరు ఈ ఉత్పత్తిని ఎలా రేట్ చేస్తారు?",
            submitRating: "సమర్పించు",
            feedbackComment: "మీ సమీక్షను జోడించండి",
        },
        footer: {
            quickShop: "త్వరిత దుకాణం",
            informations: "సమాచారం",
            customerService: "వినియోగదారుల సేవ",
            contactUs: "మమ్మల్ని సంప్రదించండి",
            supportChat: "మద్దతు చాట్",
        },
        mobileFooter: {
            home: "ఇల్లు",
            category: "వర్గం",
            new: "కొత్త",
            me: "నేను",
            bag: "బ్యాగ్",
            myOrders: "నా ఆదేశాలు",
            categoriesDrawer: {
                categories: "కేటగిరీలు",
            },
        },
        common: { addToCart: "కార్ట్‌కి జోడించండి" },
        card: {
            button: { addToCart: "కార్ట్‌కి జోడించండి", productDetail: "వివరాలను వీక్షించండి" },
        },
        shop: {
            home: "ఇల్లు",
            mobileDisplay: "మొబైల్ ప్రదర్శన",
            categoryHeading: "కేటగిరీలు",
            showingHeading: "చూపిస్తోంది",
            size: "పరిమాణం",
            price: "ధర",
            brand: "బ్రాండ్",
            discount: "రాయితీ",
            andMore: "ఇంకా చాలా",
            color: "రంగు",
            filter: "ఫిల్టర్లు",
            allFilter: "అన్ని ఫిల్టర్లు",
            applyAllFilters: "అన్ని ఫిల్టర్‌లను వర్తింపజేయి",
            clear: "క్లియర్",
            more: "మరింత",
            productFilters: "ఉత్పత్తి ఫిల్టర్లు",
            noFilters: "ఫిల్టర్లు లేవు",
            showingProducts: "ఉత్పత్తులను చూపుతోంది",
            products: "ఉత్పత్తులు",
            of: "యొక్క",
            noProductFound: "ఉత్పత్తి కనుగొనబడలేదు",
            noProducts: "ఉత్పత్తులు లేవు",
        },
        productDetails: {
            all: "అన్ని సమీక్షలను వీక్షించండి",
            one: "ఒకటి",
            two: "రెండు",
            three: "మూడు",
            four: "నాలుగు",
            five: "ఐదు",
            soldBy: "సేల్డ్ బై",
            outOfStock: "స్టాక్ లేదు",
            addToWishList: "కోరిక జాబితాకి జోడించండి",
            viewShop: "వ్యూ షాప్",
            chatWithUs: "మాతో చాట్ చేయండి",
            ratings: "రేటింగ్స్",
            followers: "అనుచరులు",
            products: "ఉత్పత్తులు",
            productDetails: "వస్తువు యొక్క వివరాలు",
            productRatingAndReviews: "ఉత్పత్తి రేటింగ్‌లు & సమీక్షలు",
            similarProducts: "సారూప్య ఉత్పత్తులు",
            reviews: "సమీక్షలు",
            removeFromWishlist: "కోరికల జాబితా నుండి తీసివేయి",
            share: "షేర్",
            off: 'ఆఫ్',
            youSave: "మీరు సేవ్ చేయండి",
            placeOrderPinCode: "పిన్‌కోడ్‌ని నమోదు చేయండి",
            check: "తనిఖీ",
            checkDelivery: "డెలివరీని తనిఖీ చేయండి",
            inputLabel: "డెలివరీ & సేవలను తనిఖీ చేయడానికి దయచేసి పిన్ కోడ్‌ని నమోదు చేయండి",
            deliveryDateOne: "సాధారణంగా 7 రోజుల్లో డెలివరీ అవుతుంది.",
            deliveryDateTwo: "డెలివరీపై చెల్లింపు అందుబాటులో ఉంది.",
            deliveryDateThree: "అవాంతరాలు లేని 7 రోజుల వాపసు",
        },
        myOrders: {
            filters: "ఫిల్టర్లు",
            orderStatus: "ఆర్డర్ స్థితి",
            onTheWay: "దారిలో",
            delivered: "బట్వాడా చేయబడింది",
            cancelled: "రద్దు",
            returned: "తిరిగి",
            orderTime: "ఆర్డర్ సమయం",
            last30Days: "గత 30 రోజులు",
            processing: "ప్రాసెసింగ్",
            older: "పాత",
            soldBy: "సేల్డ్ బై",
            viewOrder: "ఆర్డర్ చూడండి",
            cancelOrder: "ఆర్డర్ రద్దు చేయి",
            returnOrder: "రిటర్న్ రిక్వెస్ట్",
            returnCancelOrder: "రిటర్న్ అభ్యర్థనను రద్దు చేయి",
            address: "చిరునామా",
            paymentDetail: "చెల్లింపు వివరాలు",
            COd: "COD",
            transactionId: "లావాదేవి ఐడి ",
            myOrders: "నా ఆదేశాలు",
            filter: "ఫిల్టర్లు",
            openMainMenu: "ప్రధాన మెనూని తెరవండి",
            orderedOn: "ఆర్డర్ చేయబడింది",
            walletAmount: "వాడిన వాలెట్",
            orderAmount: "ఆర్డర్ మొత్తం",
            qty: "Qty",
        },
        myProfile: {
            profile: "ప్రొఫైల్",
            alert: "హెచ్చరిక",
            ok: "అలాగే",
            updateProfile: "నవీకరణ",
            firstName: "మొదటి పేరు",
            city: "నగరం",
            email: "ఇమెయిల్ చిరునామా",
            phone: "ఫోను నంబరు",
            state: "రాష్ట్రం",
            address: "చిరునామా",
            dialing_code: "దేశం కోడ్",
            contactMessage: "ఫోన్ నంబర్ మార్చడానికి మద్దతును సంప్రదించండి",
            personalInfo: "వ్యక్తిగత సమాచారం",
            accountInfo: "ఖాతా వివరములు",
            alert: "OTP ధృవీకరణ",
            message: "OTP మీ మొబైల్ నంబర్‌కు పంపబడింది",
            emailMessage: "OTP మీ ఇమెయిల్‌కి పంపబడింది",
            otpError: "otp ఫీల్డ్ అవసరం",
            image: "సిఫార్సు చేయబడిన పరిమాణం 500x500px",
            phoneError: "ఫోన్ నంబర్ ఇప్పటికే ఉంది",
            emailError: "ఈమెయిలు ఇదివరకే ఉంది",
            changePassword: "పాస్‌వర్డ్ మార్చు",
            password: "పాత పాస్వర్డ్",
            newPass: "కొత్త పాస్వర్డ్",
            confirmPass: "పాస్‌వర్డ్‌ని నిర్ధారించండి",
            confirmPassError: "పాస్‌వర్డ్ సరిపోలలేదని నిర్ధారించండి",
        },
        providingFeatures: {
            features: {
                i: {
                    heading: "సురక్షిత చెల్లింపు",
                    subHeading: "ప్రపంచంలోని అత్యంత సురక్షితమైన చెల్లింపు పద్ధతులతో చెల్లించండి",
                },
                ii: {
                    heading: "విశ్వాసం",
                    subHeading: "రక్షణ మీ కొనుగోలు మరియు వ్యక్తిగత డేటాను కవర్ చేస్తుంది",
                },
                iii: {
                    heading: "పాన్ ఇండియా డెలివరీ",
                    subHeading: "200+ కంటే ఎక్కువ దేశాలు & ప్రాంతాలకు ఉచిత & వేగవంతమైన షిప్పింగ్.",
                },
                iv: {
                    heading: "హాట్‌లైన్",
                    subHeading: "4141 456 789, 4125 666 888లో మీ ప్రశ్న కోసం హెల్ప్ లైన్‌తో మాట్లాడండి",
                },
            },
        },
        cartDetails: {
            product: "ఉత్పత్తి",
            quantity: "పరిమాణం",
            total: "మొత్తం",
            price: "ధర",
            shopNow: "ఇప్పుడు కొను",
            noItems: "బండిలో వస్తువులు లేవు",
            checkout: "చెక్ అవుట్",
            subtotal: "ఉప మొత్తం",
            discount: "డిస్కౌంట్ ఆర్డర్",
            shipping: "షిప్పింగ్",
            yourCart: "మీ బండి",
            addOnlyTenProducts: "మీరు కేవలం పది ఉత్పత్తులను మాత్రమే జోడించగలరు",
            action: "యాక్షన్",
            ok: "అలాగే",
            nocartitem: 'కార్ట్‌లో వస్తువులు లేవు',
            cartEmpty: "మీ సరుకుల సంచీ ఖాళీగా నున్నది.",
            wishlistEmpty: "మీ కోరికల జాబితా ఖాలీ గా ఉంది."
        },

        checkout: {
            alert: "హెచ్చరిక",
            ok: "అలాగే",
            checkout: "చెక్ అవుట్",
            refresh: "రిఫ్రెష్",
            orderProcessing: "మీ ఆర్డర్ చెల్లింపు ప్రాసెస్ చేయబడుతోంది",
            thankYouForOrder: "ఆర్డర్ చేసినందుకు ధన్యవాదాలు",
            orderNumberIs: "ఆర్డర్ నంబర్",
            transactionIdIs: "లావాదేవీ ఐడి",
            continueShopping: "షాపింగ్ కొనసాగించడానికి",
            back: "వెనుకకు",
            next: "తరువాత",
            placeOrder: "ప్లేస్ ఆర్డర్",
            payNow: "ఇప్పుడు చెల్లించండి",
            shippingAddress: "షిప్పింగ్ చిరునామా",
            paymentMethod: 'చెల్లింపు పద్ధతి',
            orderSummary: "కొనుగోలు వివరణ",
        },
        review: {
            flashSaleCouponMsg : "గమనిక: ఫ్లాష్ సేల్ ఉత్పత్తుల విషయంలో కూపన్ వర్తించదు.",
            flashSaleWalletMsg : "గమనిక: ఫ్లాష్ సేల్ ఉత్పత్తుల విషయంలో వాలెట్ వర్తించదు.",
            to: "కు",
            days: "రోజులు",
            minimumOrderValue: "కనీస ఆర్డర్ విలువ",
            maimumDiscountIS: "గరిష్ట తగ్గింపు",
            orderSummary: "కొనుగోలు వివరణ",
            productName: "ఉత్పత్తి నామం",
            originalPrice: "అసలు ధర",
            margin: "మార్జిన్",
            offerPrice: "తగ్గింపు ధర",
            orderAmount: "ఆర్డర్ మొత్తం",
            offerDiscount: "ఉత్పత్తి తగ్గింపు",
            bankDiscount: "బ్యాంక్ ఆఫర్",
            subTotal: "ఉప మొత్తం",
            Qty: "Qty",
            productAmount: "ఉత్పత్తి ధర",
            deliveryFee: "డెలివరీ రుసుము",
            marginPrice: "మార్జిన్ ధర",
            orderDiscount: "కూపన్ డిస్కౌంట్",
            orderTotal: "ఆర్డర్ మొత్తం",
            youSave: "మీరు సేవ్ చేయండి",
            chooseCoupon: "కూపన్‌ని ఎంచుకోండి",
            remove: "తొలగించు",
            applyCoupon: "కూపన్ వర్తించు",
            youSaveAdditional: "మీరు అదనంగా సేవ్ చేయండి",
            shipping: "షిప్పింగ్ చిరునామా",
            paymentMethod: "చెల్లింపు పద్ధతి",
            total: "మొత్తం",
            errorMessage: 'దయచేసి చెల్లుబాటు అయ్యే చిరునామాను ఎంచుకోండి',
            margin: "మార్జిన్ మొత్తాన్ని నమోదు చేయండి",
            message: "కూపన్ తగ్గింపును లెక్కించండి",
            orderPlaceMessage: "మీ ఆర్డర్ ప్రాసెస్ చేయబడుతోంది...",
            paymentMessage: 'మీ కార్డ్‌తో చెల్లించండి..',
            applyCoupen: "కూపన్ కోడ్ వర్తించు",
            sellingMessage: 'ఉత్పత్తిని తిరిగి విక్రయిస్తున్నారా?',
            marginMessage: 'మీ మార్జిన్‌ను జోడించడానికి అవునుపై క్లిక్ చేయండి',
            yes: "అవును",
            close: 'దగ్గరగా',
            enterMargin: 'మార్జిన్ మొత్తాన్ని నమోదు చేయండి',
            addMargin: 'మార్జిన్‌ని జోడించు',
            marginError1: 'దయచేసి మార్జిన్ వరకు నమోదు చేయండి',
            marginError2: 'మొత్తం మొత్తం',
            couponApply: 'కూపన్ అప్లైడ్',
            valid: 'వరకు చెల్లుతుంది',
            totalAmount: 'మొత్తం మొత్తం',
            paymentdetails: 'చెల్లింపు వివరాలు',
            reviewOrder: 'నీ కొనుగోలు సరిచూసుకో',
            online: 'ఆన్‌లైన్',
            deliveryServices: "డెలివరీ సేవలను ఎంచుకోండి",
            freeDeliveryMessage: "పై ఆర్డర్లపై",
            freeDeliveryNotMessage: "అర్హత లేదు",
            freeDelivery: 'ఉచిత డెలివరీ',
            serviceSelectMessage: "దయచేసి డెలివరీ సేవను ఎంచుకోండి",
            marginError: 'మార్జిన్ మొత్తం దీని కంటే తక్కువగా లేదా సమానంగా ఉండాలి'
        },

        reasons: {
            returnReasonOne: "లోపం ముక్క.",
            returnReasonTwo: "తప్పు ఉత్పత్తిని స్వీకరించారు.",
            returnReasonThree: "అవసరం లేదు.",
            cancelReasonOne: "ఉత్పత్తి నాణ్యత సమస్యల కారణంగా నేను రద్దు చేయాలనుకుంటున్నాను",
            cancelReasonTwo: "ఉత్పత్తి ధర తగ్గింది",
            cancelReasonThree: "అంచనా డెలివరీ సమయం చాలా ఎక్కువ",
            cancelReasonFour: "నేను ఉత్పత్తిని వేరే చోట కొనుగోలు చేసాను",
            cancelReasonFive: "నేను ఆర్డర్ కోసం చిరునామాను మార్చాలనుకుంటున్నాను",
            cancelReasonSix: "నేను నా మనసు మార్చుకున్నాను",
            cancelReasonSeven: "నేను నా ఆర్డర్‌ను ప్రీపెయిడ్‌గా మార్చాలనుకుంటున్నాను",
            cancelReasonEight: "నేను నా ఫోన్ నంబర్ మార్చాలనుకుంటున్నాను",
        },
        wishList: {
            addToCart: "కార్ట్‌కి జోడించు",
            product: "ఉత్పత్తి",
            offerPrice: "తగ్గింపు ధర",
            price: "ధర",
            yourWishlist: "నా కోరికల జాబితా",
            addOnlyTenProducts: "మీరు కేవలం పది ఉత్పత్తులను మాత్రమే జోడించగలరు",
        },
        attributeModal: {
            price: "ధర",
            chooseOptions: "ఐచ్ఛికాలను ఎంచుకోండి",
            noProducts: "కోరికల జాబితాలో ఏ అంశం లేదు",
            shopNow: "ఇప్పుడు కొను",
            addToCart: "కార్ట్‌కి జోడించు",
            alert: "హెచ్చరిక",
            ok: "అలాగే",
        },
        chats: {
            chats: "చాట్‌లు",
            noChats: "మీకు చాట్‌లు లేవు",
            noThanks: "వద్దు ధన్యవాదాలు",
            upgradeNow: "ఇప్పుడే అప్‌గ్రేడ్ చేయండి",
            makeAOffer: "ఆఫర్ చేయండి",
            acceptOffer: "ఆఫర్‌ని అంగీకరించు",
            am: "am",
            pm: "pm",
            addToCart: "కార్ట్‌కి జోడించు",
        },
        login: {
            loginWithPhone: "ఫోన్‌తో లాగిన్",
            loginWithEmail: "ఈమెయిల్‌తో లాగిన్",
            login: "ప్రవేశించండి",
            newUser: "Fashionleoకి కొత్త? ఖాతాను సృష్టించండి",
            sendOtp: "OTP పంపు",
            signUp: "సైన్ అప్ ఫారమ్",
            signUpButton: "చేరడం",
            or: "లేదా",
            resendOtp: "OTPని మళ్లీ పంపు",
            forgotPassword: "పాస్‌వర్డ్ మర్చిపోయాను",
            enterPhone: "మీ ఫోన్ నెంబర్ ను ఎంటర్ చేయండి",
            dialingCode: "డయలింగ్_కోడ్",
            alreadyAccount: "ఇప్పటికే ఖాతా ఉందా ? లాగిన్",
            verify: "ధృవీకరించు",
            signUp: "చేరడం",
            enterTheOtp: "దయచేసి otp నమోదు చేయండి",
            password: "పాస్‌వర్డ్‌ను నమోదు చేయండి",
            emailPhone: "ఇమెయిల్/మొబైల్ నంబర్",
            email: "ఈమెయిల్‌ని నమోదు చేయండి",
            name: "మీ పూర్తి పేరును నమోదు చేయండి",
            codeError: 'దయచేసి దేశం డయలింగ్ కోడ్‌ని ఎంచుకోండి',
            phoneError: 'దయచేసి చెల్లుబాటు అయ్యే మొబైల్ నంబర్‌ను నమోదు చేయండి',
            termsError: "దయచేసి నిబంధనలు మరియు షరతులను అంగీకరించండి.",
            termCondition: "నేను అన్ని నిబంధనలు మరియు షరతులను అంగీకరిస్తున్నాను.",
            termConditionOne: "నేను అన్నింటినీ అంగీకరిస్తున్నాను",
            termConditionTwo: "నిబంధనలు మరియు షరతులు",
            nameError: "పేరు ఫీల్డ్ అవసరం",
            forgotMessage: "దయచేసి మీ రిజిస్టర్డ్ ఫోన్‌ని నమోదు చేయండి మరియు మేము మీకు OTPని పంపుతాము",
        },
        pageNotFound: {
            oops: "అయ్యో! పేజీ కనుగొనబడలేదు",
            unAvailable: "మీరు యాక్సెస్ చేయడానికి ప్రయత్నిస్తున్న పేజీ తీసివేయబడి ఉండవచ్చు, మార్చబడి ఉండవచ్చు లేదా అందుబాటులో ఉండకపోవచ్చు.",
            goToHome: "హోమ్ పేజీకి వెళ్లు",
            tryExploring: "అన్వేషించడానికి ప్రయత్నించండి",
        },
        vendorDetails: {
            myFollowing: "నా ఫాలోయింగ్",
            vendorDetails: "విక్రేత వివరాలు",
        },
        brands: {
            allbrands: "బ్రాండ్ల వారీగా షాపింగ్ చేయండి",
        },
        profileMenu: {
            profile: "ప్రొఫైల్",
            myBankDetails: "నా బ్యాంక్ వివరాలు",
            myEarnings: "నా సంపాదన",
            myReviews: "నా సమీక్షలు",
            deleteAccount: "ఖాతాను తొలగించు",
            myChats: "నా చాట్స్",
            myOrders: "నా ఆదేశాలు",
            myCart: "నా బండి",
            myFollowing: "నా ఫాలోయింగ్",
            manageAddress: "చిరునామా నిర్వహించు",
            logout: "లాగౌట్",
        },
        deletePopup: {
            confirmToDelete: "నిర్ధారించు",
            delAccount: "ఖాతాను తొలగించు",
            delMsg: "మీ ఖాతాను తొలగించడం ద్వారా కింది సమాచారం తొలగించబడుతుంది మరియు రద్దు చేయబడదు",
            msgOne: "వ్యక్తిగత వివరాలు",
            msgTwo: "విక్రేత ప్రొఫైల్ (అందుబాటులో ఉంటే)",
            msgThree: "వ్యక్తిగత పత్రాలు",
            msgFour: "చిరునామాలు",
            msgFive: "బ్యాంక్ వివరములు",
            delConfirm: "దయచేసి ఇప్పుడు మీ ఖాతాను తొలగించడానికి నిర్ధారించుపై క్లిక్ చేయండి?",
        },
        reviewTr: {
            title: "నా సమీక్ష",
            titleEarn: "నా సంపాదన",
            viewRec: "రసీదుని వీక్షించండి",
            TransactionId: "లావాదేవి ఐడి",
            walletMsg: "మీ రిఫరల్ కోడ్‌ను రూపొందించడానికి మరియు రిఫరల్ చెవిపోగులను ప్రారంభించడానికి మొదటి ఆర్డర్‌ను పూర్తి చేయండి",
            totalEarn: "మొత్తం సంపాదన",
            walletBalance: "వాలెట్ బ్యాలెన్స్",
            inviteMsg: "మీ స్నేహితుడిని ఫ్యాషన్‌లియోకి ఆహ్వానించండి మరియు వారి మొదటి విజయవంతమైన ఆర్డర్‌పై క్యాష్‌బ్యాక్ పొందండి. మరింత సమాచారం కోసం నిబంధనలు & షరతులను చదవండి",
            refferalCode: "రెఫరల్ కోడ్",
        },
        chatPopUp: {
            actualPrice: "అసలు ధర",
            makeAOffer: "ఆఫర్ చేయండి",
            am: "am",
            pm: "pm",
            acceptOffer: "ఆఫర్‌ని అంగీకరించు",
            send: "పంపు",
            addToCart: "కార్ట్‌కి జోడించు",
        },
        sortForm: {
            newArrivals: "కొత్తగా వచ్చిన",
            mostPopular: "అత్యంత ప్రజాదరణ",
            priceLowToHigh: "ధర (తక్కువ నుండి ఎక్కువ)",
            priceHighToLow: "ధర (అధిక నుండి తక్కువ",
            ratingHighToLow: "రేటింగ్‌లు(ఎక్కువ నుండి తక్కువ)",
            sort: 'క్రమీకరించు',
        },
        myFollowing: {
            ratings: "రేటింగ్స్",
            followings: "ఫాలోయింగ్స్",
            products: "ఉత్పత్తులు",
            allProducts: "అన్ని ఉత్పత్తులు",
            showing: "చూపిస్తోంది",
            follow: "అనుసరించు",
            following: "ఫాలోయింగ్",
        },
        bankDetails: {
            title: "బ్యాంక్ వివరములు",
            bankName: "బ్యాంకు పేరు",
            accountNumber: "ఖాతా సంఖ్య",
            confirmAccountNumber: "ఖాతా సంఖ్యను నిర్ధారించండి",
            swiftCode: "స్విఫ్ట్ కోడ్ / రూటింగ్ నంబర్",
            accountHolderName: "ఖాతాదారుని పేరు",
            bankCode: "బ్యాంక్ కోడ్",
            branchCode: "శాఖయొక్క సంకేత పదం",
            accountType: "ఖాతా రకం",
            submit: "సమర్పించు",
            bankNameError: "బ్యాంక్ పేరు అవసరం.",
            NameError: "ఖాతా హోల్డర్ పేరు అవసరం.",
            AccountError: "ఖాతా నంబర్ అవసరం.",
            accountError: "ఖాతా సంఖ్య 6 కంటే ఎక్కువగా ఉండాలి.",
            confirmAccountError: "ఖాతా నంబర్‌ని నిర్ధారించడం అవసరం.",
            ifscError: "స్విఫ్ట్ కోడ్ / రూటింగ్ నంబర్ అవసరం.",
            bankCodeError: "బ్యాంక్ కోడ్ అవసరం.",
            branchCodeError: "బ్రాంచ్ కోడ్ అవసరం.",
            accountTypeError: "ఖాతా రకం అవసరం.",
        },
        home: {
            recentProducts: "తాజా రాకపోకలు",
            messageText: "మీ సందేశాన్ని టైప్ చేయండి",
            button: { viewAll: "అన్నీ చూడండి" },
            categories: { title: "కేటగిరీలు" },
            brand: { title: "బ్రాండ్‌ల వారీగా షాపింగ్ చేయండి" },
            fCollection: { title: "ఫీచర్ చేయబడిన ఉత్పత్తులు" },
            flashSale: { title: "ఫ్లాష్ సేల్", left: "ఎడమ" },
            trProducts: { title: "ట్రెండింగ్ ఉత్పత్తులు" },
            topProducts: { title: "అగ్ర ఉత్పత్తులు" },
            nearbySellers: { title: "సమీపంలోని విక్రేతలు" },
            recentViewProduct: { title: "ఇటీవలి వీక్షణ ఉత్పత్తి" },
            providingFeatures: {
                i: {
                    main: "ఉచిత షిప్పింగ్ & రిటర్న్",
                    secondary: "అర్హత ఉన్న ఆర్డర్‌లపై అందుబాటులో ఉంటుంది",
                },
                ii: {
                    main: "డబ్బు హామీ",
                    secondary: "30 రోజుల మనీ బ్యాక్ గ్యారెంటీ",
                },
                iii: {
                    main: "ఆన్‌లైన్ మద్దతు",
                    secondary: "మేము ఆన్‌లైన్‌లో 24/7 రోజుకి మద్దతిస్తాము",
                },
                iv: {
                    main: "సురక్షిత చెల్లింపులు",
                    secondary: "అన్ని చెల్లింపులు సురక్షితమైనవి మరియు విశ్వసనీయమైనవి",
                },
            },
        },
        affiliate: {
            headingRegister: "Affiliate డాష్బోర్డ్",
            phone: "Phone",
            firstName: "Name",
            lastName: "Last Name",
            emailAddress: "Email Address",
            terms: "I have read and agree to the website terms and conditions",
            register: "Register",
            password: "Password",
            amount: "Amount"
        },
        affiliateDashboard: {
            dashboard: "డాష్బోర్డ్",
            commission: "కమిషన్",
            visits: "సందర్శనలు",
            payment: "చెల్లింపు",
            link: "లింక్ జనరేటర్",
            settings: "సెట్టింగులు",
            generateLinks: "లింక్‌లను రూపొందించండి",
            paymentInfo: "చెల్లింపు సమాచారం",
            status: "అన్ని స్థితి",
            rowsPerpage: "வரிசைகள்",
            urlTitle: "మీ రెఫరల్",
            commissionHeading: "ఇటీవలి కమీషన్లు",
        },
        affiliatePages: {
            recenteVisit: " ఇటీవలి సందర్శనలు (అన్నీ చూడండి)",
            affiliate: "Affiliate",
            totalEarn: "మొత్తం సంపాదన",
            totalpaid: "మొత్తం చెల్లించబడింది",
            totalpaid: "మొత్తం రీఫండ్ చేయబడింది",
            balance: "సంతులనం",
            commitssionRate: "కమీషన్ రేటు",
            conversionRate: "మారకపు ధర",
            visits: "సందర్శనలు",
            visitsToday: "ఈరోజు సందర్శిస్తారు",
            date: "తేదీ",
            product: "ఉత్పత్తి",
            orderNo: "ఆర్డర్ నం.",
            commissionPrice: "కమిషన్ ధర",
            status: "స్థితి",
        },
    },
};
export default tl;