import React, { useEffect, useState } from "react";
import AccountMenu from "./Dashboard/AccountMenu";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Fade, Popper } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AffiliateDashboard = () => {
  const data = JSON.parse(localStorage.getItem("UserData"));
  const [dashboardData, setDashboarddata] = useState();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [publicIP, setPublicIP] = useState('');
  const [orders, setOrders] = useState([])
  const { t } = useTranslation()

  const getDashboardData = () => {
    fetch(
      "https://admin.fashionleo.in/api/v2/user/affiliate-dashboard",
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiZWY3ZWE0OGM4MjdmNTYxNjFiZWM4MWNjYWFjOWQ0MjIyYmVmNjZiYWRhMWUwNWVlYTBhMjExNGRhMDEwOGFiYTEzNjk2OGMwYzYxMTU0NmQiLCJpYXQiOjE2ODQ1NTc5NzEuODg3MTg4OTExNDM3OTg4MjgxMjUsIm5iZiI6MTY4NDU1Nzk3MS44ODcxOTIwMTA4Nzk1MTY2MDE1NjI1LCJleHAiOjE3MTYxODAzNzEuODgxODE0MDAyOTkwNzIyNjU2MjUsInN1YiI6IjQxIiwic2NvcGVzIjpbXX0.buCe8m_Ms5hw2aFW-xve2qFt7A8rO65Lw-1Nf4Ivv7oDRnDqIUo4cebkhGbkGBuyFzWLpdapqqbNRCkvaWT66GxmKZIhAjn8sqNrDhbylMxYTIrJdgnuivCmqSyU2JUU8gFNn2eojatIp0dYx7wczEUcv_77VNpGmY-a82DVKWimDckI0g0Wwh-4EX86YCGhmpxQpEwUfbJBqFJMv4SVrRh3M_w_dOkxu-7aCUS0yVsMfRkdRwJehCWiVua5bY1Abkgqps7cyg9bhage3epN5sqUkzsOhf6sY9Kj8gCpNZshlUpGN6HPxdK9leoab36-xPzbnvsH2b6m7befU1gWXFxV5l8o10GOSo98F0SoYnsq9aJIG42kbBh27jBwEZKB3jsaX2Fszoufle4GIy3-KHUBWFEeU6lAXLOUMrAlvRALiY8b_STQaLuwYXwUi_Cql2iLEGH97omjRa4KzWk0KAfLA0KHqE679g7H139jtFBVmcAHSY-UNjneB2xD3Elak9KC6_z6X24ki7eQ4Cm7jte-EipXZ2XIkJicKXF_UB-_1a4kl5K3Sm2oiRntAdGNVoxYyyNfHyLD4H9wLXmJ0x9iF95kJPURnC3FXkI0IcNb5u3vNbE0hl8E6jHyutiJs4JQZ6OrLw_ULHOj-aUOX7-lTUaFYC23Ew_v2dq07ck",
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        
        setDashboarddata(res.data);
        setOrders(res.data.recent_orders)
      });
  };

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setPublicIP(data.ip);
      } catch (error) {
      }
    };

    fetchIP();
  }, []);

  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div className="">
      <div className="md:ml-32 p-5">
        <div className="text-3xl font-semibold text-black">{t("affiliatePages.affiliate")}</div>
      </div>
      <div className="md:w-2/3 w-11/12 mx-auto">
        <AccountMenu />
        <div className="py-5 md:flex mx-4 w-full">
          <div className="my-2 text-xl font-medium">{t("affiliateDashboard.urlTitle")}</div>
          <div>
            <div className="flex-col lg:flex-row  border-2 md:w-full w-11/12 mr-2 lg:ml-3 p-2 lg:justify-between border-gray-300 flex border-dotted">
              <div className="text-gray-500">{data?.affiliate_link}</div>
              <div
                onClick={(event) => {
                  navigator.clipboard
                    .writeText(data?.affiliate_link)
                    .then(() => {
                      setAnchorEl(event.currentTarget);
                    })
                    .catch((error) => {
                      console.error("Error copying text to clipboard:", error);
                    });
                  setAnchorEl(event.currentTarget);
                  setOpen((previousOpen) => !previousOpen);
                  setTimeout(() => {
                    setOpen((previousOpen) => !previousOpen);
                  }, 4000);
                }}
                className="lg:pt-0 pt-3"
              >
                <CopyAllIcon fontSize="small" />
                Copy Url
              </div>
            </div>
          </div>
          <Popper open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <div className="text-xs bg-darkBackground text-fontWhite p-2 rounded-xl">
                  URL Copied
                </div>
              </Fade>
            )}
          </Popper>
        </div>
      </div>
      <div className="md:w-2/3 w-11/12 mx-auto">
        <div className="md:flex md:justify-between md:gap-10 md:my-0 my-3">
          <div className="border border-darkBackground md:w-1/3 w-11/12 mx-auto rounded-md">
            <div className="block text-center p-7">
              <div>Total earnings</div>
              <div className="font-bold text-3xl">
                ₹ {dashboardData?.total_earning}
              </div>
            </div>
            <div className="border-b border-backgroundColor p-3 flex justify-between lg:px-8 px-5">
              <div>Total paid</div>
              <div>₹{dashboardData?.total_paid}</div>
            </div>
            <div className="border-b border-backgroundColor p-3 flex justify-between lg:px-8 px-5">
              <div>Total refunded</div>
              <div>₹ 0.00</div>
            </div>
            <div className="border-b  p-3 flex justify-between lg:px-8 px-5">
              <div>Balance</div>
              <div>₹ 0.00</div>
            </div>
          </div>
          <div className="border items-center border-darkBackground md:my-0 my-5 md:w-1/3 w-11/12 mx-auto rounded-md">
            <div className="border-b py-9 border-darkBackground block text-center p-7">
              <div>Commission Rate</div>
              <div className="font-bold text-3xl">
                {dashboardData?.commission_rate}%
              </div>
            </div>
            <div className="block text-center items-center p-7">
              <div>Conversion rate</div>
              <div className="font-bold text-3xl">0%</div>
            </div>
          </div>
          <div className="border items-center border-darkBackground md:my-0 my-5 md:w-1/3 w-11/12 mx-auto rounded-md">
            <div className="border-b py-9 border-darkBackground block text-center p-7">
              <div>Visits</div>
              <div className="font-bold text-3xl">
                {dashboardData?.total_link_visit}
              </div>
            </div>
            <div className="block text-center items-center p-7">
              <div>Visits Today</div>
              <div className="font-bold text-3xl">
                {dashboardData?.today_link_visit}
              </div>
            </div>
          </div>
        </div>
        <div className="text-3xl md:w-auto w-11/12 mx-auto py-5 font-semibold text-black">
          {t("affiliateDashboard.commissionHeading")}
        </div>
        <div>
          <div className="relative  md:w-auto w-11/12 mx-auto overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs border h-16 text-gray-700 bg-white bg-transparent py-5 uppercase  dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className=" ">
                    <div className="w-full text-center bg-blue-100 py-2 ">
                      Date
                    </div>
                  </th>
                  <th scope="col" className="">
                    <div className="w-full text-center bg-blue-100 py-2 ">
                      Product
                    </div>
                  </th>
                  <th scope="col" className="">
                    <div className="w-full text-center bg-blue-100 py-2 ">
                      Order No.
                    </div>
                  </th>
                  <th scope="col" className="">
                    <div className="w-full text-center bg-blue-100 py-2">Commission Price </div>
                  </th>
                  <th scope="col" className="">
                    <div className="w-full text-center bg-blue-100 py-2">Status </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((ele) => {
                  const { commission_total_price, created_at, order_number, status } = ele
                  return <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-3 border-r py-4 font-medium whitespace-nowrap dark:text-white"
                    >
                      {moment(created_at).format('ll')}
                    </th>
                    <td className="pl-6 border-r py-4">
                      { }
                    </td>
                    <td className="pl-6 border-r py-4">
                      {order_number}
                    </td>
                    <td className="px-4 border-l py-4">
                      <div className="flex justify-between">
                        <div className="my-auto">₹{commission_total_price}</div>
                      </div>
                    </td>
                    <td className="px-4 border-l py-4">
                      <div className="border-l-1 mx-2 px-8 py-2 text-fontWhite bg-red-600">
                        {status}
                      </div>
                    </td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>
        </div>
        <div className="text-3xl md:w-auto w-11/12 mx-auto py-5 font-semibold text-black">
          {t("affiliatePages.recenteVisit")}
        </div>
      </div>
    </div>
  );
};

export default AffiliateDashboard;
