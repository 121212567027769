import { Card, Typography } from "@material-ui/core";
import { Rating } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ApiService from "../services/apiService";

export default function VendorDetails() {
  const [sellers, setSellers] = useState();
  const params = useParams();
  
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const id = sessionStorage.getItem("user_id");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.type === "near-by-sellers") {
      // if type is near by seller then we will call near by seller api
      const _form = new FormData();
      _form.append("location_lat", "24.5871289");
      _form.append("location_long", "73.6969651");
      // _form.append("city", "udaipur");
      // _form.append("state", "rajasthan");
      ApiService.getSellers(_form).then((res) => {
        
        if (res.code === 200) {
          setSellers(res.data.data.vendors);
          setLoading(false);
        }
      });
    } else {
      // if type is not near by seller then we will call getFollowingDetails api
      ApiService.getFollowingDetails(id).then((res) => {
        if (res.code === 200) {
          setSellers(res.data.data.users);
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-background dark:bg-darkBackground">
        <Typography
          variant={"h5"}
          className="py-5 text-center uppercase dark:text-fontWhite dark:bg-lightDarkBackground bg-gray-200 border-b-2"
        >
          {params.type === "near-by-sellers"
            ? `${t("vendorDetails.vendorDetails")}`
            : `${t("vendorDetails.myFollowing")}`}
        </Typography>

        <div>{sellers?.length === 0 && <div className="text-center text-lg pb-8 pt-4">You are not following any store.</div>}</div>
        <div className="grid lg:grid-cols-5 gap-2 sm:grid-cols-4 lg:gap-4 grid-cols-1 mx-2 lg:mx-10 my-10 lg:px-10 py-10">
          {loading ? (
            <React.Fragment>
              {[1, 2, 3, 4, 5].map((ele, key) => (
                <Skeleton height={270} width={200} key={key} />
              ))}
            </React.Fragment>
          ) : (
            sellers?.map((ele, key) => {
              return (
                <div className="transform w-full h-72 transition duration-500 lg:ml-4 hover:scale-110 shadow-md my-6">
                  <Card
                    className="h-72 dark:bg-lightDarkBackground cursor-pointer"
                    onClick={() => {
                      history.push({
                        pathname: `/seller-store/${ele?.slug}/${ele?.id}`,
                        state: { id: ele?.id, type: "sellers" },
                      });
                    }}
                  >
                    <img
                      draggable={false}
                      alt="text"
                      // style={{objectFit:"cover"}}
                      className="h-56  -mb-0.5 m-auto w-full lg:p-0 justify-items-center hover:opacity-40 hover:text-black  "
                      src={ele?.profile}
                    />

                    <CardContent className="py-1 px-2">
                      <Typography
                        className="dark:text-fontWhite"
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >

                        {ele?.vendor?.business_name.split(" ").slice(0, 3).join(" ")}...
                      </Typography>

                      <Rating
                        className="-mx-1"
                        name="simple-controlled"
                        value={ele?.rating}
                      />
                    </CardContent>
                  </Card>
                </div>
                )
              }
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
