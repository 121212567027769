import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AppConfig from "../appConfig";
import FeaturedCollection from "../components/HomeSpecificComponents/featuredcollection/FeaturedCollection";
import ProvidingFeatures from "../components/HomeSpecificComponents/features/ProvidingFeatures";
import FlashSale from "../components/HomeSpecificComponents/FlashSale";
import OfferZone from "../components/HomeSpecificComponents/OfferZone";
import RecentProduct from "../components/HomeSpecificComponents/RecentProduct";
import SellersSlider from "../components/HomeSpecificComponents/SellersSlider/SellersSlider";
import TopProducts from "../components/HomeSpecificComponents/TopProducts";
import TrendingProducts from "../components/HomeSpecificComponents/TrendingProducts";
import Banner from "../core-components/Banner";
import Brand from "../core-components/Brand";
import HomeSlider from "../core-components/Slider";
import ApiService from "../services/apiService";
import { useSelector } from "react-redux";
import Categories from "../core-components/Categories";
import RecentViewProduct from "../core-components/RecentViewProduct";

export default function Home() {
  const user_id = window.sessionStorage.getItem("user_id");
  const [image, setImages] = useState([]);
  const session_id = sessionStorage.getItem("session_id");
  const [allData, setAllData] = useState();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState();
  const [bannerLoading, setBannerLoading] = useState(true);
  const [loading, setLoading] = useState(true)
  let trendingProductData = JSON.parse(sessionStorage.getItem("myData")) ?? []

  useEffect(() => {
    if (window.location.href.includes("ref_id")) {
      sessionStorage.setItem(AppConfig.localReferalKey, window.location.href.split('ref_id=')[1])

    }
  }, [])

  useEffect(() => {
    ApiService.getProfile().then((res) => {
      if (res.code === 200) {
        sessionStorage.setItem("wallet", res.data.data.user.wallet.balance)
      }
    });
  }, [])


  useEffect(() => {
    setViewWidth(window.innerWidth);
    if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
    else setMobile(true);
    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
      if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
      else setMobile(true);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
        if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
        else setMobile(true);
      });
    };
  }, []);

  const overwritecss = () => {
    const revieweElement = document.querySelectorAll('.css-iljtu2-MuiRating-root')
    const revieweElementArray = [...revieweElement]
    revieweElementArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
    const revieweElementOnLive = document.querySelectorAll('.css-1ipqyij')
    const revieweElementOnLiveArray = [...revieweElementOnLive]
    revieweElementOnLiveArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const _form = new FormData();

    _form.append(
      "session_id",
      user_id !== "null" && user_id !== null ? user_id : session_id
    );

    ApiService.getBannerList("home").then((res) => {
      if (res.code === 200) {
        setImages(res.data.data.data);
        setBannerLoading(false)
      }
    });
    // below api get the all homePageData
    ApiService.getBrandList().then((res) => {
      if (res.code === 200) {
        setLoading(false)
        setAllData(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    overwritecss()
  }, [allData])
  return (

    <React.Fragment>
      <Helmet>
        <meta name="description" content="ALl data" />
        <meta property="og:site_name" content="fashion-leo" />
        <meta property="og:title" content={`fashion-leo `} />

        <meta property="og:url" content={`fashion-leo `} />
        <meta property="og:image" content="" />
      </Helmet>
      <HomeSlider viewWidth={viewWidth} mobile={mobile} />

      {trendingProductData.length > 0 && <RecentViewProduct
        mobile={mobile}
      />}
      <Categories viewWidth={viewWidth} />
      <Banner layout={image[0]} mobile={mobile} loading={bannerLoading} />
      <Banner layout={image[1]} mobile={mobile} loading={bannerLoading} />
      {/* <Brand mobile={mobile} /> */}
      {/* <Brand brand={allData?.brand} mobile={mobile} /> */}
      <Banner layout={image[2]} mobile={mobile} loading={bannerLoading} />
      <Banner layout={image[3]} mobile={mobile} loading={bannerLoading} />
      {allData?.OfferZone.length > 0 && <OfferZone offerzone={allData?.OfferZone} />}

      {allData?.flashSale?.length > 0 && <FlashSale flashSaleData={allData?.flashSale[0]} viewWidth={viewWidth} />}
      {allData?.flashSale?.length > 1 && <FlashSale flashSaleData={allData?.flashSale[1]} viewWidth={viewWidth} />}
      {allData?.flashSale?.length > 2 && <FlashSale flashSaleData={allData?.flashSale[2]} viewWidth={viewWidth} />}
      {allData?.flashSale?.length >= 3 && allData.flashSale.map((item, key) => (
        <FlashSale flashSaleData={item} viewWidth={viewWidth} />
      ))}
      <FeaturedCollection
        featuredProductData={allData?.featured}
        mobile={mobile}
      />
      <Banner layout={image[4]} mobile={mobile} loading={bannerLoading} />
      <RecentProduct recentProductData={allData?.recentProduct} mobile={mobile} />
      <TopProducts topProductData={allData?.topdata} mobile={mobile} />
      <Banner layout={image[5]} mobile={mobile} loading={bannerLoading} />
      <TrendingProducts
        trendingProductData={allData?.trendingdata}
        mobile={mobile}
      />

      {/* /*Not Open*/}
      {/* <Banner layout={image[6]} mobile={mobile} />
      <Banner layout={image[7]} mobile={mobile} /> */}

      <SellersSlider mobile={mobile} />

      <Banner layout={image[8]} mobile={mobile} loading={bannerLoading} />
      <ProvidingFeatures loading={loading} />

      {/*  */}
    </React.Fragment>
    // :
    // <div className="text-center">
    //   <CircularProgress style={{
    //     width: '50px',
    //     height: '50px',
    //     margin: '100px',
    //     color: '#ddc697'
    //   }} />
    // </div>
  );
}
