import {
  AppBar,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appConfig from "../appConfig";
import { ActionTypes } from "../redux/contants/ActionType";
import store from "../redux/store";
import Apiservice from "../services/apiService";

import animationDataEmptyCart from "../assets/animations/EmptyCart.json";
import animationDataSadFace from "../assets/animations/SadFace.json";

export default function CartDetails(props) {
  const { t } = useTranslation();

  const history = useHistory();
  const cartCount = useSelector(({ allCategories }) => allCategories.cartCount);
  const [loading, setLoading] = useState(true);
  const defaultOptionsEmptyCart = {
    loop: true,
    autoplay: true,
    animationData: animationDataEmptyCart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsSadFace = {
    loop: true,
    autoplay: true,
    animationData: animationDataSadFace,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const id = sessionStorage.getItem("session_id");
  const user_id = sessionStorage.getItem("user_id");
  const [update, setUpdate] = useState(false);

  const [data, setData] = useState([]);
  const [currency_type, setCurrencyType] = useState();
  const [subTotal, setSubTotal] = useState(0);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  // delete item from cart
  const deleteRecord = (id) => {
    let deleteRecord = data.filter((item) => item.id !== id);
    setData(deleteRecord);
    Apiservice.deleteCartItem(id).then((res) => {
      if (res.code === 200) {
        setUpdate(!update);
        store.dispatch({
          type: ActionTypes.ADD_USER_CART_COUNT,
          payload: cartCount - 1,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const _form = new FormData();

    _form.append("session_id", id);
    if (user_id !== null || user_id !== "null") {
      _form.append("user_id", user_id);
    }
    // for getting cart detail there should always be either user id or session id or both
    if (
      (id !== "null" && id !== null) ||
      (user_id !== "null" && user_id !== null)
    ) {
      // function for getting CartDetails
      Apiservice.getCartDetails(_form).then((res) => {
        var totalprice = 0;
        var discount = 0;
        var totalOffertemp = 0;
        var totalOriginalTemp = 0;
        var subtotal = 0;
        if (res.code === 200) {
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: res.data.length,
          });
          if (res.data.length > 0) {
            setData(res.data);
            setCurrencyType(
              res.data[0].get_product_data?.currency_data?.symbol
            );
            res.data.map((row) => {
              // setting totalPrice by mapping all cart items and adding multipliction of product's  offer_price and quantity
              totalprice =
                totalprice + Number(row.offer_price) * Number(row.quantity);
              subtotal =
                Number(subtotal) +
                Number(row.orignal_price) * Number(row.quantity);
              totalOffertemp =
                Number(totalOffertemp) +
                Number(row.offer_price) * Number(row.quantity);
              totalOriginalTemp =
                Number(totalOriginalTemp) +
                Number(row.orignal_price) * Number(row.quantity);
              discount = Number(totalOriginalTemp) - Number(totalOffertemp);
              setDiscount(discount);
              setSubTotal(subtotal);
              setTotalCartPrice(totalprice);
            });

            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(false);
    }
  }, [update]);

  // function for incrementing particular  cart item  quantity
  const handleIncrement = (id) => {
    // this method is similar to handleDecrement method so you can check handleDecrement function for understanding below logic
    let quantity = 1;
    let totalQty;
    setData((data) =>
      data.map((item) =>
        // if id of the product on which have tried to inncrease quantity matches to item.id then we have to increase the quantity of that particular object
        id === item.id
          ? parseInt(item.quantity) <= parseInt(item.get_product_data.qty)
            ? {
              ...item,
              quantity:
                parseInt(item.quantity) < 10 &&
                  parseInt(item.quantity) < parseInt(item.get_product_data.qty)
                  ? parseInt(item.quantity) + 1
                  : item.quantity,
            }
            : setOpenModal(false)
          : { ...item }
      )
    );
    data.map((item) => {
      if (id == item.id) {
        quantity = parseInt(item.quantity) + 1;
        totalQty = parseInt(item.get_product_data.qty);
      }
    });
    if (quantity <= 10 && quantity <= totalQty) {
      const _form = new FormData();
      _form.append("id", id);
      _form.append("quantity", quantity);
      Apiservice.updateCartItem(_form).then((res) => {
        if (res.code == 200) {
          setUpdate(!update);
        }
      });
    } else {
      setOpenModal(true);
    }
  };

  const [openModal, setOpenModal] = useState(false);

  // function for decrementing particular  cart item  quantity
  const handleDecrement = (id) => {
    // we will map our data array which contains our cart data
    let quantity;
    setData((data) =>
      data.map((item) =>
        // if id of the product on which have tried to inncrease quantity matches to item.id then we have to decrease the quantity of that particular object
        id === item.id
          ? {
            ...item,
            // if quantity is already 1 then we will minus 0 from current quantity else we will subtract 1
            // now we have updated our state on front end level
            quantity: parseInt(item.quantity) - (item.quantity > 1 ? 1 : 0),
          }
          : { ...item }
      )
    );
    // Now we have update the quantity of product to backend so for that we will quantity variable
    data.map((item) => {
      if (item.id == id) {
        // if quantity is already 1 then we will minus 0 from current quantity else we will subtract 1
        quantity = parseInt(item.quantity) - (item.quantity >= 1 ? 1 : 0);
      }
    });
    if (quantity >= 1) {
      // Now quantity is 1 or greater than 1 then only decrement api will get called
      const _form = new FormData();
      _form.append("id", id);
      _form.append("quantity", quantity);
      Apiservice.updateCartItem(_form).then((res) => {
        if (res.code == 200) {
          setUpdate(!update);
        }
      });
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  // function for navigating user to checkout page
  const handleCheckout = () => {
    if (user_id === null || user_id === "null") {
      // if user is not loggged in pushing it to login component
      history.push({
        pathname: `/login`,
        state: {
          navigationUrl: window.location.href,
        },
      });
    } else {
      history.push(appConfig.routes.checkout);
    }
  };

  return (
    <React.Fragment>
      {/* pop up will get shown user tries to add quantity more than of a particular product  */}
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
      >
        <AppBar
          position="static"
          className="bg-primary text-white"
          elevation={1}
        ></AppBar>
        <DialogContent
          classes={{ root: "p-20" }}
          className="bg-login dark:bg-lightDarkBackground"
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            className="p-4"
          >
            <Typography
              variant={"body1"}
              component={"span"}
              className="text-fontDark dark:text-login"
            >
              {t("cartDetails.addOnlyTenProducts")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="bg-login dark:bg-lightDarkBackground">
          <Button
            className="normal-case whitespace-no-wrap  text-white   bg-button "
            variant="contained"
            onClick={handleModalClose}
            type="button"
          >
            {t("cartDetails.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      {typeof props.overview !== "undefined" && props.overview ? (
        <div className="w-full h-full flex flex-col ">
          <>
            {loading && <Skeleton variant="rectangular" height={70} />}
            {!loading && data && data.length == 0 && (
              <div className=" mt-8 text-lg text-button dark:text-fontWhite font-bold self-center">
                {t("cartDetails.nocartitem")}
              </div>
            )}
            {!loading && data && data.length > 0 && (
              <div className="w-full overflow-y-scroll h-72 demoImages">
                {data.map((row) => {
                  return (
                    <div className="grid bg-whiteBackground dark:bg-lightDarkBackground grid-cols-3 w-full my-4 relative rounded-xl shadow-xl mx-0 p-2">
                      <img
                        className="col-span-1 w-24 h-24 object-contain"
                        src={row.get_product_data?.ImageSrc}
                        alt={row.get_product_data?.product_name}
                      />
                      <CloseIcon
                        className="absolute text-primary top-2 p-0.5 right-2 cursor-pointer"
                        onClick={() => deleteRecord(row.id)}
                      />
                      <div className="col-span-2 flex gap-2 flex-col">
                        <span
                          onClick={() => {
                            history.push({
                              pathname: `/product-details/${row.get_product_data?.slug}/${row.product_id}`,
                              state: {
                                id: row.product_id,
                                type: "Cart Details",
                              },
                            });
                          }}
                          className="text-xs tracking-widest mr-8 text-justify hover:underline cursor-pointer text-fontDark dark:text-fontWhite self-start uppercase"
                        >
                          {row.get_product_data?.product_name.length > 40 ?
                            `${row.get_product_data?.product_name.slice(0, 40)}...` : row.get_product_data?.product_name}
                        </span>
                        <div className="flex">
                          {/* {row?.product_option?.length > 0 && JSON.parse(row?.product_option)?.map(
                            (key, value) => (
                              <div className="text-xs text-fontDark dark:text-fontWhite  font-bold mr-2 ">
                                {key?.attr_type}  : {key.value}
                              </div>
                            )
                          )} */}
                        </div>
                        <div className="flex items-center text-xs gap-4">
                          <div className="text-fontDark dark:text-fontWhite font-bold">
                            {t("cartDetails.total")}
                          </div>

                          <div className=" font-bold bg-gray-700 text-login py-0.5 px-1.5 rounded">
                            {row.get_product_data.currency_data.symbol}{" "}
                            {(row.offer_price * row.quantity).toFixed(2)}
                          </div>
                        </div>
                        <div className="flex w-full">
                          <button
                            className=" bg-button text-xs text-login w-6 h-6 font-bold rounded"
                            onClick={() => handleDecrement(row.id)}
                          >
                            -
                          </button>
                          <span className="mx-2 text-sm text-fontDark dark:text-fontWhite">
                            {" "}
                            {row.quantity}{" "}
                          </span>
                          <button
                            className=" text-xs bg-button text-login font-bold w-6 h-6 rounded"
                            onClick={() => handleIncrement(row.id)}
                          >
                            {" "}
                            +{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {data && data.length > 0 && (
              <div
                onClick={() => handleCheckout()}
                className=" h-8 mt-2 flex justify-center items-center font-bold text-sm w-1/3 text-center cursor-pointer  rounded-lg self-center bg-button text-login"
              >
                {" "}
                {t("cartDetails.checkout")}
              </div>
            )}
          </>
        </div>
      ) : (
        <div className="sm:px-4 min-h-screen lg:px-10 dark:bg-darkBackground ">
          <Typography
            variant={"h5"}
            className="py-5 text-fontDark dark:text-fontWhite text-center uppercase dark:bg-lightDarkBackground bg-gray-200 border-b-2"
          >
            {t("cartDetails.yourCart")}
          </Typography>
          {data.length == 0 && loading == false ? (
            <>
              <div className="block w-full py-16 justify-center">
                <Lottie
                  options={defaultOptionsEmptyCart}
                  height={200}
                  width={200}
                />
                <div className="d-flex justify-content-center my-10">
                  <div>
                    {" "}
                    <Typography
                      variant={"body1"}
                      component={"span"}
                      className="text-2xl   text-gray-400 font-bold"
                    >
                      {t("cartDetails.cartEmpty")}
                    </Typography>
                  </div>
                  <div className="-mt-3">
                    <Lottie
                      options={defaultOptionsSadFace}
                      height={50}
                      width={50}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-3 m-0 py-4">
              {data.length > 0 && (
                <div className="grid grid-cols-1 gap-2 md:gap-6 col-span-3 sm:col-span-2 m-0 sm:mr-8 lg:mr-0 px-2">
                  {data.map((row) => {
                    return (
                      <div className="w-full dark:bg-lightDarkBackground  border-2 border-gray-300 grid m-0 grid-cols-3 relative rounded-2xl shadow-md md:shadow-2xl shadow-black p-4">
                        <div className="col-span-1 h-48 lg:px-10 flex flex-col ">
                          <img
                            alt="text"
                            className="object-contain w-full h-full col-span-1 "
                            src={row?.get_product_data.ImageSrc}
                          />
                        </div>
                        <div className="col-span-2 pl-3 md:px-4">
                          {/* <div className="text-lg font-bold">{row.get_product_data.brand}</div> */}
                          <div
                            className="w-full pr-6 text-sm md:text-base font-semibold md:font-bold cursor-pointer"
                            onClick={() => {
                              history.push({
                                pathname: `/product-details/${row.get_product_data.slug}/${row.product_id}`,
                                state: {
                                  id: row.product_id,
                                  type: "Cart Details",
                                },
                              });
                            }}
                          >
                            {row?.get_product_data?.product_name}
                            <div className="flex my-1">
                              {
                                JSON.parse(row?.product_option) !== null &&
                                JSON.parse(row?.product_option).map((key, value) => (
                                  <div className="my-1 flex-1 ">
                                    <Typography
                                      variant="body2"
                                      className="text-md dark:text-fontWhite font-bold "
                                      key={value}
                                    >
                                      {key?.attr_type}  : {key?.value}
                                    </Typography>
                                  </div>
                                ))}
                            </div>
                          </div>
                          {/* <div>{row.get_product_data.short_desc.length > 70 ? row.get_product_data.short_desc.slice(0, 70) : row.get_product_data.short_desc}</div> */}
                          <div className="grid md:grid-cols-2 mx-0 md:gap-4 mt-1 md:mt-4 w-full">
                            <div className="hidden md:block flex flex-col">
                              <div className="text-gray-700 dark:text-fontWhite font-bold">
                                {t("cartDetails.price")}
                              </div>
                              <div className="dark:text-fontWhite">
                                {row.get_product_data.currency_data.symbol}{" "}
                                {Number(row.offer_price).toFixed(2)}
                              </div>
                            </div>
                            <div className="hidden md:block flex flex-col">
                              <div className="text-gray-700 dark:text-fontWhite font-bold">
                                {t("review.originalPrice")}
                              </div>
                              <div className="dark:text-fontWhite">
                                {row.get_product_data.currency_data.symbol}{" "}
                                {Number(row.orignal_price).toFixed(2)}
                              </div>
                            </div>
                            <div className="block md:hidden flex flex-col">
                              <div className="text-gray-700 dark:text-fontWhite font-bold">
                                {t("cartDetails.price")}
                              </div>
                              <div className="flex">
                                <div className="dark:text-fontWhite">
                                  {row.get_product_data.currency_data.symbol}{" "}
                                  {Number(row.offer_price).toFixed(2)}
                                </div>
                                <div className="dark:text-fontWhite line-through pl-2">
                                  {row.get_product_data.currency_data.symbol}{" "}
                                  {Number(row.orignal_price).toFixed(2)}
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-between md:flex-col">
                              <div className="flex flex-col pt-1 md:pt-0">
                                <div className="text-gray-700 dark:text-fontWhite font-bold">
                                  {t("cartDetails.total")}
                                </div>

                                <div className="dark:text-fontWhite">
                                  {row.get_product_data.currency_data.symbol}{" "}
                                  {(row.offer_price * row.quantity).toFixed(2)}
                                </div>
                              </div>

                              <div className="flex mt-2 items-center">
                                <button
                                  // variant="outlined"
                                  className={`font-semibold border-1 border-gray-400 py-0 rounded-sm bg-gray-300 px-2 md:px-[6px] m-0.5 min-w-0 min-h-0`}
                                  onClick={() => handleDecrement(row.id)}
                                >
                                  -
                                </button>
                                <span className="mx-2 dark:text-fontWhite">
                                  {" "}
                                  {row.quantity}{" "}
                                </span>
                                <button
                                  // variant="outlined"
                                  className={`font-semibold border-1 border-gray-400 rounded-sm py-0 md:px-[6px] px-2 bg-gray-300 min-w-0`}
                                  onClick={() => handleIncrement(row.id)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>

                          <div>
                            <CloseIcon
                              className="absolute top-4 p-0.5 right-4 cursor-pointer dark:text-login"
                              onClick={() => deleteRecord(row.id)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <Card className="grid col-span-3 dark:bg-lightDarkBackground sm:sticky sm:top-28 lg:top-36 lg:mb-8 sm:col-span-1 grid-flow-row px-2 md:h-64 rounded-2xl md:py-4 pb-6 pt-6  mx-3 sm:mx-0 lg:mx-3  border lg:px-6">
                <div className="grid grid-cols-2 mb-1 md:mb-3 mx-2">
                  <div className="text-left">
                    {loading ? (
                      <Skeleton height={30} width={150} className="" />
                    ) : (
                      <Typography
                        variant={"span"}
                        className="text-sm font-bold dark:text-fontWhite text-left uppercase"
                      >
                        {t("cartDetails.subtotal")}
                      </Typography>
                    )}
                  </div>
                  <div className="text-sm  dark:text-fontWhite font-bold text-right">
                    {loading ? (
                      <Skeleton height={30} width={50} className="" />
                    ) : (
                      `${currency_type} ${subTotal.toFixed(2)}`
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 md:mb-3 mb-1 mx-2 ">
                  <div className="text-left">
                    {loading ? (
                      <Skeleton height={30} width={150} className="" />
                    ) : (
                      <Typography
                        variant={"span"}
                        className="text-sm dark:text-fontWhite font-bold text-left uppercase"
                      >
                        {t("cartDetails.discount")}
                      </Typography>
                    )}
                  </div>
                  <div className="text-sm dark:text-fontWhite font-bold text-right">
                    {loading ? (
                      <Skeleton height={30} width={50} className="" />
                    ) : (
                      `${currency_type} ${discount.toFixed(2)}`
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 mb-3 mx-2">
                  <div className="text-left">
                    {loading ? (
                      <Skeleton height={30} width={150} className="" />
                    ) : (
                      <Typography
                        variant={"span"}
                        className="text-sm dark:text-fontWhite font-bold text-left uppercase"
                      >
                        {t("cartDetails.total")}
                      </Typography>
                    )}
                  </div>
                  <div className="text-sm dark:text-fontWhite font-bold text-right">
                    {loading ? (
                      <Skeleton height={30} width={50} className="" />
                    ) : (
                      `${currency_type} ${totalCartPrice}`
                    )}
                  </div>
                </div>

                <div className="mt-3 md:mt-5">
                  {loading ? (
                    <Skeleton height={50} className="" />
                  ) : (
                    <Button
                      disabled={data.length == 0}
                      className="w-full h-12 bg-button text-white"
                      variant="contained"
                      onClick={() => handleCheckout()}
                    >
                      {t("cartDetails.checkout")}
                    </Button>
                  )}
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
