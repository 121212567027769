import {
    Avatar,
    Button,
    Card,
    CardContent,
    createTheme,
    makeStyles,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import Formsy from "formsy-react";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SelectFormsy from "../../core-components/SelectFormsy";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import ApiService from "../../services/apiService";
import logo from '../../assets/images/FashionLeoImages/fashioLeoLogo.png'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import userSignUp from '../../assets/images/userSignUp.png'

function SignUpSeller() {
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation();

    const [errorsSignUp, setErrorsSignUp] = useState(null);
    const [refferCodeMsg, setRefferCodeMsg] = useState('')
    const [formatedDialingCode, setFormatedDialingCode] = useState([]);
    const [errorsLogin, setErrorsLogin] = useState(null);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(null);
    const [show, setShow] = useState(false);
    const [TermsConditionAccepted, setTermsConditionAccepted] = useState(false);
    const [termsError, setTermsError] = useState(null);
    const [changeStatus, setChangeStatus] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [forgotpasswordError, setForgotPasswordError] = useState(null);
    const [forgotPasswordState, setForgotPassword] = useState(false);
    const [images, setImages] = useState([])
    const [coverImages, setCoverImages] = useState([])
    const [blobCoverImage, setBlobCoverImage] = useState([])
    const [blobImage, setBlobImage] = useState([])
    const [otpShow, setOtpShow] = useState()
    const [pincodeValidationMsg, setPincodeValidationMsg] = useState()
    const [pincodeStatus, setPincodeStatus] = useState(false)
    const [stateName, setStateName] = useState([])

    const [form, setForm] = useState({
        name: "",
        email: "",
        city: "",
        state: "",
        country: "",
        address: "",
        phone: "",
        dialing_code: "+91",
        code: "",
        password: "",
        password_confirmation: "",
        state_code: "",
        pin: "",
        currency: "",
        // referral_code: ""
        otp: "",
    });

    const AddressValidationApi = () => {
        let _form = new FormData();
        _form.append("pincode", form.pin ? form.pin : "")
        _form.append('vendor_id', '1')

        ApiService.checkCourierService(_form).then((res) => {
            if (res.code !== 422) {
                if (res.data.pricing !== null) {
                    if (res.data.pricing.status === 200) {
                        setPincodeValidationMsg('')
                        handleSignUp()
                        setPincodeStatus(true)
                    }
                    else if (res.data.pricing.status === 404) {
                        handleSignUp()
                        setPincodeStatus(false)
                        setPincodeValidationMsg('delivery is not available');
                    }
                    else {
                        setPincodeStatus(false)
                        setPincodeValidationMsg('pincode error');
                    }
                } else {
                    setPincodeStatus(false)
                    setPincodeValidationMsg('delivery is not available');
                }
            } else {
                setPincodeValidationMsg('pincode is required');
                handleSignUp()
            }
        });
    }

    const handleSignUp = () => {

        let _form = new FormData();

        let temp;
        formatedDialingCode.map((item, key) => {
            if (item.id === form.dialing_code) {
                temp = item.code;
            }
        });

        if (form.phone !== "") {
            var type = new AsYouType(temp);
            type.input(form.phone);
            _form.append("phone", type.getChars());
            setPhoneError({ phone: '' });
        } else if (form.phone !== "") {
            setPhoneError({
                // ...errorsSignUp,
                phone: t("login.phoneError")
            });
        }

        _form.append("cover_image", coverImages ? coverImages : '');
        _form.append("profileImage", images ? images : '');
        _form.append("currency", "INR");
        _form.append("owner_name", form.name);
        // _form.append("state_code", form.state_code);
        _form.append("pin", form.pin);
        _form.append("country_code", "+91");
        // _form.append("name", form.name);
        _form.append("username", form.name);
        _form.append("name", form.name);
        _form.append(
            "dialing_code", form.dialing_code
        );
        _form.append("password", form.password);
        _form.append("password_confirmation", form.password_confirmation);
        _form.append("email", form.email);
        _form.append("city", form.city);
        _form.append("state", form.state);
        // _form.append("country", form.country);
        _form.append("address", form.address);
        if (form.otp) {
            _form.append("otp", form.otp);
            ApiService.verifyOtp(_form).then((res) => {
                if (res.code === 200) {
                    setHideButton(false);
                    window.open('https://admin.fashionleo.in')
                    // window.open('https://fashion-leo.servepratham.com')
                } else if (res.code === 422) {
                    setHideButton(false);
                    setErrorsSignUp(res.errors);
                }
            });
        } else {
            ApiService.sellerSignUp(_form).then((res) => {
                if (res.code === 200) {
                    setHideButton(false);
                    setShow(true);
                    changeStatus(true)
                } else if (res.code === 422) {
                    setHideButton(false);
                    setErrorsSignUp(res.errors);
                    // store.dispatch(
                    //     showMessage({ message: response.message, variant: "error" })
                    // );

                }
            }).catch((er) => {
                setErrorsSignUp(er.errors);
            })

        }



        if (TermsConditionAccepted === false) {
            let error = { terms: "" };
            if (TermsConditionAccepted === false) {
                error.terms = t("login.termsError");
            }
            setTermsError(error);
        }
    };


    const onStateChange = (name, data) => {
        setForm({ ...form, ["state"]: data });
    }


    React.useEffect(() => {
        ApiService.getState().then((res) => {
            setStateName(res)
        });
    }, [])


    const handleChange = (e) => {
        var phoneNumber = parsePhoneNumber(e.target.value, form.dialing_code);

        let temp;
        formatedDialingCode.map((item, key) => {
            if (item.id === form.dialing_code) {
                temp = item.code;
            }
        });
        setHideButton(false);
        let phoneNew = e.target.value;
        if (e.target.name === "phone") {
            if (
                form.dialing_code === "" ||
                form.dialing_code === null ||
                typeof form.dialing_code === "null"
            ) {
                if (forgotPasswordState) {
                    setForgotPasswordError({
                        ...forgotpasswordError,
                        ["dialing_code"]: t("login.codeError"),
                    });
                } else {
                    setErrorsLogin({
                        ...errorsLogin,
                        ["dialing_code"]: t("login.codeError"),
                    });
                }
            }
            else {
                let value = phoneNew.toString();
                let final = "";
                if (value.includes("(") && !value.includes(")")) {
                    phoneNew = value.replace("(", "");
                    var type = new AsYouType(temp);
                    type.input(phoneNew);

                    final = type.getChars();
                }

                if (final === "") {
                    var number = new AsYouType(temp).input(phoneNew);
                    setPhone(number);
                    setForm({ ...form, [e.target.name]: phoneNew });
                } else {
                    setPhone(final);
                    setForm({ ...form, [e.target.name]: final });
                }
            }
        } else {
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    };

    const onPropsReceiveChange = (name, data) => {
        setForm({ ...form, [name]: data });
        setErrorsLogin({ ["dialing_code"]: "" });
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            // color: theme.palette.primary.contrastText,
            zIndex: "-1",
        },
        leftSection: {},
        rightSection: {
            // background: `linear-gradient(to right, ${theme.palette.primary.dark
            //   } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
            color: theme.palette.primary.contrastText,
        },
        button: {
            background: "#009acc",
            padding: "15px 45px",
            textAlign: "center",
            textTransform: "uppercase",
            transition: "0.5s",
            // color: AppConfig.color.button,
            borderRadius: "8px",
            display: "block",
            // boxShadow: "0 0 5px 0 #597593 inset",
            // borderColor: "#597593",
            "&:hover": {
                background: "#009acc",
            },
        },
        // backImage: {
        //   backgroundImage: `url(${image})`,
        //   backgroundSize: "cover",
        // },
    }));
    const classes = useStyles();

    const resendOtp = () => {
        console.log("resendOtp");
    }

    const coverImageFileHandleChange = (e) => {
        setCoverImages([...images, e.target.files[0]])
        setBlobCoverImage([...blobCoverImage, URL.createObjectURL(e.target.files[0])])
    }

    const profileImageFileHandleChange = (e) => {
        setImages([...images, e.target.files[0]])
        setBlobImage([...blobImage, URL.createObjectURL(e.target.files[0])])
    }

    return (
        <div
            className={clsx(
                classes.root,
                "flex mt-5  flex-auto pb-1 items-center justify-center bg-contain bg-left-top bg-no-repeat   dark:bg-darkBackground "
            )}
        >
            <div
                className={`flex ${show ? "my-24 mx-0" : "m-0"
                    }	w-full mb-6 lg:mt-0 mt-6 sm:w-8/12 md:w-6/12 border-color: rgb(180 83 9);  items-center bg-white  rounded-2xl  bg-cyan-500 shadow-lg shadow-cyan-500/50 justify-center overflow-hidden loginCard
            `}
            >
                <Card
                    className="w-full bg-contain bg-right-center bg-no-repeat justify-center items-center"
                    style={{ backgroundColor: "gray !important", color: "black !important" }}
                >
                    <CardContent className="lg:grid block px-10 lg:px-0 items-center justify-center ">
                        <React.Fragment>
                            <div className="flex items-center mt-4 ">
                                <img
                                    className="logo-icon mr-auto ml-auto w-22 h-28 sm:h-28 "
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                            <div className="text-center my-3">
                                <Typography variant="h6">
                                    {t("login.signUp")}
                                </Typography>
                            </div>
                            <Formsy
                                autoComplete="off"
                                className=" items-center justify-center  gap-2"
                            >
                                <div className=" items-center justify-center">
                                    <div className="py-4 lg:grid lg:grid-cols-1 block justify-between   ">
                                        <div className="dialDark lg:grid block lg:grid-cols-2 gap-5 justify-center lg:px-10">

                                            <div className="flex justify-center items-center flex-col pb-5 lg:pb-0">
                                                {blobImage.length > 0 ?
                                                    <div className="relative">
                                                        <img src={blobImage} alt="" className="h-24 w-24 rounded-full" />
                                                        <div className="absolute top-0 md:top-3 -right-2" onClick={() => setBlobImage([])}>
                                                            <CloseIcon />
                                                        </div>
                                                    </div>
                                                    :
                                                    <label htmlFor="profile-button-file" >
                                                        <img src={userSignUp} alt="profile" className="h-16 w-16" />
                                                    </label>}
                                                <span className="text-red-600">{errorsSignUp?.profileImage && errorsSignUp?.profileImage}</span>
                                            </div>
                                            <div className="border flex justify-center items-center cursor-pointer py-4 bg-gray-200 rounded-md">
                                                {blobCoverImage.length > 0 ?
                                                    <div className="relative">
                                                        <img src={blobCoverImage} alt="" className="h-24 w-full" />
                                                        <div className="absolute top-0 right-0" onClick={() => { setBlobCoverImage([]) }}>
                                                            <CloseIcon />
                                                        </div>
                                                    </div>
                                                    :
                                                    <label htmlFor="cover-button-file">
                                                        <div className="flex items-center">
                                                            <span className="pr-2">select cover Image</span>
                                                            <AddIcon />
                                                        </div>
                                                    </label>
                                                }
                                                <span className="text-red-600">{errorsSignUp?.cover_image && errorsSignUp?.cover_image}</span>
                                            </div>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="cover-button-file"
                                                name="cover_image"
                                                onChange={(e) => coverImageFileHandleChange(e)}
                                            />

                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="profile-button-file"
                                                onChange={(e) => profileImageFileHandleChange(e)}
                                            />

                                            <TextFieldFormsy
                                                type="text"
                                                name="name"
                                                className="w-100 border-0 my-2"
                                                label={t("login.name")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.name}
                                                serverError={errorsSignUp?.name}
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            />
                                            <TextFieldFormsy
                                                type="email"
                                                className="w-100 border-0 my-2"
                                                name="email"
                                                label={t("login.email")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.email}
                                                serverError={
                                                    errorsSignUp &&
                                                    errorsSignUp.email &&
                                                    errorsSignUp?.email[0]
                                                }
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            />

                                            <TextFieldFormsy
                                                type="text"
                                                name="city"
                                                className="w-100 border-0 my-2"
                                                label={t("login.city")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.city}
                                                serverError={errorsSignUp?.city}
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            />
                                            <Formsy>
                                                {stateName.length > 0 && (
                                                    <SelectFormsy
                                                        onChange={onStateChange}
                                                        className='w-100 border-0 my-2'
                                                        name="state"
                                                        label={t("myProfile.state")}
                                                        variant="filled"
                                                        value={form.state}
                                                        validationError={errorsLogin?.state}
                                                    >
                                                        {stateName.map((item, index) => (
                                                            <MenuItem
                                                                value={item.name}
                                                                key={index}
                                                                className="-my-2"
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </SelectFormsy>
                                                )}
                                            </Formsy>
                                            {/* <TextFieldFormsy
                                                type="text"
                                                name="country"
                                                className="w-100 border-0 my-2"
                                                label={t("login.country")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.country}
                                                serverError={errorsSignUp?.country}
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            /> */}
                                            <TextFieldFormsy
                                                type="text"
                                                name="address"
                                                className="w-100 border-0 my-2"
                                                label={t("login.address")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.address}
                                                serverError={errorsSignUp?.address}
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            />
                                            <div>
                                                <TextFieldFormsy
                                                    type="text"
                                                    name="pin"
                                                    className="w-100 border-0 my-2"
                                                    label={t("login.pin")}
                                                    onChange={(e) => handleChange(e)}
                                                    value={form.pin}
                                                    // serverError={errorsSignUp?.pin}
                                                    validations={{
                                                        minLength: 20,
                                                    }}
                                                    variant="filled"
                                                />
                                                <Typography
                                                    className={pincodeStatus ? "text-green-700 font-semibold text-xs" : 'text-red-600 font-medium text-xs pl-3'}
                                                >
                                                    {pincodeValidationMsg}
                                                </Typography>
                                            </div>

                                            {/* <TextFieldFormsy
                                                type="text"
                                                name="state_code"
                                                className="w-100 border-0 my-2"
                                                label={t("login.state_code")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.state_code}
                                                serverError={errorsSignUp?.state_code}
                                                validations={{
                                                    minLength: 20,
                                                }}
                                                variant="filled"
                                            /> */}

                                            {/* <div>
                                        <TextFieldFormsy
                                            type="text"
                                            name="referral_code"
                                            className="w-100 border-0 my-2"
                                            label={t("referral code")}
                                            onChange={(e) => handleChange(e)}
                                            value={form.referral_code}
                                            serverError={refferCodeMsg?.name}
                                            validations={{
                                                minLength: 20,
                                            }}
                                            variant="filled"
                                        />
                                        {refferCodeMsg === 'false' &&
                                            <div className="text-red-500">Referral code is Invalid</div>
                                        }
                                    </div> */}

                                            {formatedDialingCode.length > 0 && (
                                                <>
                                                    <SelectFormsy
                                                        onChange={onPropsReceiveChange}
                                                        className="w-100 my-2 "
                                                        name="dialing_code"
                                                        labelId="demo-simple-select-error-label"
                                                        id="demo-simple-select-outlined"
                                                        label={t("myProfile.dialing_code")}
                                                        variant="filled"
                                                        value={form.dialing_code}
                                                        validationError={
                                                            errorsSignUp?.dialing_code
                                                        }
                                                    >
                                                        {formatedDialingCode.map(
                                                            (item, index) => (
                                                                <MenuItem
                                                                    className=" -my-2"
                                                                    value={item.id}
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </SelectFormsy>
                                                </>
                                            )}
                                            <TextFieldFormsy
                                                className="w-100 my-2"
                                                type="text"
                                                // id="filled-basic"
                                                name="phone"
                                                label={t("login.enterPhone")}
                                                onChange={(e) => handleChange(e)}
                                                value={phone !== "" ? phone : form.phone}
                                                serverError={!phoneError ? errorsSignUp?.phone : phoneError?.phone}
                                                validations={{
                                                    minLength: 4,
                                                }}
                                                variant="filled"
                                            />
                                            <TextFieldFormsy
                                                type="password"
                                                className="w-100 border-0 my-2"
                                                name="password"
                                                label={t("login.password")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.password}
                                                serverError={errorsSignUp?.password}
                                                variant="filled"
                                            />
                                            <TextFieldFormsy
                                                type="password"
                                                className="w-100 border-0 my-2"
                                                name="password_confirmation"
                                                label={t("login.password_confirmation")}
                                                onChange={(e) => handleChange(e)}
                                                value={form.password_confirmation}
                                                serverError={errorsSignUp?.password_confirmation}
                                                variant="filled"
                                            />
                                            {show && (
                                                <TextFieldFormsy
                                                    className="w-100 my-2"
                                                    type="password"
                                                    name="otp"
                                                    label={t("login.enterTheOtp")}
                                                    value={form.otp}
                                                    onChange={(e) => handleChange(e)}
                                                    serverError={errorsSignUp?.otp}
                                                    validations={{
                                                        minLength: 4,
                                                    }}
                                                    validationErrors={{
                                                        minLength: "Min character length is 4",
                                                    }}
                                                    variant="filled"
                                                    inputProps={{
                                                        className: classes.input,
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className=" lg:grid block lg:grid-cols-1 justify-between  lg:px-10 ">
                                            {show && (
                                                <Typography
                                                    variant={"body2"}
                                                    color="inherit"
                                                    className=""
                                                >
                                                    <Typography
                                                        className="cursor-pointer text-black"
                                                        onClick={() => resendOtp()}
                                                    >
                                                        {t("login.resendOtp")}
                                                    </Typography>
                                                </Typography>
                                            )}

                                            <FormControlLabel
                                                className="mt-2 dark:text-darkBackground text-button cursor-pointer"
                                                control={
                                                    <Checkbox
                                                        value="allowExtraEmails"
                                                        color="primary"
                                                        onChange={() => {
                                                            setTermsConditionAccepted(
                                                                !TermsConditionAccepted
                                                            );
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        className="my-2  text-black cursor-pointer"
                                                        onClick={() =>
                                                            // history.push({
                                                            //   pathname: `/cms/terms-conditions`,
                                                            //   state: { type: "terms-conditions" },
                                                            // })
                                                            window.open('/cms/terms-conditions')
                                                        }
                                                    >
                                                        {t("login.termCondition")}
                                                    </Typography>
                                                }
                                            />
                                            {termsError?.terms && (
                                                <Typography className="cursor-pointer  text-xs mb-1 text-danger">
                                                    {termsError?.terms}
                                                </Typography>
                                            )}

                                            {!changeStatus ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={clsx(
                                                        classes.button,
                                                        "w-100 normal-case m-0"
                                                    )}
                                                    aria-label="LOG IN"
                                                    disabled={!TermsConditionAccepted}
                                                    onClick={() => {
                                                        AddressValidationApi();
                                                    }}
                                                    value="legacy"
                                                >
                                                    {t("login.signUpButton")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    // disabled={
                                                    //   form.otp == "" ||
                                                    //   !TermsConditionAccepted ||
                                                    //   hideButton
                                                    // }
                                                    variant="contained"
                                                    color="primary"
                                                    className={clsx(
                                                        classes.button,
                                                        "w-100 normal-case m-0"
                                                    )}
                                                    aria-label="LOG IN"
                                                    onClick={() => {
                                                        handleSignUp();
                                                        setHideButton(true);
                                                    }}
                                                    value="legacy"
                                                >
                                                    {t("login.verify")}
                                                </Button>
                                            )}
                                            <Typography
                                                onClick={() => {
                                                    setForm({
                                                        name: "",
                                                        email: "",
                                                        city: "",
                                                        state: "",
                                                        // country: "",
                                                        address: "",
                                                        phone: "",
                                                        dialing_code: "+91",
                                                        code: "",
                                                        password: "",
                                                        password_confirmation: "",
                                                        // state_code: "",
                                                        pin: "",
                                                        currency: "",
                                                    });
                                                    setPhone("");
                                                    setShow(false);
                                                    setErrorsLogin({ ["dialing_code"]: "" });
                                                    setHideButton(false);
                                                    window.open('https://admin.fashionleo.in')
                                                    // window.open('https://fashion-leo.servepratham.com')
                                                }
                                                }
                                                className="fs-6 mt-4 mb-3 text-black w-100 text-center cursor-pointer"
                                            >
                                                {t("login.alreadyAccount")}
                                            </Typography>
                                            <Typography
                                                onClick={() => {
                                                    setForm({
                                                        name: "",
                                                        email: "",
                                                        city: "",
                                                        state: "",
                                                        country: "",
                                                        address: "",
                                                        phone: "",
                                                        dialing_code: "+91",
                                                        code: "",
                                                        password: "",
                                                        password_confirmation: "",
                                                        state_code: "",
                                                        pin: "",
                                                        currency: "",
                                                    });
                                                    setPhone("");
                                                    setShow(false);
                                                    setErrorsLogin({ ["dialing_code"]: "" });
                                                    setHideButton(false);
                                                    history.push('/login')
                                                }
                                                }
                                                className="fs-6  mb-4 text-black w-100 text-center cursor-pointer"
                                            >
                                                {t("login.alreadyAccountCustomer")}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Formsy>
                        </React.Fragment>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default SignUpSeller