import { Card, Typography } from "@material-ui/core";
import { CleaningServices } from "@mui/icons-material";
import { Rating } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ApiService from "../services/apiService";

export default function AllBrands() {
  const [brands, setBrands] = useState();
  const params = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const id = sessionStorage.getItem("user_id");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.type === "shop-by-brands") {      
      ApiService.getBrandList().then((res) => {
        if (res.code === 200) {
          setBrands(res.data.data?.brand);
          setLoading(false); 
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-background dark:bg-darkBackground">
        <Typography
          variant={"h5"}
          className="py-5 text-center uppercase dark:text-fontWhite dark:bg-lightDarkBackground bg-gray-200 border-b-2"
        >
          {/* {`${t("brands.allbrands")}`} */}
        </Typography>
        <div className="grid lg:grid-cols-4 gap-2 sm:grid-cols-4 lg:gap-4 grid-cols-1 mx-2 lg:mx-10 my-10 lg:px-10 py-10">
          {loading ? (
            <React.Fragment>
              {[1, 2, 3, 4].map((ele, key) => (
                <Skeleton height={270} width={200} key={key} />
              ))}
            </React.Fragment>
          ) : (
            brands?.map((ele, key) => {
              return (
                <div className="transform w-full h-72 transition duration-500 lg:ml-4 hover:scale-110 shadow-md my-6">
                  <Card
                    className="h-72 dark:bg-lightDarkBackground"
                    onClick={() => {
                      history.push({
                        pathname: `/shop/brands/${ele?.slug}/${ele?.id}`,
                        state: { id: ele?.id, type: "brands" },
                      });
                    }}
                  >
                    <img
                      draggable={false}
                      alt="text"
                      // style={{objectFit:"cover"}}
                      className="h-56  -mb-0.5 m-auto w-full lg:p-0 justify-items-center hover:opacity-40 hover:text-black object-contain "
                      src={ele.imageUrl}
                    />

                    <CardContent className="py-1 px-2">
                      <Typography
                        className="dark:text-fontWhite"
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {ele.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              )
            }
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
