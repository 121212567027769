import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppConfig from "../../../appConfig";
import ProductCard from "../../../core-components/ProductCard";
import ViewAllButton from "../../../core-components/ViewAllButton";
import { ActionTypes } from "../../../redux/contants/ActionType";
import store from "../../../redux/store";
import ApiService from "../../../services/apiService";

export default function FeaturedCollection({ mobile, featuredProductData }) {
  const { t } = useTranslation();
  const history = useHistory();
  const cartCount = useSelector(({ allCategories }) => allCategories.cartCount);
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      history.push(AppConfig.routes.login);
    }
  };
  const user_id = sessionStorage.getItem("user_id");

  // Below function is for adding product to cart


  return (
    <React.Fragment>
      <div className="bg-background dark:bg-darkBackground ">
        <div
          className={`d-flex flex-md-column flex-row sm:flex-row align-items-center pr-3 pt-3 pt-md-0 pb-2 pb-md-0 pl-2 pr-md-8 pl-md-8 sm:px-10 align-items-center justify-content-between`}
        >
          <Typography
            variant="subtitle1"
            className="md:pt-6 text-nowrap uppercase text-fontDark dark:text-fontWhite tracking-wider sm:tracking-normal text-lg sm:text-2xl"
          >
            {t("home.fCollection.title")}
          </Typography>
          <div className="sm:float-right">
            <ViewAllButton type="featured-products" />
          </div>
        </div>
        <div className="grid lg:grid-cols-5 sm:grid-cols-4 sm:gap-4 grid-cols-2 pt-2 md:pt-10 gap-3 lg:gap-4 mx-2 sm:mx-10">
          {/* below condition reason : Because initially featuredProductData will be undefined untill data comes from home component */}
          {featuredProductData == undefined
            ? [1, 2, 3, 4, 5].map((ele, key) => (
              <Skeleton height={300} className="" key={key} />
            ))
            : featuredProductData.slice(0, 5).map((image, key) => {
              return (
                <React.Fragment key={key}>
                  <ProductCard
                    image={image}
                    type="Featured Product"
                    typeForUrl="featured-product"
                    addToWishlist={addToWishlist}
                  // wishArray={wishArray}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}
