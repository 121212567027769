import { Card, Checkbox, Tooltip, Typography } from "@material-ui/core";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Rating } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppConfig from "../appConfig";
import AlertComponent from "../core-components/AlertComponent";
import { ActionTypes } from "../redux/contants/ActionType";
import store from "../redux/store";
import ApiService from "../services/apiService";
import Utils from "../services/Utils";

const ProductCard = ({
  image,
  type,
  rating = true,
  addToWishlist,
  typeForUrl,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [style, setStyle] = useState({ display: "none" });
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [checkedItem, setCheckedItem] = useState([]);
  const [mobile, setMobile] = useState();
  const [value, setValue] = React.useState({ key: 0, value: 2 });
  const [viewWidth, setViewWidth] = useState(window.viewWidth);
  const [alertContent, setAlertContent] = useState("");
  const session_id = Utils.generateGUID();
  const newSessionId = sessionStorage.getItem("session_id");
  const user_id = sessionStorage.getItem("user_id");

  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };
  const [currentlyWished, setCurrentlyWished] = useState(image?.IsWishlist);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [cartDetails, setCartDetail] = useState([]);

  //  setting color of rating icon by adding dark class

  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [storage, setStorage] = useState(null);

  const [addCart, setAddCart] = useState(false);
  const cartCount = useSelector(({ allCategories }) => allCategories.cartCount);
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    const storedData = sessionStorage.getItem('myData');
    if (storedData) {
      setReviewData(JSON.parse(storedData));
    }
  }, []);

  const updateData = (newData) => {
    const filterReview = reviewData.filter((item, index) => item.id !== newData.id)
    setReviewData(reviewData);
    sessionStorage.setItem('myData', JSON.stringify([...filterReview, { ...newData }]));
  };

  const handleWishlistClick = (e) => {
    const bg = e.target.style.backgroundColor;
    e.target.style.backgroundColor = AppConfig.color.background;
    e.target.style.color = AppConfig.color.font;
    if (currentlyWished) setCurrentlyWished(false);
    else setCurrentlyWished(true);
    setTimeout(() => {
      e.target.style.color = AppConfig.color.secondary;
      e.target.style.backgroundColor = bg;
    }, 300);
  };

  const handleClickAddToCart = (type, data) => {
    if (Array.isArray(image.OptionKey)) {
      if (newSessionId === null) {
        sessionStorage.setItem(AppConfig.localSessionIdKey, session_id);
      }
      // below condition reson !!! if user has logged in we will send user_id and if user has not logged we will send either session id or newSessionId id
      // newSessionId will be send if user has already created his session id by adding a product into cart and if user dont have both user_id and newSessionId then we will send session_id which we will create by using Utils.generateGUID() function
      const sendableSessionId =
        user_id !== "null" && user_id !== null
          ? user_id
          : newSessionId !== null && newSessionId !== "null "
            ? newSessionId
            : session_id;

      const _form = new FormData();

      _form.append("vendor_id", data.user_id);
      _form.append("product_id", data.id);
      _form.append("quantity", "1");
      _form.append("orignal_price", data.price);
      _form.append("offer_price", data.offer_price);
      _form.append("session_id", sendableSessionId);

      ApiService.addToCart(_form).then((res) => {
        if (res.code === 200) {
          setAddCart(true);
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: res.data.data.cart_count,
          });

          if (type === "buy") {
            history.push(AppConfig.routes.checkout);
          }
        } else if (res.code === 422) {
          setOpenAlertModal(true);
        }
      });
    } else {
      history.push({
        pathname: `/product-details/${image.slug}/${image.id}`,
        state: {
          id: image.id,
          type: typeForUrl,
        },
      });
    }
  };

  useEffect(() => {
    setCurrentlyWished(image.IsWishlist);
  }, [image]);

  useEffect(() => {
    setViewWidth(window.innerWidth);
    setViewWidth(window.innerWidth);
    if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
    else setMobile(true);
    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
      if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
      else setMobile(true);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
        if (window.innerWidth > AppConfig.breakpoints.mobile) setMobile(false);
        else setMobile(true);
      });
    };
  }, []);

  return (
    <div
      className={`${typeof type !== "undefined"
        ? type === "Featured Product" || type === "Top Product"
          ? ""
          : "lg:px-2 h-80 mb-4"
        : "lg:px-2 h-80 mb-4"
        }`}
    >
      {typeof image.product_name !== "undefined" && (
        <Card
          className={`relative shadow-new-mobile md:shadow-new cursor-pointer w-full bg-background dark:bg-lightDarkBackground hover:shadow-2xl hover:shadow-inherit md:mb-4`}
          onMouseEnter={(e) => {
            e.currentTarget.querySelector("img").src = image.ImageSrc;
            setStyle({
              display: "block",
            });
            setShow(true);
            setData({
              id: image.id,
              ImageSrc: image.ImageSrc,
              title: image.product_name,
              price: image.price,
              offer_price: image.offer_price,
            });
          }}
          onMouseLeave={(e) => {
            setShow(false);
            e.currentTarget.querySelector("img").src = image.ImageSrc;
            setStyle({ display: "none" });
          }}
          onClick={() => {
            history.push({
              pathname: `/product-details/${image.slug}/${image.id}`,
              state: {
                id: image.id,
                type: typeForUrl,
              },
            });
            updateData(image)
          }
          }
        >
          <div className="w-full h-56 lg:max-h-52">
            <img
              draggable={false}
              alt="product img"
              className="object-contain justify-items-center w-full h-full"
              src={image.ImageSrc}
            />
          </div>
          {user_id &&
            <span style={style} className="d-flex justify-content-center">
              {show && data.id === image.id && (
                <div
                  className="absolute  dark:text-fontWhite text-primary shadow-black shadow-dark top-1/2 opacity-90 px-2 mx-4  text-center d-flex bg-dark h-12 font-light rounded-xl cursor-pointer
                                                              align-items-center justify-content-center"
                  onClick={(e) => {
                    if (!addCart) {
                      setCheckedItem([...checkedItem, image.id]);
                      handleClickAddToCart("cart", image);
                      e.stopPropagation();
                    } else {
                      e.stopPropagation();
                    }
                  }}
                >
                  <>
                    {/* if option key is array means it has no attreibutes so directly add product to the cart  so we will show add to cart  */}
                    {Array.isArray(image.OptionKey) ? (
                      <span className="ml-3 text-fontWhite font-bold mb-0">
                        {t("card.button.addToCart")}
                      </span>
                    ) : (
                      <span className="ml-3 text-fontWhite font-bold mb-0">
                        {t("card.button.productDetail")}
                      </span>
                    )}

                    <Checkbox
                      className="dark:text-fontWhite text-primary"
                      icon={
                        addCart && checkedItem.includes(image.id) ? (
                          <CheckBoxIcon
                            className="mt-1"
                            onClick={() => (user_id === "null" && user_id === null) ? history.push(AppConfig.routes.login) : history.push(AppConfig.routes.cart)}
                          />
                        ) : Array.isArray(image.OptionKey) ? (
                          <AddShoppingCartIcon className="mt-1" />
                        ) : (
                          <RemoveRedEyeIcon className="p-0.5" />
                        )
                      }
                      checkedIcon={
                        addCart ? (
                          <CheckBoxIcon
                            className="mt-1 mx-1"
                            onClick={(e) => {
                              if (user_id === "null" && user_id === null) {
                                history.push(AppConfig.routes.login);
                              } else {
                                history.push(AppConfig.routes.cart);
                                e.stopPropagation();
                              }
                            }}
                          />
                        ) : Array.isArray(image.OptionKey) ? (
                          <AddShoppingCartIcon className="dark:text-fontWhite text-primary" />
                        ) : (
                          <RemoveRedEyeIcon className="p-0.5" />
                        )
                      }
                      checked={image.IsWishlist}
                    />
                  </>
                </div>
              )}
            </span>
          }
          <CardContent
            className="relative lg:py-1 h-28"
            onClick={() => {
              history.push({
                pathname: `/product-details/${image.slug}/${image.id}`,
                state: {
                  id: image.id,
                  type: typeForUrl,
                },
              });
            }}
          >
            <div className="mt-1 sm:mt-1 lg:mt-0 px-3">
              <Tooltip title={image.product_name}>
                <span className="font-bold  text-black text-sm  sm:font-bold  sm:pt-1 dark:text-fontWhite ">
                  {image.product_name.length > 35 ? `${image.product_name.substring(0, 30)}...` : image.product_name}
                </span>
              </Tooltip>
              <span className="dark:text-fontWhite text-black absolute justify-between flex bottom-0 z-1 mb-0 sm:-mb-2 -mr-2 sm:mr-0 right-0 w-100">
                <div className="pt-2 md:pt-0">{rating && (
                  <Rating
                    className={"lg:mt-2  lg:mx-3 pointer-events-none"}
                    style={{
                      transform: viewWidth < 1024 ? "scale(0.8)" : "scale(1)",
                      fontSize: 18,
                    }}
                    name="simple-controlled"
                    value={image.AverageRating}
                    color="red"
                    onChange={(event, newValue) => {
                      setValue({
                        key: null,
                        value: newValue,
                      });
                    }}
                  />
                )}</div>
                <div>
                  <Tooltip
                    title={
                      image.IsWishlist
                        ? t("productcard.removefromwishlist")
                        : t("productcard.addtowishlist")
                    }
                  >
                    <Checkbox
                      className="dark:text-fontWhite text-primary"
                      icon={
                        <FavoriteBorderIcon
                          className={`   sm:dark:text-fontWhite text-extrasmall2 lg:mb-2 mr-1 sm:mr-0 delay-30 duration-200  text-primary sm:mb-1
                    box-content rounded-full`}
                        />
                      }
                      checkedIcon={
                        <FavoriteIcon
                          className={`lg:mb-2 mr-1 sm:mr-0  sm:mb-1
                    box-content rounded-full duration-200 delay-30  text-primarysm:dark:text-fontWhite text-extrasmall2`}
                        />
                      }
                      checked={currentlyWished}
                      onClick={(e) => {
                        handleWishlistClick(e);
                        addToWishlist(image);
                        e.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </div>
              </span>
            </div>
            <div className={`md:flex lg:pt-1 lg:pb-0 ml-3 lg:ml-4 pb-1`}>
              <div className="flex">
                <Typography
                  variant="body2"
                  className="  text-black md: text-1em dark:text-login text-center pr-1 lg:pr-2"
                >
                  {image?.currency_data?.symbol}

                  {/* {image?.OptionKey?.Size?.length > 0 ? image?.OptionKey?.Size[0]?.offer_price || image?.OptionKey?.Size[0]?.price : image.offer_price || image.price} */}
                  {image.offer_price ? image.offer_price : image.price}
                </Typography>
                {image.offer_price && (
                  <Typography
                    variant="body2"
                    className="  text-red-500   line-through  text-center md: text-1em font-normal "
                  >
                    {image?.currency_data?.symbol}
                    {image.price}
                  </Typography>
                )}
              </div>
              <div>
                {image.offer_price &&
                  Number(image.price) > Number(image.offer_price) ? (
                  <span className="pointer-events-none  text-primary dark:text-fontWhite text-xs font-bold md:mx-2 md:px-1 sm:px-2 rounded-md">
                    {/* ({`${Math.trunc(
                      Math.round(
                        ((image.offer_price - image.price) * 100) / image.price
                      )
                    )} %`}) */}
                    {(image?.discounts)}%
                  </span>
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <AlertComponent
        productData={image}
        price={data.price}
        offerPrice={data.offer_price}
        openAlertModal={openAlertModal}
        alertContent={alertContent}
        handleAlertModalClose={handleAlertModalClose}
      />
    </div>
  );
};

export default ProductCard;
