const kn = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart: "ನಿಮ್ಮ ಕಾರ್ಟ್ ಇತರ ಮಾರಾಟಗಾರರಿಂದ ಉತ್ಪನ್ನಗಳನ್ನು ಹೊಂದಿದೆ ದಯವಿಟ್ಟು ಕಾರ್ಟ್ ಅನ್ನು ತೆಗೆದುಹಾಕಿ",
      alert: "ಎಚ್ಚರಿಕೆ",
      removeOtherAddThis: "ಇತರ ಐಟಂಗಳನ್ನು ತೆಗೆದುಹಾಕಿ ಮತ್ತು ಇದನ್ನು ಸೇರಿಸಿ",
      cancel: "ರದ್ದುಮಾಡು",
    },
    productcard: {
      addtowishlist: "ಇಷ್ಟಪಟ್ಟಿಗೆ ಸೇರಿಸು",
      removefromwishlist: "ಇಷ್ಟಪಟ್ಟಿಯಿಂದ ತೆಗೆದುಹಾಕಿ",
    },
    header: {
      search: "ಹುಡುಕಿ Kannada...",
      language: "ಭಾಷೆ",
      categories: "ವರ್ಗಗಳು",
      home: "ಮನೆ",
      alert: "ಎಚ್ಚರಿಕೆ",
      stockNotAvailable: "ಸ್ಟಾಕ್ ಲಭ್ಯವಿಲ್ಲ....",
      ok: "ಸರಿ",
      profile: "ಪ್ರೊಫೈಲ್",
      myChats: "ನನ್ನ ಚಾಟ್‌ಗಳು",
      myFollowing: "ನನ್ನ ಅನುಸರಣೆ",
      logout: "ಲಾಗ್ ಔಟ್",
      myCart: "ನನ್ನ ಕಾರ್ಟ್",
      myOrders: "ನನ್ನ ಆಜ್ಞೆಗಳು",
      wishlist: "ಇಷ್ಟಪಟ್ಟಿ",
      notifications: "ಅಧಿಸೂಚನೆಗಳು",
      cart: "ಕಾರ್ಟ್",
      login: "ಲಾಗಿನ್",
      account: "ಖಾತೆ",
    },
    paymentForm: {
      paymentOptions: "ಡೆಬಿಟ್ ಕಾರ್ಡ್, ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್, ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್, UPI",
      cashOnDelivery: "ತಲುಪಿದಾಗ ನಗದು ಪಾವತಿಸುವಿಕೆ",
      flashSaleNote: "ಗಮನಿಸಿ: ಫ್ಲ್ಯಾಶ್ ಸೇಲ್ ಉತ್ಪನ್ನಗಳಲ್ಲಿ ಆನ್‌ಲೈನ್ ಪಾವತಿ ಮಾತ್ರ ಲಭ್ಯವಿದೆ"
    },
    checkOutForm: {
      payNow: "ಈಗ ಪಾವತಿಸಿ",
    },
    addressForm: {
      city: "ನಗರ",
      close: 'ಮುಚ್ಚಿ',
      document: 'CPF',
      state: "ರಾಜ್ಯ",
      locality: "ಸ್ಥಳ",
      shippingAddress: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ",
      address: "ವಿಳಾಸ",
      addAddress: "ಹೊಸ ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
      saveAddress: 'ವಿಳಾಸ ಉಳಿಸಿ',
      editAddress: 'ವಿಳಾಸ ಸಂಪಾದಿಸು',
      name: "ಹೆಸರು",
      mobile: "ಮೊಬೈಲ್",
      landmark: "ಹೆಗ್ಗುರುತು",
      pinCode: "ಪಿನ್‌ಕೋಡ್",
      addressType: "ವಿಳಾಸದ ವಿಧ",
      searchplace: 'ಸ್ಥಳಗಳನ್ನು ಹುಡುಕಿ...',
      home: "ಮನೆ",
      office: "ಕಚೇರಿ",
      country: "ದೇಶ",
      NearByLocation: "ಸ್ಥಳದ ಮೂಲಕ",
      statecode: "ರಾಜ್ಯ ಕೋಡ್",
      countrycode: "ದೇಶದ ಕೋಡ್",
      confirmation: "ದೃಢೀಕರಣ",
      confirmationMessage: "ನಿಮ್ಮ ವ್ಯಾಪಾರದ ವಿಳಾಸ ದೇಶದ ಕೋಡ್ ಅನ್ನು ಬದಲಾಯಿಸಿರುವುದರಿಂದ, ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ ಮತ್ತು ಹಿಂದೆ ಸೇರಿಸಿದ ಉತ್ಪನ್ನಗಳನ್ನು ನಿಷ್ಕ್ರಿಯಕ್ಕೆ ಹೊಂದಿಸಲಾಗುತ್ತದೆ. ದಯವಿಟ್ಟು ವಿನಂತಿಯನ್ನು ದೃಢೀಕರಿಸಿ ಅಥವಾ ರದ್ದುಗೊಳಿಸಿ."
    },
    noFound: {},
    order: {
      shippingAddress: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ",
      mobile: "ಮೊಬೈಲ್",
      deliveryAt: "ವಿತರಣೆ",
      orderDetails: "ಆದೇಶದ ವಿವರಗಳು",
      qty: "Qty",
      status: "ಸ್ಥಿತಿ",
      paymentMethod: "ಪಾವತಿ ವಿಧಾನ",
      orderedOn: "ಆದೇಶಿಸಲಾಗಿದೆ",
      customerDetails: "ಗ್ರಾಹಕರ ವಿವರಗಳು",
      trackOrder: "ಟ್ರ್ಯಾಕ್ ಆರ್ಡರ್",
      backToOrder: "ಆದೇಶಕ್ಕೆ ಹಿಂತಿರುಗಿ",
      orderAmount: "ಆರ್ಡರ್ ಮೊತ್ತ",
      orderId: 'ಆರ್ಡರ್ ಐಡಿ',
      more: 'ಇನ್ನಷ್ಟು',
      discountAmount: 'ಆರ್ಡರ್ ರಿಯಾಯಿತಿ ಮೊತ್ತ',
      rating: 'ರೇಟಿಂಗ್ ನೀಡಿ',
    },

    cancelOrder: {
      reasonForCancel: "ರದ್ದತಿಗೆ ಕಾರಣ",
      reasonForReturn: "ಹಿಂತಿರುಗಲು ಕಾರಣ",
      orderCancellation: "ಆದೇಶ ರದ್ದತಿ",
      orderCancel: "ಆದೇಶ ರದ್ದು",
      returnCancel: "ಆರ್ಡರ್ ರಿಟರ್ನ್",
      cancelHeading: "ರಿಟರ್ನ್ ವಿನಂತಿಯನ್ನು ರದ್ದುಮಾಡುವುದೇ?",
      returnRequestCancel: "ಹೌದು, ನಾನು ರದ್ದುಗೊಳಿಸಲು ಬಯಸುತ್ತೇನೆ",
      continue: "ಮುಂದುವರಿಯಿರಿ",
      itemInfo: "ಐಟಂ ಮಾಹಿತಿ",
      qty: "Qty",
      deliveryFee: "ವಿತರಣಾ ಶುಲ್ಕ",
      orderDiscount: "ಆರ್ಡರ್ ಡಿಸ್ಕೌಂಟ್",
      productAmount: "ಉತ್ಪನ್ನ ಮೊತ್ತ",
      price: "ಬೆಲೆ",
      comments: "ಕಾಮೆಂಟ್‌ಗಳು",
      confirmMsg: "ನಿಮಗೆ ಖಚಿತವಾಗಿದೆಯೇ, ಈ ಆರ್ಡರ್‌ಗಾಗಿ ರಿಟರ್ನ್ ವಿನಂತಿಯನ್ನು ರದ್ದುಗೊಳಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
      feedback: "ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೀಡಿ",
      feedbackMsg: "ಈ ಉತ್ಪನ್ನವನ್ನು ನೀವು ಹೇಗೆ ರೇಟ್ ಮಾಡುತ್ತೀರಿ?",
      submitRating: "ಸಲ್ಲಿಸು",
      feedbackComment: "ನಿಮ್ಮ ವಿಮರ್ಶೆಯನ್ನು ಸೇರಿಸಿ",
    },
    footer: {
      quickShop: "ತ್ವರಿತ ಅಂಗಡಿ",
      informations: "ಮಾಹಿತಿ",
      customerService: "ಗ್ರಾಹಕ ಸೇವೆ",
      contactUs: "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
      supportChat: "ಬೆಂಬಲ ಚಾಟ್",
    },
    mobileFooter: {
      home: "ಮನೆ",
      category: "ವರ್ಗ",
      new: "ಹೊಸ",
      me: "ನಾನು",
      bag: "ಬ್ಯಾಗ್",
      myOrders: "ನನ್ನ ಆಜ್ಞೆಗಳು",
      categoriesDrawer: {
        categories: "ವರ್ಗಗಳು",
      },
    },
    common: { addToCart: "ಕಾರ್ಟ್ಗೆ ಸೇರಿಸಿ" },
    card: {
      button: { addToCart: "ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸಿ", productDetail: "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ" },
    },
    shop: {
      home: "ಮನೆ",
      mobileDisplay: "ಮೊಬೈಲ್ ಪ್ರದರ್ಶನ",
      categoryHeading: "ವರ್ಗಗಳು",
      showingHeading: "ತೋರಿಸುತ್ತಿದೆ",
      size: "ಗಾತ್ರ",
      price: "ಬೆಲೆ",
      brand: "ಬ್ರಾಂಡ್",
      discount: "ರಿಯಾಯಿತಿ",
      andMore: "ಇನ್ನೂ ಸ್ವಲ್ಪ",
      color: "ಬಣ್ಣ",
      filter: "ಫಿಲ್ಟರ್‌ಗಳು",
      allFilter: "ಎಲ್ಲಾ ಫಿಲ್ಟರ್‌ಗಳು",
      applyAllFilters: "ಎಲ್ಲಾ ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಅನ್ವಯಿಸು",
      clear: "ಸ್ಪಷ್ಟ",
      more: "ಹೆಚ್ಚು",
      productFilters: "ಉತ್ಪನ್ನ ಶೋಧಕಗಳು",
      noFilters: "ಫಿಲ್ಟರ್‌ಗಳಿಲ್ಲ",
      showingProducts: "ಉತ್ಪನ್ನಗಳನ್ನು ತೋರಿಸಲಾಗುತ್ತಿದೆ",
      products: "ಉತ್ಪನ್ನಗಳು",
      of: "ನ",
      noProductFound: "ಯಾವುದೇ ಉತ್ಪನ್ನ ಕಂಡುಬಂದಿಲ್ಲ",
      noProducts: "ಉತ್ಪನ್ನಗಳಿಲ್ಲ",
    },
    productDetails: {
      all: "ಎಲ್ಲಾ ವಿಮರ್ಶೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
      one: "ಒಂದು",
      two: "ಎರಡು",
      three: "ಮೂರು",
      four: "ನಾಲ್ಕು",
      five: "ಐದು",
      soldBy: "ಮಾರಾಟ",
      outOfStock: "ಸ್ಟಾಕ್ ಇಲ್ಲ",
      addToWishList: "ಇಷ್ಟಪಟ್ಟಿಗೆ ಸೇರಿಸು",
      viewShop: "ವೀಕ್ಷಣೆ ಮಳಿಗೆ",
      chatWithUs: "ನಮ್ಮೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಿ",
      ratings: "ರೇಟಿಂಗ್‌ಗಳು",
      followers: "ಅನುಯಾಯಿಗಳು",
      products: "ಉತ್ಪನ್ನಗಳು",
      productDetails: "ಉತ್ಪನ್ನ ವಿವರಗಳು",
      productRatingAndReviews: "ಉತ್ಪನ್ನ ರೇಟಿಂಗ್‌ಗಳು ಮತ್ತು ವಿಮರ್ಶೆಗಳು",
      similarProducts: "ಇದೇ ರೀತಿಯ ಉತ್ಪನ್ನಗಳು",
      reviews: "ವಿಮರ್ಶೆಗಳು",
      removeFromWishlist: "ವಿಶ್‌ಲಿಸ್ಟ್‌ನಿಂದ ತೆಗೆದುಹಾಕಿ",
      share: "ಹಂಚಿಕೊಳ್ಳಿ",
      off: 'ಆರಿಸಿ',
      youSave: "ನೀನು ಉಳಿಸು",
      placeOrderPinCode: "ಪಿನ್‌ಕೋಡ್ ನಮೂದಿಸಿ",
      check: "ಪರಿಶೀಲಿಸಿ",
      checkDelivery:"ವಿತರಣೆಯನ್ನು ಪರಿಶೀಲಿಸಿ",
      inputLabel: "ವಿತರಣೆ ಮತ್ತು ಸೇವೆಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ದಯವಿಟ್ಟು ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ",
      deliveryDateOne: "ಸಾಮಾನ್ಯವಾಗಿ 7 ದಿನಗಳಲ್ಲಿ ತಲುಪಿಸಬಹುದು.",
      deliveryDateTwo: "ವಿತರಣೆಯಲ್ಲಿ ಪಾವತಿ ಲಭ್ಯವಿದೆ.",
      deliveryDateThree: "ಜಗಳ ಮುಕ್ತ 7 ದಿನಗಳ ವಾಪಸಾತಿ",
    },
    myOrders: {
      filters: "ಫಿಲ್ಟರ್‌ಗಳು",
      orderStatus: "ಆರ್ಡರ್ ಸ್ಥಿತಿ",
      onTheWay: "ದಾರಿಯಲ್ಲಿ",
      delivered: "ವಿತರಿಸಲಾಗಿದೆ",
      cancelled: "ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
      returned: "ಮರಳಿ",
      orderTime: "ಆದೇಶದ ಸಮಯ",
      last30Days: "ಕಳೆದ 30 ದಿನಗಳು",
      processing: "ಸಂಸ್ಕರಣೆ",
      older: "ಹಳೆಯ",
      soldBy: "ಮಾರಾಟ",
      viewOrder: "ಆದೇಶವನ್ನು ವೀಕ್ಷಿಸಿ",
      cancelOrder: "ಆದೇಶವನ್ನು ರದ್ದುಮಾಡಿ",
      returnOrder: "ರಿಟರ್ನ್ ವಿನಂತಿ",
      returnCancelOrder: "ರಿಟರ್ನ್ ವಿನಂತಿಯನ್ನು ರದ್ದುಮಾಡಿ",
      address: "ವಿಳಾಸ",
      paymentDetail: "ಪಾವತಿ ವಿವರ",
      COd: "COD",
      transactionId: "ವಹಿವಾಟು ID",
      myOrders: "ನನ್ನ ಆಜ್ಞೆಗಳು",
      filter: "ಫಿಲ್ಟರ್‌ಗಳು",
      openMainMenu: "ಮುಖ್ಯ ಮೆನು ತೆರೆಯಿರಿ",
      orderedOn: "ಆದೇಶಿಸಲಾಗಿದೆ",
      walletAmount: "ಬಳಸಿದ ಕೈಚೀಲ",
      orderAmount: "ಆರ್ಡರ್ ಮೊತ್ತ",
      qty: "Qty",
    },
    myProfile: {
      profile: "ಪ್ರೊಫೈಲ್",
      alert: "ಎಚ್ಚರಿಕೆ",
      ok: "ಸರಿ",
      updateProfile: "ನವೀಕರಿಸಿ",
      firstName: "ಮೊದಲ ಹೆಸರು",
      city: "ನಗರ",
      email: "ಇಮೇಲ್ ವಿಳಾಸ",
      phone: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
      state: "ರಾಜ್ಯ",
      address: "ವಿಳಾಸ",
      dialing_code: "ದೇಶದ ಕೋಡ್",
      contactMessage: "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಬದಲಾಯಿಸಲು ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ",
      personalInfo: "ವಯಕ್ತಿಕ ಮಾಹಿತಿ",
      accountInfo: "ಖಾತೆ ಮಾಹಿತಿ",
      alert: "OTP ಪರಿಶೀಲನೆ",
      message: "ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ OTP ಕಳುಹಿಸಲಾಗಿದೆ",
      emailMessage: "ನಿಮ್ಮ ಇಮೇಲ್‌ಗೆ OTP ಕಳುಹಿಸಲಾಗಿದೆ",
      otpError: "otp ಕ್ಷೇತ್ರ ಅಗತ್ಯವಿದೆ",
      image: "ಶಿಫಾರಸು ಮಾಡಿದ ಗಾತ್ರ 500x500px",
      phoneError: "ಫೋನ್ ಸಂಖ್ಯೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
      emailError: "ಇಮೇಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
      changePassword: "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
      password: "ಹಳೆಯ ಪಾಸ್ವರ್ಡ್",
      newPass: "ಹೊಸ ಪಾಸ್ವರ್ಡ್",
      confirmPass: "ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ",
      confirmPassError: "ಪಾಸ್‌ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ ಎಂದು ದೃಢೀಕರಿಸಿ",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "ಸುರಕ್ಷಿತ ಪಾವತಿ",
          subHeading: "ವಿಶ್ವದ ಅತ್ಯಂತ ಸುರಕ್ಷಿತ ಪಾವತಿ ವಿಧಾನಗಳೊಂದಿಗೆ ಪಾವತಿಸಿ",
        },
        ii: {
          heading: "ವಿಶ್ವಾಸ",
          subHeading: "ರಕ್ಷಣೆಯು ನಿಮ್ಮ ಖರೀದಿ ಮತ್ತು ವೈಯಕ್ತಿಕ ಡೇಟಾವನ್ನು ಒಳಗೊಳ್ಳುತ್ತದೆ",
        },
        iii: {
          heading: "ಪ್ಯಾನ್ ಇಂಡಿಯಾ ಡೆಲಿವರಿ",
          subHeading: "200+ ದೇಶಗಳು ಮತ್ತು ಪ್ರದೇಶಗಳಿಗೆ ಉಚಿತ ಮತ್ತು ವೇಗದ ಶಿಪ್ಪಿಂಗ್.",
        },
        iv: {
          heading: "ಹಾಟ್ಲೈನ್",
          subHeading: "4141 456 789, 4125 666 888 ನಲ್ಲಿ ನಿಮ್ಮ ಪ್ರಶ್ನೆಗೆ ಸಹಾಯವಾಣಿಗೆ ಮಾತನಾಡಿ",
        },
      },
    },
    cartDetails: {
      product: "ಉತ್ಪನ್ನ",
      quantity: "ಪ್ರಮಾಣ",
      total: "ಒಟ್ಟು",
      price: "ಬೆಲೆ",
      shopNow: "ಈಗ ಖರೀದಿಸು",
      noItems: "ಕಾರ್ಟ್‌ನಲ್ಲಿ ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ",
      checkout: "ಚೆಕ್ಔಟ್",
      subtotal: "ಉಪ ಒಟ್ಟು",
      discount: "ರಿಯಾಯಿತಿ ಆದೇಶ",
      shipping: "ಶಿಪ್ಪಿಂಗ್",
      yourCart: "ನಿಮ್ಮ ಕಾರ್ಟ್",
      addOnlyTenProducts: "ನೀವು ಕೇವಲ ಹತ್ತು ಉತ್ಪನ್ನಗಳನ್ನು ಮಾತ್ರ ಸೇರಿಸಬಹುದು",
      action: "ಕ್ರಿಯೆ",
      ok: "ಸರಿ",
      nocartitem: 'ಕಾರ್ಟ್‌ನಲ್ಲಿ ಯಾವುದೇ ವಸ್ತುಗಳಿಲ್ಲ',
      cartEmpty: "ನಿಮ್ಮ ಕಾರ್ಟ್ ಖಾಲಿಯಾಗಿದೆ.",
      wishlistEmpty: "ನಿಮ್ಮ ಇಚ್ಛೆಪಟ್ಟಿ ಖಾಲಿಯಾಗಿದೆ."
    },

    checkout: {
      alert: "ಎಚ್ಚರಿಕೆ",
      ok: "ಸರಿ",
      checkout: "ಚೆಕ್ ಔಟ್",
      refresh: "ರಿಫ್ರೆಶ್",
      orderProcessing: "ನಿಮ್ಮ ಆರ್ಡರ್ ಪಾವತಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ",
      thankYouForOrder: "ಆದೇಶಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು",
      orderNumberIs: "ಆರ್ಡರ್ ಸಂಖ್ಯೆ",
      transactionIdIs: "ವಹಿವಾಟು ಐಡಿ",
      continueShopping: "ಕೊಳ್ಳುವಿಕೆಯನ್ನು ಮುಂದುವರೆಸಿರಿ",
      back: "ಹಿಂದೆ",
      next: "ಮುಂದೆ",
      placeOrder: "ಪ್ಲೇಸ್ ಆರ್ಡರ್",
      payNow: "ಈಗ ಪಾವತಿಸಿ",
      shippingAddress: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ",
      paymentMethod: 'ಪಾವತಿ ವಿಧಾನ',
      orderSummary: "ಆರ್ಡರ್ ಸಾರಾಂಶ",
    },
    review: {
      flashSaleCouponMsg : "ಗಮನಿಸಿ: ಫ್ಲಾಶ್ ಮಾರಾಟ ಉತ್ಪನ್ನಗಳ ಸಂದರ್ಭದಲ್ಲಿ ಕೂಪನ್ ಅನ್ವಯಿಸುವುದಿಲ್ಲ.",
      flashSaleWalletMsg : "ಗಮನಿಸಿ: ಫ್ಲ್ಯಾಶ್ ಸೇಲ್ ಉತ್ಪನ್ನಗಳ ಸಂದರ್ಭದಲ್ಲಿ ವ್ಯಾಲೆಟ್ ಅನ್ವಯಿಸುವುದಿಲ್ಲ.",
      to: "ಗೆ",
      days: "ದಿನಗಳು",
      minimumOrderValue: "ಕನಿಷ್ಠ ಆರ್ಡರ್ ಮೌಲ್ಯ",
      maimumDiscountIS: "ಗರಿಷ್ಠ ರಿಯಾಯಿತಿ",
      orderSummary: "ಆರ್ಡರ್ ಸಾರಾಂಶ",
      productName: "ಉತ್ಪನ್ನ ಹೆಸರು",
      originalPrice: "ಮೂಲ ಬೆಲೆ",
      margin: "ಅಂಚು",
      offerPrice: "ಆಫರ್ ಬೆಲೆ",
      orderAmount: "ಆರ್ಡರ್ ಮೊತ್ತ",
      offerDiscount: "ಉತ್ಪನ್ನ ರಿಯಾಯಿತಿ",
      bankDiscount: "ಬ್ಯಾಂಕ್ ಆಫರ್",
      subTotal: "ಉಪ ಒಟ್ಟು",
      Qty: "Qty",
      productAmount: "ಉತ್ಪನ್ನ ಬೆಲೆ",
      deliveryFee: "ವಿತರಣಾ ಶುಲ್ಕ",
      marginPrice: "ಅಂಚು ಬೆಲೆ",
      orderDiscount: "ಕೂಪನ್ ರಿಯಾಯಿತಿ",
      orderTotal: "ಆರ್ಡರ್ ಒಟ್ಟು",
      youSave: "ನೀನು ಉಳಿಸು",
      chooseCoupon: "ಕೂಪನ್ ಆಯ್ಕೆಮಾಡಿ",
      remove: "ತೆಗೆದುಹಾಕು",
      applyCoupon: "ಕೂಪನ್ ಅನ್ವಯಿಸು",
      youSaveAdditional: "ನೀವು ಹೆಚ್ಚುವರಿ ಉಳಿಸಿ",
      shipping: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ",
      paymentMethod: "ಪಾವತಿ ವಿಧಾನ",
      total: "ಒಟ್ಟು",
      errorMessage: 'ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ವಿಳಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
      margin: "ಮಾರ್ಜಿನ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
      message: "ಕೂಪನ್ ರಿಯಾಯಿತಿಯನ್ನು ಲೆಕ್ಕಾಚಾರ ಮಾಡಿ",
      orderPlaceMessage: "ನಿಮ್ಮ ಆರ್ಡರ್ ಪ್ರಕ್ರಿಯೆಗೊಳ್ಳುತ್ತಿದೆ...",
      paymentMessage: 'ನಿಮ್ಮ ಕಾರ್ಡ್ ಮೂಲಕ ಪಾವತಿಸಿ..',
      applyCoupen: "ಕೂಪನ್ ಕೋಡ್ ಅನ್ವಯಿಸು",
      sellingMessage: 'ಉತ್ಪನ್ನವನ್ನು ಮರುಮಾರಾಟ ಮಾಡುವುದೇ?',
      marginMessage: 'ನಿಮ್ಮ ಅಂಚು ಸೇರಿಸಲು ಹೌದು ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ',
      yes: "ಹೌದು",
      close: 'ಮುಚ್ಚಿ',
      enterMargin: 'ಮಾರ್ಜಿನ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ',
      addMargin: 'ಅಂಚನ್ನು ಸೇರಿಸಿ',
      marginError1: 'ದಯವಿಟ್ಟು ಮಾರ್ಜಿನ್ ವರೆಗೆ ನಮೂದಿಸಿ',
      marginError2: 'ಒಟ್ಟು ಮೊತ್ತದ',
      couponApply: 'ಕೂಪನ್ ಅನ್ವಯಿಸಲಾಗಿದೆ',
      valid: 'ವರೆಗೆ ಮಾನ್ಯ',
      totalAmount: 'ಒಟ್ಟು ಮೊತ್ತ',
      paymentdetails: 'ಪಾವತಿ ವಿವರಗಳು',
      reviewOrder: 'ನಿಮ್ಮ ಆದೇಶವನ್ನು ಪರಿಶೀಲಿಸಿ',
      online: 'ಆನ್‌ಲೈನ್',
      deliveryServices: "ವಿತರಣಾ ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      freeDeliveryMessage: "ಮೇಲಿನ ಆದೇಶದ ಮೇರೆಗೆ",
      freeDeliveryNotMessage: "ಅನರ್ಹ",
      freeDelivery: 'ಉಚಿತ ವಿತರಣೆ',
      serviceSelectMessage: "ದಯವಿಟ್ಟು ವಿತರಣಾ ಸೇವೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      marginError: 'ಮಾರ್ಜಿನ್ ಮೊತ್ತವು ಇದಕ್ಕಿಂತ ಕಡಿಮೆ ಅಥವಾ ಸಮಾನವಾಗಿರಬೇಕು'
    },

    reasons: {
      returnReasonOne: "ದೋಷದ ತುಣುಕು.",
      returnReasonTwo: "ತಪ್ಪಾದ ಉತ್ಪನ್ನವನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ.",
      returnReasonThree: "ಅಗತ್ಯವಿಲ್ಲ.",
      cancelReasonOne: "ಉತ್ಪನ್ನ ಗುಣಮಟ್ಟದ ಸಮಸ್ಯೆಗಳಿಂದಾಗಿ ನಾನು ರದ್ದುಗೊಳಿಸಲು ಬಯಸುತ್ತೇನೆ",
      cancelReasonTwo: "ಉತ್ಪನ್ನದ ಬೆಲೆ ಕಡಿಮೆಯಾಗಿದೆ",
      cancelReasonThree: "ನಿರೀಕ್ಷಿತ ವಿತರಣಾ ಸಮಯ ತುಂಬಾ ಉದ್ದವಾಗಿದೆ",
      cancelReasonFour: "ನಾನು ಉತ್ಪನ್ನವನ್ನು ಬೇರೆಡೆ ಖರೀದಿಸಿದ್ದೇನೆ",
      cancelReasonFive: "ನಾನು ಆದೇಶಕ್ಕಾಗಿ ವಿಳಾಸವನ್ನು ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತೇನೆ",
      cancelReasonSix: "ನಾನು ನನ್ನ ಮನಸ್ಸನ್ನು ಬದಲಾಯಿಸಿದ್ದೇನೆ",
      cancelReasonSeven: "ನನ್ನ ಆರ್ಡರ್ ಅನ್ನು ಪ್ರಿಪೇಯ್ಡ್ ಆಗಿ ಪರಿವರ್ತಿಸಲು ನಾನು ಬಯಸುತ್ತೇನೆ",
      cancelReasonEight: "ನಾನು ನನ್ನ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಬದಲಾಯಿಸಲು ಬಯಸುತ್ತೇನೆ",
    },
    wishList: {
      addToCart: "ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸು",
      product: "ಉತ್ಪನ್ನ",
      offerPrice: "ಆಫರ್ ಬೆಲೆ",
      price: "ಬೆಲೆ",
      yourWishlist: "ನನ್ನ ಇಚ್ಚೆಯ ಪಟ್ಟಿ",
      addOnlyTenProducts: "ನೀವು ಕೇವಲ ಹತ್ತು ಉತ್ಪನ್ನಗಳನ್ನು ಮಾತ್ರ ಸೇರಿಸಬಹುದು",
    },
    attributeModal: {
      price: "ಬೆಲೆ",
      chooseOptions: "ಆಯ್ಕೆಗಳನ್ನು ಆರಿಸಿ",
      noProducts: "ಇಷ್ಟಪಟ್ಟಿಯಲ್ಲಿ ಯಾವುದೇ ಐಟಂ ಇಲ್ಲ",
      shopNow: "ಈಗ ಖರೀದಿಸು",
      addToCart: "ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸು",
      alert: "ಎಚ್ಚರಿಕೆ",
      ok: "ಸರಿ",
    },
    chats: {
      chats: "ಚಾಟ್‌ಗಳು",
      noChats: "ನೀವು ಯಾವುದೇ ಚಾಟ್‌ಗಳನ್ನು ಹೊಂದಿಲ್ಲ",
      noThanks: "ಬೇಡ ಧನ್ಯವಾದಗಳು",
      upgradeNow: "ಈಗ ನವೀಕರಿಸಿ",
      makeAOffer: "ಒಂದು ಪ್ರಸ್ತಾಪವನ್ನು ಮಾಡಿ",
      acceptOffer: "ಆಫರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿ",
      am: "ಆಮ್",
      pm: "pm",
      addToCart: "ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸು",
    },
    login: {
      loginWithPhone: "ಫೋನ್ ಮೂಲಕ ಲಾಗಿನ್ ಮಾಡಿ",
      loginWithEmail: "ಇಮೇಲ್ ಮೂಲಕ ಲಾಗಿನ್",
      login: "ಲಾಗಿನ್",
      newUser: "Fashionleo ಗೆ ಹೊಸದು ? ಖಾತೆಯನ್ನು ರಚಿಸಿ",
      sendOtp: "OTP ಕಳುಹಿಸಿ",
      signUp: "ಸೈನ್ ಅಪ್ ಫಾರ್ಮ್",
      signUpButton: "ಸೈನ್ ಅಪ್",
      or: "ಅಥವಾ",
      resendOtp: "ಒಟಿಪಿ ಮರುಕಳುಹಿಸಿ",
      forgotPassword: "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ",
      enterPhone: "ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
      dialingCode: "ಡಯಲಿಂಗ್_ಕೋಡ್",
      alreadyAccount: "ಈಗಾಗಲೇ ಖಾತೆಯನ್ನು ಹೊಂದಿರುವಿರಾ? ಲಾಗಿನ್",
      verify: "ಪರಿಶೀಲಿಸಿ",
      signUp: "ಸೈನ್ ಅಪ್",
      enterTheOtp: "ದಯವಿಟ್ಟು ಒಟಿಪಿ ನಮೂದಿಸಿ",
      password: "ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
      emailPhone: "ಇಮೇಲ್/ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
      email: "ಇಮೇಲ್ ನಮೂದಿಸಿ",
      name: "ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      codeError: 'ದಯವಿಟ್ಟು ದೇಶದ ಡಯಲಿಂಗ್ ಕೋಡ್ ಆಯ್ಕೆಮಾಡಿ',
      phoneError: 'ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
      termsError: "ದಯವಿಟ್ಟು ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಸ್ವೀಕರಿಸಿ.",
      termCondition: "ನಾನು ಎಲ್ಲಾ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೇನೆ.",
      termConditionOne: "ನಾನು ಎಲ್ಲವನ್ನೂ ಸ್ವೀಕರಿಸುತ್ತೇನೆ",
      termConditionTwo: "ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು",
      nameError: "ಹೆಸರು ಕ್ಷೇತ್ರ ಅಗತ್ಯವಿದೆ",
      forgotMessage: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ನೋಂದಾಯಿತ ಫೋನ್ ಅನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ನಾವು ನಿಮಗೆ OTP ಕಳುಹಿಸುತ್ತೇವೆ",
    },
    pageNotFound: {
      oops: "ಓಹ್! ಪುಟ ಕಂಡುಬಂದಿಲ್ಲ",
      unAvailable: "ನೀವು ಪ್ರವೇಶಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಪುಟವನ್ನು ತೆಗೆದುಹಾಕಿರಬಹುದು, ಬದಲಾಯಿಸಿರಬಹುದು ಅಥವಾ ಲಭ್ಯವಿಲ್ಲ.",
      goToHome: "ಮುಖಪುಟಕ್ಕೆ ಹೋಗಿ",
      tryExploring: "ಅನ್ವೇಷಿಸಲು ಪ್ರಯತ್ನಿಸಿ",
    },
    vendorDetails: {
      myFollowing: "ನನ್ನ ಅನುಸರಣೆ",
      vendorDetails: "ಮಾರಾಟಗಾರರ ವಿವರಗಳು",
    },
    brands: {
      allbrands: "ಬ್ರಾಂಡ್‌ಗಳ ಮೂಲಕ ಶಾಪಿಂಗ್ ಮಾಡಿ",
    },
    profileMenu: {
      profile: "ಪ್ರೊಫೈಲ್",
      myBankDetails: "ನನ್ನ ಬ್ಯಾಂಕ್ ವಿವರಗಳು",
      myEarnings: "ನನ್ನ ಗಳಿಕೆ",
      myReviews: "ನನ್ನ ವಿಮರ್ಶೆಗಳು",
      deleteAccount: "ಖಾತೆ ಅಳಿಸು",
      myChats: "ನನ್ನ ಚಾಟ್ಸ್",
      myOrders: "ನನ್ನ ಆಜ್ಞೆಗಳು",
      myCart: "ನನ್ನ ಕಾರ್ಟ್",
      myFollowing: "ನನ್ನ ಅನುಸರಣೆ",
      manageAddress: "ವಿಳಾಸವನ್ನು ನಿರ್ವಹಿಸಿ",
      logout: "ಲಾಗ್ ಔಟ್",
    },
    deletePopup: {
      confirmToDelete: "ದೃಢೀಕರಿಸಿ",
      delAccount: "ಖಾತೆ ಅಳಿಸು",
      delMsg: "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಳಿಸುವ ಮೂಲಕ ಈ ಕೆಳಗಿನ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ರದ್ದುಗೊಳಿಸಲಾಗುವುದಿಲ್ಲ",
      msgOne: "ವೈಯಕ್ತಿಕ ವಿವರಗಳು",
      msgTwo: "ಮಾರಾಟಗಾರರ ಪ್ರೊಫೈಲ್ (ಲಭ್ಯವಿದ್ದರೆ)",
      msgThree: "ವೈಯಕ್ತಿಕ ದಾಖಲೆಗಳು",
      msgFour: "ವಿಳಾಸಗಳು",
      msgFive: "ಬ್ಯಾಂಕ್ ವಿವರಗಳು",
      delConfirm: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಈಗಲೇ ಅಳಿಸಲು ದೃಢೀಕರಣದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ?",
    },
    reviewTr: {
      title: "ನನ್ನ ವಿಮರ್ಶೆ",
      titleEarn: "ನನ್ನ ಗಳಿಕೆ",
      viewRec: "ವೀಕ್ಷಣೆ ರಶೀದಿ",
      TransactionId: "ವ್ಯವಹಾರ ಐಡಿ",
      walletMsg: "ನಿಮ್ಮ ರೆಫರಲ್ ಕೋಡ್ ಅನ್ನು ರಚಿಸಲು ಮತ್ತು ರೆಫರಲ್ ಕಿವಿಯೋಲೆಗಳನ್ನು ಪ್ರಾರಂಭಿಸಲು ಮೊದಲ ಆರ್ಡರ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ",
      totalEarn: "ಒಟ್ಟು ಗಳಿಕೆಗಳು",
      walletBalance: "ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್",
      inviteMsg: "ನಿಮ್ಮ ಸ್ನೇಹಿತರನ್ನು fashionLeo ಗೆ ಆಹ್ವಾನಿಸಿ ಮತ್ತು ಅವರ ಮೊದಲ ಯಶಸ್ವಿ ಆದೇಶದಲ್ಲಿ ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್ ಪಡೆಯಿರಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಓದಿ",
      refferalCode: "ರೆಫರಲ್ ಕೋಡ್",
    },
    chatPopUp: {
      actualPrice: "ವಾಸ್ತವ ಬೆಲೆ",
      makeAOffer: "ಒಂದು ಪ್ರಸ್ತಾಪವನ್ನು ಮಾಡಿ",
      am: "ಆಮ್",
      pm: "pm",
      acceptOffer: "ಆಫರ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿ",
      send: "ಕಳುಹಿಸು",
      addToCart: "ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸು",
    },
    sortForm: {
      newArrivals: "ಹೊಸ ಆಗಮನ",
      mostPopular: "ಅತ್ಯಂತ ಜನಪ್ರಿಯ",
      priceLowToHigh: "ಬೆಲೆ (ಕಡಿಮೆಯಿಂದ ಹೆಚ್ಚು)",
      priceHighToLow: "ಬೆಲೆ (ಹೆಚ್ಚು ಕಡಿಮೆ",
      ratingHighToLow: "ರೇಟಿಂಗ್‌ಗಳು(ಹೆಚ್ಚು ಕಡಿಮೆ)",
      sort: 'ವಿಂಗಡಿಸು',
    },
    myFollowing: {
      ratings: "ರೇಟಿಂಗ್‌ಗಳು",
      followings: "ಕೆಳಗಿನವುಗಳು",
      products: "ಉತ್ಪನ್ನಗಳು",
      allProducts: "ಎಲ್ಲಾ ಉತ್ಪನ್ನಗಳು",
      showing: "ತೋರಿಸುತ್ತಿದೆ",
      follow: "ಅನುಸರಿಸಿ",
      following: "ಅನುಸರಿಸುತ್ತಿದೆ",
    },
    bankDetails: {
      title: "ಬ್ಯಾಂಕ್ ವಿವರಗಳು",
      bankName: "ಬ್ಯಾಂಕ್ ಹೆಸರು",
      accountNumber: "ಖಾತೆ ಸಂಖ್ಯೆ",
      confirmAccountNumber: "ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ದೃಢೀಕರಿಸಿ",
      swiftCode: "ಸ್ವಿಫ್ಟ್ ಕೋಡ್ / ರೂಟಿಂಗ್ ಸಂಖ್ಯೆ",
      accountHolderName: "ಖಾತೆದಾರನ ಹೆಸರು",
      bankCode: "ಬ್ಯಾಂಕ್ ಸಂಕೇತ",
      branchCode: "ಬ್ರಾಂಚ್ ಕೋಡ್",
      accountType: "ಖಾತೆಯ ಪ್ರಕಾರ",
      submit: "ಸಲ್ಲಿಸು",
      bankNameError: "ಬ್ಯಾಂಕ್ ಹೆಸರು ಅಗತ್ಯವಿದೆ.",
      NameError: "ಖಾತೆದಾರರ ಹೆಸರು ಅಗತ್ಯವಿದೆ.",
      AccountError: "ಖಾತೆ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ.",
      accountError: "ಖಾತೆ ಸಂಖ್ಯೆ 6 ಕ್ಕಿಂತ ಹೆಚ್ಚಿರಬೇಕು.",
      confirmAccountError: "ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ದೃಢೀಕರಿಸುವ ಅಗತ್ಯವಿದೆ.",
      ifscError: "ಸ್ವಿಫ್ಟ್ ಕೋಡ್ / ರೂಟಿಂಗ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ.",
      bankCodeError: "ಬ್ಯಾಂಕ್ ಕೋಡ್ ಅಗತ್ಯವಿದೆ.",
      branchCodeError: "ಬ್ರಾಂಚ್ ಕೋಡ್ ಅಗತ್ಯವಿದೆ.",
      accountTypeError: "ಖಾತೆಯ ಪ್ರಕಾರದ ಅಗತ್ಯವಿದೆ.",
    },
    home: {
      recentProducts: "ತಾಜಾ ಆಗಮನ",
      messageText: "ನಿಮ್ಮ ಸಂದೇಶವನ್ನು ಟೈಪ್ ಮಾಡಿ",
      button: { viewAll: "ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ" },
      categories: { title: "ವರ್ಗಗಳು" },
      brand: { title: "ಬ್ರಾಂಡ್‌ಗಳ ಮೂಲಕ ಶಾಪಿಂಗ್ ಮಾಡಿ" },
      fCollection: { title: "ವೈಶಿಷ್ಟ್ಯಗೊಳಿಸಿದ ಉತ್ಪನ್ನಗಳು" },
      flashSale: { title: "ಫ್ಲ್ಯಾಶ್ ಮಾರಾಟ", left: "ಎಡಕ್ಕೆ" },
      trProducts: { title: "ಟ್ರೆಂಡಿಂಗ್ ಉತ್ಪನ್ನಗಳು" },
      topProducts: { title: "ಉನ್ನತ ಉತ್ಪನ್ನಗಳು" },
      nearbySellers: { title: "ಹತ್ತಿರದ ಮಾರಾಟಗಾರರು" },
      recentViewProduct: {title: "ಇತ್ತೀಚಿನ ವೀಕ್ಷಣೆ ಉತ್ಪನ್ನ"},
      providingFeatures: {
        i: {
          main: "ಉಚಿತ ಶಿಪ್ಪಿಂಗ್ ಮತ್ತು ರಿಟರ್ನ್",
          secondary: "ಅರ್ಹ ಆರ್ಡರ್‌ಗಳಲ್ಲಿ ಲಭ್ಯವಿದೆ",
        },
        ii: {
          main: "ಹಣ ಗ್ಯಾರಂಟಿ",
          secondary: "30 ದಿನಗಳ ಹಣವನ್ನು ಹಿಂತಿರುಗಿಸುವ ಭರವಸೆ",
        },
        iii: {
          main: "ಆನ್‌ಲೈನ್ ಬೆಂಬಲ",
          secondary: "ನಾವು ದಿನದ 24/7 ಆನ್‌ಲೈನ್ ಅನ್ನು ಬೆಂಬಲಿಸುತ್ತೇವೆ",
        },
        iv: {
          main: "ಸುರಕ್ಷಿತ ಪಾವತಿಗಳು",
          secondary: "ಎಲ್ಲಾ ಪಾವತಿ ಸುರಕ್ಷಿತ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹವಾಗಿದೆ",
        },
      },
    },
    affiliate: {
      headingRegister: "Affiliate Dashboard",
      phone: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
      firstName: "ಮೊದಲ ಹೆಸರು",
      lastName: "Last Name",
      emailAddress: "ಇಮೇಲ್ ವಿಳಾಸ",
      terms: " ನಾನು ವೆಬ್‌ಸೈಟ್ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಓದಿದ್ದೇನೆ ಮತ್ತು ಒಪ್ಪುತ್ತೇನೆ",
      register: "register",
      password: "ಗುಪ್ತಪದ",
      amount: "ಮೊತ್ತ",
    },
    affiliateDashboard: {
      dashboard: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
      commission: "ಆಯೋಗ",
      visits: "ಭೇಟಿಗಳು",
      payment: "ಪಾವತಿ",
      link: "ಲಿಂಕ್ ಜನರೇಟರ್",
      settings: "ಸಂಯೋಜನೆಗಳು",
      generateLinks: "ಲಿಂಕ್ ಅನ್ನು ರಚಿಸಿ",
      directBank: "ನೇರ ಬ್ಯಾಂಕ್/ವೈರ್ ವರ್ಗಾವಣೆ",
      paymentInfo: "ಪಾವತಿ ಮಾಹಿತಿ",
      status: "ಎಲ್ಲಾ ಸ್ಥಿತಿ",
      rowsPerpage: "ಸಾಲುಗಳು",
      urlTitle: "ನಿಮ್ಮ ರೆಫರಲ್ URL ಆಗಿದೆ :",
      commissionHeading: "ಇತ್ತೀಚಿನ ಆಯೋಗಗಳು",
    },
    affiliatePages: {
      recenteVisit: "ಇತ್ತೀಚಿನ ಭೇಟಿಗಳು (ಎಲ್ಲವನ್ನೂ ವೀಕ್ಷಿಸಿ)",
      affiliate: "Affiliate",
      totalEarn: "ಒಟ್ಟು ಗಳಿಕೆಗಳು",
      totalpaid: "ಒಟ್ಟು ಪಾವತಿಸಲಾಗಿದೆ",
      totalpaid: "ಒಟ್ಟು ಮರುಪಾವತಿ ಮಾಡಲಾಗಿದೆ",
      balance: "ಸಮತೋಲನ",
      commitssionRate: "ಆಯೋಗದ ದರ",
      conversionRate: "ಪರಿವರ್ತನೆ ದರ",
      visits: "ಭೇಟಿಗಳು",
      visitsToday: "ಇಂದು ಭೇಟಿ",
      date: "ದಿನಾಂಕ",
      product: "ಉತ್ಪನ್ನ",
      orderNo: "ಆದೇಶ ಸಂಖ್ಯೆ.",
      commissionPrice: "ಆಯೋಗದ ಬೆಲೆ",
      status: "ಸ್ಥಿತಿ",
    },
  },
};
export default kn;