import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en";
import tam from "./tm";
import tel from "./tl";
import hi from './hi';
import kan from './kan'
import { lang } from "moment";

// 1l8n file will take three different files en for english language variables ,spanish for spanish language, pt for portuguese 
// name and structure of all the variables should be same in all three files en , sp , pt 
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en,
    hi,
    tel,
    tam,
    kan,
  },
  lng: "en",
  fallbackLng: "en",
});

export default i18n;
