import { Typography } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import AppConfig from "../../appConfig";
import ProductCard from "../../core-components/ProductCard";
import ViewAllButton from "../../core-components/ViewAllButton";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";


const TrendingProducts = ({ trendingProductData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { innerWidth } = window;
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";

    return (
      <div className=" relative z-10">
        {props.type === "next" ? (
          <ArrowRight
            className="absolute border-2 bg-black shadow-lg shadow-black  w-8 h-8 opacity-90 sm:w-10 sm:h-10 rounded-full right-0 top-32 lg:-right-4 sm:top-36  sm:right-10"
            onClick={props.onClick}
            style={{borderColor:"#009acc", color:"#009acc"}}
          />
        ) : (
          <ArrowLeft
            onClick={props.onClick}
            className="absolute border-2 bg-black shadow-lg shadow-black opacity-90 w-8 h-8 sm:w-10 sm:h-10 rounded-full left-0 top-32 lg:-left-4  sm:top-36 sm:left-10"
            style={{borderColor:"#009acc", color:"#009acc"}}
          />
        )}
      </div>
    );
  }
  const settings = {
    dots: false,
    slidesToShow: 5,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    autoplay: true,
    arrows: true,
    infinite: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 280, settings: { slidesToShow: 1 } },
      { breakpoint: 640, settings: { slidesToShow: 2 } },
      { breakpoint: 767, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 5 } },
      { breakpoint: 1300, settings: { slidesToShow: 5 } },
    ],
  };

  // function to add item to wishlist
  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      // wishItemToggle(data.id);

      _form.append("product_id", data.id);
      // add item to wishlist api
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      history.push(AppConfig.routes.login);
    }
  };

  const user_id = sessionStorage.getItem("user_id");

  // getting wishlist count from redux
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  // Below Function is for adding product to cart

  return (
    <React.Fragment>
      <div className="bg-background dark:bg-darkBackground">
        <div className={`d-flex flex-md-column flex-row sm:flex-row align-items-center pr-3 pt-7 pt-md-0 pb-2 pb-md-0 pl-2 pr-md-8 pl-md-8 sm:px-10 align-items-center justify-content-between`}>
          <Typography
            variant="subtitle1"
            className="font-normal text-fontDark dark:text-fontWhite text-lg tracking-wider sm:tracking-normal sm:text-2xl uppercase"
          >
            {t("home.trProducts.title")}
          </Typography>
          <div className="lg:float-right ">
            <ViewAllButton type="trending-products" />
          </div>
        </div>

        {/* if length of trendingProductData is greater than only we will show slider component else we will show grid layout Because when less than 5 products the slider starts repeating products */}
        {trendingProductData?.length > 5 ? (

          <div className={`md:container mx-auto md:px-3 lg:px-7 mt-4 md:mt-10`} style={{ width: innerWidth === 820 ? "720px" : innerWidth < 900 ? innerWidth - 30 : '100%', padding: (innerWidth < 900 && innerWidth > 540) && '0px' }}>
            <Slider {...settings} className=" flex">
              {trendingProductData == undefined
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, key) => (
                  <Skeleton
                    height={300}
                    width={200}
                    className="mx-10"
                    key={key}
                  />
                ))
                : trendingProductData?.map((image, key) => {
                  return (
                    <div className="px-2 lg:px-0" key={key}>
                      <ProductCard
                        image={image}
                        shop={true}
                        type="Trending Product"
                        typeForUrl="trending-product"
                        addToWishlist={addToWishlist}
                      // wishArray={wishArray}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        ) : (
          <div className="grid lg:grid-cols-5 sm:grid-cols-4 sm:gap-4 grid-cols-2 pt-4 md:pt-10 gap-3 lg:gap-8 mx-2 sm:mx-10">
            {/* below condition reason : Because initially featuredProductData will be undefined untill data comes from home component */}
            {trendingProductData == undefined
              ? [1, 2, 3, 4, 5].map((ele, key) => (
                <Skeleton height={300} className="" key={key} />
              ))
              : trendingProductData.map((image, key) => {
                return (
                  <React.Fragment key={key}>
                    <ProductCard
                      image={image}
                      type="Featured Product"
                      typeForUrl="featured-product"
                      addToWishlist={addToWishlist}
                    // wishArray={wishArray}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TrendingProducts;
