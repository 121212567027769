import { Transition } from "@headlessui/react";
import {
  Box,
  Button, CircularProgress, Dialog, Grid, makeStyles,
  Typography
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AppConfig from "../../appConfig";
import AlertComponent from "../../core-components/AlertComponent";
import TextFieldFormsy from '../../core-components/TextFieldFormsy';
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService/apiService";
import blurbackground from './blurbackground.png';

const useStlyes = makeStyles((theme) => ({
  container: {
    clear: "both",
    position: "relative",
  },
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },

    "--main-bg-color": "pink",
  },
  input: {
    color: "pink",
  },
  modal: {
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],

    padding: theme.spacing(2, 4, 3),
  },
  chatdiv: {
    height: "82vh",
    overflow: "auto",
    position: "relative",
    marginTop: "10px",

    "&:hover": {},

    "&::-webkit-scrollbar": { width: 10 },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#597593",
      borderRadius: "0px",
    },
    "&::-webkit-scrollbar-track": {
      // height:"4px"
    },

    [theme.breakpoints.down("md")]: {
      height: "77vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "89vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "87vh",

      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
  },

  listItem: {
    cursor: "pointer",
    // borderBottom:"0.5px solid #0c0c1c",
    boxShadow: "0 0.1px 0 rgb(165, 151, 91)",

    backgroundColor: "white",
    "& > *": {
      color: "black",
    },
    "&:hover": {
      // backgroundColor:"rgb(173, 216, 230)",
    },
  },
  arrow: {
    width: "12px",
    height: "20px",
    overflow: "hidden",
    position: "relative",
    float: "left",
    top: "6px",
    right: "-1px",
  },

  outer: {
    width: "0",
    height: "0",
    borderRight: "20px solid #000000",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    position: "absolute",
    top: "0",
    left: "0",
  },
  inner: {
    width: "0",
    height: "0",
    borderRight: "20px solid #ffffff",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    position: "absolute",
    top: "0",
    left: "2px",
  },
  messageBody: {
    width: "300px",
    height: "auto",
    border: "1px solid #000000",
    backgroundColor: "#ffffff",
    padding: "6px 8px",
    WebkitBorderRadius: "5px",
    MozBorderRadius: "5px",
    BorderRadius: "5px",
    borderRadius: "5px",
  },
  button: {
    // backgroundImage: 'linear-gradient(to right, #E55D87 0%, #5FC3E4 51%, #E55D87 100%)',
    background:
      "linear-gradient(to bottom,rgba(255,255,255,0) 0%, #597593 100%)",

    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    color: AppConfig.color.button,
    borderRadius: "10px",
    borderWidth: '2px',
    display: "block",
    boxShadow: "0 0 5px 0 #ffffff inset",
    "&:hover": {
      background: "#597593",
    },
  },
}));
export default function MyChats() {
  const [messageId, setMessageId] = useState();
  const [negotiatedPrice, setNegotiatedPrice] = useState();
  const acceptOffer = (messageId) => {
    const form = new FormData();
    form.append("message_id", messageId);
    form.append("status", "accepted");
    ApiService.acceptOffer(form).then((res) => { });
  };
  const addToCart = (messageId, negotiatedPrice) => {
    setMessageId(messageId);
    setNegotiatedPrice(price);
    const form = new FormData();
    form.append("message_id", messageId);
    form.append("product_id", product_id);
    form.append("session_id", user_id);
    form.append("vendor_id", vendor_id);
    form.append("quantity", "1");
    form.append("orignal_price", negotiatedPrice);
    form.append("offer_price", negotiatedPrice);
    ApiService.addToCart(form).then((res) => {
      if (res.code === 200) {
        store.dispatch({
          type: ActionTypes.ADD_USER_CART_COUNT,
          payload: res.data.data.cart_count,
        });
      } else if (res.code == 422) {
        setAlertContent(res.errros);
        setOpenAlertModal(true);
      }
    });
  };
  const SetproductPrice = (currentValue, index) => {
    var myvalue;
    // Below we are setting the price and offer Price of the product
    // and explaination for this has been done in setProductPrice function  of attributeModal Component
    const filteredArray = firstIndexArray.filter((firstIndexArray) => {
      return firstIndexArray.attribute !== currentValue.attribute;
    });

    filteredArray.push(currentValue);

    let initialPrice = [];
    let initialOfferPrice = [];
    filteredArray.map((filteredArray) => {
      initialPrice.push(filteredArray.price);
      initialOfferPrice.push(filteredArray.offer_price);
    });

    const maximumPrice = initialPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });
    const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });

    setFirstIndexArray(filteredArray);
    setPrice(maximumPrice);

    if (
      maximumOfferPrice == 0 ||
      maximumOfferPrice == null ||
      maximumOfferPrice == "null"
    ) {
      setOfferPrice(maximumPrice);
    } else {
      setOfferPrice(maximumOfferPrice);
    }
  };
  const [msg, setMsg] = useState("");

  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);
  const [myProductChat, setMyproductChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [profile, setProfile] = useState();
  const [state, setState] = useState(1);
  const [productChatId, setProductChatId] = useState();
  const [product_id, setProduct_id] = useState();
  const [vendor_id, setVendor_id] = useState();
  const location = useLocation();
  const [price, setPrice] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [currency_type, setCurrencyType] = useState();
  const [firstIndexArray, setFirstIndexArray] = useState([]);
  const [optionKey, setOptionKey] = useState([]);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [counterOfferError, setCounterOfferError] = useState("");
  // const handleOfferModalClose = () => {
  //   setCounterOfferError("");
  //   setOpenOfferModal(false);
  //   setFirstIndexArray([]);
  // };
  const [darkTheme, setDarkTheme] = useState(false)
  const currentTheme = window.localStorage.getItem('theme')
  useEffect(() => {
    if (currentTheme == 'dark') {

      setDarkTheme(true)
    }
    else {
      setDarkTheme(false)
    }
  }, [currentTheme])
  // const makeaOffer = () => {
  //   ApiService.getProductById(product_id).then((res) => {
  //     if (res.code === 200) {
  //       if (Array.isArray(res.data.data.product.OptionKey)) {
  //         setPrice(res.data.data.product.price);
  //         setCurrencyType(res.data.data.product.currency_data.symbol)
  //         if (res.data.data.product.offer_price == null) {
  //           setOfferPrice(res.data.data.product.price);
  //         } else {
  //           setOfferPrice(res.data.data.product.offer_price);
  //         }
  //       } else {
  //         setOptionKey(Object.entries(res.data.data.product.OptionKey));
  //         if (Array.isArray(res.data.data.product.OptionKey)) {
  //           setPrice(res.data.data.product.price);
  //           if (res.data.data.product.offer_price !== null) {
  //             setOfferPrice(res.data.data.product.offer_price);
  //           } else {
  //             setOfferPrice(res.data.data.product.price);
  //           }
  //           setLoading(false);
  //         } else {
  //           // Below we are setting the price and offer Price of the product
  //           // and explaination for this has been done in useEffect of attributeModal Component
  //           Object.entries(res.data.data.product.OptionKey).map((optionkey) => {
  //             firstIndexArray.push(optionkey[1][0]);
  //           });
  //           let initialPrice = [];
  //           let initialOfferPrice = [];
  //           firstIndexArray.map((firstIndexArray) => {
  //             initialPrice.push(firstIndexArray.price);
  //             initialOfferPrice.push(firstIndexArray.offer_price);
  //           });

  //           const maximumPrice = initialPrice.reduce(function (a, b) {
  //             return Math.max(a, b);
  //           });
  //           const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
  //             return Math.max(a, b);
  //           });

  //           setPrice(maximumPrice);

  //           if (
  //             maximumOfferPrice == 0 ||
  //             maximumOfferPrice == null ||
  //             maximumOfferPrice == "null"
  //           ) {
  //             setOfferPrice(maximumPrice);
  //           } else {
  //             setOfferPrice(maximumOfferPrice);
  //           }
  //         }
  //       }
  //       setOpenOfferModal(true);
  //     }
  //   });
  // };
  var user_id = window.sessionStorage.getItem("user_id");
  const messListener = (id, data) => {
    let PusherClient = new Pusher("87909e984abafa89a22c", {
      cluster: "ap2",
      wsHost: "fashion-leo.com.br",
      wsPort: "6001",
      wssHost: "fashion-leo.com.br",
      wssPort: "6001",
      enabledTransports: ["ws", "wss"],
      forceTLS: false,
    });
    const channel = PusherClient.subscribe("ProductChat");
    const echo = new Echo({
      broadcaster: "pusher",
      client: PusherClient,
    });
    // echo channel function will listen to product chat channel and we will also pass id of the product and whenever message on that product comes it will go inside listener function and we will set that incoming object to our product chat data array
    echo.channel(`ProductChat.${id}`).listen("ProductChatEvent", (ev) => {
      setMyproductChat((myProductChat) => [...myProductChat, ev.data]);
    });
    // after listening we have to simultaneously call the productEndChatEvent listner so that we only get the message on the product of which we have opened the chats
    echo
      .channel(`ProductChat.${id}`)
      .listen("ProductChatEndEvent", async (e) => { });
  };

  useEffect(() => {
    // scolling to the messageEndRef div because it is at bottom of the all chats so that user will always see latest chat untill he go upwards
    messageEndRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  }, [myProductChat]);

  useEffect(() => {
    ApiService.getmyChats().then((res) => {
      setImages(res.data.data);
    });
    if (location.state !== undefined) {
      ApiService.getchatbyproduct(location.state.chatId).then((res) => {
        if (res.code == 200) {
          // setting chat data in our state
          setProduct_id(location.state.productId);
          setMyproductChat(res.data.data);
          setVendor_id(res.data.data[0].user._id);
          setData(location.state.productName);

          // for scolling to bottom
          messageEndRef.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      });
    }
  }, [state]);

  const darkThemeCss = {
    backgroundImage: darkTheme ? "url(https://cdn.allthings.how/wp-content/uploads/2021/07/allthings.how-how-to-enable-dark-mode-in-teams-chat-on-windows-11-teams-chat-dark-mode.png)" : "url(https://cdn.allthings.how/wp-content/uploads/2021/07/allthings.how-how-to-enable-dark-mode-in-teams-chat-on-windows-11-teams-chat-dark-mode.png)",
    filter: 'hue-rotate(316deg)',

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // // backgroundSize:"100% 100%",
    // backgroundPosition:"center",
  }
  const whiteThemecss = {
    backgroundSize: "contain",
    //  `url(${blurbackground})`
    backgroundImage: modal ?
      `url(${blurbackground})`
      : "url(https://img.freepik.com/free-vector/chatting-concept-illustration_114360-500.jpg?w=2000)",


  }
  const setMessage = () => {

    // below getting user id from session storage
    if (typeof msg === "undefined" || msg === undefined) {
      setCounterOfferError('please enter valid offer');
    } else {
      const user_id = sessionStorage.getItem("user_id");

      const form = new FormData();
      form.append("content", msg);
      form.append("user_id", user_id);
      form.append("product_id", product_id);
      form.append("to_user", vendor_id);

      // Below function is for sending message to the admin
      ApiService.sendChat(productChatId, form).then((res) => {
        if (res.code == 200) {
          setMsg("");
          setCounterOfferError("");
          refreshProductChat();
        } else {
          setCounterOfferError('please enter valid offer');
        }
      });
    }
  };
  const sendMessageOnEnter = (e, offer) => {
    if (e.code == "Enter" || e == "Enter") {

      if (offer != "" && Number(msg) > Number(offerPrice)) {
        setCounterOfferError("Counter price cant be more than actual price");
        setMsg("");
      } else {
        setOpenOfferModal(false);
        const user_id = sessionStorage.getItem("user_id");
        if (msg == "") {
        } else {
          const form = new FormData();
          if (openOfferModal) {
            form.append("type", "deal");
            firstIndexArray.map((firstIndexArray) => {
              form.append(
                `product_option[${firstIndexArray.attribute}]`,
                firstIndexArray.values
              );
            });
          }
          form.append("content", msg);
          form.append("user_id", user_id);
          form.append("product_id", product_id);
          form.append("to_user", vendor_id);
          // Below function is for sending message to the Vendor

          ApiService.sendChat(productChatId, form).then((res) => {
            if (res.code == 200) {
              setMsg("");
              refreshProductChat();
            }
          });
        }
      }
    }
  };
  const messageEndRef = useRef(null);
  const setChangeMessage = (e) => {
    setMsg(e.target.value);
  };
  const setData = (e) => {
    if (window.screen.availWidth <= 1023) {
      setOpenMessageBox(false);
    }
    setTitle(e);
    setModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const drawer = Boolean(anchorEl);
  const toggleDrawer = (event) => {
    setModal(false);
    setOpenMessageBox(true);
  };

  const handleClose = (e) => {
    setModal(false);
    setAnchorEl(null);
  };

  const [chatData, setChatData] = useState("");
  const [chatModel, setChatModel] = useState(false);
  var obj = {
    name: "ghoyraiuas",
  };

  const handlepopupClose = () => {
    setChatModel(false);
  };
  // Below Function is for getting chat data of a particular product
  const getChatByProduct = (id, name, vendor_id, productChatid, product) => {
    setProduct_id(id);
    setVendor_id(vendor_id);
    setProductChatId(productChatid);
    ApiService.getchatbyproduct(productChatid).then((res) => {
      if (res.code == 200) {
        // setting chat data in our state
        setMyproductChat(res.data.data);
        setData(name);
        messageEndRef.current?.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
      messListener(productChatid, res.data.data);
    });

    // below: setting product Data to the state. we will use this data in sending the new message in send message function
  };

  // below function will get called just after message get sends to admin.it will again call the getchatbyproduct function so that chat box get refreshed
  const refreshProductChat = () => {
    ApiService.getchatbyproduct(productChatId).then((res) => {
      if (res.code == 200) {
        setMyproductChat(res.data.data);
      }
    });
  };
  const [openMessageBox, setOpenMessageBox] = useState(true);
  const classes = useStlyes();
  const { t } = useTranslation();

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };
  const [alertContent, setAlertContent] = useState("");
  return (
    <Box>
      {/* <Dialog
        open={openOfferModal}
        onClose={handleOfferModalClose}
        // fullWidth
        // maxWidth="sm"
      >
        <>
          <div className={`flex justify-between items-center bg-primary `}>
            <div className="d-flex p-2 items-center">
              <ListItemAvatar className=" ml-4">
                <Avatar alt="Remy Sharp" src={profile} />
              </ListItemAvatar>
              <Typography className=" text-white opacity-100 ">
                {title}
              </Typography>
            </div>

            <CloseIcon
              className="mr-4 cursor-pointer text-white "
              onClick={() => {
                setCounterOfferError("");
                setOpenOfferModal(false);
                setFirstIndexArray([]);
              }}
            />
          </div>
          <div className="pl-4">
            {optionKey.length > 0 &&
              optionKey.map((optionKey, key) => (
                <>
                  <h2 className="mt-4 mb-2 font-bold">{optionKey[0]} </h2>
                  <Grid className="mt-2" container>
                    {optionKey[1].map((product_option, key) => (
                      <Grid item lg="auto" md={3}>
                        <Button
                          className={` mx-2 ${firstIndexArray.find(
                            (o) =>
                              o.attribute === product_option.attribute &&
                              o.price === product_option.price &&
                              o.values === product_option.values
                          )
                            ? "bg-button "
                            : "bg-background "
                            }`}
                          onClick={() => {
                            SetproductPrice(product_option, key);
                          }}
                          variant="contained"
                          color="secondary"
                        >
                           <Typography className={` ${firstIndexArray.find(
                            (o) =>
                              o.attribute === product_option.attribute &&
                              o.price === product_option.price &&
                              o.values === product_option.values
                          )
                            ?"text-white": "text-primary"}`}>
                          {product_option.values}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ))}
          </div>
          <div className="my-3 px-2 mt-4 mx-2 gap-2 flex items-center">
            <div className="text-sm font-bold text-gray-700">
              {t("chatPopUp.actualPrice")}
            </div>
            <div className="text-sm font-bold text-primary">
              {" "}
              {currency_type} {offerPrice}
            </div>
          </div>
          <div className="my-4 ml-4 flex gap-4">
            <TextFieldFormsy
              id="outlined-basic"
              type="number"
              onKeyPress={(e) => {
                sendMessageOnEnter(e, "making a offer");
              }}
              onChange={(e)=>setChangeMessage(e)}
              placeholder="Your counter offer"
              value={msg}
              // variant="outlined"
              // inputProps={{ className: classes.input }}
              className="bottom-0 text-primary bg-loginDark flex-1 p-2 rounded-md"
              serverError={counterOfferError}

              sx={{
                input: {
                  // color: "#black",
                  padding: "8px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                },
                width: "100%",
                borderRadius: "80px",
                marginLeft: "8px",
                "& label": {
                  // color: "white",
                },
                "& label.Mui-focused": {
                  // color: "white",
                },
                "& .MuiInput-underline:after": {
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
            <SendIcon
              className="text-3xl text-primary cursor-pointer mr-1.5 my-4 "
              onClick={() => {
                sendMessageOnEnter("Enter", "making a offer");
              }}
            />
          </div>
        </>
      </Dialog> */}
      {window.screen.availWidth >= 1024 ? (
        <Box>
          <div className="grid grid-cols-1  absolute bottom-0 lg:mx-0 w-full lg:grid-cols-3 ">
            <div
              className={
                classes.chatdiv +
                `${openMessageBox ? " block " : " none "}` +
                "overflow-hidden"
              }
            >
              <div className="  bg-primary border-l-0 py-5 px-2 dark:bg-darkBackground  border-r-4 text-white w-full ">
                <h2 className="text-white font-weight-bold">
                  {" "}
                  {t("chats.chats")}{" "}
                </h2>
              </div>

              <div className="w-full h-94% bg-background dark:bg-darkBackground border-r-4 overflow-auto ">
                <List
                  className="flex-1 dark:bg-lightDarkBackground  "
                  sx={{
                    width: "100%",
                    "& ul": { padding: 0, overflow: "hidden" },
                  }}
                >
                  {loading ? (
                    <div className="overflow-y-scroll relative h-500 w-full flex justify-center items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {images.length > 0 ? (
                        <>
                          {" "}
                          {images.map((ProductChatData) => (
                            <React.Fragment>
                              <ListItem
                                className="cursor-pointer dark:bg-lightDarkBackground"
                                onClick={() => {
                                  getChatByProduct(
                                    ProductChatData.user.product_id,
                                    ProductChatData.user.product_name,
                                    ProductChatData.user.id,
                                    ProductChatData.id,
                                    ProductChatData
                                  );
                                  // setProfile(ProductChatData.user.product_image);
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={ProductChatData.user.product_image}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  className="text-black dark:text-fontWhite"
                                  primary={ProductChatData.user.product_name}
                                  secondary={ProductChatData.message}
                                />
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          ))}
                          <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={chatModel}
                            onClose={handlepopupClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={chatModel}>
                              <div
                                className={` ${classes.paper} flex items-center h-auto rounded-2xl flex-col`}
                              >
                                <p
                                  className="font-bold text-white"
                                  id="transition-modal-title"
                                >
                                  {chatData}{" "}
                                </p>
                                <div>
                                  <Button
                                    onClick={() => {
                                      setChatModel(false);
                                    }}
                                    className="my-2 mt-4"
                                    variant="contained"
                                  >
                                    {t("chats.noThanks")}
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      history.push("/choose-plan");
                                    }}
                                    className="my-2 mx-2 mt-4"
                                    variant="contained"
                                  >
                                    {t("chats.upgradeNow")}
                                  </Button>
                                </div>
                              </div>
                            </Fade>
                          </Modal>
                        </>
                      ) : (
                        <div className="h-500 flex justify-center items-center w-25vw  ">
                          <h1 className="text-base dark:text-fontWhite text-black">
                            {t("chats.noChats")}
                          </h1>
                        </div>
                      )}
                    </>
                  )}
                </List>
              </div>
            </div>

            {
              <div
                className={clsx(
                  classes.chatdiv,
                  " lg:col-span-2 bg-no-repeat  bg-center  bg-blend-darken "
                )}


                style={
                  darkTheme ? darkThemeCss : whiteThemecss

                }
              >
                <div
                  className="h-full w-full"
                  style={{ filter: darkTheme ? 'hue-rotate(-316deg)' : '' }}
                >
                  {modal && (
                    <div className="absolute bottom-1 right-0 w-full z-2 flex items-center justify-between bg-background rounded-40 border-1 border-solid border-secondary">
                      <TextField
                        id="outlined-basic"
                        onKeyPress={(e) => sendMessageOnEnter(e, "")}
                        placeholder="Type Your Message"
                        value={openOfferModal ? "" : msg}
                        // variant="outlined"
                        // inputProps={{ className: classes.input }}
                        className="bottom-0"
                        onChange={setChangeMessage}
                        sx={{
                          input: {
                            color: "#black",
                            padding: "8px",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                          },
                          width: "100%",
                          borderRadius: "80px",
                          marginLeft: "8px",
                          "& label": {
                            color: "white",
                          },
                          "& label.Mui-focused": {
                            color: "white",
                          },
                          "& .MuiInput-underline:after": {
                            border: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                      <SendIcon
                        className="text-3xl text-primary cursor-pointer mr-6px"
                        onClick={setMessage}
                      />
                    </div>
                  )}
                  <div className="h-full">
                    {modal ? (
                      <>
                        <div className="bg-primary dark:bg-darkBackground flex justify-between items-center p-1">
                          <div className="d-flex">
                            <ListItemAvatar className="pt-2">
                              <Avatar alt="Remy Sharp" src={profile} />
                            </ListItemAvatar>
                            <Typography className=" py-3 text-white opacity-100 ">
                              {title}
                            </Typography>
                          </div>
                          {/* <Button
                            onClick={() => {
                              makeaOffer();
                            }}
                            className={clsx(
                              classes.button,
                              "bg-primary text-background  border-fontWhite rounded-md mr-14 text-md font-normal w-30 uppercase"
                            )}
                          >
                            {t("chats.makeAOffer")}
                          </Button> */}
                          <CloseIcon
                            className="mr-4 cursor-pointer text-white "
                            onClick={toggleDrawer}
                          />
                        </div>
                        <div className="w-full overflow-y-auto overflow-x-hidden h-4/5">
                          {myProductChat.map((ProductChatData, key) => (
                            <React.Fragment>
                              <Typography className="text-center">
                                {key == 0 ? (
                                  <>
                                    <div className="bg-primary   shadow-md w-fit m-auto mt-1 p-2 rounded-2xl">
                                      <h1 className="text-white font-bold ">
                                        {ProductChatData.createdAt.slice(0, 10)}{" "}
                                      </h1>
                                    </div>
                                  </>
                                ) : ProductChatData.createdAt.slice(0, 10) !==
                                  myProductChat[key - 1].createdAt.slice(
                                    0,
                                    10
                                  ) ? (
                                  <div className="bg-primary shadow-md w-fit m-auto p-2 rounded-2xl">
                                    <h1 className="text-white font-bold ">
                                      {ProductChatData.createdAt.slice(0, 10)}{" "}
                                    </h1>
                                  </div>
                                ) : null}
                              </Typography>
                              <div
                                className={`bubble ${ProductChatData.user._id == user_id
                                  ? "m-auto"
                                  : "m-1"
                                  } h-auto mb-5 mt-3 mr-2 w-fit block  shadow-1`}
                              >
                                <div
                                  className={`rounded-chatscreen py-0.5 leading-tight px-1 ${ProductChatData.receiver_msg == ""
                                    ? "bg-primary"
                                    : "bg-primary"
                                    } ,shadow-md `}
                                >
                                  <Typography className="h-auto  text-white cursor-pointer">
                                    {" "}
                                    {ProductChatData.message_deal_status ==
                                      "accepted"
                                      ? ProductChatData.deal_accept_msg
                                      : ProductChatData.receiver_msg == ""
                                        ? ProductChatData.text
                                        : ProductChatData.user._id == user_id
                                          ? ProductChatData.sender_msg
                                          : ProductChatData.receiver_msg}
                                    <br />
                                    <span className="text-sm">
                                      {ProductChatData.createdAt.slice(11, 19)}
                                    </span>
                                    {parseInt(
                                      ProductChatData.createdAt.slice(11, 13)
                                    ) < 12 ? (
                                      <span className="ml-2 text-sm">
                                        {t("chats.am")}
                                      </span>
                                    ) : (
                                      <span className="text-sm">
                                        {t("chats.pm")}
                                      </span>
                                    )}
                                  </Typography>
                                  {Number(ProductChatData.user._id) !==
                                    Number(user_id) &&
                                    ProductChatData.receiver_msg !== "" && (
                                      <>
                                        {ProductChatData.message_deal_status ==
                                          "pending" && (
                                            <Button
                                              onClick={() =>
                                                acceptOffer(
                                                  ProductChatData.message_id
                                                )
                                              }
                                              className={clsx(
                                                classes.button,
                                                "fw-bold my-1 bg-primary text-background rounded-md mr-14 text-md font-normal w-30 uppercase"
                                              )}
                                            >
                                              {t("chats.acceptOffer")}
                                            </Button>
                                          )}
                                        {ProductChatData.message_deal_status ==
                                          "accepted" && (
                                            <Button
                                              onClick={() =>
                                                addToCart(
                                                  ProductChatData.message_id,
                                                  ProductChatData.text
                                                )
                                              }
                                              className={clsx(
                                                classes.button,
                                                "           bg-primary text-background my-1  rounded-md mr-14 text-md font-normal w-30 uppercase"
                                              )}
                                            >
                                              {t("chats.addToCart")}
                                            </Button>
                                          )}
                                      </>
                                    )}

                                  {Number(ProductChatData.user._id) ==
                                    Number(user_id) &&
                                    ProductChatData.message_deal_status ==
                                    "accepted" && (
                                      <Button
                                        onClick={() =>
                                          addToCart(
                                            ProductChatData.message_id,
                                            ProductChatData.text
                                          )
                                        }
                                        className={clsx(
                                          classes.button,
                                          "fw-bold my-1  bg-primary text-background rounded-md mr-14 text-md font-normal w-30 uppercase"
                                        )}
                                      >
                                        {t("chats.addToCart")}
                                      </Button>
                                    )}
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          <div ref={messageEndRef}></div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            }
            {/* Chat section for small screen started */}
            {/* mobile chat section  */}
            <Transition
              show={drawer}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <List
                className="flex-1 "
                sx={{
                  width: "100%",
                  marginBottom: "42px",

                  overflow: "auto",
                  maxHeight: 450,
                  "& ul": { padding: 0 },
                }}
              >
                {images.map((ProductChatData) => (
                  <React.Fragment>
                    <ListItem onClick={() => setData(ProductChatData.name)}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={ProductChatData.name}
                        secondary={ProductChatData.message}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Transition>
          </div>
        </Box>
      ) : (
        <Box>
          {!modal ? (
            <div className={classes.chatdiv}>
              <div className="bg-primary px-3 py-3 border-red-500 text-white w-full border-l-0 fixed p-5 z-10">
                <h2 className="text-white  font-weight-bold">Chats</h2>
              </div>
              <List
                className="flex-1 "
                sx={{
                  width: "100%",
                  marginBottom: "42px",
                  marginTop: "40px",

                  "& ul": { padding: 0 },
                }}
              >
                {images.length > 0 ? (
                  images.map((ProductChatData) => (
                    <React.Fragment>
                      <ListItem
                        onClick={() => {
                          getChatByProduct(
                            ProductChatData.user.product_id,
                            ProductChatData.user.product_name,
                            ProductChatData.user.id,
                            ProductChatData.id,
                            ProductChatData
                          );
                          setProfile(ProductChatData.user.product_image);
                          setProduct_id(ProductChatData.user.product_id);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src={ProductChatData.user.product_image}
                          ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className="dark:text-fontWhite"
                          primary={ProductChatData.user.product_name}
                          secondary={ProductChatData.message}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                ) : (
                  <div className=" h-500 flex justify-center items-center w-90vw">
                    <h1 className="text-base dark:text-fontWhite text-black">
                      {t("chats.noChats")}
                    </h1>
                  </div>
                )}
              </List>
            </div>
          ) : (
            <div
              className={`${classes.button} dark:bg-lightDarkBackground lg:col-span-2 bg-no-repeat bg-contain bg-center bg-blend-overlay `}
            // className={clsx(classes.chatdiv, {` ${classes.button} lg:col-span-2 bg-no-repeat bg-contain bg-center `}
            // style={{

            //   backgroundImage: modal
            //     ? `url(${blurbackground})`
            //     : "url(https://img.freepik.com/free-vector/chatting-concept-illustration_114360-500.jpg?w=2000)",
            // }}
            >
              {modal && (
                <div className="absolute  bottom-32 right-0 w-full z-50 bg-background rounded-40 border-1 border-secondary">
                  <TextField
                    id="outlined-basic"
                    onKeyPress={(e) => sendMessageOnEnter(e)}
                    placeholder="Type Your Message"
                    value={msg}
                    className="bottom-0"
                    onChange={setChangeMessage}
                    sx={{
                      input: {
                        color: "#black",
                        padding: "8px",
                        paddingLeft: "18px",
                        paddingTop: "10px",
                      },
                      width: "100%",
                      marginLeft: "8px",
                      borderRadius: "80px",
                      "& label": {
                        color: "white",
                      },
                      "& label.Mui-focused": {
                        color: "white",
                      },
                      "& .MuiInput-underline:after": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                  <SendIcon
                    className="text-3xl text-white cursor-pointer mr-1.5"
                    onClick={setMessage}
                  />
                </div>
              )}
              <div className="h-full fixed w-screen ">
                <div className="bg-primary flex justify-between items-center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={profile} />
                  </ListItemAvatar>
                  <Typography className="pl-2 py-2 pt-3 text-white opacity-100 ">
                    {title.slice(0, 20)}...
                  </Typography>
                  <CloseIcon
                    className="cursor-pointer mr-4 text-white"
                    onClick={toggleDrawer}
                  />
                </div>
                {/* <Button
                  onClick={() => {
                    makeaOffer();
                  }}
                  className={`${classes.button} bg-primary border-fontWhite fw-bold my-4  rounded-md mr-14 ml-2 text-md font-normal w-30 uppercase text-background p-2.5`}
                >
                  {t("chats.makeAOffer")}
                </Button> */}
                <div className="w-full overflow-y-auto overflow-x-hidden h-56% pb-2.5">
                  {myProductChat.map((ProductChatData, key) => (
                    <React.Fragment>
                      <Typography className="text-current">
                        {/* if key of current element is 0 means it is the first so we will show data coming with the data without checking any other condition  */}
                        {key == 0 ? (
                          <>
                            <div className="bg-background mx-auto mb-0 my-1 shadow-md w-fit">
                              <h1 className="text-primary font-bold ">
                                {ProductChatData.createdAt.slice(0, 10)}{" "}
                              </h1>
                            </div>
                          </>
                        ) : // now for other dates we will not show date with every message so for that we will check if the date of current element and previous element is different then only data will get shown

                          // below condition is like previous.createat = current element.createdAt
                          ProductChatData.createdAt.slice(0, 10) !==
                            myProductChat[key - 1].createdAt.slice(0, 10) ? (
                            <div className="bg-background shadow-md w-fit mt-1 mb-0 mx-auto ">
                              <h1 className="text-primary font-bold ">
                                {ProductChatData.createdAt.slice(0, 10)}{" "}
                              </h1>
                            </div>
                          ) : null}
                      </Typography>
                      <div
                        // if user id in current element is same as my user id then i will do margin auto to align the div right side
                        className={`bubble shadow-1 h-auto mb-5 mt-3 max-w-70p mr-1.5 w-fit block

                                               ${ProductChatData.user._id ==
                            user_id
                            ? "ml-auto"
                            : "ml-2"
                          }`}
                      >
                        <div
                          className={`shadow-md rounded-chatscreen leading-tight  py-0.5 px-2
                                                ${ProductChatData.receiver_msg ==
                              ""
                              ? "bg-primary"
                              : "bg-primary"
                            }`}
                        >
                          <Typography className="h-auto  cursor-pointer text-white">
                            {/* this below logic will get cleard if u have productChatData opened in front of u  */}
                            {ProductChatData.message_deal_status == "accepted"
                              ? ProductChatData.deal_accept_msg
                              : ProductChatData.receiver_msg == ""
                                ? ProductChatData.text
                                : ProductChatData.user._id == user_id
                                  ? ProductChatData.sender_msg
                                  : ProductChatData.receiver_msg}
                            <br />
                            <span className="text-sm">
                              {ProductChatData.createdAt.slice(11, 19)}
                            </span>
                            {parseInt(ProductChatData.createdAt.slice(11, 13)) <
                              12 ? (
                              <span className="ml-2 text-sm">
                                {t("chats.am")}
                              </span>
                            ) : (
                              <span className="ml-2 text-sm">
                                {t("chats.pm")}
                              </span>
                            )}
                          </Typography>
                          {Number(ProductChatData.user._id) !==
                            Number(user_id) &&
                            ProductChatData.receiver_msg !== "" && (
                              <>
                                {ProductChatData.message_deal_status ==
                                  "pending" && (
                                    <Button
                                      onClick={() =>
                                        acceptOffer(ProductChatData.message_id)
                                      }
                                      className={clsx(
                                        classes.button,
                                        "fw-bold my-1 bg-primary text-background rounded-md mr-14 text-md font-normal w-30 uppercase"
                                      )}
                                    >
                                      {t("chats.acceptOffer")}
                                    </Button>
                                  )}
                                {/* this below logic will get cleard if u have productChatData opened in front of u  */}
                                {ProductChatData.message_deal_status ==
                                  "accepted" && (
                                    <Button
                                      onClick={() =>
                                        addToCart(
                                          ProductChatData.message_id,
                                          ProductChatData.text
                                        )
                                      }
                                      className={clsx(
                                        classes.button,
                                        "fw-bold my-1 bg-primary text-background rounded-md mr-14 text-md font-normal w-30 uppercase"
                                      )}
                                    >
                                      {t("chats.addToCart")}
                                    </Button>
                                  )}
                              </>
                            )}
                          {/* this below logic will get cleard if u have productChatData opened in front of u  */}
                          {Number(ProductChatData.user._id) ==
                            Number(user_id) &&
                            ProductChatData.message_deal_status ==
                            "accepted" && (
                              <Button
                                onClick={() =>
                                  addToCart(
                                    ProductChatData.message_id,
                                    ProductChatData.text
                                  )
                                }
                                className={clsx(
                                  classes.button,
                                  "fw-bold my-bg-primary bg-primary text-background rounded-md mr-14 text-md font-normal w-30 uppercase"
                                )}
                              >
                                {" "}
                                {t("chats.addToCart")}
                              </Button>
                            )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  {/* this below div is at the bottom of the mappped chats and after every chat rendering we will scroll to this div so that user will always see latest chat by default untill he scrolls up */}
                  <div ref={messageEndRef}></div>
                </div>
              </div>
            </div>
          )}
        </Box>
      )}
      {/* this alert AlertComponent will get render when user tries to add a product to cart  of another seller  */}
      <AlertComponent
        openAlertModal={openAlertModal}
        setShow={setOpenOfferModal}
        // we will be sending type as a key because depending upon the time alert AlertComponent will behave diffrently
        type="myChats"
        alertContent={alertContent}
        // below function is to close the alert modal
        handleAlertModalClose={handleAlertModalClose}
        // negotiatedPrice price is the price where deal has done between customer and vendor
        productData={{ messageId, product_id, negotiatedPrice, vendor_id }}
      />
    </Box>
  );
}
