import { amber, blue, green } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../redux/reducers/messageSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: "#FFFFFF",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: "#FFFFFF",
  },
  warning: {
    backgroundColor: amber[600],
    color: "#FFFFFF",
  },
}));

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

function Message(props) {
  const dispatch = useDispatch();
  let state = useSelector(({ message }) => message.state);
  const options = useSelector(({ message }) => message.options);
  const classes = useStyles();
  return (
    state === true && (
      <Snackbar
        {...options}
        open={state}
        className="bottom-4 right-4 left-4"
        onClose={() => dispatch(hideMessage())}
        autoHideDuration={options.autoHideDuration}
        classes={{
          root: classes.root,
        }}
        ContentProps={{
          variant: "body2",
          color: "secondary",
          headlineMapping: {
            body1: "div",
            body2: "div",
          },
        }}
      >
        <SnackbarContent
          className={clsx(classes[options.variant])}
          message={
            <div className="flex items-center">
              {variantIcon[options.variant] && (
                <Icon color="inherit">{variantIcon[options.variant]}</Icon>
              )}
              <Typography className="lg:mx-10 text-white">
                {options.message}
              </Typography>
            </div>
          }
          action={[
            <IconButton
              className="float-right absolute top-0 right-0"
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => dispatch(hideMessage())}
            >
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  );
}
// We use React.memo because if any Component gives same result for same input then we should not re render we should re render this component only when props changesm and React.memo will do this thing for us
export default React.memo(Message);
