import {
  Button, createStyles, Dialog, Grid,
  makeStyles, Menu,
  TextField, Typography
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AppConfig from "../../appConfig";
import AlertComponent from "../../core-components/AlertComponent";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
const useStlyes = makeStyles((theme) => ({
  root: {
    // height: 300,
    flexGrow: 1,
    // minWidth: 300,
    transform: "translateZ(0)",

    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
  },
  button: {
    // backgroundImage: 'linear-gradient(to right, #E55D87 0%, #5FC3E4  51%, #E55D87  100%)',
    background: "#009acc",
    borderColor: "#ffffff",
    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    // color: AppConfig.color.button,
    borderRadius: "10px",
    display: "block",
    // boxShadow: "0 0 5px 0 #597593 inset",
    "&:hover": {
      background: "#009acc",
    },
  },
  paper: {
    // border: '2px solid #000',
    height: "auto",
    borderRadius: "20px",
    backgroundColor: "#484848",
    boxShadow: theme.shadows[5],

    padding: theme.spacing(2, 4, 3),
  },
  MenuCard: {
    width: "30vw",
    height: "60vh",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
    },
  },
  dialogPaper: {
    marginTop: "35vh",
    marginLeft: "65vw",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0vw",
      marginTop: "4vh",
    },
  },
  productNameinMenu: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  cardContent: {
    height: "85%",
  },
}));

const creatingStyle = makeStyles(() =>
  createStyles({
    list: {
      padding: "0",
    },
  })
);

export default function ChatPopup({
  product,
  productChatid,
  setAnchorEl,
  anchorEl,
  offerprice,
  open,
  startChat,
  getProductChat,
  productChatData,
  setOpenChatModel,
  openChatModel,
}) {
  const classes = useStlyes();
  const [renderChatMenu, setRenderChatMenu] = useState(1);
  const creatingStyleforMenu = creatingStyle();
  const [product_id, setProduct_id] = useState();
  const [vendor_id, setVendor_id] = useState();

  const [price, setPrice] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [currency_type, setCurrencyType] = useState();
  const [firstIndexArray, setFirstIndexArray] = useState([]);
  const [optionKey, setOptionKey] = useState([]);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [counterOfferError, setCounterOfferError] = useState("");
  const [negotiatedPrice, setNegotiatedPrice] = useState("");
  const [messageId, setMessageId] = useState("");

  const { t } = useTranslation();
  const handleOfferModalClose = () => {
    setCounterOfferError("");
    setOpenOfferModal(false);
    setFirstIndexArray([]);
  };
  const setMessage = () => {
    
    //  below getting user id from session storage
    if (typeof msg === "undefined" || msg === undefined || msg > offerPrice) {
      setCounterOfferError('please enter valid offer');
    } else {
      const user_id = sessionStorage.getItem("user_id");

      const form = new FormData();
      form.append("content", msg);
      form.append('type', 'deal')
      form.append("user_id", user_id);
      form.append("product_id", product.id);
      form.append("to_user", product.vendor.id);

      // Below function is for sending message to the admin
      ApiService.sendChat(productChatid, form).then((res) => {
        if (res.code == 200) {
          setMsg("");
          setCounterOfferError("");
          setRenderChatMenu(renderChatMenu + 1);
        }
      });
    }
  };
  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };
  // const makeaOffer = () => {
  //   ApiService.getProductById(product.id).then((res) => {
  //     if (res.code === 200) {
  //       setCurrencyType(res.data.data.product.currency_data.symbol);
  //       if (Array.isArray(res.data.data.product.OptionKey)) {
  //         setPrice(res.data.data.product.price);
  //         if (res.data.data.product.offer_price == null) {
  //           setOfferPrice(res.data.data.product.price);
  //         } else {
  //           setOfferPrice(res.data.data.product.offer_price);
  //         }
  //         setOpenOfferModal(true);
  //       } else {
  //         setOptionKey(Object.entries(res.data.data.product.OptionKey));
  //         if (Array.isArray(res.data.data.product.OptionKey)) {
  //           setPrice(res.data.data.product.price);
  //           if (res.data.data.product.offer_price !== null) {
  //             setOfferPrice(res.data.data.product.offer_price);
  //           } else {
  //             setOfferPrice(res.data.data.product.price);
  //           }
  //         } else {
  //           // Below we are setting the price and offer Price of the product
  //           // and explaination for this has been done in useEffect of attributeModal Component
  //           Object.entries(res.data.data.product.OptionKey).map((optionkey) => {
  //             firstIndexArray.push(optionkey[1][0]);
  //           });
  //           let initialPrice = [];
  //           let initialOfferPrice = [];
  //           firstIndexArray.map((firstIndexArray) => {
  //             initialPrice.push(firstIndexArray.price);
  //             initialOfferPrice.push(firstIndexArray.offer_price);
  //           });

  //           const maximumPrice = initialPrice.reduce(function (a, b) {
  //             return Math.max(a, b);
  //           });
  //           const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
  //             return Math.max(a, b);
  //           });

  //           setPrice(maximumPrice);

  //           if (
  //             maximumOfferPrice == 0 ||
  //             maximumOfferPrice == null ||
  //             maximumOfferPrice == "null"
  //           ) {
  //             setOfferPrice(maximumPrice);
  //           } else {
  //             setOfferPrice(maximumOfferPrice);
  //           }
  //         }

  //         setOpenOfferModal(true);
  //       }
  //     }
  //   });
  // };
  const sendMessageOnEnter = (e, offer) => {
    if (e.code == "Enter") {
      if (offer != "" && Number(msg) > Number(offerPrice)) {
        setCounterOfferError("Counter price cant be more than actual price");
        setMsg("");
      } else {
        setOpenOfferModal(false);
        const user_id = sessionStorage.getItem("user_id");
        if (msg == "") {
        } else {
          const form = new FormData();
          if (offer !== "") {
            form.append("type", "deal");
          }
          form.append("content", msg);
          form.append("user_id", user_id);
          form.append("product_id", product.id);
          form.append("to_user", product.vendor_id);
          // Below function is for sending message to the Vendor

          ApiService.sendChat(productChatid, form).then((res) => {
            if (res.code == 200) {
              setMsg("");
              setRenderChatMenu(renderChatMenu + 1);
            }
          });
        }
      }
    }
  };
  const SetproductPrice = (currentValue, index) => {
    var myvalue;
    // Below we are setting the price and offer Price of the product
    // and explaination for this has been done in setProductPrice of attributeModal Component
    const filteredArray = firstIndexArray.filter((firstIndexArray) => {
      return firstIndexArray.attribute !== currentValue.attribute;
    });

    filteredArray.push(currentValue);

    let initialPrice = [];
    let initialOfferPrice = [];
    filteredArray.map((filteredArray) => {
      initialPrice.push(filteredArray.price);
      initialOfferPrice.push(filteredArray.offer_price);
    });

    const maximumPrice = initialPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });
    const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });

    setFirstIndexArray(filteredArray);
    setPrice(maximumPrice);

    if (
      maximumOfferPrice == 0 ||
      maximumOfferPrice == null ||
      maximumOfferPrice == "null"
    ) {
      setOfferPrice(maximumPrice);
    } else {
      setOfferPrice(maximumOfferPrice);
    }
  };
  var user_id = sessionStorage.getItem("user_id");
  const [msg, setMsg] = useState();
  useEffect(() => {
    // .MuiInputBase-inputMultiline
    // setTimeout(() => {
    //   const placeholder = document.querySelectorAll('.MuiInputBase-inputMultiline')


    //   const placeholderArray = [...placeholder]
    //   console.log("this is dropdown ",placeholderArray)
    //   placeholderArray.forEach((ele)=>{
    //   // ele.classList.add('dark:bg-lightDarkBackground')
    //   ele.classList.add('dark:text-fontWhite')
    //   })
    // }, 0);

    // to scroll  to bottom of the div to show user latest message
    messageEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  }, [productChatData]);
  const messageEndRef = useRef(null);
  // messageEndRef has the refrence to div which is at the bottom of our chats

  // on change method for message change
  const setChangeMessage = (e) => {
    setMsg(e.target.value);
  };

  // close chat popup
  const handlechatClose = () => {
    setOpenChatModel(false);
  };

  // the div which has updateMenu have refernce will get re render whenever the value of renderChatMenu changes
  const updateMenu = useMemo(
    function MultiCount() {
      if (renderChatMenu == 1) {
      } else {
        openChatModel && getProductChat();
      }
    },
    [renderChatMenu]
  );

  // function for sending message
  const sendMessage = () => {
    const user_id = sessionStorage.getItem("user_id");

    const form = new FormData();
    form.append("content", msg);
    form.append("user_id", user_id);

    form.append("to_user", product.vendor.id);
    form.append("product_id", product.id);

    // send chat api call
    ApiService.sendChat(productChatid, form).then((res) => {
      setMsg("");

      setRenderChatMenu(renderChatMenu + 1);
      messageEndRef.current?.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    });
  };

  // accept offer function
  const acceptOffer = (messageId) => {
    const form = new FormData();
    form.append("message_id", messageId);
    form.append("status", "accepted");
    ApiService.acceptOffer(form).then((res) => { });
  };

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const addToCart = (messageId, negotiatedprice) => {
    // negotiatedPrice price is the price where deal is done
    setNegotiatedPrice(negotiatedprice);
    setMessageId(messageId);
    const form = new FormData();
    form.append("message_id", messageId);
    form.append("product_id", product.id);
    form.append("session_id", user_id);
    form.append("vendor_id", product.vendor.id);
    form.append("quantity", "1");
    form.append("orignal_price", negotiatedprice);
    form.append("offer_price", negotiatedprice);
    ApiService.addToCart(form).then((res) => {
      if (res.code === 200) {
        store.dispatch({
          type: ActionTypes.ADD_USER_CART_COUNT,
          payload: res.data.data.cart_count,
        });
      } else if (res.code == 422) {
        setOpenAlertModal(true);
      }
    });
  };
  return (
    <React.Fragment>
      <Dialog
        open={openOfferModal}
        onClose={handleOfferModalClose}
        fullWidth
        maxWidth="md"
        className="dark:bg-lightDarkBackground"
      >
        <>
          <div className="bg-primary flex justify-between   items-center">
            <div className="d-flex">
              <ListItemAvatar className="pt-2 ml-4 ">
                <Avatar
                  alt="Remy Sharp"
                  className="object-cover"
                  src={product.ImageSrc}
                />
              </ListItemAvatar>
              <Typography className=" py-3 text-white  opacity-100 ">
                {product.product_name}
              </Typography>
            </div>

            <CloseIcon
              className="mr-4 text-white cursor-pointer"
              onClick={() => {
                setCounterOfferError("");
                setOpenOfferModal(false);
                setFirstIndexArray([]);
              }}
            />
          </div>
          <div className="pl-4">
            {optionKey.length > 0 &&
              optionKey.map((optionKey, key) => (
                <>
                  <h2 className="mt-4 mb-2 font-bold ">{optionKey[0]} </h2>
                  <Grid className="mt-2.5" container>
                    {optionKey[1].map((product_option, key) => (
                      <Grid item lg="auto" md={1}>
                        <Button
                          className={`mx-2 ${firstIndexArray.find(
                            (o) =>
                              o.attribute === product_option.attribute &&
                              o.price === product_option.price &&
                              o.values === product_option.values
                          )
                            ? "bg-button text-fontWhite"
                            : "bg-background text-fontDark"
                            }`}
                          onClick={() => {
                            SetproductPrice(product_option, key);
                          }}
                          variant="contained"

                        >
                        {product_option.values}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ))}
          </div>
          <div className="my-3 mx-2 lg:p-2 font-bold text-xl text-secondary">
            {t("chatPopUp.actualPrice")} : {currency_type} {offerPrice}
          </div>
          <div className="my-4 ml-4 flex">
            <TextField
              id="outlined-basic"
              type="number"
              onKeyPress={(e) => {
                sendMessageOnEnter(e, "making a offer");

              }}
              onChange={setChangeMessage}
              placeholder="Your counter offer"
              value={msg}
              // variant="outlined"
              // inputProps={{ className: classes.input }}
              className="bottom-0"
              error={Boolean(counterOfferError)}
              helperText={counterOfferError}
              sx={{
                input: {
                  color: "#black",
                  padding: "8px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                },
                width: "100%",
                borderRadius: "80px",
                marginLeft: "8px",
                "& label": {
                  color: "white",
                },
                "& label.Mui-focused": {
                  color: "white",
                },
                "& .MuiInput-underline:after": {
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
            <SendIcon
              onClick={() => {
                // setOpenOfferModal(false);
                setMessage();

              }}
              className="cursor-pointer mr-1.5 text-secondary text-3xl"
            />
          </div>
        </>
      </Dialog>
      <Menu
        open={openChatModel}
        classes={{
          list: creatingStyleforMenu.list,
          paper: classes.dialogPaper,
        }}
        onClose={handlechatClose}
        // classes={{paper:classes.dialogPaper}}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        getContentAnchorEl={null}
      >
        <Card className={classes.MenuCard}>
          <div className="flex justify-between  fixed w-inherit bg-button">
            <div className="p-2 align-items-center flex">
              <div className="w-10 h-10">
                <img
                  src={product.ImageSrc}
                  alt=""
                  className="object-contain h-full w-full flex-auto rounded-full"
                />
              </div>
              <Typography
                className={clsx(
                  classes.productNameinMenu,
                  "py-1 px-1 flex-1 text-base font-bold text-white"
                )}
              >
                {product.product_name.split(" ").slice(0, 2).join(" ") + "..."}
              </Typography>
            </div>

            {/* <button
              onClick={() => {
                makeaOffer();
              }}
              className={clsx(
                classes.button,
                "     bg-primary text-white rounded-md text-xs py-2 my-3.5 font-normal w-1/3 uppercase"
              )}
            >
              {t("chatPopUp.makeAOffer")}
            </button> */}

            <div>
              <IconButton aria-label="settings">
                <CloseIcon
                  className="text-white mt-1"
                  onClick={() => {
                    setOpenChatModel(false);
                  }}
                />
              </IconButton>
            </div>
          </div>

          <CardContent className={classes.cardContent + " mt-10"}>
            <div className="w-full dark:bg-lightDarkBackground overflow-x-hidden overflow-y-auto h-full flex flex-col mt-1 mb-1 pb-1">
              {updateMenu}

              {productChatData.map((ProductChatData, key) => (
                <>
                  <Typography className="m-auto mt-2.5">
                    {key == 0 ? (
                      <>
                        <h1 className="mt-4 bg-button p-1 rounded-xl text-white">
                          {ProductChatData.createdAt.slice(0, 10)}{" "}
                        </h1>
                      </>
                    ) : ProductChatData.createdAt.slice(0, 10) !==
                      productChatData[key - 1].createdAt.slice(0, 10) ? (
                      <h1 className="bg-button p-1 rounded-xl text-white">
                        {ProductChatData.createdAt.slice(0, 10)}{" "}
                      </h1>
                    ) : null}
                  </Typography>

                  <div
                    className={`leading-tight ${ProductChatData.user._id == user_id ? "ml-auto" : "ml-0"
                      } inline-block w-fc max-w-70p p-2.5 mr-1 rounded-xl bg-chatpopup`}
                  >
                    <Typography
                      className={`h-auto flex text-black  flex-col ${ProductChatData.user._id == user_id && "ml-auto"
                        } `}
                    >
                      {" "}
                      {ProductChatData.message_deal_status == "accepted"
                        ? ProductChatData.deal_accept_msg
                        : ProductChatData.receiver_msg == ""
                          ? ProductChatData.text
                          : ProductChatData.user._id == user_id
                            ? ProductChatData.sender_msg
                            : ProductChatData.receiver_msg}
                      <br />
                      <div className="flex items-center">
                        <Typography
                          className={`text-sm ${ProductChatData.type == 0 && "ml-auto"
                            }`}
                        >
                          {ProductChatData.createdAt.slice(11, 19)}
                        </Typography>
                        {parseInt(ProductChatData.createdAt.slice(11, 13)) <
                          12 ? (
                          <span className="ml-2 text-13px text-gray-700">
                            {t("chatPopUp.am")}
                          </span>
                        ) : (
                          <span className="ml-2 text-13px text-gray-700">
                            {t("chatPopUp.pm")}
                          </span>
                        )}
                      </div>
                    </Typography>
                    {Number(ProductChatData.user._id) !== Number(user_id) &&
                      ProductChatData.receiver_msg !== "" && (
                        <>
                          {ProductChatData.message_deal_status == "pending" && (
                            <Button
                              onClick={() =>
                                acceptOffer(ProductChatData.message_id)
                              }
                              className={clsx(
                                classes.button,
                                "fw-bold text-white bg-primary  my-1 rounded-md mr-14 text-md font-normal w-30 uppercase"
                              )}
                            >
                              {t("chatPopUp.acceptOffer")}
                            </Button>
                          )}
                          {ProductChatData.message_deal_status ==
                            "accepted" && (
                              <Button
                                onClick={() =>
                                  addToCart(
                                    ProductChatData.message_id,
                                    ProductChatData.text
                                  )
                                }
                                className={clsx(
                                  classes.button,
                                  "fw-bold bg-primary  my-1 text-white rounded-md mr-14 text-md font-normal w-30 uppercase"
                                )}
                              >
                                {t("chatPopUp.addToCart")}
                              </Button>
                            )}
                        </>
                      )}

                    {Number(ProductChatData.user._id) == Number(user_id) &&
                      ProductChatData.message_deal_status == "accepted" && (
                        <Button
                          onClick={() =>
                            addToCart(
                              ProductChatData.message_id,
                              ProductChatData.text
                            )
                          }
                          className={clsx(
                            classes.button,
                            "fw-bold text-white  my-1 bg-primary rounded-md mr-14 text-md font-normal w-30 uppercase"
                          )}
                        >
                          {t("chatPopUp.addToCart")}
                        </Button>
                      )}
                  </div>

                  {key == productChatData.length - 1 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </>
              ))}
              <div ref={messageEndRef}></div>
            </div>
            <div className=" justify-start flex dark:bg-lightDarkBackground left-0 text-center m-auto items-center w-full bg-background bottom-0 absolute">
              <TextField
                id="outlined-basic"
                placeholder="Type your message"
                value={msg}
                multiline
                rowsMax="2"
                rowsMin="1"
                // variant="outlined"
                //   onKeyPress={(event) => setMessage(event)}
                className="bottom-0  p-2 w-4/5 rounded-xl"
                Action
                onChange={setChangeMessage}
                sx={{
                  input: { color: "white" },
                  "& label": {
                    color: "white",
                  },
                  "& label.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInput-underline:after": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    ".MuiList-padding-451": {
                      padding: "0px",
                    },
                  },
                }}
              />
              <Button
                className="m-2.5 text-center bg-primary uppercase duration-500 text-white block rounded-xl"
                onClick={sendMessage}
              >
                {t("chatPopUp.send")}
              </Button>
            </div>

          </CardContent>
        </Card>
      </Menu>
      <AlertComponent
        openAlertModal={openAlertModal}
        price={negotiatedPrice}
        offerPrice={negotiatedPrice}
        handleAlertModalClose={handleAlertModalClose}
        productData={product}
      />
    </React.Fragment>
  );
}
