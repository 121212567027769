import { makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import AppConfig from "../appConfig";
import ProductCard from "../core-components/ProductCard";
import { ActionTypes } from "../redux/contants/ActionType";
import store from "../redux/store";
import ApiService from "../services/apiService";

export default function ViewAll({ mobile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({

    ul: {
      "& .MuiPaginationItem-root": {
        color: "#050505",
      },
      li: {
        button: {
          background: "rgba(0, 0, 0, 0.08)",
        },
      }
    }
  }));
  const params = useParams();

  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const [page, setPage] = useState(1);
  const [allImage, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [sortValue, setSortValue] = React.useState("most-popular");

  useEffect(() => {
    window.scrollTo(0, 0);
    const formData = new FormData()
    formData.append("page", page);
    formData.append("type", sortValue);
    if (params.type == "featured-products") {
      // api for getting featured product data
      ApiService.getFeaturedCollection(sortValue, page).then((res) => {
        setImage(res.data.data);
        setLoading(false);
      });
    } else if (params.type == "trending-products") {
      // api for getting trending product data
      ApiService.getTrendingProducts(sortValue, page).then((res) => {
        setImage(res.data.data);
        setLoading(false);
      });
    } else if (params.type == "top-products") {
      // api for getting top product data
      ApiService.getTopProducts(sortValue, page).then((res) => {
        setImage(res);
        setLoading(false);
      });
    } else {
      ApiService.getFreshArrivals(page).then(res => {
        setImage(res.data?.data);
        setLoading(false);
      })
    }
  }, [page]);

  // if (params.type == "brand") {
  //   ApiService.getBrandList().then((res) => {
  //     setImage(res.data?.data?.brand);
  //     setLoading(false);
  //   });
  // } else 

  // function to add item to wishlist
  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      // wishItemToggle(data.id);
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      // if user has not logged then he will not be able to see wishlist
      history.push(AppConfig.routes.login);
    }
  };

  const user_id = sessionStorage.getItem("user_id");
  const classes = useStyles();
  // function for adding product to cart


  return (
    <React.Fragment>
      <div className="bg-gray-100 dark:bg-darkBackground">
        <div
          className={`d-flex align-items-center pr-8 pl-8 sm:px-10 justify-content-between ${mobile ? "flex-column" : "flex-row"
            }`}
        >
          <Typography
            variant="subtitle1"
            className=" pt-6 sm:pt-10 text-nowrap dark:text-login uppercase w-full text-center text-xl sm:text-2xl"
          >
            {params.type}
          </Typography>
          <div className="self-center sm:self-end"></div>
        </div>
        <div className="grid lg:grid-cols-5 sm:grid-cols-4 md:gap-4 grid-cols-2  pt-10 gap-3 lg:gap-4 mx-3 sm:mx-10">
          {loading
            ? [1, 2, 3, 4, 5].map((ele, key) => (
              <Skeleton height={300} className="" key={key} />
            ))
            : allImage?.data?.map((image, key) => {
              return (
                <React.Fragment key={key}>
                  <ProductCard
                    image={image}
                    type="Featured Product"
                    typeForUrl="featured-product"
                    addToWishlist={addToWishlist}
                  // wishArray={wishArray}
                  />
                </React.Fragment>
              );
            })}
        </div>
        <div className="lg:py-2 py-12 my-8 text-center w-full  flex  justify-center">
          <Pagination
            count={allImage?.last_page}
            classes={{ ul: classes.ul }}
            defaultPage={page}
            size="large"
            page={page}
            onChange={(event, value) => {
              setPage(value);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
