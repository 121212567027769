import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppConfig from "../../appConfig";
import animationDataEmptyCart from '../../assets/animations/cart.json';
import animationDataSadFace from '../../assets/animations/SadFace.json';
import AttributeModal from "../../core-components/AttributeModal";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function Wishlist() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [onModal, setOpenModal] = useState(false);
  const [color, setColor] = useState(null);
  const [msg, setMessage] = useState();
  const [modalRow, setModalRow] = useState();
  const [size, setSize] = useState(null);
  const [show, setShow] = useState(false);

  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };

  const defaultOptionsEmptyCart = {
    loop: true,
    autoplay: true,
    animationData: animationDataEmptyCart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsSadFace = {
    loop: true,
    autoplay: true,
    animationData: animationDataSadFace,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const id = sessionStorage.getItem("user_id");
  useEffect(() => {
    window.scrollTo(0, 0);
    // api to get wishlist detail
    ApiService.getWishlistDetails(id).then((res) => {
      if (res.code === 200) {
        setRows(res.data.data.products.data);
        setLoading(false);
      }

    });
  }, []);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  // function to add product in cart
  const addToCart = (data, price, offerPrice, firstIndexArray) => {
    // this function will get called from either this component or attribute modal component
    // if there are no attribute then it will get called from this component but if there are attribute then it will get called from attribute modal component because user have to select attribute before adding it to cart
    const _form = new FormData();
    _form.append("vendor_id", data.vendor.id);
    _form.append("product_id", data.id);
    _form.append("quantity", "1");
    _form.append("orignal_price", data.price);
    _form.append("offer_price", offerPrice);
    _form.append("session_id", id);

    // firstIndexArray contains all current attribute objects which user has selected we will use this array to dynamically send product option in add to api
    // if (firstIndexArray !== undefined && firstIndexArray.length > 0) {
    //   firstIndexArray.map((firstIndexArray) => {
    //     _form.append(
    //       `product_option[${firstIndexArray.attribute}]`,
    //       firstIndexArray.values
    //     );
    //   });
    // }

    (firstIndexArray.length > 0 &&
      firstIndexArray.map((firstIndexArray, index) => {
        _form.append(`product_option[${index}][attr_type]`, firstIndexArray.attribute);
        _form.append(`product_option[${index}][value]`, firstIndexArray.values);
        _form.append(`product_option[${index}][price]`, firstIndexArray.price);
      }))


    ApiService.addToCart(_form).then((res) => {
      if (res.code === 200) {
        store.dispatch({
          type: ActionTypes.ADD_USER_CART_COUNT,
          payload: res.data.data.cart_count,
        });
        history.push(AppConfig.routes.cart);
      } else if (res.code == 422) {
        // if res code == 422 means cart contains product from another seller so we will open alert modal that you have product from different sellers in your cart
        setOpenAlertModal(true);
      }
    });
  };

  // delete a cart item
  const deleteRecord = (id) => {
    let deleteRecord = rows.filter((item) => item.id !== id);
    setRows(deleteRecord);
    let _form = new FormData();
    // setting wishlist count to currentCount -1
    store.dispatch({
      type: ActionTypes.ADD_USER_WISHLIST_COUNT,
      payload: wishlistCount - 1,
    });
    _form.append("product_id", id);
    ApiService.addItemToWishlist(_form).then((res) => { });
  };

  console.log(rows,"row");
  return (
    <React.Fragment>
      {show && (
        // this is attribute modal component which will get open when user clicks on add to cart and if product contains attributes
        <AttributeModal
          show={show}
          setShow={setShow}
          modalRow={modalRow}
          addToCart={addToCart}
          setColor={setColor}
          setSize={setSize}
          color={color}
          size={size}
          openAlertModal={openAlertModal}
          setOpenAlertModal={setOpenAlertModal}
        />
      )}
      <Dialog open={onModal} onClose={handleModalClose} fullWidth maxWidth="sm">
        <AppBar
          className="h-64 lg:h-72 bg-primary text-white"
          position="static"
          elevation={1}
        >
          <Toolbar className="flex w-full">
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 text-xl font-semibold"
            >
              {t("wishlist.alert")}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent classes={{ root: "p-20" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography className="text-center my-4 py-10 mx-4">
              {msg}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="px-3 py-1 mx-3 text-white normal-case whitespace-no-wrap bg-black border-2 rounded-md"
            onClick={handleModalClose}
            color="primary"
          >
            {t("wishlist.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        variant={"h5"}
        className="py-5 text-center dark:text-fontWhite uppercase dark:bg-lightDarkBackground bg-gray-200 border-b-2"
      >
        {t("wishList.yourWishlist")}
      </Typography>
      <div className="w-full py-4  lg:p-10">

        <div className="w-full justify-items-center m-0 sm:mb-8 lg:mb-0 sm:px-4 px-2 lg:px-0 gap-8 lg:gap-20 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
          {loading ?
            [1, 2, 3, 4].map((ele, key) => (<Skeleton height={300} width={500} className='lg:rounded-3xl' />))
            :
            rows.length === 0 ?
              <div className="block w-full col-span-2 py-16 justify-center">
                <Lottie options={defaultOptionsEmptyCart} height={200} width={200} />
                <div className="d-flex justify-content-center my-10">
                  <div> <Typography variant={"body1"} component={'span'} className='text-2xl   text-gray-400 font-bold'>{t('cartDetails.wishlistEmpty')}</Typography></div>
                  <div className="-mt-3">
                    <Lottie options={defaultOptionsSadFace} height={50} width={50} className='' />
                  </div>
                </div>
              </div>
              :
              rows.map((row) => (
                <div className="py-4 lg:p-4  bg-whiteBackground dark:bg-lightDarkBackground w-full relative justify-items-center
                    m-0 lg:border shadow-lg shadow-black lg:rounded-lg lg:border-gray-300">
                  <div className="w-full h-56 lg:max-h-52">
                    <img
                      alt="text"
                      className="w-full shadow-black border-gray-300 h-full object-contain lg:rounded-lg"
                      src={row.ImageSrc}
                    />
                  </div>
                  <div className="w-full flex flex-col h-full pt-3 px-2 lg:px-0">
                    <div className="flex-col w-full">
                      <div className="w-full text-black font-black text-base lg:text-base">
                        {row.product_name}
                      </div>
                      <div className="w-full gap-2 flex pb-2 pt-1">
                        <div className="font-bold text-black dark:text-fontWhite text-sm ">
                          {row.currency_data.symbol}{" "}
                          {Number(row.offer_price || row.price).toFixed(2)}
                        </div>
                        <div className="text-danger dark:text-fontWhite line-through text-sm ">
                          {row.currency_data.symbol} {Number(row.price).toFixed(2)}
                        </div>
                      </div>

                      {/* {row.long_desc && <div className="w-full text-primary dark:text-fontWhite font-black text-base lg:text-sm my-2">
                        <Typography component={'span'} variant={'body2'}>Description:</Typography>
                        <Typography component={'span'} variant={'body2'} className='text-xs mt-1'>
                          {ReactHtmlParser(row.long_desc)}
                        </Typography>
                      </div>} */}
                    </div>
                    <div className="flex gap-4 justify-between">
                      <Button
                        className="w-full p-2 bg-button text-white text-sm font-normal text-nowrap text-center text-white"
                        size={"small"}
                        onClick={async () => {
                          // if in option key data comes it will be  a object so if it is array it means dont have data so we directly add product to cart
                          // OptionKey will look like this in this condition OptionKey = []
                          if (Array.isArray(row.OptionKey)) {
                            addToCart(row, row.price, row.offer_price);
                          } else {
                            // This will open the attribute modal for user to select the attribute
                            await setModalRow(row);
                            setShow(true);
                          }
                        }}
                      >
                        {t('wishList.addToCart')}
                      </Button>
                      <div className="absolute top-2 right-2">
                        <HighlightOffIcon
                          className="cursor-pointer"
                          onClick={() => deleteRecord(row.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </React.Fragment>
  );
}
