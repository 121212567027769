const tm = {
    translation: {
        alertComponent: {
            otherSellerProductsinCart:
                "உங்கள் வண்டியில் மற்ற விற்பனையாளர்களிடமிருந்து தயாரிப்புகள் உள்ளன, தயவுசெய்து வண்டியை அகற்றவும்",
            alert: "எச்சரிக்கை",
            removeOtherAddThis: "மற்ற உருப்படிகளை அகற்றி இதைச் சேர்க்கவும்",
            cancel: "ரத்துசெய்",
        },
        productcard: {
            addtowishlist: "விருப்பப்பட்டியலில் சேர்",
            removefromwishlist: "விருப்பப்பட்டியலில் இருந்து அகற்று",
        },
        header: {
            search: "தேடு ...",
            language: "மொழி",
            categories: "பிரிவுகள்",
            home: "வீடு",
            alert: "எச்சரிக்கை",
            stockNotAvailable: "பங்கு கிடைக்கவில்லை ....",
            ok: "சரி",
            profile: "சுயவிவரம்",
            myChats: "என் அரட்டைகள்",
            myFollowing: "எனது பின்வருபவை",
            logout: "வெளியேறு",
            myCart: "என் வண்டி",
            myOrders: "என் கட்டளைகள்",
            wishlist: "விருப்பப்பட்டியல்",
            notifications: "அறிவிப்புகள்",
            cart: "வண்டி",
            login: "உள்நுழைய",
            account: "கணக்கு",
        },
        paymentForm: {
            paymentOptions: "டெபிட் கார்டு, கிரெடிட் கார்டு, நிகர வங்கி, யுபிஐ",
            cashOnDelivery: "டெலிவரி போது பணம்",
            flashSaleNote: "குறிப்பு: ஃபிளாஷ் விற்பனை தயாரிப்புகளில் ஆன்லைன் கட்டணம் மட்டுமே கிடைக்கும்"
        },
        checkOutForm: {
            payNow: "இப்போது செலுத்த",
        },
        addressForm: {
            city: "நகரம்",
            close: "நெருக்கமான",
            document: "சிபிஎஃப்",
            state: "நிலை",
            locality: "இடம்",
            shippingAddress: "சேரும் முகவரி",
            address: "முகவரி",
            addAddress: "புதிய முகவரியைச் சேர்க்கவும்",
            saveAddress: "முகவரியைச் சேமிக்கவும்",
            editAddress: "முகவரியைத் திருத்து",
            name: "பெயர்",
            mobile: "கைபேசி",
            landmark: "மைல்கல்",
            pinCode: "அஞ்சல் குறியீடு",
            addressType: "முகவரி வகை",
            searchplace: "தேடு இடங்கள் ...",
            home: "வீடு",
            office: "அலுவலகம்",
            country: "நாடு",
            NearByLocation: "இடத்திற்கு அருகில்",
            statecode: "மாநில குறியீடு",
            countrycode: "நாட்டின் குறியீடு",
            confirmation: "உறுதிப்படுத்தல்",
            confirmationMessage:
                "உங்கள் வணிக முகவரி நாட்டின் குறியீடு மாற்றப்பட்டதிலிருந்து, இயல்புநிலை நாணயம் மற்றும் முன்னர் சேர்க்கப்பட்ட தயாரிப்புகள் செயலற்றதாக அமைக்கப்படும்.கோரிக்கையை உறுதிப்படுத்தவும் அல்லது ரத்து செய்யவும்.",
        },
        noFound: {},
        order: {
            shippingAddress: "சேரும் முகவரி",
            mobile: "கைபேசி",
            deliveryAt: "டெலிவரி",
            orderDetails: "ஆணை விவரங்கள்",
            qty: "qty",
            status: "நிலை",
            paymentMethod: "கட்டண முறை",
            orderedOn: "ஆர்டர் செய்யப்பட்டது",
            customerDetails: "வாடிக்கையாளர் விவரங்கள்",
            trackOrder: "பாதையில் பொருட்டு",
            backToOrder: "மீண்டும் ஆர்டர்",
            orderAmount: "ஆர்டர் தொகை",
            orderId: "ஐடி ஆர்டர்",
            more: "மேலும்",
            discountAmount: "தள்ளுபடி தொகையை ஆர்டர் செய்யுங்கள்",
            rating: "மதிப்பீடு கொடுங்கள்",
        },

        cancelOrder: {
            reasonForCancel: "ரத்து செய்வதற்கான காரணம்",
            reasonForReturn: "திரும்புவதற்கான காரணம்",
            orderCancellation: "ஆர்டர் ரத்து",
            orderCancel: "ஆர்டர் ரத்துசெய்",
            returnCancel: "ஆர்டர் திரும்ப",
            cancelHeading: "திரும்ப கோரிக்கையை ரத்து செய்யவா?",
            returnRequestCancel: "ஆம், நான் ரத்து செய்ய விரும்புகிறேன்",
            continue: "தொடரவும்",
            itemInfo: "உருப்படி தகவல்",
            qty: "qty",
            deliveryFee: "விநியோக கட்டணம்",
            orderDiscount: "ஒழுங்கு தள்ளுபடி",
            productAmount: "தயாரிப்பு தொகை",
            price: "விலை",
            comments: "கருத்துகள்",
            confirmMsg:
                "நீங்கள் உறுதியாக நம்புகிறீர்களா, இந்த ஆர்டருக்கான வருவாய் கோரிக்கையை ரத்து செய்ய விரும்புகிறீர்களா?",
            feedback: "உங்கள் கருத்தை கொடுங்கள்",
            feedbackMsg: "இந்த தயாரிப்பை எவ்வாறு மதிப்பிடுவீர்கள்?",
            submitRating: "சமர்ப்பிக்கவும்",
            feedbackComment: "உங்கள் மதிப்பாய்வைச் சேர்க்கவும்",
        },
        footer: {
            quickShop: "விரைவான கடை",
            informations: "தகவல்",
            customerService: "வாடிக்கையாளர் சேவை",
            contactUs: "எங்களை தொடர்பு கொள்ள",
            supportChat: "ஆதரவு அரட்டை",
        },
        mobileFooter: {
            home: "வீடு",
            category: "வகை",
            new: "புதியது",
            me: "நான்",
            bag: "பை",
            myOrders: "என் கட்டளைகள்",
            categoriesDrawer: {
                categories: "வகைகள்",
            },
        },
        common: { addToCart: "பெட்டகத்தில் சேர்" },
        card: {
            button: {
                addToCart: "பெட்டகத்தில் சேர்",
                productDetail: "விபரங்களை பார்",
            },
        },
        shop: {
            home: "வீடு",
            mobileDisplay: "மொபைல் டிஸ்ப்ளே",
            categoryHeading: "பிரிவுகள்",
            showingHeading: "காட்டுகிறது",
            size: "அளவு",
            price: "விலை",
            brand: "பிராண்ட்",
            discount: "தள்ளுபடி",
            andMore: "இன்னமும் அதிகமாக",
            color: "நிறம்",
            filter: "வடிப்பான்கள்",
            allFilter: "அனைத்து வடிப்பான்களும்",
            applyAllFilters: "அனைத்து வடிப்பான்களையும் பயன்படுத்துங்கள்",
            clear: "தெளிவான",
            more: "மேலும்",
            productFilters: "தயாரிப்பு வடிப்பான்கள்",
            noFilters: "வடிப்பான்கள் இல்லை",
            showingProducts: "தயாரிப்புகளைக் காட்டுகிறது",
            products: "தயாரிப்புகள்",
            of: "of",
            noProductFound: "தயாரிப்பு எதுவும் கிடைக்கவில்லை",
            noProducts: "தயாரிப்புகள் இல்லை",
        },
        productDetails: {
            all: "அனைத்து மதிப்பாய்வையும் பார்க்கவும்",
            one: "ஒன்று",
            two: "இரண்டு",
            three: "மூன்று",
            four: "நான்கு",
            five: "ஐந்து",
            soldBy: "விற்றவர்",
            outOfStock: "பங்கு இல்லை",
            addToWishList: "விருப்பப்பட்டியலில் சேர்",
            viewShop: "கடைக்குக் காண்க",
            chatWithUs: "எங்களுடன் அரட்டை அடிப்பது",
            ratings: "மதிப்பீடுகள்",
            followers: "பின்தொடர்பவர்கள்",
            products: "தயாரிப்புகள்",
            productDetails: "தயாரிப்பு விவரங்கள்",
            productRatingAndReviews: "தயாரிப்பு மதிப்பீடுகள் மற்றும் மதிப்புரைகள்",
            similarProducts: "ஒத்த தயாரிப்புகள்",
            reviews: "மதிப்புரைகள்",
            removeFromWishlist: "விருப்பப்பட்டியலில் இருந்து அகற்று",
            share: "பகிர்",
            off: "ஆஃப்",
            youSave: "நீ காப்பாற்று",
            placeOrderPinCode: "பின்கோடு உள்ளிடவும்",
            check: "காசோலை",
            checkDelivery: "விநியோகத்தைச் சரிபார்க்கவும்",
            inputLabel: "டெலிவரி மற்றும் சேவைகளைச் சரிபார்க்க பின் குறியீட்டை உள்ளிடவும்",
            deliveryDateOne: "பொதுவாக 7 நாட்களில் டெலிவரி செய்யப்படும்.",
            deliveryDateTwo: "டெலிவரியில் பணம் கிடைக்கிறது",
            deliveryDateThree: "தொந்தரவு இல்லாத 7 நாட்கள் திரும்பும்",
        },
        myOrders: {
            filters: "வடிப்பான்கள்",
            orderStatus: "நிலை",
            onTheWay: "வரும் வழியில்",
            delivered: "வழங்கப்பட்டது",
            cancelled: "ரத்து செய்யப்பட்டது",
            returned: "திரும்பினார்",
            orderTime: "ஒழுங்கு நேரம்",
            last30Days: "கடந்த 30 நாட்கள்",
            processing: "செயலாக்கம்",
            older: "பழைய",
            soldBy: "விற்றவர்",
            viewOrder: "வரிசையைக் காண்க",
            cancelOrder: "ஆர்டரை ரத்துசெய்",
            returnOrder: "கோரிக்கை திரும்பவும்",
            returnCancelOrder: "திரும்ப கோரிக்கையை ரத்துசெய்",
            address: "முகவரி",
            paymentDetail: "கட்டண விவரம்",
            COd: "Cod",
            transactionId: "பரிவர்த்தனை ஐடி",
            myOrders: "என் கட்டளைகள்",
            filter: "வடிப்பான்கள்",
            openMainMenu: "பிரதான மெனுவைத் திறக்கவும்",
            orderedOn: "ஆர்டர் செய்யப்பட்டது",
            walletAmount: "பயன்படுத்திய பணப்பை",
            orderAmount: "ஆர்டர் தொகை",
            qty: "qty",
        },
        myProfile: {
            profile: "சுயவிவரம்",
            alert: "எச்சரிக்கை",
            ok: "சரி",
            updateProfile: "புதுப்பிப்பு",
            firstName: "முதல் பெயர்",
            city: "நகரம்",
            email: "மின்னஞ்சல் முகவரி",
            phone: "தொலைபேசி எண்",
            state: "நிலை",
            address: "முகவரி",
            dialing_code: "நாட்டின் குறியீடு",
            contactMessage: "தொலைபேசி எண்ணை மாற்ற ஆதரவைத் தொடர்பு கொள்ளுங்கள்",
            personalInfo: "தனிப்பட்ட தகவல்",
            accountInfo: "கணக்கு விபரம்",
            alert: "OTP சரிபார்ப்பு",
            message: "உங்கள் மொபைல் எண்ணுக்கு OTP அனுப்பப்பட்டுள்ளது",
            emailMessage: "OTP உங்கள் மின்னஞ்சலுக்கு அனுப்பப்பட்டுள்ளது",
            otpError: "OTP புலம் தேவை",
            image: "பரிந்துரைக்கப்பட்ட அளவு 500x500px",
            phoneError: "தொலைபேசி எண் ஏற்கனவே உள்ளது",
            emailError: "மின்னஞ்சல் ஏற்கனவே உள்ளது",
            changePassword: "கடவுச்சொல்லை மாற்று",
            password: "பழைய கடவுச்சொல்",
            newPass: "புதிய கடவுச்சொல்",
            confirmPass: "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
            confirmPassError: "கடவுச்சொல் பொருந்தவில்லை என்பதை உறுதிப்படுத்தவும்",
        },
        providingFeatures: {
            features: {
                i: {
                    heading: "பாதுகாப்பான கட்டணம்",
                    subHeading:
                        "உலகின் மிகவும் பாதுகாப்பான கட்டண முறைகளுடன் பணம் செலுத்துங்கள்",
                },
                ii: {
                    heading: "நம்பிக்கை",
                    subHeading:
                        "பாதுகாப்பு உங்கள் கொள்முதல் மற்றும் தனிப்பட்ட தரவை உள்ளடக்கியது",
                },
                iii: {
                    heading: "பான் இந்தியா டெலிவரி",
                    subHeading:
                        "200+ க்கும் மேற்பட்ட நாடுகளுக்கும் பிராந்தியங்களுக்கும் இலவச மற்றும் விரைவான கப்பல்.",
                },
                iv: {
                    heading: "ஹாட்லைன்",
                    subHeading:
                        "4141 456 789, 4125 666 888 என்ற எண்ணில் உங்கள் கேள்விக்கு உதவி வரிக்கு பேசுங்கள்",
                },
            },
        },
        cartDetails: {
            product: "தயாரிப்பு",
            quantity: "அளவு",
            total: "மொத்தம்",
            price: "விலை",
            shopNow: "இப்பொழுது வாங்கு",
            noItems: "வண்டியில் உருப்படிகள் இல்லை",
            checkout: "சரிபார்",
            subtotal: "கூட்டுத்தொகை",
            discount: "தள்ளுபடி ஒழுங்கு",
            shipping: "கப்பல்",
            yourCart: "உங்கள் வண்டி",
            addOnlyTenProducts: "நீங்கள் பத்து தயாரிப்புகளை மட்டுமே சேர்க்க முடியும்",
            action: "செயல்",
            ok: "சரி",
            nocartitem: "வண்டியில் உருப்படிகள் இல்லை",
            cartEmpty: "உங்கள் வண்டி காலியாக உள்ளது.",
            wishlistEmpty: "உங்கள் விருப்பம் பட்டியல் காலியாக உள்ளது.",
        },

        checkout: {
            alert: "எச்சரிக்கை",
            ok: "சரி",
            checkout: "சரிபார்",
            refresh: "புதுப்பிப்பு",
            orderProcessing: "உங்கள் ஆர்டர் கட்டணம் செயலாக்குகிறது",
            thankYouForOrder: "ஆர்டருக்கு நன்றி",
            orderNumberIs: "ஆர்டர் எண்",
            transactionIdIs: "பரிவர்த்தனை ஐடி",
            continueShopping: "ஷாப்பிங் தொடரவும்",
            back: "மீண்டும்",
            next: "அடுத்தது",
            placeOrder: "இடம் ஆர்டர்",
            payNow: "இப்போது செலுத்த",
            shippingAddress: "சேரும் முகவரி",
            paymentMethod: "கட்டண முறை",
            orderSummary: "ஒழுங்கு சுருக்கம்",
        },
        review: {
            flashSaleCouponMsg : "குறிப்பு: ஃபிளாஷ் விற்பனை தயாரிப்புகளுக்கு கூப்பன் பொருந்தாது.",
            flashSaleWalletMsg : "குறிப்பு: ஃபிளாஷ் விற்பனை தயாரிப்புகளுக்கு வாலட் பொருந்தாது.",
            to: "க்கு",
            days: "நாட்களில்",
            minimumOrderValue: "குறைந்தபட்ச ஆர்டர் மதிப்பு",
            maimumDiscountIS: "அதிகபட்ச தள்ளுபடி",
            orderSummary: "ஆர்டர் சுருக்கம்",
            productName: "பொருளின் பெயர்",
            originalPrice: "உண்மையான விலை",
            margin: "விளிம்பு",
            offerPrice: "சலுகை விலை",
            orderAmount: "ஆர்டர் தொகை",
            offerDiscount: "தயாரிப்பு தள்ளுபடி",
            bankDiscount: "வங்கி சலுகை",
            subTotal: "கூட்டுத்தொகை",
            Qty: "Qty",
            productAmount: "தயாரிப்பு விலை",
            deliveryFee: "விநியோக கட்டணம்",
            marginPrice: "விளிம்பு விலை",
            orderDiscount: "கூப்பன் தள்ளுபடி",
            orderTotal: "ஆர்டர் மொத்தம்",
            youSave: "நீ காப்பாற்று",
            chooseCoupon: "கூப்பனைத் தேர்வுசெய்க",
            remove: "அகற்று",
            applyCoupon: "கூப்பனைப் பயன்படுத்துங்கள்",
            youSaveAdditional: "நீங்கள் கூடுதல் சேமிக்கிறீர்கள்",
            shipping: "சேரும் முகவரி",
            paymentMethod: "கட்டண முறை",
            total: "மொத்தம்",
            errorMessage: "செல்லுபடியாகும் முகவரியைத் தேர்ந்தெடுக்கவும்",
            margin: "விளிம்பு தொகையை உள்ளிடவும்",
            message: "கூப்பன் தள்ளுபடியைக் கணக்கிடுங்கள்",
            orderPlaceMessage: "உங்கள் ஆர்டர் செயலாக்குகிறது ...",
            paymentMessage: "உங்கள் அட்டையுடன் செலுத்துங்கள் ..",
            applyCoupen: "கூப்பன் குறியீட்டைப் பயன்படுத்துங்கள்",
            sellingMessage: "தயாரிப்பை மறுவிற்பனை செய்கிறீர்களா?",
            marginMessage: "உங்கள் விளிம்பைச் சேர்க்க ஆம் என்பதைக் கிளிக் செய்க",
            yes: "ஆம்",
            close: "நெருக்கமான",
            enterMargin: "விளிம்பு தொகையை உள்ளிடவும்",
            addMargin: "விளிம்பு சேர்",
            marginError1: "தயவுசெய்து விளிம்பை உள்ளிடவும்",
            marginError2: "மொத்த தொகையில்",
            couponApply: "கூப்பன் பயன்படுத்தப்பட்டது",
            valid: "வரை செல்லுபடியாகும்",
            totalAmount: "மொத்த தொகை",
            paymentdetails: "கட்டண விவரங்கள்",
            reviewOrder: "உங்கள் ஆர்டரை மதிப்பாய்வு செய்யவும்",
            online: "நிகழ்நிலை",
            deliveryServices: "டெலிவரி சேவைகளைத் தேர்ந்தெடுக்கவும்",
            freeDeliveryMessage: "மேலே உள்ள ஆர்டர்களில்",
            freeDeliveryNotMessage: "தகுதியற்ற",
            freeDelivery: "இலவச விநியோகம்",
            serviceSelectMessage: "தயவுசெய்து டெலிவரி சேவையைத் தேர்ந்தெடுக்கவும்",
            marginError: "விளிம்பு அளவு குறைவாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்",
        },

        reasons: {
            returnReasonOne: "குறைபாடு துண்டு.",
            returnReasonTwo: "தவறான தயாரிப்பு பெறப்பட்டது.",
            returnReasonThree: "தேவையில்லை.",
            cancelReasonOne:
                "தயாரிப்பு தர சிக்கல்கள் காரணமாக நான் ரத்து செய்ய விரும்புகிறேன்",
            cancelReasonTwo: "தயாரிப்புக்கான விலை குறைந்துள்ளது",
            cancelReasonThree: "எதிர்பார்க்கப்படும் விநியோக நேரம் மிக நீளமானது",
            cancelReasonFour: "நான் தயாரிப்பை வேறொரு இடத்தில் வாங்கினேன்",
            cancelReasonFive: "ஆர்டருக்கான முகவரியை மாற்ற விரும்புகிறேன்",
            cancelReasonSix: "நான் என் மனதை மாற்றிக்கொண்டேன்",
            cancelReasonSeven: "எனது ஆர்டரை ப்ரீபெய்ட் ஆக மாற்ற விரும்புகிறேன்",
            cancelReasonEight: "எனது தொலைபேசி எண்ணை மாற்ற விரும்புகிறேன்",
        },
        wishList: {
            addToCart: "பெட்டகத்தில் சேர்",
            product: "தயாரிப்பு",
            offerPrice: "சலுகை விலை",
            price: "விலை",
            yourWishlist: "என் விருப்பப்பட்டியல்",
            addOnlyTenProducts: "நீங்கள் பத்து தயாரிப்புகளை மட்டுமே சேர்க்க முடியும்",
        },
        attributeModal: {
            price: "விலை",
            chooseOptions: "விருப்பங்களைத் தேர்வுசெய்க",
            noProducts: "விருப்பப்பட்டியலில் எந்த உருப்படியும் இல்லை",
            shopNow: "இப்பொழுது வாங்கு",
            addToCart: "பெட்டகத்தில் சேர்",
            alert: "எச்சரிக்கை",
            ok: "சரி",
        },
        chats: {
            chats: "அரட்டைகள்",
            noChats: "உங்களிடம் எந்த அரட்டைகளும் இல்லை",
            noThanks: "இல்லை நன்றி",
            upgradeNow: "இப்போது மேம்படுத்தவும்",
            makeAOffer: "ஒரு சலுகையை உருவாக்குங்கள்",
            acceptOffer: "சலுகையை ஏற்றுக்கொள்",
            am: "நான்",
            pm: "மாலை",
            addToCart: "பெட்டகத்தில் சேர்",
        },
        login: {
            loginWithPhone: "தொலைபேசியுடன் உள்நுழைக",
            loginWithEmail: "மின்னஞ்சலுடன் உள்நுழைக",
            login: "உள்நுழைய",
            newUser: "Fashionleo க்கு புதியவரா? ஒரு கணக்கை உருவாக்க",
            sendOtp: "OTP ஐ அனுப்பு",
            signUp: "படிவத்தை பதிவு செய்க",
            signUpButton: "பதிவுபெறுக",
            or: "அல்லது",
            resendOtp: "OTP ஐ மீண்டும் அனுப்புங்கள்",
            forgotPassword: "கடவுச்சொல்லை மறந்துவிட்டீர்களா",
            enterPhone: "உங்கள் தொடர்பு எண்ணை அளிக்கவும்",
            dialingCode: "டயலிங்_கோட்",
            alreadyAccount: "ஏற்கனவே ஒரு கணக்கு இருக்கிறதா? உள்நுழைவு",
            verify: "சரிபார்க்கவும்",
            signUp: "பதிவுபெறுக",
            enterTheOtp: "தயவுசெய்து OTP ஐ உள்ளிடவும்",
            password: "கடவுச்சொல்லை உள்ளிடவும்",
            emailPhone: "மின்னஞ்சல்/மொபைல் எண்",
            email: "மின்னஞ்சலை உள்ளிடவும்",
            name: "உங்கள் முழு பெயரை உள்ளிடவும்",
            codeError: "நாட்டு டயலிங் குறியீட்டைத் தேர்ந்தெடுக்கவும்",
            phoneError: "செல்லுபடியாகும் மொபைல் எண்ணை உள்ளிடவும்",
            termsError:
                "தயவுசெய்து விதிமுறைகளையும் நிபந்தனைகளையும் ஏற்றுக்கொள்ளுங்கள்.",
            termCondition:
                "நான் எல்லா விதிமுறைகளையும் நிபந்தனைகளையும் ஏற்றுக்கொள்கிறேன்.",
            termConditionOne: "நான் அனைத்தையும் ஏற்றுக்கொள்கிறேன்",
            termConditionTwo: "விதிமுறைகள் மற்றும் நிபந்தனைகள்",
            nameError: "பெயர் புலம் தேவை",
            forgotMessage:
                "தயவுசெய்து உங்கள் பதிவு செய்யப்பட்ட தொலைபேசியை உள்ளிடவும், நாங்கள் உங்களுக்கு ஒரு OTP ஐ அனுப்புவோம்",
        },
        pageNotFound: {
            oops: "அச்சச்சோ! பக்கம் கிடைக்கவில்லை",
            unAvailable:
                "    நீங்கள் அணுக முயற்சிக்கும் பக்கம் அகற்றப்பட்டிருக்கலாம், மாற்றப்பட்டிருக்கலாம் அல்லது கிடைக்கவில்லை.",
            goToHome: "   முகப்பு பக்கத்திற்கு செல்லவும்",
            tryExploring: "ஆராய முயற்சிக்கவும்",
        },
        vendorDetails: {
            myFollowing: "எனது பின்வருபவை",
            vendorDetails: "விற்பனையாளர் விவரங்கள்",
        },
        brands: {
            allbrands: "பிராண்டுகளின் கடை",
        },
        profileMenu: {
            profile: "சுயவிவரம்",
            myBankDetails: "எனது வங்கி விவரங்கள்",
            myEarnings: "என் வருவாய்",
            myReviews: "எனது மதிப்புரைகள்",
            deleteAccount: "கணக்கை நீக்குக",
            myChats: "என் அரட்டைகள்",
            myOrders: "என் கட்டளைகள்",
            myCart: "என் வண்டி",
            myFollowing: "எனது பின்வருபவை",
            manageAddress: "முகவரியை நிர்வகிக்கவும்",
            logout: "வெளியேறு",
        },
        deletePopup: {
            confirmToDelete: "உறுதிப்படுத்தவும்",
            delAccount: "கணக்கை நீக்குக",
            delMsg:
                "உங்கள் கணக்கை நீக்குவதன் மூலம் பின்வரும் தகவல்கள் நீக்கப்படும், மேலும் அவை செயல்தவிர்க்க முடியாது",
            msgOne: "சொந்த விவரங்கள்",
            msgTwo: "விற்பனையாளர் சுயவிவரம் (கிடைத்தால்)",
            msgThree: "தனிப்பட்ட ஆவணங்கள்",
            msgFour: "முகவரிகள்",
            msgFive: "வங்கி விவரங்கள்",
            delConfirm:
                "உங்கள் கணக்கை இப்போது நீக்க உறுதிப்படுத்தவும் என்பதைக் கிளிக் செய்யவா?",
        },
        reviewTr: {
            title: "எனது விமர்சனம்",
            titleEarn: "என் வருவாய்",
            viewRec: "ரசீது காண்க",
            TransactionId: "பரிவர்த்தனை ஐடி",
            walletMsg: "உங்கள் பரிந்துரைக் குறியீட்டை உருவாக்க மற்றும் பரிந்துரை காதணிகளைத் தொடங்க முதல் ஆர்டரை முடிக்கவும்",
            totalEarn: "மொத்த வருவாய்",
            walletBalance: "பணப்பை இருப்பு",
            inviteMsg: "உங்கள் நண்பரை ஃபேஷன் லியோவிற்கு அழைத்து அவர்களின் முதல் வெற்றிகரமான ஆர்டரில் கேஷ்பேக்கைப் பெறுங்கள். மேலும் தகவலுக்கு, விதிமுறைகள் மற்றும் நிபந்தனைகளைப் படிக்கவும்",
            refferalCode: "பரிந்துரை குறியீடு",
        },
        chatPopUp: {
            actualPrice: "உண்மையான விலை",
            makeAOffer: "ஒரு சலுகையை உருவாக்குங்கள்",
            am: "நான்",
            pm: "மாலை",
            acceptOffer: "சலுகையை ஏற்றுக்கொள்",
            send: "அனுப்பு",
            addToCart: "பெட்டகத்தில் சேர்",
        },
        sortForm: {
            newArrivals: "புதிய வருகை",
            mostPopular: "மிகவும் பிரபலமான",
            priceLowToHigh: "விலை (குறைந்த முதல் உயர்)",
            priceHighToLow: "விலை (உயர் முதல் குறைந்த",
            ratingHighToLow: "மதிப்பீடுகள் (உயர் முதல் குறைந்த வரை)",
            sort: "வகைபடுத்து",
        },
        myFollowing: {
            ratings: "மதிப்பீடுகள்",
            followings: "பின்தொடர்வுகள்",
            products: "தயாரிப்புகள்",
            allProducts: "அனைத்து தயாரிப்புகளும்",
            showing: "காட்டுகிறது",
            follow: "பின்தொடரவும்",
            following: "பின்தொடர்தல்",
        },
        bankDetails: {
            title: "வங்கி விவரங்கள்",
            bankName: "வங்கி பெயர்",
            accountNumber: "கணக்கு எண்",
            confirmAccountNumber: "கணக்கு எண்ணை உறுதிப்படுத்தவும்",
            swiftCode: "ஸ்விஃப்ட் குறியீடு / ரூட்டிங் எண்",
            accountHolderName: "கணக்கு வைத்திருப்பவரின் பெயர்",
            bankCode: "வங்கி குறியீடு",
            branchCode: "கிளை குறியீடு",
            accountType: "கணக்கு வகை",
            submit: "சமர்ப்பிக்கவும்",
            bankNameError: "வங்கி பெயர் தேவை.",
            NameError: "கணக்கு வைத்திருப்பவர் பெயர் தேவை.",
            AccountError: "கணக்கு எண் தேவை.",
            accountError: "கணக்கு எண் 6 க்கு மேல் இருக்க வேண்டும்.",
            confirmAccountError: "கணக்கு எண் தேவை என்பதை உறுதிப்படுத்தவும்.",
            ifscError: "ஸ்விஃப்ட் குறியீடு / ரூட்டிங் எண் தேவை.",
            bankCodeError: "வங்கி குறியீடு தேவை.",
            branchCodeError: "கிளை குறியீடு தேவை.",
            accountTypeError: "கணக்கு வகை தேவை.",
        },
        home: {
            recentProducts: "புதிய வருகை",
            messageText: "உங்கள் செய்தியைத் தட்டச்சு செய்க",
            button: { viewAll: "அனைத்தையும் காட்டு" },
            categories: { title: "வகைகள்" },
            brand: { title: "பிராண்டுகளின் கடை" },
            fCollection: { title: "சிறப்பு தயாரிப்புகள்" },
            flashSale: { title: "ஃபிளாஷ் விற்பனை", left: "இடது" },
            trProducts: { title: "பிரபலமான தயாரிப்புகள்" },
            topProducts: { title: "சிறந்த தயாரிப்புகள்" },
            nearbySellers: { title: "அருகிலுள்ள விற்பனையாளர்கள்" },
            recentViewProduct: { title: "சமீபத்திய காட்சி தயாரிப்பு" },
            providingFeatures: {
                i: {
                    main: "இலவச கப்பல் & திரும்ப",
                    secondary: "தகுதியான ஆர்டர்களில் கிடைக்கும்",
                },
                ii: {
                    main: "பண உத்தரவாதம்",
                    secondary: "30 நாட்கள் பணம் திரும்ப உத்தரவாதம்",
                },
                iii: {
                    main: "ஆன்லைன் ஆதரவு",
                    secondary: "நாங்கள் ஆன்லைனில் 24/7 ஐ ஆதரிக்கிறோம்",
                },
                iv: {
                    main: "பாதுகாப்பான கொடுப்பனவுகள்",
                    secondary:
                        "அனைத்து கட்டணங்களும் பாதுகாக்கப்பட்டவை மற்றும் நம்பகமானவை",
                },
            },
        },
        affiliate: {
            headingRegister: " Affiliate பதிவு",
            phone: "தொலைபேசி",
            firstName: "Name",
            lastName: "Last Name",
            emailAddress: "Email Address",
            terms: " I have read and agree to the website terms and conditions",
            register: "Register",
            password: "Password",
            amount: "Amount"
        },
        affiliateDashboard: {
            dashboard: "டாஷ்போர்டு",
            commission: "தரகு",
            visits: "வருகைகள்",
            payment: "கட்டணம்",
            link: "இணைப்பு ஜெனரேட்டர்",
            settings: "அமைப்புகள்",
            generateLinks: "இணைப்புகளை உருவாக்குகிறது",
            paymentInfo: "கட்டண தகவல்",
            status: "அனைத்து நிலை",
            rowsPerpage: "வரிசைகள்",
            urlTitle: "உங்கள் பரிந்துரை URL :",
            commissionHeading: "சமீபத்திய கமிஷன்கள்",
        },
        affiliatePages: {
            recenteVisit: "சமீபத்திய வருகைகள் (அனைத்தையும் காண்க)",
            affiliate: "Affiliate",
            totalEarn: "மொத்த வருவாய்",
            totalpaid: "மொத்தம் செலுத்தியது",
            totalpaid: "திரும்பப் பெறப்பட்டது",
            balance: "இருப்பு",
            commitssionRate: "கமிஷன் விகிதம்",
            conversionRate: "மாற்று விகிதம்",
            visits: "வருகைகள்",
            visitsToday: "இன்று வருகை",
            date: "தேதி",
            product: "தயாரிப்பு",
            orderNo: "உத்தரவு எண்.",
            commissionPrice: "கமிஷன் விலை",
            status: "நிலை",
        },
    },
};
export default tm;