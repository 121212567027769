import { makeStyles } from '@material-ui/core';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { withFormsy } from 'formsy-react';
import React from 'react';
import { useSelector } from 'react-redux';
import _ from "../utils/@lodash";

function SelectFormsy(props) {
	const themes = useSelector(({ allCategories }) => allCategories.theme);
	const useStyles = makeStyles({
		input: {
			cursor: props.disabled ? 'not-allowed' : "alias",
			color: '#fff',
			'& .MuiSelect-selectMenu': {
				color: "black"
			},
			'& .MuiSelect-selectMenu::placeholder': {
				// textOverflow: 'ellipsis !important',  
				filter: "brightness(500%)",
				opacity: "10",
				color: '#022530'
			}

		}
	});
	const classes = useStyles();

	const importedProps = _.pick(props, [
		'autoWidth',
		'children',
		'classes',
		'displayEmpty',
		'input',
		'inputProps',
		'MenuProps',
		'multiple',
		'native',
		'onChange',
		'onClose',
		'onOpen',
		'open',
		'renderValue',
		'SelectDisplayProps',
		'value',
		'variant',
		'color'
	]);

	// An error message is returned only if the component is invalid
	const { validationError, value } = props;

	function input() {
		switch (importedProps.variant) {
			case 'outlined':
				return <OutlinedInput labelWidth={props.label.length * 8} id={props.name} />;
			case 'filled':
				return <FilledInput id={props.name} />;
			default:
				return <Input id={props.name} />;
		}
	}

	function changeValue(event) {
		props.setValue(event.target.value);
		if (props.onChange) {
			props.onChange(props.name, event.target.value);
		}
	}
	return (
		<FormControl
			error={Boolean((!props.isPristine && props.showRequired) || validationError)}
			className={props.className}
			variant={importedProps.variant}
		>
			{props.label && <InputLabel className={classes.input} htmlFor={props.name}>{props.label}</InputLabel>}
			<Select {...importedProps} className={classes.input} value={value} onChange={changeValue} input={input()} />
			{Boolean(validationError) && <FormHelperText>{validationError}</FormHelperText>}
		</FormControl>
	);
}

export default React.memo(withFormsy(SelectFormsy));
