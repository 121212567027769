import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { Rating } from "@mui/material";
import ApiService from "../services/apiService";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function MyReviews() {
  const { t } = useTranslation();
  const history = useHistory();
  const [review, setReview] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    ApiService.getCustomerReview().then(response => {
      setReview(response?.data?.data?.ratings);
    })
  }, [])

  return (
    <React.Fragment>
      <div>
        <div className="mb-4">
          <Typography
            variant={"h5"}
            className="py-5 text-center uppercase dark:text-fontWhite dark:bg-lightDarkBackground bg-gray-200 border-b-2"
          >
            {t("reviewTr.title")}
          </Typography>
        </div>

        <div>
          {review?.data?.length === 0 &&
            <div className="text-center text-lg pb-12 pt-2">You have not given reviews on any products</div>}
        </div>
        <div className="w-full my-6 pl-8 ">
          <div className="grid md:grid-cols-2  grid-cols-1 gap-5">

            {review?.data?.map((item) => {
              return (
                <Card
                  className="flex lg:my-4 w-11/12 dark:bg-lightDarkBackground hover:bg-gray-100 shadow-lg rounded-2xl m-auto relative  py-1 px-4 lg:py-4 box-content gap-4 border-2 border-gray-500 bg-gray-100 cursor-pointer"
                  onClick={() => {
                    history.push(`/product-details/${item.product.slug}/${item.product.id}`)
                  }}>
                  <div>
                    <Avatar
                      src={`${item?.product?.ImageSrc}`}
                      sx={{
                        width: 100,
                        height: 100,
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col">
                      <label className="text-fontDark font-bold text-base">
                        {item?.product?.product_name}
                      </label>
                      <Rating
                        value={item.rating}
                        readOnly
                      />
                      <label className="text-fontDark text-base">
                        {item?.description}
                      </label>
                    </div>
                    <div className="flex justify-end w-full h-full items-end">
                      <label className="text-fontDark text-base">{moment(item?.created_at).format("DD-MM-yyyy")}</label>
                    </div>
                  </div>
                </Card>
              )
            })
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}