import { makeStyles } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import AppConfig from "../appConfig";

const useStyles = makeStyles({
  button: {
    background: "#009acc",
    margin: 10,

    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    color: AppConfig.color.button,
    borderRadius: "10px",
    display: "block",
  },
});

export default function ViewAllButton(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    // separate component for view all button
    <>
      <Button
        className={clsx(
          classes.button,
          "hidden md:block fw-bold p-2.5 my-2  text-black rounded-md  text:xs sm:text-md font-normal w-30 uppercase"
        )}
        onClick={() =>
          history.push({
            pathname: `/view-all/${props.type}`,
            state: {
              id: null,
              type: "Featured Collection",
            },
          })
        }
      >
        <RemoveRedEyeIcon className="p-0.5" />{" "}
        <span
          className="text-xs text-fontWhite text-muted sm:text-normal
                    font-bold w-auto me-2"
        >
          {t("home.button.viewAll")}
        </span>
      </Button>

      <button
        className="block md:hidden border-none fw-bold text-black text:xs sm:text-md font-normal w-30 uppercase"
        onClick={() =>
          history.push({
            pathname: `/view-all/${props.type}`,
            state: {
              id: null,
              type: "Featured Collection",
            },
          })
        }
      >
        <span
          className="text-xs text-muted sm:text-normal
                     font-bold w-auto me-2"
        >
          {t("home.button.viewAll")}
        </span>
      </button>
    </>
  );
}
