import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Slider,
  Typography
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Pagination } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import AppConfig from "../../appConfig";
import ProductCard from "../../core-components/ProductCard";
import SortForm from "../../core-components/SortForm";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
import cartImage from "./noproduct.png";
import Box from "@mui/material/Box";

export default function Shop() {
  const [brand, setBrand] = useState([]);
  const [length, setlength] = useState([]);

  var urlString;
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const useStyles = makeStyles((theme) => ({
    header: {
      boxShadow: "none",
    },
    panel: {
      boxShadow: "none",
    },
    expanded: {
      boxShadow: "none",
    },
    root: {
      width: 300,
    },
    margin: {
      height: theme.spacing(3),
    },
    thumb: {
      background: "white",
      border: "2px solid black",
      "&~&": {
        background: "white",
        border: "2px solid black",
      },
    },
    mark: {
      background: "black",
    },
    rail: {
      background: "black",
    },
    track: {
      background: "black",
      height: "3px",
    },
    valueLabel: {
      "&>*": {
        background: "black",
      },
    },
    ul: {
      "& .MuiPaginationItem-root": {
        color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505"
      }
    }
  }));
  const [page, setPage] = React.useState(1);
  const classes = useStyles();
  const { t } = useTranslation();
  const type = useParams();
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);

  const [mobileDisplay, setMobileDisplay] = useState([]);
  const [ram, setRam] = useState([]);
  const [storage, setStorage] = useState([]);
  const [image, setImage] = useState([]);
  const [sortValue, setSortValue] = React.useState("most-popular");
  const [filterArray, setFilterArray] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = React.useState([0, 1000]);
  const drawerWidth = 550;
  const user_id = sessionStorage.getItem("user_id");
  const [parentFilterId, setParentFilterId] = useState(0)

  const discountPriceArray = [
    {
      heading: "10 ",
      start_price: "10",
    },
    {
      heading: "20 ",
      start_price: "20",
    },
    {
      heading: "30 ",
      start_price: "30",
    },
    {
      heading: "40 ",
      start_price: "40",
    },
    {
      heading: "50 ",
      start_price: "50",
    },
    {
      heading: "60 ",
      start_price: "60",
    },
    {
      heading: "70 ",
      start_price: "80",
    },
    {
      heading: "90 ",
      start_price: "90",
    },
  ];
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  const state = useLocation()
  // const [wishArray, setWishArray] = useState([]);
  const fields = useSelector(({ allCategories }) => allCategories.categories);

  const [discValues, setDiscValues] = useState([0, 100]);
  const [attr, setAttr] = useState([]);
  const [activeArr, setActiveArr] = useState([]);
  const [selectedDisc, setSelectedDisc] = useState(-1);

  const minValue = [
    { minPrice: "250", maxPrice: "499" },
    { minPrice: "500", maxPrice: "999" },
    { minPrice: "1000", maxPrice: "1999" },
    { minPrice: "2000", maxPrice: "2999" },
    { minPrice: "3000", maxPrice: "100000" }
  ];
  const [minFilterPrice, setMinFilterPrice] = useState()
  const [maxFilterPrice, setMaxFilterPrice] = useState()

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  // add product to wishlist function
  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      // wishItemToggle(data.id);
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      // if user is not logged in push it to login page
      history.push(AppConfig.routes.login);
    }
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}`;
  }

  // handle On change

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [priceSearchFilter, setPriceSearchFilter] = useState(false)
  const [discountSearchFilter, setDiscountSearchFilter] = useState(false)
  const [priceSearchArray, setPriceSearchArray] = useState([])
  const [filterPricingArray, setFilterPricingArray] = useState([])
  const [filterMaxPricingArray, setFilterMaxPricingArray] = useState([])
  const [brandSearch, setBrandSearch] = useState(false)
  const [brandArray, setBrandArray] = useState([])
  const [brandFilterArray, setBrandFilterArray] = useState([])
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [minMaxPriceFilter, setMinMaxPriceFilter] = useState({
    min: "",
    max: ""
  })
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    setPage(1)
    window.scrollTo(0, 0)
    localStorage.setItem("_typeid", type.id);
    localStorage.setItem("_typeslug", type.slug);
    localStorage.setItem("_typeshop", type.shop);
    return () => {
      // when component will unmount it will remove above items from localStorage
      localStorage.removeItem("_typeshop");
      localStorage.removeItem("_typeslug");
      localStorage.removeItem("_typeid");
    };
  }, [type]);

  useEffect(() => {
    // This will component will run on different url condition so depending upon which url you are different api will get called
    if (type.type == "search") {
      setLoading(true)
      let lastElement = filterMaxPricingArray.sort(function (a, b) { return a - b });

      const sparseArray = Math.max.apply(null, lastElement.filter(function (x) {
        return isFinite(x);
      }));

      const form = new FormData();
      if (filterPricingArray.length > 0 && filterPricingArray !== undefined) {
        form.append("start_price", filterPricingArray[0])
        form.append("end_price", sparseArray)
      }
      form.append("search", type.id);
      form.append("type", sortValue)
      form.append("page", page);

      ApiService.searchProduct(form).then((res) => {
        setImage(res.data.data);
        setLoading(false);
      });
    }
    else {
      setLoading(true)
      if (type.type == "featured-product") {
        ApiService.getFeaturedCollection(sortValue, page).then((res) => {
          setImage(res.data.data);
          setLoading(false);
        });
      } else if (type.type == "trending-product") {
        ApiService.getTrendingProducts(sortValue, page).then((res) => {
          setImage(res.data.data);
          setLoading(false);
        });
      } else if (type.type == "brands") {
        const formData = new FormData();
        formData.append("page", page);
        formData.append("type", sortValue);

        if (parentFilterId || state.state.id) {
          formData.append("id", parentFilterId === 0 ? state.state.id : parentFilterId);
        }

        {
          state.state.sub_id &&
            formData.append("getSubCategory", state.state?.sub_id)
        }

        attr.length > 0 &&
          attr.forEach((a, i) => formData.append(`attributes[${i}]`, a));
        if (filterArray.length > 0) {
          filterArray.map((item, index) => {
            formData.append(`brand[${index}]`, item);
          });
        }

        ApiService.getCategoriesById(formData).then((res) => {
          if (res.products) {
            if (image.length > 0) {

              image.data.push(res.products)
            }
            else {
              setImage(res.products);
            }
          }
          else {

            if (image.length > 0) {
              image.data.push(res)
            }
            else {
              setImage(res);
            }
          }
          setLoading(false);
        });
      } else if (type.type === "category-product") {
        setLoading(true)
        let lastElement = filterMaxPricingArray.sort(function (a, b) { return a - b });

        const sparseArray = Math.max.apply(null, lastElement.filter(function (x) {
          return isFinite(x);
        }));

        const _form = new FormData();
        if (filterPricingArray.length > 0 && filterPricingArray !== undefined) {
          _form.append("start_price", filterPricingArray[0])
          _form.append("end_price", sparseArray)
        }
        _form.append("id", state?.state?.id);
        {
          state.state.sub_id &&
            _form.append("getSubCategory", state.state?.sub_id)
        }
        _form.append("page", page);
        _form.append("type", sortValue);

        const startvalue = Number(discValues[0]);
        const endValue = Number(discValues[1]);

        attr.length > 0 &&
          attr.forEach((a, i) => _form.append(`attributes[${i}]`, a));
        if (filterArray.length > 0) {
          filterArray.map((item, index) => {
            _form.append(`brand[${index}]`, item);
          });
        }
        ApiService.getCategoriesById(_form).then((res) => {
          if (res.products) {
            if (image.length > 0) {

              image.data.push(res.products)
            }
            else {
              setImage(res.products);
            }
          }
          else {

            if (image.length > 0) {
              image.data.push(res)
            }
            else {
              setImage(res);
            }
          }

          setLoading(false);
        });
      } else if (type.type == "offer") {
        const newString = type.id.split("-");
        const startPriceArray = newString[0].split("=");
        const endPriceArray = newString[1].split("=");
        const startPrice = startPriceArray[1];
        const endPrice = endPriceArray[1];
        const form = new FormData();

        if (parentFilterId || state.state.id) {
          form.append("id", parentFilterId === 0 ? state.state.id : parentFilterId);
        }
        {
          state.state.sub_id &&
            form.append("getSubCategory", state.state?.sub_id)
        }

        form.append("end_discount", endPrice);
        form.append("start_discount", startPrice);
        form.append("page", page);
        form.append("type", sortValue);

        ApiService.getCategoriesById(form).then((res) => {

          setImage(res)
          setLoading(false);
        });
      } else {
        ApiService.getTopProducts(sortValue, page).then((res) => {
          setImage(res);
          setLoading(false);
        });
      }

      // ApiService.getTopProducts(sortValue, page).then((res) => {
      //   setImage(res);
      //   setLoading(false);
      // });
    }
    ApiService.getAttributesByCategoriesId(type.id).then((res) => {
      Object.entries(res.data.data).map((item, key) => {
        if (item[0] === "brand") {
          setBrand(item[1]);
        } else if (item[0] === "storage") {
          setStorage(item[1]);
        } else if (item[0] === "ram") {
          setRam(item[1]);
        } else if (item[0] === "length") {
          setlength(item[1]);
        } else if (item[0] === "Color") {
          setColor(item[1]);
        } else if (item[0] === "Size") {
          setSize(item[1]);
        } else if (item[0] === "category") {
          // setFields(item[1]);
        }
        else if (item[0].includes("mobile display")) {
          setMobileDisplay(item[1]);
        }
      });
    });
    const inputLabel = document.querySelectorAll('.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root')
    const inputLabelArray = [...inputLabel]
    inputLabelArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
    const select = document.querySelectorAll('.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root')
    const selectArray = [...select]
    selectArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
    const selectActive = document.querySelectorAll('.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root')
    const selectActiveArray = [...selectActive]
    selectActiveArray.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
    const selectActive1 = document.querySelectorAll('.MuiSelect-selectMenu')
    const selectActiveArray1 = [...selectActive1]
    selectActiveArray1.forEach((ele) => {
      ele.classList.add('dark:text-fontWhite')
      ele.classList.add('text-bolder')
      ele.classList.add('text-primary')
    })
  }, [type.categoryId, page, discValues, type.id, attr, sortValue, filterPricingArray, filterMaxPricingArray]);

  const addFilterItem = (item) => {
    let tempArray = filterArray ?? [];

    if (tempArray.includes(item)) {
      tempArray = tempArray.filter((element) => element !== item);
    } else {
      tempArray.push(item);
    }
    let sortMinValue = tempArray.sort(function (a, b) { return a - b });

    setFilterArray(tempArray);
    let _form = new FormData();

    _form.append("page", page);
    if (type.type === 'search') {
      _form.append("search", type.id);
    } else {
      if (parentFilterId || state.state.id) {
        _form.append("id", parentFilterId === 0 ? state.state.id : parentFilterId);
      }
    }
    apiCalling(_form, tempArray);
  };

  const applyFilter = (valueMin, valueMax) => {
    const _formData = new FormData();

    if (type.type === 'offer') {
      const newString = type.id.split("-");
      const startPriceArray = newString[0].split("=");
      const endPriceArray = newString[1].split("=");
      const startPrice = startPriceArray[1];
      const endPrice = endPriceArray[1];

      {
        state.state.sub_id &&
          _formData.append("getSubCategory", state.state?.sub_id)
      }
      _formData.append("end_discount", endPrice);
      _formData.append("start_discount", startPrice);
      _formData.append("page", page);
      _formData.append("type", sortValue);

    }

    let minPricingArray = filterPricingArray ?? [];

    if (minPricingArray.includes(valueMin)) {
      minPricingArray = minPricingArray.filter((element) => element !== valueMin);
    } else {
      minPricingArray.push(valueMin);
    }

    let sortMinValuee = minPricingArray.sort(function (a, b) { return a - b });
    setFilterPricingArray(sortMinValuee);

    // maxValue
    let maxPricingArray = filterMaxPricingArray ?? [];

    if (maxPricingArray.includes(valueMax)) {
      maxPricingArray = maxPricingArray.filter((element) => element !== valueMax);
    } else {
      maxPricingArray.push(valueMax);
    }
    setFilterMaxPricingArray(maxPricingArray);

    let lastElement = maxPricingArray.sort(function (a, b) { return a - b });

    const sparseArray = Math.max.apply(null, lastElement.filter(function (x) {
      return isFinite(x);
    }));

    setMaxFilterPrice(sparseArray)

    if (minPricingArray.length > 0) {
      _formData.append("start_price", sortMinValuee[0]);
      _formData.append("end_price", sparseArray);
    }

    if (parentFilterId || state.state.id) {
      _formData.append("id", parentFilterId === 0 ? state.state.id : parentFilterId);
    }
    _formData.append("page", page);
    apiCalling(_formData);
  };

  const apiCalling = (_formData, tempArray = filterArray) => {
    setLoading(true)
    attr.length > 0 &&
      attr.forEach((a, i) => _formData.append(`attributes[${i}]`, a));

    if (filterArray.length > 0) {
      tempArray.map((item, index) => {
        _formData.append(`brand[${index}]`, item);
      });
    }

    {
      state.state.sub_id &&
        _formData.append("getSubCategory", state.state?.sub_id)
    }


    ApiService.getCategoriesById(_formData).then((res) => {
      if (res.products) {
        setImage(res.products);
        setLoading(false)
      }
      else {
        setImage(res);
        setLoading(false)
      }
    });

  };

  //apply filter pricing --Search--
  const addFilterBrandSearch = (ele) => {
    let _formData = new FormData();

    let tempArray = brandFilterArray ?? [];

    if (tempArray.includes(ele)) {
      tempArray = tempArray.filter((element) => element !== ele);
    } else {
      tempArray.push(ele);
    }
    setBrandFilterArray(tempArray);

    if (brandFilterArray.length > 0) {
      tempArray.map((item, index) => {
        _formData.append(`brand[${index}]`, item);
      });
    }
    _formData.append("search", type.id);

    apiCallingSearch(_formData, tempArray)
  }

  const applyFilterSearch = (valueMin, valueMax) => {
    // minValue
    let minPricingArray = filterPricingArray ?? [];

    if (minPricingArray.includes(valueMin)) {
      minPricingArray = minPricingArray.filter((element) => element !== valueMin);
    } else {
      minPricingArray.push(valueMin);
    }

    let sortMinValuee = minPricingArray.sort(function (a, b) { return a - b });
    setFilterPricingArray(sortMinValuee);

    // maxValue
    let maxPricingArray = filterMaxPricingArray ?? [];

    if (maxPricingArray.includes(valueMax)) {
      maxPricingArray = maxPricingArray.filter((element) => element !== valueMax);
    } else {
      maxPricingArray.push(valueMax);
    }
    setFilterMaxPricingArray(maxPricingArray);

    let lastElement = maxPricingArray.sort(function (a, b) { return a - b });

    const sparseArray = Math.max.apply(null, lastElement.filter(function (x) {
      return isFinite(x);
    }));

    let _formData = new FormData();

    _formData.append("search", type.id);
    if (minPricingArray.length > 0) {
      _formData.append("start_price", sortMinValuee[0]);
      _formData.append("end_price", sparseArray);
    }
    _formData.append("page", page);

    apiCallingSearch(_formData)
  }

  const apiCallingSearch = (_formData, tempArray = brandFilterArray) => {
    setLoading(true)
    attr.length > 0 &&
      attr.forEach((a, i) => _formData.append(`attributes[${i}]`, a));

    if (brandFilterArray.length > 0) {
      tempArray.map((item, index) => {
        _formData.append(`brand[${index}]`, item);
      });
    }

    ApiService.searchProduct(_formData).then((res) => {
      setImage(res.data.data);
      setLoading(false);
    });

  };
  const renderTopLevelList = (parent) => {
    return parent?.childrens?.map((ele) => {
      return (
        <React.Fragment>
          <div className="flex-1 w-full ">
            <ListItem
              button
              className={classes.menuItem}
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                  state: {
                    type: "Categories",
                    id: ele.id,
                  },
                });
              }}
            >
              <h5 className="font-sans px-2 dark:text-fontWhite text-muted hover:text-dark">
                {ele.name}
              </h5>
            </ListItem>
          </div>
        </React.Fragment>
      );
    });
  };
  const renderMiddleLevelList = () => {
    const allchildrens = fields.map((par) => par.childrens);
    const target = []
      .concat(...allchildrens)
      .find((child) => child.id == type.id);
    if (target?.childrens.length > 0) {
      return target?.childrens?.map((ele) => {
        return (
          <React.Fragment>
            <div className="flex-1 dark:text-fontWhite w-full px-4">
              <ListItem
                button
                className={classes.menuItem}
                onClick={() => {
                  history.push({
                    pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                    state: {
                      type: "Categories",
                      id: ele.id,
                    },
                  });
                }}
              >
                <h5 className="font-sans dark:text-fontWhite px-2 text-muted hover:text-dark">
                  {ele.name}
                </h5>
              </ListItem>
            </div>
          </React.Fragment>
        );
      });
    }
    if (!target?.childrens?.length) {
      const everyChildrenList = []
        .concat(...allchildrens)
        .map((child) => child.childrens);
      const everyChildren = [].concat(...everyChildrenList);
      const subtarget = everyChildren.find((sub) => sub?.id == type.id);
      let sublist = everyChildren.filter(
        (c) => c?.parent_id == subtarget?.parent_id
      );
      const parentsub = []
        .concat(...allchildrens)
        .find((e) => e?.id == subtarget?.parent_id);
      const topparentsub = fields.find((e) => e?.id == parentsub?.parent_id);
      return (
        parentsub &&
        topparentsub && (
          <>
            <div
              className="text-muted dark:text-fontWhite cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${topparentsub?.slug}/${topparentsub?.id}`,
                  state: {
                    type: "Categories",
                    id: topparentsub?.id,
                  },
                });
              }}
            >
              {topparentsub.name}
            </div>
            <div
              className="text-mute dark:text-fontWhite px-4 py-2 cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${parentsub?.slug}/${parentsub?.id}`,
                  state: {
                    type: "Categories",
                    id: parentsub?.id,
                  },
                });
              }}
            >
              {parentsub.name}
            </div>
            {sublist.map((ele) => {
              return (
                <React.Fragment>
                  <div className="flex-1 dark:text-fontWhite w-full px-4">
                    <ListItem
                      button
                      className={classes.menuItem}
                      onClick={() => {
                        history.push({
                          pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                          state: {
                            type: "Categories",
                            id: ele.id,
                          },
                        });
                      }}
                    >
                      <h5 className="font-sans dark:text-fontWhite px-2 text-muted hover:text-dark">
                        {ele.name}
                      </h5>
                    </ListItem>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )
      );
    }
  };

  // const renderOptions = () => {
  //   let parent;
  //   parent = fields.find((par) => par.id == type.id);
  //   if (parent) return renderTopLevelList(parent);
  //   else return renderMiddleLevelList();
  // };

  // function getParentName() {
  //   let p;
  //   p = fields.find((par) => par.id == type.id);
  //   if (p?.name)
  //     return (
  //       <div
  //         className="text-muted dark:text-fontWhite cursor-pointer"
  //         onClick={() => {
  //           history.push({
  //             pathname: `/shop/category-product/${p.slug}/${p.id}`,
  //             state: {
  //               id: p.id,
  //               type: "Categories",
  //             },
  //           });
  //         }}
  //       >
  //         {p.name}
  //       </div>
  //     );
  //   else {
  //     const allchildrens = fields.map((par) => par.childrens);
  //     let parentName;
  //     const target = []
  //       .concat(...allchildrens)
  //       .find((child) => child.id == type.id); //flatten array
  //     if (target?.name) {
  //       let p;
  //       p = fields.find((par) => par.id === target.parent_id);

  //       return (
  //         <div className="text-muted dark:text-fontWhite d-flex flex-column">
  //           <div
  //             className="cursor-pointer"
  //             onClick={() => {
  //               history.push({
  //                 pathname: `/shop/category-product/${p.slug}/${p.id}`,
  //                 state: {
  //                   id: p.id,
  //                   type: "Categories",
  //                 },
  //               });
  //             }}
  //           >
  //             {p.name || null}
  //           </div>
  //           <div
  //             className="px-4 py-2 dark:text-fontWhite cursor-pointer"
  //             onClick={() => {
  //               history.push({
  //                 pathname: `/shop/category-product/${target.slug}/${target.id}`,
  //                 state: {
  //                   id: target.id,
  //                   type: "Categories",
  //                 },
  //               });
  //             }}
  //           >
  //             {target.name}
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return null;
  //     }
  //   }
  // }

  const toggleDrawer = (opentoggle) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(opentoggle);
  };

  const [subCategory, setSubCategory] = useState(false);
  const [childCategory, setChildCategory] = useState();
  const [childId, setChildId] = useState();
  const openSubCategories = (id) => {
    setParentFilterId(id ? id : 0)
    const child = fields.filter((item) => { return item.id === id });
    setChildId(child[0].id);
    setChildCategory(child[0]);
    setSubCategory(true);
  }

  const [singleChildData, setSingleChildData] = useState();
  const [singleChildId, setSingleChildId] = useState();
  const [subChild, setSubChild] = useState(false);
  const openChilds = (id) => {
    const childs = childCategory.childrens.map((item) => { return item });
    const subChilds = childs.filter((e) => { return (e.id === id && e.childrens) });
    const singleChild = subChilds.map((item) => { return item });
    setSingleChildData(singleChild);
    setSingleChildId(singleChild[0].id);
    setSubChild(true);
  }

  const [lastChildId, setLastChildId] = useState();
  const [lastChildMenu, setLastChildMenu] = useState();
  const handleOpenChilds = () => {
    setLastChildId(singleChildData[0].id);
    setLastChildMenu(true);
  }

  const renderMenu = (
    <React.Fragment>

      <Helmet>
        <title>{`FashionLeo - ${type.slug === undefined ? type.categoryId : type.slug}`}</title>
        <meta name="description" content="Categories" />
        <meta property="og:site_name" content="FashionLeo" />
        <meta property="og:title" content={type.slug === undefined ? type.categoryId : type.slug} />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content={type.shop} />
        {/* <meta property="og:image" content={data?.ImageSrc}></meta> */}
      </Helmet>
      {fields.length > 0 && (
        <div className="py-2 mb-4 bg-whiteBackground dark:bg-lightDarkBackground">
          <Accordion
            className="border-0  bg-whiteBackground dark:bg-lightDarkBackground shadow-none"
            defaultExpanded expanded={expanded === 'panel1'}
            onChange={handleChangeAccordian('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase dark:text-fontWhite">
                {" "}
                {t("shop.categoryHeading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="">

              <Box
                sx={{ width: 280, height: '100%' }}
                role="presentation"
              >
                <List>
                  {fields.map((item, index) => {
                    return (
                      <>
                        <div key={index} className="flex justify-between items-center">
                          <Box
                            sx={{ width: 280, height: '100%' }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                          >
                            <ListItem
                              className=" dark:text-fontWhite text-muted hover:bg-white hover:text-primary text-base cursor-pointer"
                              onClick={() => {
                                openSubCategories(item.id);
                                history.push({
                                  pathname: `/shop/category-product/${item.name}/${item.id}`,
                                  state: {
                                    type: "Categories",
                                    // sub_id: item.id,
                                    id: item.id,
                                    name: item.name,
                                  },
                                })
                                setPage(1)
                              }
                              }
                            >
                              {item.name}
                            </ListItem>
                          </Box>
                          {item.childrens.length !== 0 && (subCategory && childId === item.id ? <span className="text-xl cursor-pointer w-6"
                            onClick={() => setSubCategory(false)}>&#8722;</span> :
                            <span className="text-xl cursor-pointer w-6"
                              onClick={() => {
                                openSubCategories(item.id)
                              }}>&#43;</span>)}
                        </div>
                        {subCategory && childId === item.id && <div className="grid-flow-row w-full">
                          {item.childrens.map((item, index) => {
                            return (
                              <>
                                <div key={index} className="flex px-4">
                                  <Box
                                    className=""
                                    sx={{ width: 280, height: '100%' }}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                  >
                                    <p className="p-1 flex justify-between items-center cursor-pointer dark:text-fontWhite"
                                      onClick={() => {
                                        toggleDrawer(false);
                                        history.push({
                                          pathname: `/shop/category-product/${item.name}/${item.id}`,
                                          state: {
                                            type: "Categories",
                                            id: parentFilterId,
                                            sub_id: item.id,
                                            name: item.name,
                                          },
                                        })
                                        setPage(1)
                                      }
                                      }>{item.name}</p>
                                  </Box>
                                  {item.childrens.length !== 0 && (subChild && singleChildId === item.id ?
                                    <span className="text-xl w-6 cursor-pointer"
                                      onClick={() => setSubChild(false)}>&#8722;</span> :
                                    <span className="text-xl w-6 cursor-pointer"
                                      onClick={() => openChilds(item.id)}>&#43;</span>)}
                                </div>
                                {subChild && singleChildId === item.id && <div className="grid-flow-col w-full py-2 px-7">
                                  {item.childrens.map((child, index) => {
                                    return (
                                      <>
                                        <div className="flex" key={index}>
                                          <Box
                                            className=""
                                            sx={{ width: 280, height: '100%' }}
                                            role="presentation"
                                            onClick={toggleDrawer(false)}
                                            onKeyDown={toggleDrawer(false)}
                                          >
                                            <p className="p-1 cursor-pointer dark:text-fontWhite"
                                              onClick={() => {
                                                history.push({
                                                  pathname: `/shop/category-product/${child.slug}/${child.id}`,
                                                  state: {
                                                    type: "Categories",
                                                    sub_id: child.id,
                                                    id: parentFilterId,
                                                    name: child.name,
                                                  },
                                                })
                                                setPage(1)
                                              }
                                              }>{child.name}</p>
                                          </Box>
                                          {child.childrens.length !== 0 && (lastChildMenu && lastChildId === item.id ?
                                            <span className="text-xl w-6 cursor-pointer"
                                              onClick={() => setLastChildMenu(false)}>&#8722;</span> :
                                            <span className="text-xl w-6 cursor-pointer"
                                              onClick={() => handleOpenChilds(item.id)}>&#43;</span>)}
                                        </div>
                                        {lastChildMenu && lastChildId === item.id && <div className="grid-flow-col w-full py-2 px-7">
                                          {child.childrens.map((res, index) => {
                                            return (
                                              <p key={index} className="p-1 cursor-pointer dark:text-fontWhite"
                                                onClick={() => {
                                                  history.push({
                                                    pathname: `/shop/category-product/${res.slug}/${res.id}`,
                                                    state: {
                                                      type: "Categories",
                                                      id: parentFilterId,
                                                      sub_id: res.id,
                                                      name: res.name,
                                                    },
                                                  })
                                                  setPage(1)
                                                }
                                                }>{res.name}</p>
                                            )
                                          })}
                                        </div>}
                                      </>
                                    )
                                  })}
                                </div>}
                              </>
                            )
                          })}
                        </div>}
                      </>
                    );
                  })}
                </List>
                {/* <Divider />
      {fields.length > 0 && (
        <div className="grid-flow-row w-60">{renderMenu}</div>
      )} */}
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <div className="">
        <Accordion
          className="border-0 bg-whiteBackground dark:bg-lightDarkBackground shadow-none"
          expanded={expanded === 'panel2'}
          onChange={handleChangeAccordian('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uppercase dark:text-fontWhite">
              {" "}
              {t("shop.price")}{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="w-full  -mt-8">
            <div className="grid w-full grid-flow-row px-6 ">

              {minValue.slice(0, 5).map((ele, key) => (
                <FormGroup>
                  <FormControlLabel
                    className="text-primary dark:text-fontWhite"
                    control={<Checkbox onChange={() => {

                      type.type === 'search' ? applyFilterSearch(ele.minPrice, ele.maxPrice) : applyFilter(ele.minPrice, ele.maxPrice)

                      setMinMaxPriceFilter({
                        min: ele.minPrice,
                        max: ele.maxPrice,
                      })
                    }} />}
                    label={`${ele.minPrice} - ${ele.maxPrice}`}
                  />
                </FormGroup>
              ))}


            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        {size.length > 0 && (
          <Accordion
            className="border-0 shadow-none bg-whiteBackground dark:bg-lightDarkBackground"
            expanded={expanded === 'panel3'}
            onChange={handleChangeAccordian('panel3')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase dark:text-fontWhite">
                {" "}
                {t("shop.size")}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="grid w-full grid-cols-3  sm:grid-cols-4 overflow-wrap gap-5 mx-3 text-center cursor-pointer">
                {size.map((ele, key) => (
                  <div
                    onClick={() => {
                      let reqarr;
                      const tempArray = [...attr];
                      if (tempArray.includes(ele)) {
                        reqarr = tempArray.filter((e) => e != ele);
                        setAttr(reqarr);
                        setActiveArr(reqarr);
                      } else {
                        setAttr([...attr, ele]);
                        setActiveArr([...attr, ele]);
                      }
                    }}
                    className={`relative block float-left h-6 mb-1 mr-2 text-xs leading-5 text-center text-black bg-gray-200 dark:bg-whiteBackground 
                          rounded-b-md border-b-2 border-secondary cursor-pointer w-7 opacity-${activeArr.includes(ele) ? "100" : "70"
                      } hover:opacity-90`}
                  >
                    {ele}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>

      <div>
        {color.length > 0 && (
          <Accordion
            className="border-0 shadow-none dark:bg-lightDarkBackground bg-whiteBackground"
            expanded={expanded === 'panel4'}
            onChange={handleChangeAccordian('panel4')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase dark:text-fontWhite">
                {" "}
                {t("shop.color")}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
                <div className="overflow-wrap px-1 py-1 thinScroll grid w-full grid-cols-3 sm:grid-cols-4  gap-4 mx-0 text-center">

                  {color.map((ele, key) => (
                    !ele.includes(' ') && !ele.includes('.') &&
                    <div className='relative blockrounded-lg float-left h-7 mb-1 mx-0 text-xs leading-5 text-center  mix-blend-color-overlay rounded-lg  cursor-pointer w-9 hover:opacity-90' style={{
                      border: ` ${activeArr.includes(ele) ? `2px solid ${ele}` : ''}`
                    }}>
                      <div
                        key={key}
                        onClick={() => {
                          let reqarr;
                          const tempArray = [...attr];
                          if (tempArray.includes(ele)) {
                            reqarr = tempArray.filter((e) => e != ele);
                            setAttr(reqarr);
                            setActiveArr(reqarr);
                          } else {
                            setAttr([...attr, ele]);
                            setActiveArr([...attr, ele]);
                          }
                        }}
                        className={`relative block dark:text-primary float-left h-6 mb-1 mx-0 text-xs leading-5 text-center text-gray-400 mix-blend-color-overlay   cursor-pointer w-8 hover:opacity-90`}
                        style={{
                          backgroundColor: ele,
                          border: ` ${activeArr.includes(ele) ? `3px solid ${ele}` : ''}`
                        }}
                      >
                        {/* {ele} */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {storage.length > 0 && (
          <Accordion className="border-0 shadow-none" expanded={expanded === 'panel5'}
            onChange={handleChangeAccordian('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase">Storage</Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
                <div className="overflow-scroll px-1 py-1 thinScroll grid w-full grid-cols-5 gap-5 mx-0 text-center cursor-pointer lg:grid-cols-8">
                  {storage.map((ele, key) => (
                    <div
                      className="relative block rounded-lg float-left h-6 mb-1 mx-0 text-xs leading-5 text-center text-black border cursor-pointer w-7 "
                      style={{
                        backgroundColor: ele,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {ram.length > 0 && (
          <Accordion className="border-0 shadow-none" expanded={expanded === 'panel6'}
            onChange={handleChangeAccordian('panel6')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase">Ram</Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
                <div className="overflow-scroll px-1 py-1 thinScroll grid w-full grid-cols-5 gap-5 mx-0 text-center cursor-pointer lg:grid-cols-8">
                  {ram.map((ele, key) => (
                    <div
                      className="relative block rounded-lg float-left h-6 mb-1 mx-0 text-xs leading-5 text-center text-black border cursor-pointer w-7 "
                      style={{
                        backgroundColor: ele,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {mobileDisplay.length > 0 && (
          <Accordion className="border-0 shadow-none" expanded={expanded === 'panel7'}
            onChange={handleChangeAccordian('panel7')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase">
                {" "}
                {t("shop.mobileDisplay")}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
                <div className="overflow-scroll px-1 py-1 thinScroll grid w-full grid-cols-1 gap-5 mx-0 text-left cursor-pointer lg:grid-cols-8">
                  {color.map((ele, key) => (
                    <div
                      className="relative block rounded-lg float-left h-6 mb-1 mx-0 text-xs leading-5 text-left text-black border cursor-pointer w-100 "
                      style={{
                        backgroundColor: ele,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {length.length > 0 && (
          <Accordion className="border-0 shadow-none" expanded={expanded === 'panel8'}
            onChange={handleChangeAccordian('panel8')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase">Length</Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
                <div className="overflow-scroll px-1 py-1 thinScroll grid w-full grid-cols-4 gap-5 mx-0 text-center cursor-pointer lg:grid-cols-8">
                  {length.map((ele, key) => (
                    <div
                      className="relative block rounded-lg float-left h-6 mb-1 mx-0 text-xs leading-5 text-center text-black border cursor-pointer w-7 "
                      style={{
                        backgroundColor: ele,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {brand.length > 0 && type.type !== "brand" && (
          <Accordion
            className="border-0 shadow-none bg-whiteBackground dark:bg-lightDarkBackground"
            expanded={expanded === 'panel9'}
            onChange={handleChangeAccordian('panel9')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="uppercase dark:text-fontWhite">
                {t("shop.brand")}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-full ">
              <div className="block w-full  gap-5 mx-3 text-center cursor-pointer lg:grid-cols-6">
                {brand.slice(0, 5).map((ele, key) => (
                  <FormGroup>
                    <FormControlLabel
                      className="text-primary dark:text-fontWhite"
                      control={<Checkbox onChange={() => {
                        if (type.type == 'search') {
                          addFilterBrandSearch(ele)
                        } else {
                          addFilterItem(ele)
                        }
                      }} />}
                      label={ele}
                    />
                  </FormGroup>
                ))}

                {anchorEl == false && brand.length > 5 && (
                  <div
                    className="text-primary text-right text-sm"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => setAnchorEl(true)}
                  >
                    {brand.length} {t("shop.more")}
                  </div>
                )}

                {anchorEl && brand.slice(5).map((ele, key) => (
                  <FormGroup>
                    <FormControlLabel
                      className="text-primary dark:text-fontWhite"
                      control={<Checkbox onChange={() => {
                        if (type.type == 'search') {
                          addFilterBrandSearch(ele)
                        } else {
                          addFilterItem(ele)
                        }
                      }} />}
                      label={ele}
                    />
                  </FormGroup>
                ))}

                {/* <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  className="mt-10 w-100"
                  open={open}
                  PaperProps={{
                    style: {
                      width: 850,
                    },
                  }}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <div className="grid grid-cols-5 px-8">
                    {brand.map((item, key) => (
                      <FormGroup>
                        <FormControlLabel control={<Checkbox />} label={item} />
                      </FormGroup>
                    ))}
                  </div> */}
                {/* <div className="text-right ">
                    <Button
                      variant="contained"
                      className="py-2 px-4 bg-fontDark text-fontWhite"
                    // onClick={() => handleWarningModalClose()}
                    >
                      {t("shop.clear")}
                    </Button>
                    <Button
                      variant="contained"
                      className="py-2 px-4 mx-8 bg-fontDark text-fontWhite"
                    // onClick={() => handleWarningModalClose()}
                    >
                      {t("applyAllFilters")}
                    </Button>
                  </div> */}
                {/* </Menu> */}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <Accordion
        className="border-0 shadow-none dark:bg-lightDarkBackground"
        expanded={expanded === 'panel10'}
        onChange={handleChangeAccordian('panel10')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="uppercase dark:text-fontWhite">
            {" "}
            {t("shop.discount")}{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-full ">
          <div className="w-100 px-2 py-1 border-1 rounded-xl border-gray">
            <MenuList>
              {discountPriceArray?.map((ele, key) => (
                <MenuItem
                  onClick={() => {
                    if (type.type == "search") {
                      setLoading(true)
                      const form = new FormData();
                      form.append("search", type.id);
                      form.append("end_discount", 100);
                      form.append("start_discount", Number(ele?.start_price));
                      form.append("page", page);

                      ApiService.searchProduct(form).then((res) => {
                        setImage(res.data.data);
                        setLoading(false);
                      });
                    } else {
                      urlString = `start_discount=${ele?.start_price}-end_discount=100`;
                      history.push({
                        pathname: `/shop/offer/${ele?.heading}-percent-and-more/${urlString}`,
                        state: {
                          id: parentFilterId === 0 ? state.state.id : parentFilterId,
                          sub_id: state?.state?.sub_id
                          // type: { type: "offer", categoryId: parentFilterId === 0 ? state.state.id : parentFilterId},
                        },
                      });
                      setDiscValues([ele.start_price, 100]);
                      setSelectedDisc(key);
                    }
                  }}
                >
                  {selectedDisc == key && (
                    <CircleIcon className="text-xs text-button -mr-2.5" />
                  )}
                  <div className="mx-0 w-80 dark:text-fontWhite ease-out duration-600 rounded-xl px-4">
                    {ele.heading}% {t("shop.andMore")}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </div>
        </AccordionDetails>
      </Accordion>

      <div className="flex dark:text-fontWhite flex-col items-center mx-4 w-full gap-1 mt-3 ">
        <Button
          size="small"
          className="justify-end w-3/5 bg-primary my-2 mx-6 py-2 text-sm font-bold border-1 border-dark hover:opacity-80 hover:shadow-2xl rounded text-white uppercase"
          onClick={() => {
            type.type === 'search' ? applyFilterSearch() : applyFilter()
          }}
        >
          {t("shop.filter")}
        </Button>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="text-center  uppercase bg-gray-200 dark:bg-lightDarkBackground  ">
        <Typography
          variant={"h5"}
          className="py-5 text-center dark:text-fontWhite uppercase  border-b-2"
        >
          {typeof location.state?.productName !== "undefined" &&
            location.state?.productName
            ? location.state?.productName
            : location.state?.type?.type}

          <div className="flex justify-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="flex text-center uppercase bg-gray-200 dark:bg-lightDarkBackground  border-b-2"
            >
              <span
                underline="hover"
                className=" text-center dark:text-fontWhite cursor-pointer text-xs"
                color="inherit"
                onClick={() => {
                  history.push("/home");
                }}
              >
                {t("shop.home")}
              </span>
              <span
                underline="hover"
                className={`cursor-pointer dark:text-fontWhite text-xs text-left ${typeof location.state?.productName !== "undefined" &&
                  !location.state?.productName &&
                  "text-black"
                  }`}
                // color="inherit"
                onClick={() => {
                  // history.goBack();
                }}
              >
                {!location.state?.name ? location.state?.type : location.state?.name}
              </span>
              {typeof location.state?.productName !== "undefined" &&
                location.state?.productName && (
                  <span
                    underline="hover"
                    className=" text-black text-center cursor-pointer text-xs"
                    color="inherit"
                    onClick={() => {
                      history.push("/home");
                    }}
                  >
                    {location.state?.productName}
                  </span>
                )}
            </Breadcrumbs>
          </div>
        </Typography>
        <div
          role="presentation"
          className=" px-14 text-center uppercase bg-gray-200"
        // onClick={handleClick}
        ></div>
      </div>
      <div></div>
      <div className="flex  bg-gray-100">
        <div className="absolute">
          <span className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-40"></span>
        </div>

        {/* <div className="w-full my-2 mx-4 bg-gray-100 sm:mx-10 lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center w-full justify-center p-2 text-gray-400 rounded-md hover:text-black focus:outline-none "
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            {!isOpen ? (
              <Button className="w-full p-2 text-white hover:bg-secondary rounded-md uppercase bg-gray-700">
                <OpenInNewIcon className="mr-2" /> {t("shop.productFilters")}
              </Button>
            ) : (
              <Button className="w-full p-2 text-white uppercase rounded-md bg-gray-700">
                {t("shop.productFilters")}
              </Button>
            )}
          </button>
        </div> */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={isOpen}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <CloseIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <div className="grid-flow-row w-80 sm:w-96  overflow-x-hidden">
            {renderMenu}
          </div>
        </Drawer>
      </div>
      <div className=" lg:block  bg-background dark:bg-darkBackground  relative ">
        <div className="grid mx-2 sm:mx-8 lg:grid-cols-4 grid-cols-1">
          {type.type == "featured-product" ? (
            <div>{t("shop.noFilters")}</div>
          ) : (
            <div className="">
              <div className="hidden lg:grid-flow-row gap-10 mt-2 lg:block">
                {renderMenu}
              </div>
            </div>
          )}
          <div className="grid-cols-1 lg:col-span-3">
            <>
              <div className="mx-auto mt-2 sm:right-0 sm:top-0 w-48 sm:absolute hidden lg:block gap-x-5 sm:m-8 md:mr-16">
                <SortForm setValue={setSortValue} sortValue={sortValue} />
              </div>
              <div className="mx-auto mt-2 sm:right-0 sm:top-0 w-full lg:absolute lg:hidden grid grid-cols-2 gap-x-5 lg:m-8 md:mr-16">
                <SortForm setValue={setSortValue} sortValue={sortValue} />
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="inline-flex items-center w-full justify-center p-2 text-fontWhite bg-gray-700 rounded-md hover:text-black focus:outline-none "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  {t("shop.productFilters")}
                </button>
              </div>
              <div className="lg:py-6 my-4 text-sm font-normal text-left mx-5">
                {loading ? (
                  <Skeleton height={30} width={180} className="" />
                ) : image?.data?.length < 1 ? (
                  <p className="dark:text-fontWhite">
                    {" "}
                    {t("shop.noProducts")}{" "}
                  </p>
                ) : (
                  <span className="text-nowrap  dark:text-fontWhite">
                    {t("shop.showingProducts")} {image?.from} - {image?.to} out
                    {t("shop.of")} {image?.total} {t("shop.products")}
                  </span>
                )}
              </div>
            </>
            <div id="grid-div" className="grid relative sm:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 grid-cols-2 lg:ml-6 lg:mr-6 ml-1 md:ml-6 md:mr-6 gap-2 lg:gap-0 mt-8 ">
              {loading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((ele, key) => (
                  <Skeleton height={250} className="" key={key} />
                ))
              ) : image?.data?.length > 0 ? (
                <>
                  {image?.data.map((ele, key) => {
                    return (
                      <ProductCard
                        image={ele}
                        type={type.type}
                        rating={true}
                        shop={true}
                        addToWishlist={addToWishlist}
                      // wishArray={wishArray}
                      />
                    );
                  })}

                </>
              ) : (
                <>
                  {image?.data?.length == 0 && (
                    <div className="sm:top-50 hidden lg:flex left-0 flex-col text-muted font-bold w-100 h-100 absolute d-flex text-xl sm:text-3xl items-center">
                      <img
                        className="opacity-70 m-4 w-20 h-20"
                        src={cartImage}
                        alt="image"
                      />
                      {t("shop.noProductFound")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="lg:py-2 py-12 my-8 text-center w-full  flex  justify-center">
          <Pagination
            count={image?.last_page}
            classes={{ ul: classes.ul }}
            defaultPage={page}
            size="large"
            page={page}
            onChange={(event, value) => {
              setPage(value);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
      {/* <ChatBot /> */}
    </React.Fragment>
  );
}

