import {
  Card,
  DialogContent,
  Divider,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
import PaymentIcon from '@material-ui/icons/Payment';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Avatar, ListItemText, Rating } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import Echo from "laravel-echo";
import moment from "moment";
import Pusher from "pusher-js";
import { default as React, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { Button, List } from "semantic-ui-react";
import { default as appConfig, default as AppConfig } from "../../appConfig";
import AlertComponent from "../../core-components/AlertComponent";
import ProductCard from "../../core-components/ProductCard";
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import { default as ApiService } from "../../services/apiService";
import Utils from "../../services/Utils";
import ChatPopup from "./ChatPopup";
import Dialog from "@material-ui/core/Dialog";
import HtmlParser from "react-html-parser";
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: "cover",
  },
  button: {
    // // background:
    // //   "linear-gradient(to bottom,rgba(255,255,255,0) 0%, #597593 100%)",
    margin: 10,
    // background: "#597593",
    background: "#009acc",

    textAlign: "center",
    textTransform: "uppercase",
    transition: "0.5s",
    color: AppConfig.color.button,
    borderRadius: "10px",
    borderColor: "#009acc",
    display: "block",
    // boxShadow: "0 0 5px 0 #597593 inset",
    // "&:hover": {
    //   background: "#597593",
    // },
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function calculateDiscount(price, offerPrice) {
  return (((price - offerPrice) * 100) / price).toFixed(0);
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 770 },
    items: 4,
  },
  maxitablet: {
    breakpoint: { max: 768, min: 720 },
    items: 4,
  },
  minitablet: {
    breakpoint: { max: 720, min: 640 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 550, min: 0 },
    items: 2,
  },
};

export default function ProductDetails(props) {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [image, setImage] = useState();
  const [modalImage, setModalImage] = useState([]);

  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );

  const getLink = () => {
    // var linkData = {
    //   campaign: 'product sale',
    //   channel: 'facebook',
    //   feature: 'dashboard',
    //   stage: 'new user',
    //   tags: ['tag1', 'tag2', 'tag3'],
    //   alias: '',
    //   data: {
    //     'custom_bool': true,
    //     'custom_int': Date.now(),
    //     'custom_string': 'hello',
    //     '$og_title': data?.product_name,
    //     '$og_description': data?.long_desc,
    //     '$og_image_url': data?.ImageSrc
    //   }
    // };
    // branch.link(linkData, function (err, link) {
    //   console.log(link);
    //   setLinkState(link)
    // });
  };

  const [products, setProducts] = useState();
  const [data, setData] = useState();

  const params = useParams();
  const session_id = Utils.generateGUID();
  const newSessionId = sessionStorage.getItem("session_id");
  const user_id = sessionStorage.getItem("user_id");

  const [loading, setLoading] = useState(true);
  const [optionKey, setOptionKey] = useState([]);
  const [firstIndexArray, setFirstIndexArray] = useState([]);

  const [alertContent, setAlertContent] = useState("");
  useEffect(() => {
    const ele = document.querySelectorAll("p");
    const arr = [...ele];

    arr.forEach((ele) => {
      ele.classList.add("dark:text-fontWhite");
    });

    const _form = new FormData();

    _form.append(
      "session_id",
      user_id !== "null" && user_id !== null ? user_id : newSessionId
    );

    setDeviceType(window.innerWidth > appConfig.breakpoints.mobile ? "L" : "S");
    window.addEventListener("resize", () => {
      setDeviceType(
        window.innerWidth > appConfig.breakpoints.mobile ? "L" : "S"
      );
    });
    return () => {
      window.removeEventListener("resize", () => {
        setDeviceType(
          window.innerWidth > appConfig.breakpoints.mobile ? "L" : "S"
        );
      });
    };
  }, [params.productId]);
  const handleAlertModalClose = () => {
    setOpenAlertModal(false);
  };

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [isWishlist, setIsWishList] = useState(false);
  const [price, setPrice] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [currency_type, setCurrencyType] = useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalSizeChart, setOpenModalSizeChart] = React.useState(false);
  const [productCmsData, setProductCmsData] = React.useState();
  const [pinCodeValue, setPinCodeValue] = React.useState();
  const [validationMsg, setValidationMsg] = React.useState();
  const [pincodeStatus, setPincodeStatus] = React.useState();
  const [selectedPrice, setSelectedPrice] = useState()
  const [optionImage, setOptionImage] = useState()
  const [sizeChartTab, setSizeChartTab] = useState('guide')
  const [configPrice, setConfigPrice] = useState()

  const { innerWidth } = window;
  // getting product data  by id using getProduct by id api
  const shouldLog = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (shouldLog.current) {
    //   console.log("if---");

    //   shouldLog.current = false;
    //   window.scrollTo(0, 0);
    ApiService.getProductById(params.productId).then((res) => {
      if (res.code === 200) {
        setBreadcrumb([
          `${t('header.home')} | ${t('mobileFooter.category')}`,
          res.data.data.product.parent_category,
          res.data.data.product.get_sub_category[0]?.getproductcat,
          res.data.data.product.product_name,
        ]);
        setIsWishList(res.data.data.product.IsWishlist);
        setProducts(res.data.data.products);
        setData(res.data.data.product);
        setCurrencyType(res.data.data.product.currency_data.symbol);
        // if product option key is empty array means it has no attribute so we will set product actual price and offerprice in response as products price and offer price
        setOptionKey(Object.entries(res.data.data.product.OptionKey));
        setPrice(res.data.data.product.price);
        if (res.data.data.product.offer_price !== null) {
          setOfferPrice(res.data.data.product.offer_price);
        } else {
          setOfferPrice(res.data.data.product.price);
        }

        if (Array.isArray(res.data.data.product)) {
          if (res.data.data.product.offer_price !== null) {
            setOfferPrice(res.data.data.product.offer_price);
          } else {
            setOfferPrice(res.data.data.product.price);
          }
          // setLoading(false);
        }
        // but if option key is a object it means product has attirbute so we will convert option key into a array to map it
        else {

          if (res.data.data.product.OptionKey.length === 0) {
            setSelectedPrice(res.data.data.product.offer_price)
            setLoading(false);
          }
          else {
            Object.entries(res.data.data.product.OptionKey).map((optionkey) => {
              firstIndexArray.push(optionkey[1][0]);
            });
            let initialPrice = [];
            let initialOfferPrice = [];
            // Below we are setting the price and offer Price of the product
            // and explaination for this has been done in useEffect of attributeModal Component
            firstIndexArray.map((firstIndexArray) => {
              initialPrice.push(firstIndexArray.price ? firstIndexArray.price : '0', firstIndexArray?.commission);
              initialOfferPrice.push(firstIndexArray.price ? firstIndexArray.price : '0', firstIndexArray?.commission);
            });

            var result = initialPrice.map(function (x) {
              return parseInt(x, 10);
            })

            const totalScores = result.reduce(
              (previousScore, currentScore, index) => previousScore + currentScore,
              0);

            let selectPrice = totalScores + Number(res.data.data.product.offer_price)
            setSelectedPrice(selectPrice)

            const maximumPrice = initialPrice.reduce(function (a, b) {
              return Math.max(a, b);
            });
            const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
              return Math.max(a, b);
            });

            setPrice(maximumPrice);

            if (
              maximumOfferPrice == 0 ||
              maximumOfferPrice == null ||
              maximumOfferPrice == "null"
            ) {
              setOfferPrice(maximumPrice);
            } else {
              setOfferPrice(maximumOfferPrice);
            }
            setLoading(false);
            getLink();
          }
        }
      }
    });
    // }
  }, [params.productId, isWishlist]);

  useEffect(() => {
    setTimeout(() => {
      const p = document.querySelectorAll('p')
      const pArray = [...p]

      pArray.forEach(ele => {
        ele.classList.add('dark:text-fontWhite')
      })
    }, 0);

  }, [params.productId, isWishlist, data])

  const PinCodeValidationApi = () => {
    let _form = new FormData();
    _form.append("pincode", pinCodeValue ? pinCodeValue : "")
    _form.append('vendor_id', data?.vendor?.id)

    ApiService.checkCourierService(_form).then((res) => {
      if (res.data.pricing !== null) {
        if (res.data.pricing.status === 200) {
          setValidationMsg(`delivery is available & estimated delivery date is ${res.data?.pricing?.data?.available_courier_companies[0]?.etd}`)
          setPincodeStatus(true)
        } else if (res.data.pricing.status == 404) {
          setValidationMsg("Delivery postcode not serviceable")
          setPincodeStatus(false)
        }
        else {

        }
      } else {
        setValidationMsg("Delivery postcode not serviceable")
        setPincodeStatus(false)
      }
    });
  }

  useEffect(() => {
    ApiService.getProductData().then((res) => {
      if (res.code === 200) {
        setProductCmsData(res.data.data.cms.content)
      } else if (res.code === 400) {
        setErrorMessage(res.errors);
      } else if (res.code === 422) {
        setAlertContent(res.errors);
        setOpenAlertModal(true);
      } else {
        setErrorMessage(res.errors);
      }
    }).catch((er) => {

    })
  }, [])

  const [errorMessage, setErrorMessage] = useState();
  const handleClickAddToCart = (type, data) => {
    // Retrieve cart details
    ApiService.getCartDetails(user_id)
      .then((res) => {
        // Check if the cart is empty
        if (res.data.length === 0) {
          addToCart(data);
        } else {
          // Cart is not empty, check flash sale condition
          const isFlashItemInCart = res?.data[0]?.get_product_data?.is_flash_item;
          const isFlashItemBeingAdded = data?.is_flash_item;

          if (isFlashItemInCart === isFlashItemBeingAdded) {
            // Flash sale condition matches, add the item
            addToCart(data);
          } else {
            // Flash sale condition does not match, log a message
            if (res?.data[0]?.get_product_data?.is_flash_item) {
              toast.error('Flash sale product added into cart, Please clear cart to add this item.')
            } else {
              toast.error('To add Flash sale product into cart you have to clear cart.')
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error retrieving cart details:", error);
      });
  };

  const addToCart = (data) => {
    // Proceed to add the item to the cart

    // Check if there's a new session ID and set it if necessary
    if (newSessionId === null) {
      sessionStorage.setItem(appConfig.localSessionIdKey, session_id);
    }

    // Determine the session ID to use
    const sendableSessionId =
      user_id !== "null" && user_id !== null
        ? user_id
        : newSessionId !== null && newSessionId !== "null "
          ? newSessionId
          : session_id;

    // Prepare form data for adding to the cart
    const _form = new FormData();
    _form.append("vendor_id", data.vendor.id);
    _form.append("product_id", data.id);
    _form.append("quantity", "1");
    _form.append("orignal_price", data.price);
    _form.append("offer_price", selectedPrice);
    _form.append("session_id", sendableSessionId);

    // Append product options to the form data
    firstIndexArray.length > 0 &&
      firstIndexArray.forEach((option, index) => {
        _form.append(`product_option[${index}][attr_type]`, option.attribute);
        _form.append(`product_option[${index}][value]`, option.values);
        _form.append(`product_option[${index}][price]`, option.price);
      });

    // Call the Add to Cart API
    ApiService.addToCart(_form)
      .then((res) => {
        if (res.code === 200) {
          // Dispatch action to update the user's cart count
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: res.data.data.cart_count,
          });
        } else if (res.code === 400) {
          setErrorMessage(res.errors);
        } else if (res.code === 422) {
          setAlertContent(res.errors);
          setOpenAlertModal(true);
        } else {
          setErrorMessage(res.errors);
        }
      })
      .catch((error) => {
        console.error("Error adding item to cart:", error);
      });
  };
  // function to add  product to wishlist
  const addtoWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          setFirstIndexArray([]);
          setIsWishList(res.data.data.isWishlist);
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      history.push({
        pathname: `/login`,
        state: {
          // sending navigationUrl so that we can come to same component after login we were before
          navigationUrl: window.location.href,
        },
      });
    }
  };

  const [productChatData, setProductChatData] = useState([]);
  const [productChatid, setProductChatid] = useState();

  const [openChatModel, setOpenChatModel] = useState(false);
  const messListener = (id, data) => {
    // below creating new Pusher object and passing our app configuration to it
    let PusherClient = new Pusher("87909e984abafa89a22c", {
      cluster: "ap2",
      wsHost: "fashion-leo.com.br",
      wsPort: "6001",
      wssHost: "fashion-leo.com.br",
      wssPort: "6001",
      enabledTransports: ["ws", "wss"],
      forceTLS: false,
    });
    // now we will use PusherClient to subscribe channel
    // now we will productChat channel to get message from this channel
    const channel = PusherClient.subscribe("ProductChat");

    // for listening to any channel we have to create Echo object

    const echo = new Echo({
      broadcaster: "pusher",
      client: PusherClient,
    });

    // now we will echo to listen to the channel
    const data1 = echo
      // we will listen to productChat event and pass it our product chat is which in the terms of pusher is a room id which is unique id
      .channel(`ProductChat.${id}`)
      .listen("ProductChatEvent", (ev) => {
        // compiler will comes inisde this function only when a message is recieved from other person
        setProductChatData((myProductChat) => [...myProductChat, ev.data]);
      });
    echo
      .channel(`ProductChat.${id}`)
      .listen("ProductChatEndEvent", async (e) => { });
  };

  // function to get product chat
  const getProductChat = () => {
    const form = new FormData();
    form.append("vendor_id", data.vendor.id);
    form.append("product_id", data.id);
    ApiService.CreateSessionIdForProduct(form).then((res) => {
      // we will setproductchatid to our state because we will have to use it while sending the message
      setProductChatid(res.data.data.id);
      var chatId = res.data.data.id;

      if (res.code == 201 || res.code === 200) {
        ApiService.getchatbyproduct(res.data.data.id).then((res) => {
          if (res.code === 200 || res.code == 201)
            setProductChatData(res.data.data);
          setOpenChatModel(true);
        });
      }
      messListener(chatId);
    });
  };
  // below function is for changing the product price when user clicks on different

  const SetproductPrice = (currentValue, index) => {
    var myvalue;
    const filteredArray = firstIndexArray.filter((firstIndexArray) => {
      return firstIndexArray.attribute !== currentValue.attribute;
    });

    filteredArray.push(currentValue);

    let initialPrice = [];
    let initialOfferPrice = [];
    filteredArray.map((filteredArray) => {
      initialPrice.push(filteredArray.price ? filteredArray.price : '0', filteredArray?.commission);
      initialOfferPrice.push(filteredArray.offer_price ? filteredArray.offer_price : '0', filteredArray?.commission);
    });

    //arrayString to arrayNumber
    var result = initialPrice.map(function (x) {
      return parseInt(x, 10);
    })

    const totalScores = result.reduce(
      (previousScore, currentScore, index) => previousScore + currentScore,
      0);

    let selectPrice = totalScores + Number(data.offer_price)
    setSelectedPrice(selectPrice)

    const maximumPrice = initialPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });
    const maximumOfferPrice = initialOfferPrice.reduce(function (a, b) {
      return Math.max(a, b);
    });

    setFirstIndexArray(filteredArray);
    setPrice(maximumPrice);

    if (
      maximumOfferPrice == 0 ||
      maximumOfferPrice == null ||
      maximumOfferPrice == "null"
    ) {
      setOfferPrice(maximumPrice);
    } else {
      setOfferPrice(maximumOfferPrice);
    }
  };

  useEffect(() => {
    ApiService.getConfig().then((res) => {
      setConfigPrice(res?.data?.Bank_offer_amount)
    })
  }, [])

  // var now = moment(new Date()); //todays date
  // var end = moment("2023-12-1"); // another date
  // var duration = moment.duration(now.diff(end));
  // var days = duration.asDays();
  // console.log(days)

  return (
    <React.Fragment>
      <Helmet>
        {/* <head> */}
        <title>{`Fashion Leo - ${data?.product_name}`} </title>
        <meta name="description" content={data?.long_desc} />
        <meta property="og:site_name" content="Nily" />
        <meta property="og:title" content={`Fashion Leo - ${data?.product_name}`} />
        {/* <meta property="og:type" content="Website"></meta> */}
        <meta property="og:url" content={`Fashion Leo - ${data?.slug}`} />
        <meta property="og:image" content={data?.ImageSrc} />
        {/* </head> */}
      </Helmet>

      {/* sizeChart */}

      <Dialog
        open={openModalSizeChart}
        onClose={() => setOpenModalSizeChart(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            width: "100%",
            minWidth: "250px !important",
            maxWidth: "250px !important",
            maxHeight: 500
          }
        }}
      >
        <DialogContent style={{ height: "50px !important" }}>
          <div className="text-end">
            <ClearIcon onClick={() => setOpenModalSizeChart(false)} className="cursor-pointer" />
          </div>

          <div className="flex justify-between mb-3 text-base cursor-pointer">
            <div className={sizeChartTab == 'guide' && 'border-b-2 border-primary text-primary'} onClick={() => setSizeChartTab('guide')} >Guide</div>
            <div className={sizeChartTab == 'measure' && 'border-b-2 border-primary text-primary'} onClick={() => setSizeChartTab('measure')}>How to measure</div>
          </div>

          <div className="flex justify-center items-center">
            <img src={sizeChartTab == 'guide' ? data?.size_chart?.chart : data?.size_chart?.measure}
              className="min-h-90 max-h-90 w-full" style={{ objectFit: "fill" }} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            width: "100%",
            minWidth: "200px !important",
            maxWidth: "200px !important",
            maxHeight: 330
          }
        }}
      >
        <DialogContent style={{ height: "50px !important" }}>
          <div className="text-end">
            <ClearIcon onClick={() => setOpenModal(false)} className="cursor-pointer" />
          </div>
          <Carousel
            ssr={false}
            arrows={deviceType == "L"}
            partialVisbile={false}
            swipeable={true}
            draggable={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1,
                },
                items: 1,
              },
            }}
            infinite={true}
            transitionDuration={300}
            className="carouselContainer rounded-xl"
          >
            {modalImage?.map((item) => (
              <div className="flex justify-center items-center">
                <img src={item?.url} className="min-h-56 max-h-56 w-full" style={{ objectFit: "contain" }} />
              </div>
            ))}
          </Carousel>

        </DialogContent>
      </Dialog>
      {openChatModel && (
        <ChatPopup
          productChatid={productChatid}
          product={data}
          setOpenChatModel={setOpenChatModel}
          getProductChat={getProductChat}
          productChatData={productChatData}
          offerprice={offerPrice}
          openChatModel={openChatModel}
        />
      )}

      <React.Fragment>
        <div className="w-100">
          <div role="presentation" className="px-6 my-2 ">
            {" "}
            {loading ? (
              <Skeleton height={20} width={450} />
            ) : (
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={"›"}
                spacing={1}
              >
                <Typography
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/home")}
                  className="cursor-pointer dark:text-fontWhite sm:dark:text-fontWhite text-sm "
                >
                  {breadcrumb[0]}
                </Typography>
                {breadcrumb[1] && (
                  <Typography
                    underline="hover"
                    color="inherit"
                    className="cursor-pointer dark:text-fontWhite text-xs sm:dark:text-fontWhite "
                    onClick={() => {
                      history.push({
                        pathname: `/shop/category-product/${breadcrumb[1]?.slug}/${breadcrumb[1]?.id}`,
                        state: {
                          id: breadcrumb[1]?.id,
                          type: "Categories",
                        },
                      });
                    }}
                  >
                    {breadcrumb[1]?.name}
                  </Typography>
                )}
                {breadcrumb[2] && (
                  <Typography
                    underline="hover"
                    color="inherit"
                    className="cursor-pointer dark:text-fontWhite  sm:dark:text-fontWhite text-sm"
                    onClick={() => {
                      history.push({
                        pathname: `/shop/category-product/${breadcrumb[2]?.slug}/${breadcrumb[2]?.id}`,
                        state: {
                          id: breadcrumb[2]?.id,
                          type: "Categories",
                        },
                      });
                    }}
                  >
                    {breadcrumb[2]?.name}
                  </Typography>
                )}
                <Typography
                  className=" text-primary cursor-pointer dark:text-fontWhite text-xs sm:dark:text-fontWhite"
                  onClick={() => {
                    history.push(`./${data?.id}`);
                  }}
                >
                  {breadcrumb[3]}
                </Typography>
              </Breadcrumbs>
            )}
          </div>
        </div>
        <div className="grid mx-0 grid-cols-1 lg:grid-cols-2 bg-background dark:bg-darkBackground focus:sr-only h-100">
          <div className={`block lg:sticky md:top-32 h-fit md:mt-4`}>
            <div className="flex lg:flex-col-reverse w-full flex-col-reverse px-0 lg:px-6">
              {loading ?
                (
                  <div className='flex gap-2'>
                    {[1, 2, 3].map((ele, key) => (<Skeleton width={100} height={150} key={key} />))}
                  </div>
                ) : (
                  <div className="lg:flex lg:w-[20%] lg:h-[20%] demoImages thinScroll d-flex mt-2 mb-0 sm:mb-4 lg:mb-12 px-4 overflow-x-scroll overflow-y-auto lg:overflow-y-hidden demoImages">
                    {/* {[data && data, ...data?.product_images].map((ele, key) => ( */}
                    {data?.product_images.map((ele, key) => (
                      <img
                        key={key}
                        className="lg:w-[20%] lg:object-contain rounded-xl mr-2 mb-2 border-gray-200 border-2
                       imageHover hover:shadow-lg cursor-pointer"
                        src={ele.ImageSrc}
                        alt="logo"
                        onMouseEnter={(e) => {
                          setImage(e.currentTarget.src);
                        }}
                        onMouseLeave={(e) => {
                          setImage(null);
                        }}
                        style={{ width: "20%", maxHeight: '125px' }}
                      />
                    ))}
                  </div>
                )}

              <div className="mb-2 w-screen sm:w-auto sm:mb-4 lg:mb-10 mx-0 lg:ml-4 lg:w-5/6 relative bg-cover bg-no-repeat">
                <div className="w-full">
                  {loading ? (
                    <Skeleton height={450} />
                  ) : (
                    <div className={`container md:mt-10`} style={{ width: innerWidth === 820 ? "720px" : (innerWidth < 900 && innerWidth > 800) ? innerWidth : '100%', padding: (innerWidth < 900) && '0px' }}>
                      <Carousel
                        ssr={false}
                        arrows={deviceType == "L"}
                        partialVisbile={false}
                        swipeable={true}
                        draggable={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1,
                            },
                            items: 1,
                          },
                        }}
                        infinite={true}
                        autoPlay={deviceType == "L"}
                        autoPlaySpeed={2500}
                        transitionDuration={300}
                        className="carouselContainer rounded-xl"
                      >
                        {[data, ...data.product_images].map((i, key) => {

                          return (
                            <img
                              className="w-full h-500 sm:px-32 lg:h-96 lg:px-0 object-contain px-4 shadow-xl rounded-xl"
                              src={optionImage ? optionImage : image || i.ImageSrc}
                              alt="logo"
                            />
                          );
                        })}
                      </Carousel>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
          <div className="grid mx-0 grid-flow-row mb-8 lg:ml-3 w-screen sm:w-auto lg:mt-6 mt-0 h-full lg:mr-10  ">
            <div>
              <Card className="py-4 pb-6 sm:pb-4 flex bg-background dark:bg-lightDarkBackground flex-col gap-2 mt-2 px-3 sm:px-6 w-screen sm:w-auto relative">
                <Tooltip
                  title={t(
                    isWishlist
                      ? "productDetails.removeFromWishlist"
                      : "productDetails.addToWishList"
                  )}
                >
                  <div className="absolute bottom-28 right-0 sm:right-0 w-8 h-8   font-light  cursor-pointer   rounded-full   mx-3 dark:text-fontWhite text-white fw-bold">
                    <Checkbox
                      icon={
                        <FavoriteBorderIcon className="-mt-1 -ml-1 dark:text-fontWhite text-black fw-bold" />
                      }
                      checkedIcon={<FavoriteIcon className="-mt-1 -ml-1" />}
                      checked={isWishlist}
                      onClick={() => addtoWishlist(data)}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={t("productDetails.share")}>
                  <div className="absolute bottom-28 right-0 w-8 h-8   font-light  cursor-pointer   rounded-full p-1 mx-16 dark:text-fontWhite text-white fw-bold ">
                    <RWebShare
                      data={{
                        text: "Check this product out ",
                        url: getLink(),
                        title: "Share",
                      }}
                    >
                      <ShareIcon className="dark:text-fontWhite text-fontDark" />
                    </RWebShare>
                  </div>
                </Tooltip>
                <div className="relative flex h-auto ">
                  {loading ? (
                    <Skeleton className="mx-6" width={200} height={20} />
                  ) : (
                    <div>
                      <Typography
                        className="  dark:text-fontWhite text-md sm:dark:text-fontWhite text-xl font-bold sm:font-extrabold"
                        variant={"h6"}
                      >
                        {data?.brand}
                      </Typography>

                      <Typography
                        className="  dark:text-fontWhite text-md sm:dark:text-fontWhite text-lg font-normal sm:font-normal"
                        variant={"h6"}
                      >
                        {data?.product_name}
                      </Typography>

                      <Typography
                        className=" dark:text-fontWhite sm:dark:text-fontWhite text-xs font-normal sm:font-normal py-2"
                        variant={"h3"}
                      >
                        {data?.sku_no}
                      </Typography>

                      <Typography
                        className=" dark:text-fontWhite text-md sm:dark:text-fontWhite text-sm font-normal sm:font-normal"
                        variant={"h4"}
                      >
                        {data?.short_desc}
                      </Typography>
                    </div>
                  )}
                  {loading ? (
                    <Skeleton height={30} width={50} />
                  ) : (
                    <Chip
                      color="success"
                      sx={{ height: 28, width: 60 }}
                      className="ml-3"
                      label={
                        <div className="flex items-center">
                          <Typography
                            variant="subtitle1"
                            className="m-1 font-bold dark:text-fontWhite text-fontWhite"
                          >
                            {data?.AverageRating}
                          </Typography>
                          <StarRateIcon
                            sx={{
                              height: 14,
                              width: 14,
                              color: "white",
                            }}
                            className="mr-1 mb-1"
                          />
                        </div>
                      }
                    />
                  )}
                </div>

                <div className={`flex my-2 gap-2`}>
                  <div className="flex items-center">
                    {loading ? (
                      <Skeleton height={20} width={45} />
                    ) : (
                      <div className="sm:pb-1  lg:dark:text-fontWhite text-lg font-semibold  text-left dark:text-fontWhite text-[#424242]">
                        {/* {currency_type} {data?.OptionKey.length === 0 ? data.offer_price : offerPrice} */}
                        {currency_type} {selectedPrice}
                      </div>
                    )}
                    {loading ? (
                      <Skeleton height={20} width={45} className="ml-2" />
                    ) : (
                      <div
                        className={`mt-1 ml-2 dark:text-primary text-sm font-normal  text-left  text-gray-500 line-through`}
                      >
                        {currency_type}{data.price ? data.price : "0"}
                        {/* {data?.OptionKey.length === 0 ? data.price : price} */}
                      </div>
                    )}
                    {loading ? (
                      <Skeleton height={20} width={45} className="ml-2" />
                    ) : (
                      <div
                        className={`mt-1 ml-2 dark:text-primary text-primary text-sm font-normal  text-left `}
                      >
                        {data.discounts ? (`${data.discounts}%`) : ""}
                        {/* {data?.OptionKey.length === 0 ? data.price : price} */}
                      </div>
                    )}
                  </div>
                </div>

                {loading ? (
                  <Skeleton height={20} width={45} className="ml-2" />
                ) : (
                  <div className="text-sm">Buy it for Rs {selectedPrice - configPrice} on using online payment</div>
                )}

                <div className="flex justify-between">
                  <div className="">
                    {optionKey.length > 0 &&
                      optionKey.map((item, index) => (
                        <div className="pb-2">
                          <div className="grid mx-0 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                            <div>
                              <div className="mt-3">
                                <Typography
                                  variant={"span"}
                                  className=" text-sm font-normal dark:text-fontWhite text-left"
                                >
                                  {item[0]}
                                </Typography>
                                <div className="flex mt-2">
                                  {item[1].map((ele, key) => {
                                    return (
                                      <button
                                        className={`w-auto py-1 px-2  mx-1 cursor-pointer dark:text-fontWhite text-sm font-normal rounded-full
                                       ${firstIndexArray.find((o) => o.attribute === ele.attribute && o.price === ele.price && o.values === ele.values)
                                            ? "dark:text-fontWhite"
                                            : "dark:bg-darkBackground  dark:text-fontWhite text-black"
                                          }`}
                                        style={firstIndexArray.find((o) => o.attribute === ele.attribute && o.price === ele.price && o.values === ele.values) ?
                                          { background: "#009acc", color: "white" } :
                                          { background: "white" }}

                                        key={key}
                                        onClick={() => {
                                          SetproductPrice(ele, key);
                                          setOptionImage(ele?.image)
                                        }}
                                        onMouseLeave={(e) => {
                                          if (
                                            !(
                                              color == ele.values ||
                                              size == ele.values
                                            )
                                          )
                                            e.currentTarget.style.borderColor =
                                              "#aaa";
                                        }}
                                        onMouseEnter={(e) => {
                                          e.currentTarget.style.borderColor =
                                            "#aaa";
                                        }}
                                      >
                                        {ele.values}
                                      </button>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  {(data?.size_chart?.measure || data?.size_chart?.chart) &&
                    <div className="pt-4 text-primary font-semibold cursor-pointer" onClick={() => setOpenModalSizeChart(true)}>Size Chart</div>}

                </div>

                {data?.stock === "inStock" && parseInt(data?.qty) > 0 ? (
                  <div className="flex w-full justify-center mt-4 dark:text-fontWhite text-center lg:grid-cols-2 gap-2">
                    {loading ? (
                      <div className='flex gap-10'>
                        <Skeleton height={20} width={10} />
                        <Skeleton height={20} width={10} />
                      </div>
                    ) : (
                      <React.Fragment>
                        <Button disabled={pincodeStatus == false}
                          className="relative mx-0 w-full bg-background dark:bg-darkBackground
                            text-black px-2 py-3 sm:p-4 lg:p-4 mt-1 dark:text-fontWhite text-xs  text-md
                             sm:dark:text-fontWhite uppercase border-black fw-bold border-2  text-nowrap"
                          onClick={() => {
                            if (user_id === null) {
                              history.push(AppConfig.routes.login)
                            } else {
                              handleClickAddToCart("cart", data)
                            }
                          }}
                        >
                          {t("card.button.addToCart")}
                        </Button>
                        <Button
                          className="relative text-fontWhite w-full mx-0 mt-1 px-2 py-3 sm:p-4 lg:p-4 dark:text-fontWhite text-xs  text-md lg:dark:text-fontWhite text-nowrap sm:dark:text-fontWhite font-normal uppercase  text-wrap"
                          style={{ background: "#009acc" }}
                          onClick={() => {
                            if (
                              typeof user_id !== "undefined" &&
                              user_id !== null &&
                              user_id !== "null"
                            ) {
                              addtoWishlist(data);
                            } else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  navigationUrl: window.location.href,
                                },
                              });
                            }
                          }}
                        >
                          {isWishlist ? (
                            `${t("productDetails.removeFromWishlist")}`
                          ) : (
                            <>{t("productDetails.addToWishList")}</>
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                ) : loading ? (
                  <Skeleton height={50} width={500} className="mt-6" />
                ) : (
                  <div className="flex mt-5  dark:text-fontWhite text-center gap-2">
                    <Button className="w-full h-full px-1 py-4  text-sm dark:text-fontWhite text-white uppercase bg-yellow-500 ">
                      {t("productDetails.outOfStock")}
                    </Button>
                  </div>
                )}
              </Card>

              <Card className="p-3 bg-background dark:bg-lightDarkBackground">
                {loading ? (
                  <Skeleton height={20} width={200} className="ml-4" />
                ) : (
                  <Typography
                    variant="subtitle1"
                    className="ml-4 dark:text-fontWhite text-lg font-bold pb-1"
                  >
                    {t("productDetails.offer")}
                  </Typography>
                )}
                {loading ? (
                  <Skeleton height={20} width={200} className="ml-4" />
                ) : (
                  <div className="pl-3 text-sm">{HtmlParser(productCmsData)}</div>
                )}
              </Card>

              <Card className="px-3 py-3 my-1 md:my-3  bg-background dark:bg-lightDarkBackground">
                {loading ? (
                  <Skeleton height={20} width={200} className="ml-4" />
                ) : (
                  <Typography
                    variant="subtitle1"
                    className="ml-4 dark:text-fontWhite text-lg font-bold"
                  >
                    {t("productDetails.checkDelivery")}
                  </Typography>
                )}

                {loading ? (
                  <Skeleton height={20} width={200} className="ml-4" />
                ) : (
                  <div className="dark:text-fontWhite px-3">
                    <div className="flex border" style={{ width: "80% !important" }}>
                      <input onChange={(e) => setPinCodeValue(e.target.value)} type="text" className="px-2 w-[80%] border-none" placeholder={t("productDetails.placeOrderPinCode")} />
                      <Button
                        style={{ background: "#009acc" }}
                        className="w-[20%] px-5 text-white border-none" onClick={() => PinCodeValidationApi()}>{t("productDetails.check")}</Button>
                    </div>
                    <div className={pincodeStatus ? "text-green-700 font-semibold" : 'text-red-700 font-semibold'}>{validationMsg}</div>
                    <div className="text-sm pt-2">{t("productDetails.inputLabel")}</div>
                    <ul className="text-sm pl-3 pt-2" style={{ listStyle: "number" }}>
                      <li>{t("productDetails.deliveryDateOne")}</li>
                      <li>{t("productDetails.deliveryDateTwo")}</li>
                      <li>{t("productDetails.deliveryDateThree")}</li>
                    </ul>
                  </div>
                )}
              </Card>

              <Card className="py-2 md:py-4 bg-background dark:bg-lightDarkBackground lg:px-3">
                {loading ? (
                  <Skeleton height={20} width={200} className="ml-4" />
                ) : (
                  <Typography
                    variant="subtitle1"
                    className="ml-4 dark:text-fontWhite text-lg font-bold"
                  >
                    {t("productDetails.productDetails")}
                  </Typography>
                )}
                {loading ? (
                  <Skeleton
                    height={150}
                    width={200}
                    className="ml-8 mt-2"
                  />
                ) : (
                  <List component="nav ">
                    <ListItem className=" ">
                      <ListItemText
                        className=" dark:text-fontWhite"
                        secondary={
                          <div>{ReactHtmlParser(data?.long_desc)}</div>
                        }
                      />
                    </ListItem>
                  </List>
                )}
              </Card>
              <div className="md:mt-3">
                <Card className="md:mt-3 mt-2 px-4 py-4 bg-background dark:bg-lightDarkBackground lg:px-6">
                  <div className=" flex justify-between align-items-top">
                    <CardContent className="pl-0 lg:ml-0 d-flex flex-column">
                      {loading ? (
                        <Skeleton height={20} width={100} />
                      ) : (
                        <Typography
                          variant={"subtitle1"}
                          className="dark:text-fontWhite text-lg font-bold mb-2"
                        >
                          {t("productDetails.soldBy")}
                        </Typography>
                      )}
                      {loading ? (
                        <Skeleton height={20} width={200} className="mt-4" />
                      ) : (
                        <div className="flex">
                          <AccountBalanceIcon className="mx-1 text-fontDark dark:text-fontWhite" />
                          <span className="text-fontDark dark:text-fontWhite">
                            {" "}
                            {data?.vendor?.vendor?.business_name}{" "}
                          </span>
                          <span className="ml-2">
                            <span className="text-fontBlack dark:text-fontWhite">
                              {`${data?.vendor.rating}  `}{" "}
                            </span>
                            {
                              <StarRateIcon
                                className="text-fontDark dark:text-fontDark"
                                sx={{
                                  height: 18,
                                  width: 18,
                                  color: "orange",
                                }}
                              />
                            }
                          </span>
                        </div>
                      )}
                    </CardContent>
                  </div>
                  <CardContent>
                    <div className="grid pt-4 px-10 grid-cols-3 lg:gap-4 mx-0 my-auto gap-1">
                      {loading ? (
                        <Skeleton height={60} width={110} />
                      ) : (
                        <div className="flex-1 py-2">
                          <div className="dark:text-fontWhite text-center text-primary">
                            {data?.total_rating}
                          </div>
                          <div className="dark:text-fontWhite text-center font-bold">
                            {" "}
                            {t("productDetails.ratings")}{" "}
                          </div>
                        </div>
                      )}
                      {loading ? (
                        <Skeleton height={60} width={110} />
                      ) : (
                        <div className="flex-1 py-2">
                          <div className="dark:text-fontWhite text-center text-primary">
                            {data?.total_follow}
                          </div>
                          <div className="dark:text-fontWhite text-center font-bold">
                            {" "}
                            {t("productDetails.followers")}{" "}
                          </div>
                        </div>
                      )}
                      {loading ? (
                        <Skeleton height={60} width={110} />
                      ) : (
                        <div className="flex-1 py-2">
                          <div className="dark:text-fontWhite text-center text-primary">
                            {data?.total_product}
                          </div>
                          <div className="dark:text-fontWhite text-center font-bold">
                            {t("productDetails.products")}
                          </div>
                        </div>
                      )}
                      {/* {loading ? (
                        <Skeleton height={40} width={100} className="mx-6" />
                      ) : (
                        <Button
                          className={clsx(
                            classes.button,
                            "fw-bold h-10 lg:ml-0 lg:-mr-6 lg:flex-1 my-4 text-black dark:text-fontWhite text-nowrap rounded-md   text-md font-normal w-30 uppercase"
                          )}
                          onClick={() => {
                            if (user_id == null || user_id == "null") { */}
                      <div className="col-span-3 flex gap-3 sm:gap-8  sm:px-10 w-full">
                        {/* {loading ? (
                          <Skeleton height={40} width={100} className="mx-6" />
                        ) : (
                          <Button
                            className={clsx(
                              classes.button,
                              "fw-bold h-10 sm:w-2/3 m-0 w-full flex-1  my-4  text-black  text-nowrap rounded-md dark:text-fontWhite text-md font-normal w-30 uppercase"
                            )}
                            disabled
                            onClick={() => {
                              if (user_id == null || user_id == "null") {
                                history.push({
                                  pathname: `/login`,
                                  state: {
                                    navigationUrl: window.location.href,
                                  },
                                });
                              } else {
                                getProductChat();
                              }
                            }}
                          >
                            {t("productDetails.chatWithUs")}
                          </Button>
                        )} */}
                        {loading ? (
                          <Skeleton height={40} width={100} className="mx-6" />
                        ) : (
                          <Button
                            className={clsx(
                              classes.button,
                              "fw-bold h-10 mx-0 py-1 my-4 flex-1 w-full sm:w-2/3 dark:text-fontWhite text-black rounded-md  text-md font-normal uppercase"
                            )}
                            onClick={() => {
                              history.push({
                                pathname: `/seller-store/${data?.vendor.slug}/${data?.vendor.id}`,
                                state: {
                                  id: data?.vendor.id,
                                  type: "Product Details",
                                },
                              });
                            }}
                          >
                            {t("productDetails.viewShop")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Card className="my-2 md:my-4 mb-0 sm:my-4 bg-background  dark:bg-lightDarkBackground py-4 px-4 lg:px-3">
                  {loading ? (
                    <Skeleton height={20} width={200} className="ml-4" />
                  ) : (
                    <Typography
                      variant={"subtitle1"}
                      className="lg:ml-4  dark:text-fontWhite text-lg font-bold mb-4"
                    >
                      {t("productDetails.productRatingAndReviews")}
                    </Typography>
                  )}
                  <div className="grid mx-0  sm:grid-cols-4 grid-cols-1 lg:px-10">
                    <div className="flex-1">
                      {loading ? (
                        <Skeleton
                          height={100}
                          width={100}
                          className="my-3 lg:mx-10"
                        />
                      ) : (
                        <div
                          className={`py-3 d-flex flex-col lg:flex-row lg:gap-4
                          dark:text-fontWhite text-3xl  text-center font-normal items-center`}
                        >
                          <div className="d-flex flex-row items-center">
                            <p className="text-fontDark dark:text-fontWhite">
                              {data?.AverageRating}
                            </p>
                            <StarRateIcon
                              sx={{
                                height: 24,
                                width: 24,
                                color: "green",
                              }}
                            />
                          </div>
                          <div className={"flex flex-row sm:flex-col"}>
                            <div className="dark:text-fontWhite text-center text-nowrap  text-sm mx-1 font-normal">
                              {data?.total_rating}{" "}
                              {t("productDetails.ratings")}
                            </div>
                            <div className="dark:text-fontWhite text-center  text-sm font-normal">
                              &
                            </div>
                            <div className="dark:text-fontWhite text-center text-nowrap mx-1  text-sm font-normal">
                              {data?.total_review}{" "}
                              {t("productDetails.reviews")}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="sm:col-span-3">
                      {loading ? (
                        <Skeleton
                          height={100}
                          width={350}
                          className="mx-10 my-3"
                        />
                      ) : (
                        <List>
                          <div className="grid-flow-row grid w-full mx-0 p-3">
                            <div className="d-flex w-64 dark:text-fontWhite text-sm items-center justify-between mx-10">
                              <div> {t("productDetails.five")}</div>
                              <div className="sm:col-span-5 flex ">
                                <StarRateIcon
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    color: "green",
                                  }}
                                  className=" mx-4"
                                />
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={data?.FiveStar}
                                  sx={{
                                    width: 100,
                                  }}
                                  className="my-1"
                                />

                                <span className="float-right mx-2">
                                  {data?.FiveStar}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-64 dark:text-fontWhite text-sm items-center justify-between mx-10">
                              <div> {t("productDetails.four")}</div>
                              <div className="flex ">
                                <StarRateIcon
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    color: "green",
                                  }}
                                  className=" mx-4"
                                />
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={data?.FourStar}
                                  sx={{
                                    width: 100,
                                  }}
                                  className="my-1"
                                />

                                <span className="float-right mx-2">
                                  {data?.FourStar}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-64 dark:text-fontWhite text-sm items-center justify-between mx-10">
                              <div> {t("productDetails.three")}</div>
                              <div className="flex ">
                                <StarRateIcon
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    color: "green",
                                  }}
                                  className=" mx-4"
                                />
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={data?.ThreeStar}
                                  sx={{
                                    width: 100,
                                  }}
                                  className="my-1"
                                />

                                <span className="float-right mx-2">
                                  {data?.ThreeStar}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-64 dark:text-fontWhite text-sm items-center justify-between mx-10">
                              <div> {t("productDetails.two")}</div>
                              <div className=" flex ">
                                <StarRateIcon
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    color: "green",
                                  }}
                                  className=" mx-4"
                                />
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={data?.TwoStar}
                                  sx={{
                                    width: 100,
                                  }}
                                  className="my-1"
                                />

                                <span className="float-right mx-2">
                                  {data?.TwoStar}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex w-64 dark:text-fontWhite text-sm items-center justify-between mx-10">
                              <div> {t("productDetails.one")}</div>
                              <div className="sm:col-span-5 flex ">
                                <StarRateIcon
                                  sx={{
                                    height: 18,
                                    width: 18,
                                    color: "green",
                                  }}
                                  className=" mx-4"
                                />
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={data?.OneStar}
                                  sx={{
                                    width: 100,
                                  }}
                                  className="my-1"
                                />

                                <span className="float-right mx-2">
                                  {data?.OneStar}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </List>
                      )}
                    </div>
                  </div>

                  <div className="grid my-3 mx-4 ">
                    <Divider className=" my-1 " />
                  </div>
                  <div className="md:grid-cols-3 sm:grid-cols-2 grid-cols-1 ">
                    {loading ? (
                      <Skeleton height={100} width={550} className="mx-10" />
                    ) : (
                      data?.top_five_review.slice(0, 3).map((ele, key) => {
                        // const newDate = new Date()
                        // const diffrenceDate = diffDate(new Date(), new Date(moment(ele.created_at).format("DD-MM-yyyy")))
                        let now = moment(new Date());
                        let end = moment(ele.created_at);
                        let duration = moment.duration(now.diff(end));
                        let days = duration.asDays();
                        let Year = duration.asYears();
                        let finalDate = days / 30

                        return (
                          <React.Fragment>
                            <div className="px-4 py-1" key={key}>
                              <div className="flex">
                                <div className="w-28 dark:text-fontWhite text-center">
                                  {ele.username.length > 0 ?
                                    <img
                                      src={ele.image_src}
                                      className="h-16 w-16"
                                    />
                                    :
                                    <Avatar sx={{ width: "60px", height: "60px" }} />}
                                </div>
                                <div className="w-full mx-4 pb-4">
                                  <Typography
                                    variant={"subtitle2"}
                                    className="dark:text-fontWhite text-nowrap"
                                  >
                                    {ele?.customer_name?.length > 0 && ele?.customer_name}
                                  </Typography>
                                  <Rating
                                    className=""
                                    name="simple-controlled"
                                    value={parseInt(ele.rating)}
                                    readOnly
                                  />
                                  <Typography
                                    className="dark:text-fontWhite"
                                    variant={"subtitle2"}
                                  >
                                    {ele.description}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    className="dark:text-fontWhite text-gray-400"
                                  >
                                    {/* {moment(ele.created_at).format("DD-MM-yyyy")} */}
                                    {Year.toFixed() == 1 ? `${'About' + " " + Year.toFixed() + " " + "Year ago"}` :
                                      Year.toFixed() > 1 ? `${'About' + " " + Year.toFixed() + " " + "Years ago"}` :
                                        days < 30 ? `${days.toFixed() + " " + "days ago"}` :
                                          finalDate.toFixed() == 1 ? `${'About' + " " + finalDate.toFixed() + " " + "month ago"}` :
                                            `${'About' + " " + finalDate.toFixed() + " " + "months ago"}`
                                    }
                                  </Typography>
                                </div>
                              </div>
                              <Divider className=" my-1 " />


                              {loading ? (
                                <Skeleton height={350} />
                              ) : (
                                <div className="lg:flex lg:w-[20%] lg:h-[15%] demoImages thinScroll d-flex mt-2 mb-0 sm:mb-4 
                                lg:mb-4 px-4 overflow-x-scroll overflow-y-auto lg:overflow-y-hidden">
                                  {
                                    ele.review_images && ele.review_images.map((item, index) => (
                                      <img src={item?.url}
                                        //  className="h-20 w-24 gap-4 rounded-md" 
                                        className="lg:w-[20%] h-24 lg:object-contain rounded-xl mr-2 mb-2 border-gray-200 border-2
                                                  imageHover hover:shadow-lg cursor-pointer"
                                        onClick={() => {
                                          setModalImage(ele?.review_images)
                                          setOpenModal(true)
                                        }}
                                        style={{ width: "20%" }}
                                      />
                                    ))
                                  }
                                </div>
                              )}
                            </div>
                            {key === 2 && (
                              <div>
                                <Typography
                                  className="dark:text-fontWhite text-center text-blue-600 cursor-pointer"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/all-reviews/${data.slug}
                                `,
                                      state: {
                                        data: data?.top_five_review,
                                        product: data,
                                      },
                                    });
                                  }}
                                >
                                  {t("productDetails.all")}{" "}
                                </Typography>
                              </div>
                            )}

                          </React.Fragment>
                        )
                      })
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div
          className="py-4 sm:py-10  w-screen sm:w-auto dark:bg-darkBackground`
           bg-background"
        >
          <div className="grid  justify-center place-items-center mx-4 sm:mx-0 dark:text-fontWhite text-center">
            <Typography
              variant="h6"
              className="font-normal text-fontDark dark:text-fontWhite justify-items-center uppercase"
            >
              {t("productDetails.similarProducts")}
            </Typography>
          </div>
          <div className="my-8 mx-4 lg:mx-10 rounded-xl shadow-md md:shadow-lg border-2">
            {loading ? (
              <Skeleton height={350} />
            ) : (
              <div>
                <Carousel
                  ssr
                  partialVisbile
                  deviceType={deviceType === "S" ? "mobile" : "desktop"}
                  itemClass="image-item p-2 sm:p-0 "
                  autoPlay
                  responsive={responsive}
                  className="sm:mx-2 my-3 mb-6 carouselContainer"
                  infinite
                >
                  {typeof products !== "undefined" &&
                    products.slice(0).map((image, key) => {
                      return (
                        <div>
                          <ProductCard
                            image={image}
                            addToWishlist={addtoWishlist}
                          // wishArray={wishArray}
                          // setWishArray={setWishArray}
                          />
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            )}
          </div>
        </div>
        <div className="grid  m-0 my-0 mt-0 mb-5 sm:mt-5 sm:px-4 pb-6 w-screen sm:w-auto  grid-col-1 sm:grid-cols-2 lg:grid-cols-3">
          {
            loading ? <Skeleton height={200} width={250} />
              : <div className="flex-1 bg-background dark:bg-lightDarkBackground shadow-lg md:shadow-lg shadow-inherit md:p-6 p-5 mx-4 mt-0 sm:mt-4 lg:mt-0">
                <div className="dark:text-fontWhite text-center justify-center flex  w-full">
                  {/* <img
                  draggable={false}
                  alt="dark:text-fontWhite text"
                  className="w-12 dark:text-primary   text-center h-12"
                  src={image1}
                /> */}
                  <PaymentIcon className="md:w-20 md:h-20 w-14 h-14" />
                </div>
                <div className="dark:text-fontWhite  text-center">
                  <Typography className=" text-center font-bold uppercase dark:text-fontWhite text-primary">
                    {t("providingFeatures.features.i.heading")}
                  </Typography>
                  <span className=" text-sm font-normal dark:text-fontWhite text-center">
                    {t("providingFeatures.features.i.subHeading")}
                  </span>
                </div>
              </div>}
          {
            loading ?

              <Skeleton height={200} width={250} />
              : <div className="flex-1 bg-background dark:bg-lightDarkBackground  shadow-lg md:shadow-lg shadow-inherit md:p-6 p-5 mx-4 mt-2 md:mt-4 lg:mt-0">
                <div className="dark:text-fontWhite text-center justify-center flex  w-full">
                  {/* <img
                  draggable={false}
                  alt="dark:text-fontWhite text"
                  src={image5}
                  className="w-12 dark:text-primary h-12"
                /> */}
                  <SecurityIcon className="md:w-20 md:h-20 w-14 h-14" />
                </div>
                <div className="dark:text-fontWhite text-center">
                  <Typography className=" text-center uppercase font-bold dark:text-fontWhite text-primary">
                    {t("providingFeatures.features.ii.heading")}
                  </Typography>
                  <span className=" text-sm font-normal dark:text-fontWhite text-center">
                    {t("providingFeatures.features.ii.subHeading")}
                  </span>
                </div>
              </div>}
          {loading ?

            <Skeleton height={200} width={250} />
            : <div className="flex-1 bg-background dark:bg-lightDarkBackground  shadow-lg md:shadow-lg  shadow-inherit md:p-6 p-5 mx-5 mt-2 md:mt-4 lg:mt-0">
              <div className="dark:text-fontWhite text-center justify-center flex  w-full">
                {/* <img
                  draggable={false}
                  alt="dark:text-fontWhite text"
                  src={image6}
                  className="w-12 dark:text-primary h-12"
                /> */}
                <LanguageIcon className="md:w-20 md:h-20 w-14 h-14" />
              </div>
              <div className="dark:text-fontWhite text-center">
                <Typography className=" text-center uppercase font-bold dark:text-fontWhite text-primary">
                  {t("providingFeatures.features.iii.heading")}
                </Typography>
                <span className=" text-sm font-normal dark:text-fontWhite text-center">
                  {t("providingFeatures.features.iii.subHeading")}
                </span>
              </div>
            </div>}
          {/* {loading ?
            <Skeleton height={200} width={250} />
            : <div className="flex-1 bg-background dark:bg-lightDarkBackground  shadow-lg md:shadow-lg shadow-inherit md:p-6 p-5 mx-5 mt-2 md:mt-8 lg:mt-0">
              <div className="dark:text-fontWhite text-center justify-center flex  w-full">
                <CallIcon className="md:w-16 md:h-16 w-12 h-12" />
              </div>
              <div className="dark:text-fontWhite text-center uppercase">
                <Typography className=" text-center font-bold dark:text-fontWhite text-primary">
                  {t("providingFeatures.features.iv.heading")}
                </Typography>
                <span className=" text-sm font-normal dark:text-fontWhite text-center">
                  {t("providingFeatures.features.iv.subHeading")}
                </span>
              </div>
            </div>} */}
        </div>

      </React.Fragment >

      <AlertComponent
        productData={data}
        price={price}
        offerPrice={offerPrice}
        currency_type={currency_type}
        openAlertModal={openAlertModal}
        alertContent={alertContent}
        firstIndexArray={firstIndexArray}
        handleAlertModalClose={handleAlertModalClose}
      />
    </React.Fragment >
  );
}