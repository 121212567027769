import { Chip, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { default as AppConfig, default as appConfig } from "../appConfig";
import ProductCard from "../core-components/ProductCard";
import { ActionTypes } from "../redux/contants/ActionType";
import store from "../redux/store";
import ApiService from "../services/apiService";


export default function MyFollowing() {
  const wishlistCount = useSelector(
    ({ allCategories }) => allCategories.wishlistCount
  );
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [device, setDevice] = useState();
  const history = useHistory();
  const { t } = useTranslation();
  const user_id = sessionStorage.getItem("user_id");
  const [loading, setLoading] = useState(true);
  const [follow, setFollow] = useState(false);
  const useStyles = makeStyles((theme) => ({
    sizeAvatar: {
      height: theme.spacing(5),
      width: theme.spacing(5),
      objectFit: "cover",
    },
    button: {
      // background:
      //   "linear-gradient(to bottom,#597593 0%, #597593 100%)",
      margin: 10,

      textAlign: "center",
      textTransform: "uppercase",
      transition: "0.5s",
      color: "white !important",
      borderRadius: "10px",
      // borderColor: 'black',
      display: "block",
      // boxShadow: "0 0 5px 0 #597593 inset",
      // "&:hover": {
      //   background: "#597593",
      // },
    },
    ul: {
      "& .MuiPaginationItem-root": {
        color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505"
      }
    }
  }));

  // params will have all parameter
  const params = useParams();

  useEffect(() => {
    if (window.innerWidth <= appConfig.breakpoints.mobile) setDevice("S");
    else setDevice("L");
    window.addEventListener("resize", () => {
      if (window.innerWidth <= appConfig.breakpoints.mobile) setDevice("S");
      else setDevice("L");
    });
    window.scrollTo(0, 0);
    return () =>
      window.removeEventListener("resize", () => {
        if (window.innerWidth <= appConfig.breakpoints.mobile) setDevice("S");
        else setDevice("L");
      });
  }, []);
  useEffect(() => {
    //window.scrollTo(0,0);To(0, 0);
    // get a vendor detail function
    ApiService.getFollowingById(params.id, page).then((res) => {
      if (res.code === 200) {
        setData(res.data.data);
        setFollow(res.data.data.user.is_follow);
        setLoading(false);
      }
    });
    // this component includes pagination so whenever page changes useEffect will run
  }, [params.id, page, localStorage.getItem('theme')]);

  //add to wishlist
  const addToWishlist = (data) => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      // wishItemToggle(data.id);
      _form.append("product_id", data.id);
      ApiService.addItemToWishlist(_form).then((res) => {
        if (res.code === 200) {
          if (res.data.message.includes("removed")) {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount - 1,
            });
          } else {
            store.dispatch({
              type: ActionTypes.ADD_USER_WISHLIST_COUNT,
              payload: wishlistCount + 1,
            });
          }
        }
      });
    } else {
      // if user has not logged then he will not be able to see wishlist
      history.push(AppConfig.routes.login);
    }
  };

  // follow seller function
  const followSeller = () => {
    if (user_id !== null && user_id !== "null") {
      let _form = new FormData();
      _form.append("follower_id", data.user.id);

      // follow seller api call
      ApiService.followSellerById(_form).then((res) => {
        setFollow(res.data.data.follow);
      });
    } else {
      history.push(appConfig.routes.login);
    }
  };
  const classes = useStyles();
  return (
    <div className="bg-white dark:bg-darkBackground">
      {loading ? (
        <Skeleton height={250} />
      ) : (
        <>
          <div
            className={`w-screen h-40 sm:h-72 bg-center bg-cover bg-no-repeat`}
            style={{
              backgroundImage: `url(${data?.user?.vendor?.cover})`,
            }}
          >
            {/* {loading ? (
              <Skeleton height={80} width={80} circle />
            ) : (
              <Avatar
                variant="rounded"
                alt="Remy Sharp"
                src={`${data?.user?.profile}`}
                sx={{
                  width: 100,
                  height: 100,
                  boxShadow: 10,
                  backgroundColor: "#fafafa",
                  left: 50,
                  top: 250,
                  position: "absolute",
                }}
              />
            )} */}
          </div>
          <hr className="w-100 border-2 border-black" />
        </>
      )}

      {/* <div className="lg:h-14 h-16 shadow-lg bg-white dark:bg-lightDarkBackground">
        {loading ? (
          <Skeleton height={60} width={280} className="ml-4 sm:-mt-5" />
        ) : (
          <div className="lg:text-4xl ml-10 sm:-mt-6 dark:text-fontWhite text-2xl text-black font-bold uppercase">
            {" "}
            {data?.user?.name}{" "}
          </div>
        )}
      </div> */}
      <hr className="w-100 border-2 border-black" />
      <div className="lg:h-44 h-50 py-6 sm:p-6 shadow-lg bg-white dark:bg-lightDarkBackground">
        <div className="w-100">
          <div className="mx-4 d-flex align-items-center justify-content-between">
            {loading ? (
              <Skeleton height={80} width={80} circle />
            ) : (
              <Avatar
                alt="Remy Sharp"
                src={`${data?.user?.profile}`}
                sx={{
                  width: 90,
                  height: 90,

                  boxShadow: 10,
                }}
              />
            )}

            <div className="w-100">
              <div className="px-4 flex flex-col sm:flex-row items-end sm:items-center justify-between w-100">
                {loading ? (
                  <Skeleton height={60} width={280} className="ml-4" />
                ) : (
                  <div className="lg:text-4xl sm:mb-4 dark:text-fontWhite text-2xl text-black font-bold uppercase">
                    {" "}
                    {data?.user?.vendor?.business_name}{" "}
                  </div>
                )}
                <div className="sm:py-2">
                  {loading ? (
                    <Skeleton height={45} width={150} />
                  ) : (
                    <Button
                      className={clsx(
                        classes.button,
                        "fw-bold h-10  lg:w-40 w-28 py-1 my-4 text-white rounded-md lg:mr-14 text-md font-normal  uppercase"
                      )}
                      style={{ background: "#009acc" }}
                      onClick={() => followSeller()}
                    >
                      {" "}
                      {follow
                        ? `${t("myFollowing.following")}`
                        : `${t("myFollowing.follow")}`}
                    </Button>
                  )}
                </div>
              </div>
              {device == "L" && (
                <div className="flex flex-row mx-3 gap-4">
                  <div>
                    {loading ? (
                      <Skeleton height={40} width={100} />
                    ) : (
                      <Chip
                        variant="outlined"
                        size="medium"
                        sx={{ width: 120, height: 35 }}
                        className="bg-black"
                        label={
                          <React.Fragment>
                            <div className="flex">
                              <Typography
                                variant="subtitle2"
                                className="text-sm text-black dark:text-fontWhite"
                              >
                                {t("myFollowing.ratings")}
                              </Typography>
                              <Typography className="px-1 text-black dark:text-fontWhite font-semibold text-md">
                                {" "}
                                {Math.round(data?.user?.rating)}{" "}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                      />
                    )}
                  </div>
                  <div>
                    {loading ? (
                      <Skeleton height={40} width={100} />
                    ) : (
                      <Chip
                        variant="outlined"
                        size="medium"
                        sx={{ width: 120, height: 35 }}
                        className="bg-black"
                        label={
                          <React.Fragment>
                            <div className="flex">
                              <Typography
                                variant="subtitle2"
                                className="text-sm text-black dark:text-fontWhite"
                              >
                                {t("myFollowing.followings")}
                              </Typography>
                              <Typography className="px-1 text-black dark:text-fontWhite font-semibold text-md">
                                {" "}
                                {data?.user?.total_follow}{" "}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                      />
                    )}
                  </div>
                  <div>
                    {loading ? (
                      <Skeleton height={40} width={100} />
                    ) : (
                      <Chip
                        variant="outlined"
                        size="medium"
                        sx={{ width: 120, height: 35 }}
                        className="bg-black"
                        label={
                          <React.Fragment>
                            <div className="flex">
                              <Typography
                                variant="subtitle2"
                                className="text-sm text-black dark:text-fontWhite font-normal "
                              >
                                {t("myFollowing.products")}
                              </Typography>
                              <Typography className="px-1 text-black dark:text-fontWhite font-semibold text-md">
                                {" "}
                                {data?.user?.total_product}{" "}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {device == "S" && (
            <div className="flex flex-row m-4 mb-0 gap-2 sm:gap-4 justify-center">
              <div>
                {loading ? (
                  <Skeleton height={40} width={100} />
                ) : (
                  <Chip
                    size="medium"
                    sx={{ width: 120, height: 35 }}
                    className="bg-black"
                    label={
                      <React.Fragment>
                        <div className="flex">
                          <Typography
                            variant="subtitle2"
                            className="text-sm text-light"
                          >
                            {t("myFollowing.ratings")}
                          </Typography>
                          <Typography className="px-1 text-light font-semibold text-md">
                            {" "}
                            {Math.round(data?.user?.rating)}{" "}
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                )}
              </div>
              <div>
                {loading ? (
                  <Skeleton height={40} width={100} />
                ) : (
                  <Chip
                    size="medium"
                    sx={{ width: 120, height: 35 }}
                    className="bg-black"
                    label={
                      <React.Fragment>
                        <div className="flex">
                          <Typography
                            variant="subtitle2"
                            className="text-sm text-light"
                          >
                            {t("myFollowing.followings")}
                          </Typography>
                          <Typography className="px-1 text-light font-semibold text-md">
                            {" "}
                            {data?.user?.total_follow}{" "}
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                )}
              </div>
              <div>
                {loading ? (
                  <Skeleton height={40} width={100} />
                ) : (
                  <Chip
                    size="medium"
                    sx={{ width: 120, height: 35 }}
                    className="bg-black"
                    label={
                      <React.Fragment>
                        <div className="flex">
                          <Typography
                            variant="subtitle2"
                            className="text-sm text-light font-normal "
                          >
                            {t("myFollowing.products")}
                          </Typography>
                          <Typography className="px-1 text-light dark:text-fontWhite font-semibold text-md">
                            {" "}
                            {data?.user?.total_product}{" "}
                          </Typography>
                        </div>
                      </React.Fragment>
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-flow-row mx-10 mt-5">
        <div className="mt-6 mb-2">
          <Typography variant="h5" className="px-3 dark:text-fontWhite">
            {t("myFollowing.allProducts")}
          </Typography>
        </div>
        <div className="mb-4">
          {loading ? (
            <Skeleton height={30} width={250} className="px-5" />
          ) : (
            <Typography className="px-3">
              {data?.products?.data.length < 1 && (
                <div className="flex w-full  justify-center">
                  <img
                    style={{ filter: "hue-rotate(209deg)" }}
                    className="self-center"
                    src="https://tradebharat.in/assets/catalogue/img/no-product-found.png"
                  />
                </div>
              )}
              {data?.products?.data.length > 0 && (
                <p className="dark:text-fontWhite">
                  {" "}
                  {t("myFollowing.showing")} {data?.products?.from} -{" "}
                  {data?.products?.to} out of {data?.products?.total}{" "}
                  {t("myFollowing.products")}{" "}
                </p>
              )}
            </Typography>
          )}
        </div>
      </div>
      <div className="my-6 rounded-md">
        {loading ? (
          <Skeleton height={400} className="w-full mb-10 " />
        ) : (
          <div className="grid gap-2 dark:bg-darkBackground lg:gap-0 py-10 grid-cols-2 sm:grid-cols-4 mx-2 sm:mt-6 sm:mb-5 lg:grid-cols-5 bg-gray-100 rounded-md sm:px-4">
            {data?.products?.data.slice(0).map((ele, key) => (
              <ProductCard
                key={key}
                image={ele}
                following={true}
                addToWishlist={addToWishlist}
              />
            ))}
          </div>
        )}

        <div className="mb-10 flex justify-center">
          <Pagination
            classes={{ ul: classes.ul }}
            count={data?.products.last_page}
            defaultPage={page}
            // color="primary"
            className="dark:text-fontDark"
            size="large"
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </div>
      </div>
    </div>
  );
}
