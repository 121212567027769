import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  MenuItem,
  Toolbar,
  Typography
} from "@material-ui/core";
import Formsy from "formsy-react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Label } from "semantic-ui-react";
import ImageUpload from "../core-components/ImageUpload";
import SelectFormsy from "../core-components/SelectFormsy";
import TextFieldFormsy from "../core-components/TextFieldFormsy";
import ApiService from "../services/apiService";

export default function MyProfile() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    profileImage: "",
    phone: "",
    dialing_code: { id: "+91", name: "+91", code: "" },
    address: "",
    category_id: "",
    city: "",
    state: "",
    otp: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      let phone = new AsYouType(
        form.dialing_code.id === "+55" ? "BR" : "US"
      ).input(e.target.value)
      setFormattedNumber(phone);
      setDisabled(false)
    } else if (e.target.name === 'email') {
      setFormattedEmail(e.target.value);
      setDisabled(false)
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  const handleChangerForDialingCode = (name, value) => {
    setForm({
      ...form,
      [name]: { id: value, name: value },
    });
  };
  const [formatedDialingCode, setFormatedDialingCode] = useState([]);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [formattedEmail, setFormattedEmail] = useState("");
  React.useEffect(() => {
    setTimeout(() => {
      // const element = document.querySelectorAll(
      //   ".MuiInputBase-root.MuiOutlinedInput-root"
      // );
      // const arr = [...element];
      // arr.forEach((ele) => {
      //   ele.classList.add("dark:text-fontWhite");

      //   ele.classList.add("text-primary");
      // });
      // const filledsTextField = document.querySelectorAll(
      //   ".MuiFilledInput-input"
      // );
      // const filledsTextFieldArray = [...filledsTextField];
      // filledsTextFieldArray.forEach((ele) => {
      //   ele.classList.add("dark:text-fontWhite");

      //   ele.classList.add("text-primary");
      // });
      // const inputBackground = document.querySelectorAll(".MuiFormControl-root");

      // const inputBackgroundArray = [...inputBackground];
      // inputBackgroundArray.forEach((ele, key) => {
      //   if (key !== 0) {
      //     ele.classList.add("dark:bg-lightDarkBackground");

      //     ele.classList.add("text-primary");
      //   }
      // });
    }, 0);
  }, [form]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProfile();
  }, []);

  const getProfile = () => {
    ApiService.getProfile().then((res) => {
      if (res.code === 200) {
        if (res.data?.data?.user?.dialing_code && res.data?.data?.user?.phone) {
          setFormattedNumber(
            new AsYouType(
              res.data?.data?.user?.dialing_code === "+55" ? "BR" : "US"
            ).input(res.data?.data?.user?.phone)
          );
        }
        if (res.data?.data?.user?.email) {
          setFormattedEmail(res.data?.data?.user?.email);
        }
        setForm({
          name: res.data.data.user.name,
          profileImage: res.data.data.user.profile,
          email: res.data.data.user.email,
          phone: res.data?.data?.user?.phone,
          category_id: res.data.data.user.category_id,
          dialing_code: {
            id: res.data.data?.user?.dialing_code,
            name: res.data.data?.user?.dialing_code,
            code: res.data.data?.user?.dialing_code === "+55" ? "BR" : "US",
          },
          address: res.data.data.user.address,
          city: res.data.data.user.city,
          state: res.data.data.user.state,
        });
        setLink(res.data.data.user.profile);
        setLoading(false);
      }
    });
    ApiService.getDialingCode().then((res) => {
      const temp = [];
      res.data.data.dialing_code.map((dialingCode) => {
        temp.push({
          id: dialingCode.dialing_code,
          name: dialingCode.dialing_code,
          code: dialingCode.code,
        });
        // formatedDialingCode will be used as dialingCode options so formatedDialingCode needs two keys id and name
      });
      setFormatedDialingCode(temp);
    });
  };
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const [errorMessage, setErrorMessage] = useState();
  const phoneNumberState = usePrevious(formattedNumber);
  const emailState = usePrevious(formattedEmail);
  const email = usePrevious(form.email);
  const [type, setType] = useState("");
  // update profile function
  const handleSubmit = (selectedType) => {
    if (selectedType === "phone") {
      let temp;
      formatedDialingCode.map((item, key) => {
        if (item.id === form.dialing_code.id) {
          temp = item.code;
        }
      });

      if (
        form.dialing_code.id === "" ||
        form.dialing_code.id === null ||
        typeof form.dialing_code.id === "null"
      ) {
        setErrors({
          ...errors,
          ["dialing_code"]: t("login.codeError"),
        });
      } else {
        var phoneNumber = parsePhoneNumber(
          formattedNumber,
          temp
        );

        if (formattedNumber !== "" && phoneNumber?.isValid()) {
          setErrors({
            ...errors,
            ["phone"]: "",
          });

          let formData = new FormData();
          formData.append("new_value", formattedNumber.replace(/[^0-9]/g, ""));
          formData.append("type", "phone");
          formData.append(
            "dialing_code",
            typeof form.dialing_code === "undefined" ? "+91" : form.dialing_code.id
          );
          ApiService.updateAccountOtp(formData).then((result) => {
            if (result.code === 200) {
              setOpenModal(true);
            } else {
              if (result.errors.includes("already exists")) {
                setErrors({ ["phone"]: t("myProfile.phoneError") });
              } else {
                setErrors(result.errors);
              }
            }
          });

        } else {
          setErrors({
            ...errors,
            ["phone"]: t("login.phoneError"),
          });
        }
      }


    } else if (selectedType === "email") {
      let formData = new FormData();
      setType("email");
      formData.append("new_value", formattedEmail);
      formData.append("type", "email");
      ApiService.updateAccountOtp(formData).then((result) => {
        if (result.code === 200) {
          setOpenModal(true);
        } else {
          if (result.errors.includes("already exists")) {
            setErrors({ ["email"]: t("myProfile.emailError") });
          } else {
            setErrors(result.errors);
          }
        }
      });
    } else {
      let _form = new FormData();
      for (const key in form) {
        if (key === "dialing_code") {
          _form.append(key, form.dialing_code.id);
        } else {
          if (form[key] !== null) {
            _form.append(key, form[key]);
          }
        }
      }
      _form.append("location_lat", sessionStorage.getItem("location_lat"));
      _form.append("location_long", sessionStorage.getItem("location_long"));
      // update profile api
      ApiService.updateProfile(_form).then((res) => {
        if (res.code === 200) {
          setFormattedNumber(
            new AsYouType(
              res.data.data.user.dialing_code === "+55" ? "BR" : "US"
            ).input(res.data.data.user.phone)
          );
          setFormattedEmail(res.data?.data?.user?.email);

          setForm({
            name: res.data.data.user.name,
            profileImage: res.data.data.user.profile,
            email: res.data.data.user.email,
            phone: res.data.data.user.phone,
            category_id: res.data.data.user.category_id,
            dialing_code: {
              id: res.data.data.user.dialing_code,
              name: res.data.data.user.dialing_code,
              code: res.data.data.user.dialing_code === "+55" ? "BR" : "US",
            },
            address: res.data.data.user.address,
            city: res.data.data.user.city,
            state: res.data.data.user.state,
          });
          setErrorMessage(res.data.message);
          // setWarningModalOpen(true);
          setLoading(false);
        } else {
          setErrors(res.errors);
        }
      });
    }
  };

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState("")

  const handlePassword = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  }

  const updatePassword = () => {
    if (passwordData.newPassword === passwordData.confirmPassword) {
      let form = new FormData();
      form.append("current_password", passwordData.oldPassword);
      form.append("new_password", passwordData.newPassword);
      form.append("password_confirmation", passwordData.confirmPassword);
      ApiService.changePassword(form).then((res) => {
        if (res.code === 200) {

        } else {
          setPasswordError(res.errors);
        }
      })
    } else {
      setConfirmPasswordError(`${t("myProfile.confirmPassError")}`)
    }
  }
  const [link, setLink] = useState();
  const changeImage = (url, data) => {
    setLink(url);
    setForm({ ...form, ["profileImage"]: data });
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const [disabled, setDisabled] = useState(false);
  const verifyOtp = () => {
    let formData = new FormData();
    formData.append("otp", form.otp);
    formData.append("phone", form.phone.replace(/[^0-9]/g, ""));
    formData.append("dialing_code", form.dialing_code?.id);

    formData.append("type", type);
    ApiService.verifyEmailOtp(formData).then((result) => {
      if (result.code === 200) {
        setOpenModal(false);
        getProfile();
        setDisabled(false);
      } else {
        setDisabled(false);
        setErrors(result.errors)
      }
    });
  };

  const [errors, setErrors] = useState();
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        // fullWidth
        // maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar
          position="static"
          className="bg-primary text-white"
          elevation={1}
        >
          <Toolbar className="flex w-full">
            <Typography variant={"subtitle1"} color="inherit">
              {t("myProfile.alert")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          className="dark:bg-lightDarkBackground text-center justify-center"
          classes={{ root: "p-2" }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            className="p-4 py-6 text-lg dark:text-fontWhite"
          >
            <Label className="text-lg dark:text-fontWhite">
              {/* {t("myProfile.message")} : {formattedNumber} */}
              {type === "phone" ? `${t("myProfile.message")} - ${formattedNumber}` : `${t("myProfile.emailMessage")} - ${formattedEmail}`}
            </Label>
            <div className="my-4 mx-4">
              <TextFieldFormsy
                className="w-100 bg-primary text-white"
                id="outlined-basic"
                label={t("login.enterTheOtp")}
                type="otp"
                name="otp"
                onChange={(e) => {
                  handleChange(e);
                }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="filled"
                value={form.otp}
                serverError={errors?.otp}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dark:bg-lightDarkBackground">
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={() => {
              handleModalClose();
              setDisabled(false);
              setForm({});
              setErrors(null)
            }}
            type="button"
          >
            {t("alertComponent.cancel")}
          </Button>
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={() => {
              if (isEmpty(form.otp)) {
                setErrors({ ...errors, ["otp"]: t("myProfile.otpError") });
              } else {
                verifyOtp();
              }
            }}
            type="button"
          >
            {t("login.verify")}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        variant={"h5"}
        className="py-5 text-center dark:text-fontWhite uppercase dark:bg-lightDarkBackground bg-gray-200 border-b-2"
      >
        {t("myProfile.profile")}
      </Typography>
      <div className="grid grid-cols-1 gap-5 lg:p-5 mx-10 my-4 lg:grid-cols-3">
        <div className="sm:px-6 text-center lg:text-end">
          {loading ? (
            <Skeleton
              height={250}
              width={280}
              className=" mb-10  float-center sm:float-right "
            />
          ) : (
            <>
              <ImageUpload
                className="justify-end text-right  lg:float-right justify-items-end"
                name="profileImage"
                value={link}
                src={link}
                accept={"image/*"}
                inputType={"image"}
                onFileChange={changeImage}
              />
            </>
          )}
        </div>
        <div className=" lg:col-span-2">
          <Label className="-ml-3 text-primary font-bold text-lg mb-4">
            {t("myProfile.personalInfo")}
          </Label>
          <div className="grid grid-flow-row gap-4 mx-4">
            <div className="grid grid-cols-1">
              {loading ? (
                <Skeleton height={60} className="w-full  " />
              ) : (
                <TextFieldFormsy
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-basic"
                  label={t("myProfile.firstName")}
                  name="name"
                  className='bg-primary text-white'
                  variant="filled"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={form.name}
                  serverError={errors?.name}
                  type="text"
                />
              )}
            </div>
            <div className="grid grid-cols-1">
              {loading ? (
                <Skeleton height={120} className="w-full  " />
              ) : (
                <TextFieldFormsy
                  id="outlined-basic"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label={t("myProfile.address")}
                  name="address"
                  type="text"
                  className='bg-primary text-white'
                  value={form.address}
                  multiline
                  rows={4}
                  serverError={errors?.address}
                  variant="filled"
                />
              )}
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              {loading ? (
                <Skeleton height={60} className="w-full  " />
              ) : (
                <TextFieldFormsy
                  id="outlined-basic"
                  label={t("myProfile.city")}
                  type="text"
                  className='bg-primary text-white'
                  name="city"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={form.city}
                  serverError={errors?.city}
                  variant="filled"
                />
              )}
              {loading ? (
                <Skeleton height={60} className="w-full  " />
              ) : (
                <TextFieldFormsy
                  id="outlined-basic"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label={t("myProfile.state")}
                  variant="filled"
                  className='bg-primary text-white'
                  name="state"
                  type="text"
                  serverError={errors?.state}
                  value={form.state}
                />
              )}
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                className="my-2 text-base bg-primary text-white dark:bg-button font-normal uppercase"
                onClick={() => handleSubmit()}
              >
                {t("myProfile.updateProfile")}
              </Button>
            </div>
            <Label className="-ml-6 text-primary font-bold text-lg mb-4">
              {t("myProfile.accountInfo")}
            </Label>
            <div className="grid grid-cols-1 gap-4 ">
              {loading ? (
                <Skeleton height={60} className="w-full  " />
              ) : (
                <div className="lg:flex gap-4">
                  <div className="block flex-1">
                    <TextFieldFormsy
                      className='bg-primary w-100 text-white'
                      id="outlined-basic"
                      label={t("myProfile.email")}
                      type="email"
                      name="email"

                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      variant="filled"
                      // value={form.email}
                      value={formattedEmail}
                      serverError={errors?.email}
                    />
                    {!errors?.email && (
                      <Typography
                        variant={"body1"}
                        component={"span"}
                        className="opacity-75 text-primary text-xs"
                      ></Typography>
                    )}
                  </div>
                  <div className="lg:text-center text-end my-2">
                    <Button
                      variant="contained"
                      className="text-base bg-primary text-white dark:bg-button font-normal uppercase"
                      onClick={() => {
                        setType("email");
                        handleSubmit('email');
                        setDisabled(true);
                      }}
                      disabled={disabled}
                    >
                      {t("myProfile.updateProfile")}
                    </Button>
                  </div>
                </div>
              )}
              <div className="lg:flex gap-x-3">
                <div className="flex-1">
                  <div className="grid grid-cols-2 gap-4">
                    {loading ? (
                      <Skeleton height={60} className="w-full pl-7" />
                    ) : (

                      <Formsy className="pl-7">
                        <SelectFormsy
                          variant="filled"
                          value={form?.dialing_code?.id}
                          label="Select Dialing Code"
                          disabled={true}
                          isReadOnly={true}
                          className='bg-primary w-100 text-white'
                          name="dialing_code"
                          onChange={(name, e) => {
                            handleChangerForDialingCode(name, e);
                          }}
                        >
                          {formatedDialingCode.map((ele) => (
                            <MenuItem value={ele.id}>{ele.name}</MenuItem>
                          ))}
                        </SelectFormsy>
                        {/* <Typography variant={'body1'} component={'span'} className="opacity-75 text-primary text-xs">
                      {t("myProfile.contactMessage")}
                    </Typography> */}
                      </Formsy>
                    )}
                    {loading ? (
                      <Skeleton height={60} className="w-full " />
                    ) : (
                      <div className="block">
                        <TextFieldFormsy
                          id="outlined-basic"
                          label={t("myProfile.phone")}
                          variant="filled"
                          name="phone"
                          className='bg-primary text-white opacity-80 w-100'
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type="text"

                          style={{ cursor: "not-allowed" }}
                          // disabled={true}
                          serverError={errors?.phone}
                          value={formattedNumber}
                        />
                        {/* <Typography variant={'body1'} component={'span'} className="opacity-75 text-primary text-xs">
                      {t("myProfile.contactMessage")}
                    </Typography> */}
                      </div>
                    )}
                  </div>
                </div>
                <div className="lg:text-center text-end my-2">
                  <Button
                    variant="contained"
                    className="my-2 ml-1 text-base bg-primary text-white dark:bg-button font-normal uppercase"
                    onClick={() => {
                      setType("phone");
                      handleSubmit('phone');
                      setDisabled(true);
                    }}
                    disabled={disabled}
                  >
                    {t("myProfile.updateProfile")}
                  </Button>
                </div>
              </div>
            </div>
            <Label className="-ml-6 text-primary font-bold text-lg mb-4">
              {t("myProfile.changePassword")}
            </Label>
            <div className="grid grid-cols-1 gap-4 ">
              {loading ? (
                <Skeleton height={60} className="w-full  " />
              ) : (
                <div className="gap-4">
                  <div className="flex-1">
                    <TextFieldFormsy
                      className='bg-primary w-100 text-white'
                      id="outlined-basic"
                      label={t("myProfile.password")}
                      type="password"
                      name="oldPassword"

                      onChange={(e) => {
                        handlePassword(e);
                      }}
                      variant="filled"
                      value={passwordData.oldPassword}
                    />
                    <FormHelperText>{passwordError.current_password}</FormHelperText>
                    <TextFieldFormsy
                      className='bg-primary w-100 mt-4 text-white'
                      id="outlined-basic"
                      label={t("myProfile.newPass")}
                      type="password"
                      name="newPassword"

                      onChange={(e) => {
                        handlePassword(e);
                      }}
                      variant="filled"
                      value={passwordData.newPassword}
                    />
                    <FormHelperText>{passwordError.new_password}</FormHelperText>
                    <TextFieldFormsy
                      className='bg-primary w-100 mt-4 text-white'
                      id="outlined-basic"
                      label={t("myProfile.confirmPass")}
                      type="password"
                      name="confirmPassword"

                      onChange={(e) => {
                        handlePassword(e);
                      }}
                      variant="filled"
                      value={passwordData.confirmPassword}
                    />
                    {confirmPasswordError && <FormHelperText> {confirmPasswordError} </FormHelperText>}
                  </div>
                  <div className="lg:text-center text-end flex justify-end mt-6 ">
                    <Button
                      variant="contained"
                      className="text-base bg-primary text-white dark:bg-button font-normal uppercase"
                      onClick={() => {
                        updatePassword();
                      }}
                      disabled={disabled}
                    >
                      {t("myProfile.updateProfile")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
