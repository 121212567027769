import Formsy from "formsy-react";
import React, { useState } from "react";
import AutoCompleteFormsy from "../../../core-components/AutoCompleteFormsy";
import { useTranslation } from "react-i18next";
import Datepiker from "../../../components/common/Datepiker";

const Filter = ({rowPerpage, type, onPropsReceiveChange, statusValue}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState();
  const [lastDate, setLastDate] = useState();
  const rows = [
    { id: 25, name: "25" },
    { id: 50, name: "50" },
    { id: 100, name: "100" },
    { id: 125, name: "125" },
  ];
  return (
    <div className="md:flex md:justify-between  py-5">
      <div className="flex gap-4 ">
        <AutoCompleteFormsy
          className="md:w-48 py-1 w-1/2 rounded-none  text-fontDark"
          name="status"
          options={type}
          variant="outlined"
          value={statusValue}
          label={` ${t("affiliateDashboard.status")}`}
          placeholder={` ${t("affiliateDashboard.status")}`}
          onSelectValueChange={onPropsReceiveChange}
        />
        {/* <div className="md:w-44">
          <Datepiker
            placeholderText="From :"
            setDate={setStartDate}
            startDate={startDate}
          />
        </div>
        <div className="md:w-44">
          <Datepiker
            placeholderText="To :"
            setDate={setLastDate}
            startDate={lastDate}
          />
        </div>
        <button className="px-5 my-2 w-20 bg-primary  text-gray-900 ">
          Filter
        </button> */}
      </div>
      <div className="my-2 flex justify-end md:my-auto">
        <div className="my-auto"> Item per Page : </div>
        <AutoCompleteFormsy
          className="w-20 rounded-none bg-whiteBackground text-fontDark"
          name="rowPerpage"
          options={rows}
          variant="outlined"
          value={rowPerpage}
          label={` ${t("affiliateDashboard.rowsPerpage")}`}
          placeholder={` ${t("affiliateDashboard.rowsPerpage")}`}
          onSelectValueChange={onPropsReceiveChange}
        />
      </div>
    </div>
  );
};

export default Filter;
