import {
  AppBar,
  DialogActions,
  DialogContent,
  DialogContentText,
  Toolbar,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import appConfig from "../appConfig";
import { ActionTypes } from "../redux/contants/ActionType";
import store from "../redux/store";
import Apiservice from "../services/apiService";
import Utils from "../services/Utils";



export default function AlertComponent({
  openAlertModal,
  handleAlertModalClose,


  productData,
  price,
  offerPrice,
  firstIndexArray,
  type,
}) {
  const session_id = Utils.generateGUID();
  const newSessionId = sessionStorage.getItem("session_id");
  const user_id = sessionStorage.getItem("user_id");

  const { t } = useTranslation();
  const deleteOtherCartItems = () => {
    // this function will add the current product in cart and remove all other products which were already in cart

    // type = myChats means we are coming to this Component from my chat component 
    // both my chats and without my chat logic and api is same but data is coming with different keys 
    const sendableSessionId = user_id !== 'null' && user_id !== null ? user_id : newSessionId !== null && newSessionId !== 'null ' ? newSessionId : session_id

    if (type == 'myChats') {
      const form = new FormData()
      form.append('message_id', productData.messageId)
      form.append('product_id', productData.product_id)
      form.append('session_id', sendableSessionId)
      form.append('orignal_price', productData.negotiatedPrice)
      form.append('offer_price', productData.negotiatedPrice)
      form.append('vendor_id', productData.vendor_id)
      form.append('quantity', '1')
      // function to remove other seller item from cart and add current product
      Apiservice.AddOtherSellerItemInCart(form).then((res) => {
        if (res.code === 200) {

          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: 1,
          });
        }

      })
    }
    else {
      if (newSessionId === null) {
        sessionStorage.setItem(appConfig.localSessionIdKey, session_id);
      }
      const _form = new FormData();
      _form.append("vendor_id", productData.vendor.id);
      _form.append("product_id", productData.id);
      _form.append("quantity", "1");
      _form.append("orignal_price", price);
      _form.append("offer_price", offerPrice);
      _form.append("session_id", sendableSessionId);
      // firstIndexArray contains all object with all attribute so that we can send attreibutes dynamically
      if (firstIndexArray !== undefined) {
        if (firstIndexArray.length > 0) {
          firstIndexArray.map((firstIndexArray) => {
            _form.append(
              `product_option[${firstIndexArray.attribute}]`,
              firstIndexArray.values
            );
          });
        }
      }
      // function to remove other seller item from cart and add current product
      Apiservice.AddOtherSellerItemInCart(_form).then((res) => {
        if (res.code === 200) {
          store.dispatch({
            type: ActionTypes.ADD_USER_CART_COUNT,
            payload: 1,
          });
          handleAlertModalClose();
        }
      });

    };


  }

  return (
    <>
      {/* this component will shown if user is trying to add product to cart  of another seller  */}

      <Dialog
        open={openAlertModal}
        onClose={handleAlertModalClose}
        // fullWidth
        // maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar
          position="static"
          className="bg-primary text-white"
          elevation={1}
        >
          <Toolbar className="flex w-full">
            <Typography variant={"subtitle1"} color="inherit">
              {t("alertComponent.alert")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className="dark:bg-lightDarkBackground" classes={{ root: "p-2" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="p-4 dark:text-fontWhite"
          >
            {t("alertComponent.otherSellerProductsinCart")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dark:bg-lightDarkBackground">
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={() => {
              // show is state for showing attribute modal because if show is not undefined means attribute modal is open so on click of cancle we have to close both the modal

              // for closing alerModal
              handleAlertModalClose();

            }}
            type="button"
          >
            {t("alertComponent.cancel")}
          </Button>
          <Button
            className="normal-case whitespace-no-wrap bg-primary text-white"
            variant="contained"
            onClick={deleteOtherCartItems}
            type="button"
          >
            {t("alertComponent.removeOtherAddThis")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
