import { Tooltip } from "@material-ui/core";
import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../utils/translations/i18n";

export default function FadeMenu({ language, setLanguage }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const intialLanguage =
      window.sessionStorage.getItem("language") || "English";
    setLanguage(intialLanguage);
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("language") !== language)
      window.sessionStorage.setItem("language", language);
    if (language == "Tamil") {
      i18n.changeLanguage("tam");
    } else if (language == "Hindi") {
      i18n.changeLanguage("hi");
    } else if (language == "Kannad") {
      i18n.changeLanguage("kan")
    } else if (language == "Telugu") {
      i18n.changeLanguage("tel")
    }
    else {
      i18n.changeLanguage("en");
    }
  }, [language]);

  const handleClose = (lang) => {
    setAnchorEl(null);
    if (lang == "Tamil") {
      setLanguage("Tamil");
    } else if (lang == "Hindi") {
      setLanguage("Hindi");
    } else if (lang == "Kannad") {
      setLanguage("Kannad");
    } else if (lang == "Telugu") {
      setLanguage("Telugu")
    } else if (lang = "English") {
      setLanguage("English")
    } else {
      setLanguage(sessionStorage.getItem('language'));
    }
  };

  return (
    <div className="h-100 w-full justify-center me-0 d-flex align-items-end">
      <span>
        <Tooltip title={t("header.language")}>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className=" flex flex-col-reverse sm:flex-row justify-end sm:gap-1 p-0 sm:mb-1"
          >
            <div className="text-xs mt-1 sm:mt-0 text-fontDark dark:text-fontWhite   sm:font-bold">
              {language[0] == "E" ? "EN" : language[0] == "T" ? "TAM" : language[0] == "H" ? "HI" : language[0] == "K" ? "KAN" : "TEL"}
            </div>
            <LanguageIcon className="text-fontDark dark:text-fontWhite  p-0" />
          </Button>
        </Tooltip>
        <div className="text-xs pl-3">Language</div>
      </span>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setLanguage("English");
            handleClose("English");
            window.location.reload(true);
          }}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Hindi");
            handleClose("Hindi");
            window.location.reload(true);
          }}
        >
          Hindi
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Tamil");
            handleClose("Tamil");
            window.location.reload(true);
          }}
        >
          Tamil
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Kannad");
            handleClose("Kannad");
            window.location.reload(true);
          }}
        >
          Kannad
        </MenuItem>
        <MenuItem
          onClick={() => {
            setLanguage("Telugu");
            handleClose("Telugu");
            window.location.reload(true);
          }}
        >
          Telugu
        </MenuItem>
      </Menu>
    </div>
  );
}
