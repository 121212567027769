import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function AccountMenu() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = window.location.href;
  return (
    <React.Fragment>
      <div className="leading-5 cursor-pointer flex gap-5 flex-nowrap w-full md:mx-auto overflow-x-auto border-b-1 border-gray-500 my-2 justify-between mb-8 pt-16">
        <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard",
            });
          }}
          className={`text-lg  uppercase  ${
            params.split("/")[4] === undefined
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
        {t("affiliateDashboard.dashboard")}
        </div>
        <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard/commission",
            });
          }}
          className={`text-lg cursor-pointer uppercase  ${
            params.split("/")[4] === "commission"
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
          {t("affiliateDashboard.commission")}
        </div>
        <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard/clicks",
            });
          }}
          className={`text-lg cursor-pointer uppercase  ${
            params.split("/")[4] === "clicks"
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
          {t("affiliateDashboard.visits")}
        </div>
        <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard/payments",
            });
          }}
          className={`text-lg cursor-pointer uppercase  ${
            params.includes("payments")
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
          {t("affiliateDashboard.payment")}
        </div>
        {/* <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard/generate-link",
            });
          }}
          className={`text-lg  uppercase  ${
            params.includes("generate")
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
          {t("affiliateDashboard.link")}
        </div> */}
        <div
          onClick={() => {
            history.push({
              pathname: "/affiliate-dashboard/settings",
            });
          }}
          className={`text-lg  uppercase  ${
            params.includes("settings")
              ? "text-fontDark font-medium border-b-2 border-collapse border-button pb-2"
              : "text-gray-600"
          }`}
        >
          {t("affiliateDashboard.settings")}
        </div>
      </div>
    </React.Fragment>
  );
}
