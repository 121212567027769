import { Card, Typography } from "@material-ui/core";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ForumIcon from "@mui/icons-material/Forum";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
export default function ProvidingFeatures(props) {
  const { t } = useTranslation();
  const { innerWidth } = window;
  return (
    <div className="mb-8 px-1 md:px-8 pt-4 bg-background dark:bg-darkBackground">
      {props.loading ?
        <div className="lg:grid-cols-4  grid grid-cols-1   gap-4   ">
          {[1, 2, 3, 4].map((ele, key) => (<Skeleton height={150} width={innerWidth < 100 ? innerWidth : 300} key={key} className='ml-4' />))}
        </div>

        : <div className="lg:grid-cols-4 grid grid-cols-1 sm:grid-cols-2 gap-2 md:gap-4">

          <>
            <Card className="grid justify-center bg-background dark:bg-lightDarkBackground mr-4 ml-10 lg:mx-0 lg:ml-6  shadow-inherit shadow-new-mobile shadow-new p-3 md:p-6">
              <LocalShippingIcon className="mx-auto w-10 h-10 md:w-12 md:h-12 text-center text-primary" />
              <Typography className="text-lg text-fontDark dark:text-fontWhite font-bold text-center ">
                {t("home.providingFeatures.i.main")}
              </Typography>
              <Typography
                variant="subtitle1"
                className="text-sm text-fontDark dark:text-fontWhite text-center font-extralight"
              >
                {t("home.providingFeatures.i.secondary")}
              </Typography>
            </Card>
            <div className="grid justify-center shadow-inherit shadow-new-mobile shadow-new dark:bg-lightDarkBackground shadow-inherit p-3 md:p-6 mr-4 ml-10 lg:mx-0">
              <AttachMoneyIcon className="mx-auto w-10 h-10 md:w-12 md:h-12 text-primary text-center" />
              <Typography className="text-lg text-fontDark dark:text-fontWhite font-bold text-center ">
                {t("home.providingFeatures.ii.main")}
              </Typography>
              <Typography
                variant="subtitle1"
                className="text-sm text-fontDark dark:text-fontWhite text-center font-extralight"
              >
                {t("home.providingFeatures.ii.secondary")}
              </Typography>
            </div>
            <div className="grid justify-center shadow-inherit shadow-new-mobile shadow-new dark:bg-lightDarkBackground shadow-inherit p-3 md:p-6 mr-4 ml-10 lg:mx-0">
              <ForumIcon className="mx-auto w-10 h-10 md:w-12 md:h-12 text-primary text-center" />
              <Typography className="text-lg text-fontDark dark:text-fontWhite font-bold text-center ">
                {t("home.providingFeatures.iii.main")}
              </Typography>
              <Typography
                variant="subtitle1"
                className="text-sm text-fontDark dark:text-fontWhite text-center font-extralight"
              >
                {t("home.providingFeatures.iii.secondary")}
              </Typography>
            </div>
            <div className="grid justify-center shadow-inherit shadow-new-mobile shadow-new  dark:bg-lightDarkBackground shadow-inherit p-3 md:p-6 mr-4 ml-10 lg:mx-0">
              <KeyboardIcon className="mx-auto w-10 h-10 md:w-12 md:h-12 text-primary text-center" />
              <Typography className="text-lg text-fontDark dark:text-fontWhite font-bold text-center ">
                {t("home.providingFeatures.iv.main")}
              </Typography>
              <Typography
                variant="subtitle1"
                className="text-sm text-center  text-fontDark dark:text-fontWhite font-extralight"
              >
                {t("home.providingFeatures.iv.secondary")}
              </Typography>
            </div>
          </>

        </div>}
    </div>
  );
}
