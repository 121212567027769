import React, { useEffect, useState } from "react";
import logo from "../assets/images/FashionLeoImages/fashioLeoLogo.png";
import { useHistory } from "react-router-dom";
import AppConfig from "../appConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
const NotFound = () => {
  const history = useHistory();
  const [level1, setLevel1] = useState([]);
  // getting categories from redux
  const field = useSelector(({ allCategories }) => allCategories.categories);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLevel1(field);
  }, [field]);
  return !field || !field.length > 0 ? (
    <Skeleton variant="rectangular" sx={{ width: "100%" }} height={500} />
  ) : (
    <div className="relative pt-3 pl-3 pr-3  flex flex-col lg:flex-row lg:pb-24 justify-center items-center bg-gray-300 w-100">
      <div className="hidden lg:flex w-100"></div>
      <div className="flex bg-white lg:fixed w-12/12 lg:w-5/12 mb-10 lg:mt-16 lg:rounded-3xl lg:left-20  flex-col items-center p-4 shadow-2xl border-gray-600 border-b-4">
        <img
          src={logo}
          className="w-20 h-auto"
        // style={{ filter: "brightness(80%)" }}
        />
        <div className="text-9xl mb-2">404</div>
        <div className="tracking-widest uppercase text-xl">
          {t("pageNotFound.oops")}
        </div>
        <div className="bg-gray-700 leading-6 shadow-lg w-full lg:w-10/12 p-2 tracking-wider uppercase lg:rounded-full mt-4 text-white text-center drop-shadow-xl text-xs font-light">
          {t("pageNotFound.unAvailable")}
        </div>
        <span
          className=" mt-4 uppercase underline cursor-pointer text-xs font-light text-black"
          onClick={() => {
            history.push(AppConfig.routes.home);
          }}
        >
          {t("pageNotFound.goToHome")}
        </span>
      </div>
      <div className="bg-gray-100 shadow-2xl w-full mb-24 lg:w-10/12 rounded-3xl lg:m-12 lg:mt-24 p-4 flex flex-col items-center">
        <div className="text-xl p-2 mb-4 rounded uppercase tracking-widest bg-button">
          {t("pageNotFound.tryExploring")}
        </div>
        <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 lg:gap-2 mx-auto w-100 items-center justify-center p-2 lg:p-10">
          {level1.map((el) => (
            <div
              className="flex mt-2 lg:m-2 text-primary py-2 items-center justify-center  bg-gray-200 duration-200 hover:shadow-xl hover:bg-gray-600 hover:text-white cursor-pointer rounded-full"
              onClick={() => {
                history.push({
                  pathname: `/shop/category-product/${el.slug}/${el.id}`,
                  state: {
                    id: el.id,
                    type: "Categories",
                  },
                });
              }}
            >
              {el.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
