import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function TablePaginationDemo({lastPage, page, setPage, rowPerPage, handleChangeRowsPerPage}) {
//   const [page, setPage] = React.useState(2);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  

  return (
    <TablePagination
      component="div"
      count={lastPage}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}