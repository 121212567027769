import React, { useState } from 'react'
import ApiService from './services/apiService';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core';
import ReactHtmlParser from "react-html-parser";

function ShippingPolicy() {
    const [cmsData, setCmsData] = useState()
  const themes = useSelector(({ allCategories }) => allCategories.theme);
    const useStyles = makeStyles((theme) => ({
        text: {
            "& h3": { color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505" },
            "& p": { color: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#fff" : "#050505" : themes === 'dark' ? "#fff" : "#050505" }
        }
    }));
    const classes = useStyles();

    React.useEffect(() => {
        ApiService.getShippingPolicy().then((res) => {
            if (res.code === 200) {
                // setRefferCodeMsg(res.data.is_valid)
                console.log(res.data.data.cms, "resss");
                setCmsData(res.data.data.cms)
            } else {
                console.log("error");
            }
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>{`Fashion Leo - ${cmsData?.slug}`}</title>
                <meta name="description" content={cmsData?.slug} />
            </Helmet>
            <div
                className="bg-contain bg-no-repeat h-40vh w-80vw text-center bg-center mx-auto my-7.5"
                style={{
                    backgroundImage: `url(${cmsData?.ImageSrc})`,
                }}
            ></div>
            <div className="w-80vw text-left my-7.5 mx-auto">
                <p className={classes.text}>{ReactHtmlParser(cmsData?.content)}</p>
            </div>
        </>
    )
}

export default ShippingPolicy