import { AppBar, Card, DialogActions, DialogContent, makeStyles, Toolbar } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Formsy from "formsy-react";
import * as React from "react";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import ApiService from "../../services/apiService";
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import store from "../../redux/store";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    "& > *": {
      // backgroundColor:"red",

      "& > *": {
        backgroundColor: "red",
      },
    },
  },
}));
export default function Review(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [allCouponCodes, setAllCouponCodes] = React.useState([]);
  const name = props.data.name;
  const [tooltipIsOpen, setToolTipIsOPen] = useState(false);
  const address = props.data.address;
  const pincode = props.data.pincode;
  const [key, setKey] = useState(-10);
  const [marginError, setMarginError] = useState('')
  const { t } = useTranslation();
  const [newLoading, setNewLoading] = useState(false);
  const [checkWallet, setCheckWallet] = useState(false);
  const user = useSelector(({ allCategories }) => allCategories.user);
  const wallletAMount = sessionStorage.getItem("wallet")
  const payment_method = props.data.payment_method;
  const serviceData = props.data.service_type;

  const [errorMessage, setErrorMessage] = useState({
    show: false,
    error: ""
  });
  const handleExpandChange = () => {
    setExpanded(!expanded);
  };

  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "2em",
          color: "yellow",
          backgroundColor: "red",
        },
      },
    },
  });
  const [marginAmount, setMarginAmount] = React.useState(0);
  const [marginPercent, setMarginPercent] = React.useState(0);
  React.useEffect(() => {
    setTimeout(() => {

      const element = document.querySelectorAll('.MuiInputBase-root.MuiOutlinedInput-root')
      const arr = [...element]
      arr.forEach((ele) => {
        ele.classList.add('dark:text-fontWhite')

        ele.classList.add('text-primary')
      })
      const filledsTextField = document.querySelectorAll('.MuiFilledInput-input')
      const filledsTextFieldArray = [...filledsTextField]
      filledsTextFieldArray.forEach((ele) => {
        ele.classList.add('dark:text-fontWhite')

        ele.classList.add('text-primary')
      })
      const inputBackground = document.querySelectorAll('.MuiFormControl-root')
      const inputBackgroundArray = [...inputBackground]
      inputBackgroundArray.forEach((ele, key) => {
        if (key !== 0) {
          ele.classList.add('dark:bg-lightDarkBackground')
          ele.classList.add('text-primary')
        }

      })
    }, 0);

  }, []);

  const [products, setProducts] = React.useState([]);
  // const handleChange = (e)=>{

  //   setMarginAmount(e.target.value)
  //   if(marginAmount<0)
  //     setMarginError("margin amount should be greater than 0")
  // else{

  //   setMarginError('')
  // }


  // }
  // below function is for margin functionality
  const handleChange = (e) => {
    if (e.target.name === "is_margin") {

      setForm({ ...form, [e.target.name]: e.target.checked });
      // props.handleReceiveProps({
      //   total_price: parseFloat(form.total_price) + parseFloat(e.target.value),
      //   original_price: form.original_price,
      //   discount_price: form.discount_price,
      //   is_margin: true,
      //   commission_total_price: commission_total_price,
      //   deliveryFee: deliveryFee,
      //   currency_type: form.currency_type,
      //   currency_id: form.currency_id,
      //   offerPrice:form.offerPrice,
      //   margin_price:form?.margin_price
      // });
    }
    else if (e.target.name === 'margin') {
      setMarginApplied(false)
      setMarginPercent(e.target.value);
      setForm({ ...form, ['margin_price']: e.target.value });
      // setCommisionTotalPrice(e.target.value )

      //  props.handleReceiveProps({
      //     total_price: parseFloat(form.total_price) + parseFloat(e.target.value),
      //     original_price: form.original_price,
      //     discount_price: form.discount_price,
      //     is_margin: form.is_margin,
      //     commission_total_price: commission_total_price,
      //     deliveryFee: deliveryFee,
      //     currency_type: form.currency_type,
      //     currency_id: form.currency_id,
      //     offerPrice:form.offerPrice,
      //     margin_price:parseFloat(e.target.value)
      //   });
      // if (e.target.value > marginPercent) {
      //   setMarginError(
      //     `Margin amount should be less than or equal to ${form.currency_type} ${commission_total_price}`
      //   );
      //   props.handleShow(true);
      // } else {
      //   setMarginError("");
      //   props.handleShow(false);
      // }
    }
    else if (e.target.name === "commission_total_price") {
      setForm({ ...form, [e.target.name]: e.target.value });
      // props.handleReceiveProps({
      //   total_price: parseFloat(form.total_price) + parseFloat(e.target.value),
      //   original_price: form.original_price,
      //   discount_price: form.discount_price,
      //   is_margin: true,
      //   commission_total_price: e.target.value,
      //   deliveryFee: deliveryFee,
      //   currency_type: form.currency_type,
      //   currency_id: form.currency_id,
      //   offerPrice:form.offerPrice
      // });


    } else {
      setForm({ ...form, [e.target.name]: e.target.value });

    }
  };
  const [form, setForm] = React.useState({
    is_margin: false,
    total_price: "",
    discount_price: "",
    coupon_detail: "",
    original_price: "",
    currency_type: "",
    currency_title: "",
    currency_id: "",
    offerPrice: "",
    margin_price: ''
  });
  const [errors, setErrors] = React.useState(null);
  const [commission_total_price, setCommisionTotalPrice] = useState(0);
  const id = sessionStorage.getItem("session_id");
  function subtotal(items) {
    return items
      ?.map((item) => item.offer_price * parseInt(item.quantity))
      .reduce((sum, i) => sum + i, 0);
  }
  function total_before_discount(items) {
    return items
      ?.map((item) => item.orignal_price * parseInt(item.quantity))
      .reduce((sum, i) => sum + i, 0);
  }
  let userData;

  const [deliveryFee, setDeliverFee] = React.useState(typeof props.data.service_type === 'string' ? 0 : Number(props.data.service_type.service_amount));
  const [showMarginField, setShowMarginField] = useState(false)
  const history = useHistory();
  const [order_discount, setOrderDiscount] = React.useState(0);
  const invoiceSubtotal = subtotal(products);
  const invoiceTotal = total_before_discount(products);
  const [chatModal, setChatModal] = useState(false);
  const [couponData, setCouponData] = React.useState([]);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [deliveryFeeData, setDeliveryFeeData] = React.useState();
  const [deliveryAmount, setDeliveryAmount] = React.useState()
  const [couponeTerms, setCouponeTerms] = useState([])
  const [termsId, setTermsId] = useState()

  const termsAndCondition = (data) => {
    setCouponeTerms(data.term_condition)
  }

  const handleClose = () => {
    setChatModal(false);
    setToolTipIsOPen(false);
  };

  React.useEffect(() => {
    ApiService.getConfig().then((res) => {
      setDeliveryFeeData(res.data)
      if (res?.data?.ADMIN_CART_VALUE > parseInt(invoiceSubtotal)) {
        setDeliveryAmount(res?.data?.FREE_DELIVERY_CHARGES)
      } else {
        setDeliveryAmount(0)
      }
    })
  }, [invoiceSubtotal])

  // apply coupon function
  const applyCoupon = () => {
    setRemoveCoupon(false);
    setNewLoading(true);

    const _form = new FormData();
    _form.append("coupon", form.coupon_code);
    // api for applying coupon codes
    ApiService.applyCouponCode(_form).then((res) => {
      if (res.code === 200) {
        setErrors({ ...errors, ["coupon_code"]: "" });
        setNewLoading(false);
        setChatModal(false);
        setCouponData(res.data.data);
        setCouponApplied(true);
        props.handleReceiveProps({
          total_price:
            res.data.data.coupon_detail.type === "Amount"
              ? parseFloat(form.total_price) -
              parseFloat(res.data.data.coupon_detail.credit)
              : parseFloat(form.total_price) -
              ((parseFloat(form.total_price) *
                parseFloat(res.data.data.coupon_detail.credit)) / 100),
          original_price: form.original_price,
          discount_price:
            res.data.data.coupon_detail.type === "Amount"
              ? parseFloat(res.data.data.coupon_detail.credit)
              : (parseFloat(form.total_price) *
                parseFloat(res.data.data.coupon_detail.credit)) /
              100,
          is_margin: form.is_margin,
          commission_total_price:
            commission_total_price
          ,
          coupon_data: res.data.data,
          deliveryFee: deliveryAmount,
          currency_type: form.currency_type,
          currency_title: form.currency_title,
          currency_id: form.currency_id,
          offerPrice: form.offerPrice,
          margin_price: form.margin_price
        });
        setForm({
          total_price:
            res.data.data.coupon_detail.type === "Amount"
              ? parseFloat(form.total_price) -
              parseFloat(res.data.data.coupon_detail.credit)
              : parseFloat(form.total_price) -
              ((parseFloat(form.total_price) *
                parseFloat(res.data.data.coupon_detail.credit)) / 100),
          original_price: form.original_price,
          discount_price:
            res.data.data.coupon_detail.type === "Amount"
              ? parseFloat(res.data.data.coupon_detail.credit)
              : (parseFloat(form.total_price) *
                parseFloat(res.data.data.coupon_detail.credit)) /
              100,
          is_margin: form.is_margin,
          commission_total_price:
            commission_total_price
          ,
          coupon_data: res.data.data,
          deliveryFee: deliveryAmount,
          currency_type: form.currency_type,
          currency_title: form.currency_title,
          currency_id: form.currency_id,
          offerPrice: form.offerPrice,
          margin_price: form.margin_price
        })
        if (res.data.data.coupon_detail.type === "Amount") {
          setOrderDiscount(parseInt(res.data.data.coupon_detail.credit));
        } else {
          setOrderDiscount(
            (parseInt(invoiceSubtotal) *
              parseInt(res.data.data.coupon_detail.credit)) /
            100
          );
        }
      } else {
        setNewLoading(false);
        setChatModal(false);
        setErrors({ ...errors, ["coupon_code"]: res.errors });
      }
    });
    setForm({ ...form, ["coupon_code"]: "" });
  };


  const [marginApplied, setMarginApplied] = useState(false);
  const [bankOffer, setBankOffer] = useState();
  const [removeCoupon, setRemoveCoupon] = useState(false);
  const [selectedCoupenId, setSelectedCoupenId] = useState(null);
  const [marginPercentage, setMarginPercentage] = useState();
  const [reCalculate, setReCalculate] = useState(false);
  const [flashSaleItem, setFlashSaleItem] = useState(false)
  var vendorId;
  React.useEffect(() => {
    // props.loading(newLoading);
    // get all coupon code api
    setReCalculate(false);
    ApiService.getCouponCodes().then((res) => {
      if (res.code === 200) {
        setAllCouponCodes(res.data.data);

      }
    });

    let original_price = 0;
    let total_price = 0;
    const _form = new FormData();
    _form.append("session_id", id);
    // get cart details api
    ApiService.getCartDetails(_form).then((res) => {
      setFlashSaleItem(res?.data[0]?.get_product_data?.is_flash_item)
      setProducts(res.data);
      props.setAllData(res.data)
      vendorId = res?.data[0]?.vendor_id
      const address_id = window.sessionStorage.getItem('address_id')
      let offerPrice = 0;
      res?.data?.map((ele, key) => {
        original_price = original_price +
          Number(ele.orignal_price) * Number(ele.quantity);

        offerPrice = offerPrice + Number(ele.offer_price) * Number(ele.quantity);
        total_price =
          offerPrice + Number(
            commission_total_price
          ) +
          Number(deliveryAmount) -
          Number(order_discount)
      });

      setForm({
        total_price: total_price.toFixed(2),
        original_price: original_price,
        discount_price: order_discount,
        is_margin: form.is_margin,
        commission_total_price: commission_total_price,
        deliveryFee: deliveryAmount,
        currency_type: res?.data[0]?.get_product_data?.currency_data.symbol,
        currency_title: res?.data[0]?.get_product_data?.currency_data.title,
        currency_id: res?.data[0]?.get_product_data?.currency_data.id,
        offerPrice: offerPrice
      })
      props.handleReceiveProps({
        total_price: total_price.toFixed(2),
        original_price: original_price,
        discount_price: order_discount,
        is_margin: form.is_margin,
        commission_total_price: commission_total_price,
        deliveryFee: deliveryAmount,
        currency_type: res?.data[0]?.get_product_data?.currency_data.symbol,
        currency_title: res?.data[0]?.get_product_data?.currency_data.title,
        currency_id: res?.data[0]?.get_product_data?.currency_data.id,
        offerPrice: offerPrice
      });
    });
  }, [reCalculate]);


  React.useEffect(() => {
    ApiService.getMargin().then(res => {
      setMarginPercentage(res?.data?.margin_percent);
      if (payment_method == "Online" && res?.data?.is_bankoffer_enabled === "true") {
        let Itemquantity = products.map((item) => item?.quantity)
        let bankOfferTotal;
        if (Itemquantity?.length > 1) {
          const Arraytotal = Itemquantity.reduce((acc, current) => acc + parseInt(current), 0)
          bankOfferTotal = Arraytotal * res?.data?.Bank_offer_amount
        } else {
          bankOfferTotal = Itemquantity * res?.data?.Bank_offer_amount
        }
        setBankOffer(bankOfferTotal)
      }
    })
  }, [bankOffer, invoiceSubtotal, order_discount, deliveryAmount])

  const [termsAndConditionForCouponCode, setTermsAndConditionForCouponCode] =
    useState("");

  const addMargin = () => {
    const data = form
    setMarginAmount(marginPercent);
    let _form = new FormData();

    if (marginPercent !== '') {
      _form.append('price', invoiceSubtotal);
      _form.append('margin', marginPercent)
      ApiService.calculateMargin(_form).then(res => {
        if (res.code === 200) {
          setMarginApplied(true);
          data.commission_total_price = form.margin_price;
          // ?  data.total_price = Number((Number(form.total_price) + Number(res.data.data)).toFixed(2))
          data.is_margin = true
          // setForm({ ...form, ['total_price']: Number(form.total_price) + Number(res.data.data) })
          setForm({ ...form, ['is_margin']: true })
          setForm({ ...form, ['margin_price']: marginPercent })
          setCommisionTotalPrice(form.margin_price);
          props.handleReceiveProps({
            total_price: parseFloat(form.total_price) + parseFloat(form.margin_price),
            original_price: form.original_price,
            discount_price: form.discount_price,
            is_margin: form.is_margin,
            commission_total_price: commission_total_price,
            deliveryFee: deliveryAmount,
            currency_type: form.currency_type,
            currency_title: form.currency_title,
            currency_id: form.currency_id,
            offerPrice: form.offerPrice
          });
          setForm({
            total_price: parseFloat(form.total_price) + parseFloat(form.margin_price),
            original_price: form.original_price,
            discount_price: form.discount_price,
            is_margin: form.is_margin,
            commission_total_price: commission_total_price,
            deliveryFee: deliveryAmount,
            currency_type: form.currency_type,
            currency_title: form.currency_title,
            currency_id: form.currency_id,
            offerPrice: form.offerPrice
          });
          // props.handleReceiveProps(data);
          setMarginError('')
        } else {

          setMarginError(`${t('review.marginError')} - ${form.currency_type} ${res.data}`)
        }
      })
    }

  }

  const [totalPrice, setTotalPrice] = useState()
  const [totalWallet, setTotalWallet] = useState(0)

  React.useEffect(() => {
    let totalAmount = ((Number(invoiceSubtotal.toFixed(2)) +
      Number(commission_total_price) +
      Number(deliveryAmount) -
      Number(order_discount)).toFixed(2))

    if (checkWallet) {
      if (Number(totalAmount) < Number(wallletAMount)) {
        let finalWalletTotal = Number(wallletAMount) - Number(totalAmount)
        setTotalWallet(finalWalletTotal)
        setTotalPrice(0)
        props.setTotalAmount(0)
        props.setWalletAmount(totalAmount)
      } else {
        let finalTotal = Number(totalAmount) - Number(wallletAMount)
        setTotalPrice(finalTotal)
        setTotalWallet(0)
        props.setWalletAmount(wallletAMount)
      }
    } else {
      setTotalPrice(totalAmount)
      props.setWalletAmount(0)
    }

  }, [invoiceSubtotal, order_discount, deliveryAmount, commission_total_price, checkWallet])

  // (checkWallet && wallletAMount)

  // const setCheck = (e) => {
  //   console.log(e.target.checked, "check");
  // }

  return (
    // below is choose coupon popup
    <MuiThemeProvider theme={theme}>
      {newLoading ?
        <div className="text-center">
          <Typography variant="h6" gutterBottom className="px-2 text-center dark:text-fontWhite">
            {t("review.message")}.
          </Typography>
          <CircularProgress style={{
            width: '50px',
            height: '50px',
            margin: '100px',
            marginTop: '20px',
            color: '#597593'
          }} />
        </div>
        :
        <React.Fragment>
          {errorMessage.show === true ?
            <Typography variant="h6" gutterBottom className="px-2 my-20 text-center dark:text-fontWhite">
              {errorMessage.error}
            </Typography>

            :
            <React.Fragment>
              <Dialog
                className="w-auto p-5 h-500"
                onClose={handleClose}
                open={chatModal}
              > <AppBar position="static" elevation={1} className='font-bold bg-button dark:text-fontWhite  text-primary'>
                  <Toolbar className="flex w-full">
                    <Typography component={'span'} variant={'body2'} className='font-bold bg-button dark:text-fontWhite  text-white'>
                      {t("review.applyCoupen")}
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent className="bg-login dark:bg-lightDarkBackground">
                  <div className="mt-2 mb-4 w-auto h-auto p-5">
                    {allCouponCodes.length > 0 &&
                      allCouponCodes.map((ele, index) => (
                        <div>
                          <div className={`cursor-pointer w-full p-4 shadow-md justify-between sm:flex-row flex-column d-flex rounded-md bg-primary mt-4 mb-1 dark:bg-whiteBackground ${selectedCoupenId === ele.id ? 'border-4 border-secondary' : ''}`} onClick={() => {
                            setForm({ ...form, ["coupon_code"]: ele.coupon_code });
                            setTermsAndConditionForCouponCode(ele.term_condition);

                            setRemoveCoupon(true);
                            setSelectedCoupenId(ele.id);
                          }}>
                            <div className="sm:w-50 w-full">
                              <h2
                                className=" inline cursor-pointer text-bolder my-2 border-dashed border-2 border-lightDarkBackground dark:border-primary dark:text-fontDark text-login p-1"

                              >
                                {" "}
                                {ele.coupon_code}{" "}
                              </h2>
                              <p className="text-xs my-2 dark:text-fontDark text-login">
                                {" "}
                                {t("review.minimumOrderValue")} {ele.min_order_value}{" "}
                              </p>
                              {ele.type == "Percentage" ?
                                <p className="text-xs my-2 dark:text-fontDark text-login">
                                  {" "}
                                  {t("review.maimumDiscountIS")} {ele.maximum_discount}{" "}
                                </p> : false}
                            </div>
                            <div>
                              <p className=" my-2 dark:text-fontDark text-login">{t('review.valid')} : {ele.valid_till}</p>

                              <Tooltip
                                open={tooltipIsOpen && key == index}
                                onClose={() => setToolTipIsOPen(false)}
                                title={
                                  <div
                                    className="bg-secondary dark:text-fontDark text-login p-2.5"
                                    style={{
                                      transform: "scale(1.1)",
                                    }}
                                  >
                                    {" "}
                                    <span className="text-xs dark:text-fontDark text-login" onClick={() => { }}>
                                      {" "}
                                      {ReactHtmlParser(ele.term_condition)}{" "}
                                    </span>{" "}
                                  </div>
                                }
                              >
                                <a href>
                                  {" "}
                                  <p
                                    onClick={() => {
                                      setToolTipIsOPen(true);
                                      setKey(index);
                                    }}
                                    className="text-red-500"
                                  >
                                    {" "}
                                  </p>{" "}
                                </a>
                              </Tooltip>

                            </div>
                          </div>
                          <div className="text-xs"
                            onClick={() => {
                              termsAndCondition(ele)
                              setTermsId(ele.id)
                            }}>Terms & Condition</div>
                          <div>
                            {ele.id === termsId &&
                              <div className="text-xs">{ReactHtmlParser(ele.term_condition)}</div>
                            }
                          </div>
                        </div>
                      ))}
                  </div>
                </DialogContent>
                <DialogActions className="bg-login dark:bg-lightDarkBackground">
                  <Button
                    className={`text-sm mt-4 font-normal uppercase bg-button dark:text-login border-2`}
                    variant="contained"
                    onClick={() => {
                      // setForm({ ...form, ["coupon_code"]: "" });
                      // setSelectedCoupenId(null)
                      // setCouponApplied(false);
                      // setCouponData(null);
                      // setOrderDiscount(0)
                      setChatModal(false);
                    }}
                  >
                    {t("review.remove")}
                  </Button>
                  <Button
                    className={`text-sm mt-4 font-normal uppercase bg-button text-white border-2 ${selectedCoupenId === null ? 'opacity-70 cursor-not-allowed' : ''}`}
                    variant="contained"
                    disabled={selectedCoupenId === null ? true : false}
                    onClick={() => applyCoupon()}
                  >
                    {t("review.applyCoupon")}
                  </Button>

                </DialogActions>
              </Dialog>

              <div className="grid mx-0 lg:grid-cols-2 gap-5 sm:px-6 grid-cols-1">
                <div className="w-full flex flex-col overflow-y-scroll productCard   rounded-2xl ">
                  <div className="border-2 bg-white shadow-md rounded-xl mb-5 p-4 flex justify-between">
                    <div>Payment Method : </div>
                    <div>
                      {props.method === "Online" ? "Online" : "Cash on delivery"}
                    </div>
                  </div>

                  {products.length > 0 &&
                    products?.map((row) => (
                      <div className="w-full grid grid-cols-4 mb-4 gap-2 dark:bg-lightDarkBackground sm:border-2 sm:border-gray-300 rounded-2xl shadow-lg shadow-black py-4 px-4 pb-2 m-0">
                        <div className="">
                          <img
                            src={row.get_product_data.ImageSrc}
                            className="w-36 h-36 rounded-xl border-2 border-gray-500 object-contain"
                            alt="product_image"
                          />
                        </div>
                        <div className='col-span-3'>
                          <div
                            onClick={() => {
                              history.push({
                                pathname: `/product-details/${row.get_product_data.slug}/${row.get_product_data.id}`,
                                state: {
                                  id: row.get_product_data.id,
                                  type: "OrderCheckout",
                                },
                              });
                            }}
                            className=" cursor-pointer w-full flex"
                          >
                            <div className="   text-sm sm:text-xl lg:text-sm  w-full text-button underline mb-2  uppercase tracking-wider font-extrabold">
                              {row.get_product_data.product_name}
                            </div>
                          </div>
                          <div className="flex gap-2 col-span-2 items-center">
                            <div className="text-sm flex-1 tracking-wider font-bold dark:text-fontWhite text-gray-700">
                              {t("review.originalPrice")}
                            </div>
                            <div className="text-sm flex-1 font-bold dark:text-fontWhite text-danger">
                              {form.currency_type}{" "}
                              {Number(row.orignal_price).toFixed(2)}
                            </div>
                          </div>
                          <div className="flex gap-2 col-span-2 items-center">
                            <div className="text-sm flex-1 tracking-wider dark:text-fontWhite font-bold text-gray-700">
                              {t("review.offerPrice")}
                            </div>

                            <div className="text-sm flex-1 font-bold dark:text-fontWhite text-success">
                              {form.currency_type}{" "}
                              {Number(row.offer_price).toFixed(2)}
                            </div>
                          </div>
                          <div className="flex col-span-2 items-center gap-2">
                            <div className="font-bold  dark:text-fontWhite tracking-wider flex-1 text-sm text-gray-700">
                              {t("review.Qty")}
                            </div>

                            <div className="text-xs dark:text-fontWhite sm:text-sm text-muted flex-1 font-bold">
                              {row.quantity}
                            </div>
                          </div>
                          <div className="flex col-span-2 items-center gap-2">
                            <div className="font-bold dark:text-fontWhite tracking-wide flex-1 text-sm text-gray-700">
                              {t("review.subTotal")}
                            </div>

                            <div className="text-sm underline dark:text-fontWhite flex-1 font-bold text-success">
                              {form.currency_type}{" "}
                              {(Number(row.quantity) * row.offer_price).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="">

                  <div className="w-full lg:flex flex-col lg:m-auto lg:p-auto m-0 p-0  gap-2">

                    <div
                      onClick={() => {
                        !flashSaleItem && setChatModal(true);
                      }}
                      className="flex justify-between cursor-pointer shadow-lg sm:border-2 sm:border-gray-300  dark:bg-lightDarkBackground rounded-xl  lg:px-3 p-4 h-15 lg:w-full"
                    >
                      <h1 className="dark:text-fontWhite">{t("review.chooseCoupon")}</h1>
                      <div>{couponData.length > 0 && couponData.coupon_code + "coupon apply"} </div>
                      <LocalOfferIcon className="float-right dark:text-fontWhite mr-4 lg:mr-0" />
                    </div>
                    {flashSaleItem && <div className='text-left pl-2 text-sm text-primary pb-2'>Note: Coupon not applicable in case of flash sale products.</div>}


                    <div className="flex flex-col cursor-pointer shadow-lg sm:border-2 sm:border-gray-300  dark:bg-lightDarkBackground rounded-xl  lg:px-3 p-4 h-15 lg:w-full">
                      <div className="flex items-center justify-between">
                        <h1 className="dark:text-fontWhite">Wallet Balance</h1>
                        <div>₹{checkWallet ? (totalWallet < totalPrice ? totalWallet : bankOffer > 0 ? totalWallet + bankOffer : totalWallet) : wallletAMount}</div>
                        {/* <div>₹{checkWallet ? totalWallet + bankOffer : wallletAMount}</div> */}
                      </div>
                      {checkWallet ? <div className="flex items-center justify-between">
                        <div>Pay using your wallet balance</div>
                        <input type="checkbox" onChange={(e) => setCheckWallet(e.target.checked)} />
                      </div> :
                        totalPrice > 500 ?
                          <div className="flex items-center justify-between">
                            <div>Pay using your wallet balance</div>
                            <input disabled={flashSaleItem} type="checkbox" onChange={(e) => setCheckWallet(e.target.checked)} />
                          </div> :
                          <div className="text-primary text-sm">It can only be used if order total is greater than 500</div>
                      }
                    </div>
                    {flashSaleItem && <div className='text-left pl-2 text-sm text-primary'>Note: Wallet not applicable in case of flash sale products.</div>}

                  </div>

                  <div className="sticky top-20  sm:top-28 lg:top-32">

                    <div className="w-full mb-2 mt-4 gap-1 p-8 dark:bg-lightDarkBackground py-4 sm:border-2 sm:border-gray-300 rounded-2xl shadow-lg flex flex-col m-0">
                      <div className="text-md  dark:bg-lightDarkBackground dark:text-button  tracking-widest uppercase w-full text-center font-bold pb-1">
                        {t('review.total')}
                      </div>
                      <div className="flex items-center">
                        <div className="font-bold dark:text-fontWhite flex-1 text-sm text-gray-700">
                          {t("review.productAmount")}
                        </div>

                        <div className="text-sm flex-1 dark:text-fontWhite text-right font-bold">
                          {form.currency_type} {invoiceTotal.toFixed(2)}
                        </div>
                      </div>
                      {marginApplied > 0 && (
                        <div className="flex col-span-2">
                          <div className="font-bold flex-1  text-sm dark:text-fontWhite text-gray-700">
                            {t("review.marginPrice")}
                          </div>

                          <div className="text-sm flex-1 text-right font-bold dark:text-fontWhite">
                            + {form.currency_type}{" "}
                            {Number(commission_total_price).toFixed(2)}
                          </div>
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="font-bold text-sm flex-1 dark:text-fontWhite text-gray-700">
                          {t("review.deliveryFee")}
                        </div>

                        <div
                          className={`text-sm flex-1 dark:text-fontWhite text-right font-bold }`}
                        >
                          + {form.currency_type} {deliveryAmount}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="font-bold flex-1 text-sm dark:text-fontWhite text-gray-700">
                          {t("review.offerDiscount")}
                        </div>

                        <div className="text-sm flex-1 text-right font-bold dark:text-fontWhite text-success">
                          - {form.currency_type}{" "}
                          {(invoiceTotal - invoiceSubtotal).toFixed(2)}
                        </div>
                      </div>

                      {bankOffer > 0 &&
                        <div className="flex items-center">
                          <div className="font-bold flex-1 text-sm dark:text-fontWhite text-gray-700">
                            {t("review.bankDiscount")}
                          </div>

                          <div className="text-sm flex-1 text-right font-bold dark:text-fontWhite text-success">
                            {form.currency_type} {bankOffer}
                          </div>
                        </div>}
                      {couponApplied > 0 && (<div className="flex items-center">
                        <div className="font-bold flex-1 text-sm dark:text-fontWhite text-gray-700">
                          {t("review.orderDiscount")}
                        </div>

                        <div className="text-sm flex-1 dark:text-fontWhite text-right font-bold text-success">
                          - {form.currency_type} {order_discount.toFixed(2)}
                        </div>
                      </div>)}

                      {checkWallet && (<div className="flex items-center">
                        <div className="font-bold flex-1 text-sm dark:text-fontWhite text-gray-700">
                          Wallet
                        </div>

                        <div className="text-sm flex-1 dark:text-fontWhite text-right font-bold text-success">
                          {wallletAMount}
                        </div>
                      </div>
                      )}

                      <div className="flex items-center bg-green-200 dark:bg-transparent">
                        <div className="font-bold flex-1 text-sm dark:text-fontWhite text-gray-700">
                          {t("review.youSave")}
                        </div>

                        <div className="text-sm flex-1 dark:text-fontWhite text-right font-bold text-success">
                          {form.currency_type}{" "}
                          {(Number((invoiceTotal - invoiceSubtotal).toFixed(2)) +
                            Number(order_discount)).toFixed(2)}
                        </div>
                      </div>
                      <hr className="border-fontDark" />

                      <Grid
                        item
                        container
                        direction="column"
                        xs={12}
                        sm={12}
                        className=""
                      >

                        <Grid container>
                          {/* <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              className="text-base font-bold  dark:text-button text-muted"
                              gutterBottom
                            >
                              {t("review.paymentMethod")}
                            </Typography>
                            <Typography
                              gutterBottom
                              className="font-bold text-success dark:text-fontWhite text-sm"
                            >
                              {payment_method}
                            </Typography>
                          </Grid> */}
                          <Grid item xs={12} className='text-right flex '>
                            <Grid item xs={6} className='text-right flex '>
                              <Typography
                                variant="h6"
                                className="text-base font-bold pr-6  dark:text-button text-muted"
                                gutterBottom
                              >
                                {t("review.total")}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} className='text-right flex justify-end '>
                              <Typography
                                className="underline dark:text-fontWhite text-success"
                                variant="subtitle1"
                                sx={{ fontWeight: 700 }}
                              >
                                {form.currency_type}{" "}
                                {/* {(Number(invoiceSubtotal.toFixed(2)) +
                                  Number(
                                    commission_total_price
                                  ) +
                                  Number(deliveryAmount) -
                                  Number(order_discount)).toFixed(2) -
                                  (checkWallet && wallletAMount)} */}

                                {totalPrice == 0 ? totalPrice : bankOffer > 0 ? (totalPrice - bankOffer).toFixed(2) : totalPrice}
                                {/* {checkWallet ? totalPrice : payment_method == "Online" ? bankOffer > 0 && (totalPrice - bankOffer)?.toFixed(2) : totalPrice} */}

                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div>{deliveryAmount !== 0 && <div className="text-primary text-sm pb-3 pl-2">Add more items upto Rs.1000 for Free Delivery</div>}</div>
                    <Card
                      container
                      className="grid shadow-lg dark:bg-lightDarkBackground mb-2 sm:border-gray-300 sm:border-2 rounded-xl shadow-black px-4 pt-2 grid-cols-1 mx-1"
                    >
                      <Grid className=" px-4 py-2" item xs={12} sm={12} >
                        <Typography
                          variant="h6"
                          gutterBottom
                          className="text-button w-full mb-0  dark:text-button font-bold"
                        >
                          {t("review.shipping")}
                        </Typography>
                        <Typography
                          gutterBottom
                          className="text-base mt-1 font-bold dark:text-fontWhite text-black"
                        >
                          {name}
                        </Typography>
                        <Typography gutterBottom className="text-sm dark:text-fontWhite tracking-wide">
                          {address}, {pincode}
                        </Typography>
                      </Grid>

                    </Card>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        </React.Fragment>}
    </MuiThemeProvider>
  );
}
