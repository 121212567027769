import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";
import _ from "../utils/@lodash";
const doc = "assets/images/examples/docp.png";
const useStyles = makeStyles((theme) => ({
  productImageUpload: {
    transitionProperty: "box-shadow",
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  pointerEvents: { pointerEvents: "none !important" },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
    align: "right",
  },
}));

// uploading a image component
function ImageUpload(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [sizeError, setSizeError] = useState(false);
  const importedProps = _.pick(props, [
    "onChange",
    "onFileChange",
    "name",
    "id",
    "placeholder",
    "src",
    "value",
    "required",
    "options",
    "errorMessage",
    "variant",
  ]);

  // on change when we select different file
  function onChildChange(e, name) {
    if (e.target.files[0].size <= 8388608) {
      if (typeof e.target.files[0] !== "undefined") {
        if (/\d/.test(props.name)) {
          let number = props.name.match(/\d+/)[0];
          props.onFileChange(
            URL.createObjectURL(e.target.files[0]),
            e.target.files[0],
            name,
            number
          );
        } else {
          props.onFileChange(
            URL.createObjectURL(e.target.files[0]),
            e.target.files[0],
            name
          );
        }
      }
    } else {
      setSizeError(true);
      // store.dispatch(showMessage({ message: 'Size must be smaller than 8 MB.', variant: 'error' }));
    }
  }

  const removeImage = (name) => {
    if (/\d/.test(props.name)) {
      let number = props.name.match(/\d+/)[0];
      props.removeImg("", "", name, number);
    } else {
      props.removeImg("", "", name);
    }
  };

  return (
    <FormControl
      error={Boolean(
        (!props.isPristine && props.showRequired) || props.serverError
      )}
      className={clsx(
        props.className,
        "z-10",
        props.showRequired ? "required" : "",
        props.showError ? "error" : null
      )}
      variant={importedProps.variant}
      enter={{
        animation: "transition.slideUpBigIn",
      }}
    >
      <Typography gutterBottom variant="subtitle1">
        {props.label}
      </Typography>
      <label
        id={props.name}
        htmlFor={props.id}
        className={clsx(
          classes.productImageUpload,
          "flex items-center border-2 border-gray-300 dark:border-0 w-64 h-64 justify-center relative  rounded-3xl  mb-1 overflow-hidden cursor-pointer shadow-1 hover:shadow-5",
          props.serverError ? "border-red-800" : ""
        )}
      >
        {typeof props.remove !== undefined && props.remove === false ? (
          ""
        ) : (
          <input
            name={props.name}
            // accept="image/*"
            accept={props.accept}
            className="invisible w-0"
            id={props.id}
            type="file"
            data-id={props.name}
            onChange={(e) => onChildChange(e, props.name)}
          />
        )}
        {props.src && (
          <React.Fragment>
            {props.inputType === "image" && (
              <React.Fragment>
                <img
                  className="w-auto h-full max-w-none "
                  value={props.value}
                  id={props.name}
                  name={props.name}
                  src={props.src ? props.src : 'https://www.lifewire.com/thmb/blKERZhp27lzE_9SjqlnovU0v-s=/1768x1326/smart/filters:no_upscale()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg'}
                />
              </React.Fragment>
            )}
            {props.inputType === "doc" && (
              <React.Fragment>
                <img
                  className="w-auto h-full max-w-none "
                  value={props.value}
                  id={props.name}
                  name={props.name}
                  src={doc}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {!props.src && (
          <Icon fontSize="large" color="action">
            cloud_upload
          </Icon>
        )}
      </label>
      {props.name.includes('profileImage')&&(
          <Label className=" text-primary  text-md ">
          {t("myProfile.image")}
        </Label>
      )}
      {sizeError && (
        <FormHelperText className="text-red-700">
          Size must be smaller than 8 MB.
        </FormHelperText>
      )}
      {props.serverError && (
        <FormHelperText className="text-red-700">
          {props.serverError}
        </FormHelperText>
      )}
    </FormControl>
  );
}
export default React.memo(ImageUpload);
