import React, { useEffect } from "react";
import AccountMenu from "./AccountMenu";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { AppBar, Button, Dialog, DialogContent, IconButton, Toolbar, Typography,
} from "@mui/material";
import TextFieldFormsy from "../../../core-components/TextFieldFormsy";
import { useState } from "react";
import apiService from "../../../services/apiService/apiService";
import MenuIcon from '@mui/icons-material/Menu';
import moment from "moment";
import AppConfig from "../../../appConfig";
import TablePaginationDemo from "../../../components/common/Pagination";

const Payment = () => {
  const { t } = useTranslation();
  const [withdraw, setWithdraw] = React.useState(false);
  const [error, setError] = useState();
  const [payment, setPayment] = useState([])
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState({id:20, name:"20"})
  const [form, setForm] = useState({
    amount: "",
  });
  const [rowPerPage, setRowperPage] = useState(20)
  const type = [
    {id:'Pending', name:'Pending'},
    {id:'Accept', name:'Accept'},
    {id:'Reject', name:"Reject"},
  ]

  const [statusValue, setStatusvalue] = useState();

  const onPropsReceiveChange = (event, value) => {
    
    if(event === "rowPerpage"){
      setRowperPage(value)
    }
    else if(event === "status"){
      setStatusvalue(value);
    }
  };


  useEffect(() => {
    const _payout = new FormData()
    _payout.append("page", page)
    if(statusValue){
      _payout.append("type", statusValue?.id)
    }
    apiService.affiliatePayout(_payout).then((response) => {
      
      setPayment(response.data.data)
      setLastPage(response.data.data.last_page)
    })
  }, [statusValue])

  const handleClose = () => {
    setWithdraw(false);
  };

  const handleWithdrawRequest = () => {
   
    const _form = new FormData();
    _form.append("amount", form.amount);
    apiService.affiliateWithdrawReq(_form).then(() => {});
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowperPage(parseInt(event.target.value, 10));
    setPage(1);
  };


  return (
    <div>
      <Dialog
        open={withdraw}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            WithDraw Amount
          </Typography>
        </Toolbar>
      </AppBar>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div>
            <div>
              <TextFieldFormsy
                label={t("affiliate.amount")}
                name="amount"
                size="small"
                value={form?.amount}
                serverError={error?.amount}
                sx={{ background: "white" }}
                onChange={handleChange}
                className="w-44 mx-auto my-3 p-0 affiliate-formField text-fontWhite bg-whiteBackground"
                variant="outlined"
              />
            </div>
            <div>
              <Button className="border border-fontDark px-4 rounded-md p-1" onClick={handleWithdrawRequest}>
                withdraw
              </Button>
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
      <div className="ml-32 mt-10 p-5">
        <div className="text-3xl font-semibold text-black">
          {t("affiliateDashboard.payment")}
        </div>
      </div>
      <div className="md:w-2/3 w-11/12 mx-auto">
        <AccountMenu />
        <div className="my-10 flex justify-between">
          <div className="text-2xl font-semibold my-auto text-black">
            {t("affiliateDashboard.payment")}
          </div>
          <div
            onClick={() => {
              setWithdraw(true);
            }}
            className="my-auto border text-whiteBackground bg-primary p-2 rounded-md cursor-pointer text-xl"
          >
            Withdraw
          </div>
        </div>
        <div className="p-3 bg-blue-200 px-5">
          <span className="font-semibold text-fontDark"> Note: </span>{" "}
          commissions will be paid by a manager according to our internal
          schedule.
        </div>
        <Filter
          statusValue={statusValue}
          onPropsReceiveChange={onPropsReceiveChange}
          type={type}
          lastPage={lastPage}
          setRowperPage={setRowperPage}
          rowPerPage={rowPerPage}
        />
        <div className="relative  overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs border h-16 text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6  bg-gray-50 py-3">
                  Requested on
                </th>
                <th scope="col" className="px-6 bg-gray-50 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 bg-gray-50 py-3">
                  Paid on
                </th>
                <th scope="col" className="px-6 bg-gray-50 py-3"></th>
              </tr>
            </thead>
            <tbody>
            {payment?.data?.length > 0 ? (
                payment?.data?.map((ele) => {
                  const {amount, status, created_at, updated_at } = ele
                  return (
                    <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-3 border-r py-4 font-medium whitespace-nowrap dark:text-white"
                      >
                        {moment(created_at).format("ll")}
                      </th>
                      <td className="pl-6 border-r py-4">
                      {AppConfig.currencyType} {amount}
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="flex justify-between">
                          <div className="my-auto border-r"></div>
                        </div>
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="border-l-1 capitalize mx-2 px-8 py-2 text-fontWhite bg-red-600">
                          {status}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="bg-white border-1  border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                  <td colspan="4" className="pl-6 rounded-md border-r py-4 ">
                    <div className=" rounded py-2  text-center">
                      Sorry! No item Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <TablePaginationDemo
            lastPage={lastPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            setPage={setPage}
            rowPerPage={rowPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
