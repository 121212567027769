import { Typography } from "@material-ui/core";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import ApiService from "../services/apiService";

export default function Categories({ viewWidth }) {
  const { t } = useTranslation();

  // getting all Categories from redux
  // allCategories is our reducer and categories is one of the state in the reducer
  const field = useSelector(({ allCategories }) => allCategories.categories);
  const [categryValue, setCategryValue] = useState([])
  const [childData, setChildData] = useState([])
  const [parentId, setParentId] = useState()
  const history = useHistory();
  let settings;
  // if (field.length > 0) {
  //   settings = {
  //     dots: false,
  //     slidesToShow: viewWidth < 767 ? 2 : 4,

  //     autoplay: true,
  //     arrows: false,
  //     infinite: true,
  //     autoplaySpeed: 2000,
  //     responsive: [
  //       { breakpoint: 280, settings: { slidesToShow: 1 } },
  //       { breakpoint: 375, settings: { slidesToShow: 2 } },
  //       { breakpoint: 767, settings: { slidesToShow: 3 } },
  //       { breakpoint: 1024, settings: { slidesToShow: 4 } },
  //       { breakpoint: 1280, settings: { slidesToShow: 5 } },
  //       { breakpoint: 1300, settings: { slidesToShow: 5 } },
  //     ],
  //   };
  // }

  // useEffect(() => {
  //   const childArr = []
  //   for (let i = 0; i < field?.length; i++) {
  //     if (field[i]?.childrens?.length) {
  //       for (let j = 0; j < field[i]?.childrens?.length; j++) {
  //         if (field[i]?.childrens[j]?.childrens?.length) {
  //           for (let x = 0; x < field[i]?.childrens[j]?.childrens?.length; x++) {
  //             childArr.push(field[i]?.childrens[j]?.childrens[x]);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   setCategryValue(childArr)
  // }, [field])

  return (
    <div className="flex flex-col pl-7 sm:px-10 bg-white md:pb-12 pt-2">
      <Typography
        variant="subtitle1"
        className=" py-4 text-lg sm:text-2xl tracking-wider sm:tracking-normal items-start uppercase "
      >
        {t("home.categories.title")}
      </Typography>
      {/* <Slider {...settings} className="flex"> */}
      <div className="grid lg:grid-cols-8 md:grid-cols-5 grid-cols-3 lg:gap-x-4 md:gap-y-8 lg:gap-y-20 gap-2 justify-center pb-0 lg:pb-10">
        {field.length === 0
          ? [1, 2, 3, 4, 5, 6, 7, 8].map((ele, key) => (
            <Skeleton height={110} width={110} key={key} className="rounded-full mx-1" />
          ))
          :
          field.map((item, index) => {
            return (
              <div
                className="flex-wrap px-2 lg:px-8 lg:w-52 w-[3rem] h-28 lg:h-32 flex flex-col lg:flex-row items-center justify-center rounded-full"
                key={index}
              >
                <img
                  draggable={false}
                  className="cursor-pointer rounded-full w-20 h-20 md:w-full sm:border-2 sm:border-black p-1 
                              shadow-2xl shadow-black lg:h-36 md:h-32 object-cover"
                  alt="text"
                  src={item.ImageSrc}

                  onClick={(e) => {
                    history.push({
                      pathname: `/shop/category-product/${item.slug}/${item.id}`,
                      state: {
                        id: item.id,
                        // sub_id: item.id,
                        type: "Categories",
                        productName: item.name,
                      },
                    });
                  }}
                />
                <CardContent className=" cursor-pointer lg:mt-3 mt-1 font-normal ">
                  <div className=" cursor-pointer  text-sm font-normal text-black ">
                    {item.name}
                  </div>
                </CardContent>
              </div>
            )
          })

        }
      </div>
      {/* </Slider> */}
    </div>
  );
}
