const hi = {
  translation: {
    alertComponent: {
      otherSellerProductsinCart:
        "आपकी कार्ट में अन्य विक्रेताओं के उत्पाद हैं, कृपया कार्ट निकालें",
      alert: "चेतावनी",
      removeOtherAddThis: "अन्य वस्तुओं को हटा दें और इसे जोड़ें",
      cancel: "रद्द करना",
    },
    productcard: {
      addtowishlist: "इच्छा सूची में जोड़ें",
      removefromwishlist: "विशलिस्ट से निकालें",
    },
    header: {
      search: "खोज...",
      language: "भाषा",
      categories: "श्रेणियाँ",
      home: "घर",
      alert: "चेतावनी",
      stockNotAvailable: "स्टॉक उपलब्ध नहीं है ....",
      ok: "ठीक",
      profile: "प्रोफ़ाइल",
      myChats: "मेरी चैट",
      myFollowing: "मेरे फालोइंग",
      logout: "लॉग आउट",
      myCart: "मेरी कार्ट",
      myOrders: "मेरे ऑर्डर",
      wishlist: "इच्छा-सूची",
      notifications: "सूचनाएं",
      cart: "कार्ट",
      login: "लॉग इन करें",
      account: "खाता",
    },
    paymentForm: {
      paymentOptions: "डेबिट कार्ड, क्रेडिट कार्ड, नेट बैंकिंग, यूपीआई",
      cashOnDelivery: "डिलवरी पर नकदी",
      flashSaleNote: "ध्यान दें: फ्लैश सेल उत्पादों पर केवल ऑनलाइन भुगतान ही उपलब्ध है"
    },
    checkOutForm: {
      payNow: "अब भुगतान करें",
    },
    addressForm: {
      city: "शहर",
      close: "बंद करना",
      document: "सीपीएफ",
      state: "राज्य",
      locality: "इलाका",
      shippingAddress: "शिपिंग पता",
      address: "पता",
      addAddress: "नया पता जोड़ें",
      saveAddress: "सहेजें पता",
      editAddress: "पता संपादित करें",
      name: "नाम",
      mobile: "मोबाईल",
      landmark: "भूमि चिन्ह",
      pinCode: "पिन कोड",
      addressType: "पता मुद्रलेख",
      searchplace: "खोज स्थान ...",
      home: "घर",
      office: "कार्यालय",
      country: "देश",
      NearByLocation: "स्थान के पास",
      statecode: "राज्य संहिता",
      countrycode: "कंट्री कोड",
      confirmation: "पुष्टीकरण",
      confirmationMessage:
        "चूंकि आपका व्यवसाय पता देश कोड बदल दिया गया है, डिफ़ॉल्ट मुद्रा और पहले जोड़े गए उत्पादों को निष्क्रिय करने के लिए सेट किया जाएगा।कृपया अनुरोध की पुष्टि या रद्द करें.",
    },
    noFound: {},
    order: {
      shippingAddress: "शिपिंग पता",
      mobile: "मोबाईल",
      deliveryAt: "पर वितरण",
      orderDetails: "ऑर्डर का विवरण",
      qty: "क्यूटी",
      status: "दर्जा",
      paymentMethod: "भुगतान विधि",
      orderedOn: "पर आदेश दिया",
      customerDetails: "ग्राहक विवरण",
      trackOrder: "ऑर्डर पर नज़र रखना",
      backToOrder: "ऑर्डर पर वापस",
      orderAmount: "ऑर्डर करने की राशि",
      orderId: "ऑर्डर कामतत्व",
      more: "अधिक",
      discountAmount: "ऑर्डर छूट राशि",
      rating: "रेटिंग देना",
    },

    cancelOrder: {
      reasonForCancel: "निरस्तीकरण का कारण",
      reasonForReturn: "वापसी की वजह",
      orderCancellation: "ऑर्डर रद्द",
      orderCancel: "ऑर्डर रद्द करें",
      returnCancel: "ऑर्डर वापसी",
      cancelHeading: "वापसी अनुरोध रद्द करें?",
      returnRequestCancel: "हां, मैं रद्द करना चाहता हूं",
      continue: "जारी रखना",
      itemInfo: "वस्तु की जानकारी",
      qty: "क्यूटी",
      deliveryFee: "वितरण शुल्क",
      orderDiscount: "छूट का आदेश करें",
      productAmount: "उत्पाद राशि",
      price: "कीमत",
      comments: "टिप्पणियाँ",
      confirmMsg:
        "क्या आप सुनिश्चित हैं, आप इस ऑर्डर के लिए वापसी अनुरोध रद्द करना चाहते हैं?",
      feedback: "अपनी प्रतिक्रिया दें",
      feedbackMsg: "इस उत्पाद को आप कैसे रेट करेंगे?",
      submitRating: "जमा करना",
      feedbackComment: "अपनी समीक्षा जोड़ें",
    },
    footer: {
      quickShop: "त्वरित दुकान",
      informations: "जानकारी",
      customerService: "ग्राहक सेवा",
      contactUs: "संपर्क करें",
      supportChat: "समर्थन चैट",
    },
    mobileFooter: {
      home: "घर",
      category: "वर्ग",
      new: "नया",
      me: "मुझे",
      bag: "थैला",
      myOrders: "मेरे ऑर्डर",
      categoriesDrawer: {
        categories: "श्रेणियाँ",
      },
    },
    common: { addToCart: "कार्ट में जोड़ें" },
    card: {
      button: { addToCart: "कार्ट में जोड़ें", productDetail: "विवरण देखें" },
    },
    shop: {
      home: "घर",
      mobileDisplay: "मोबाइल प्रदर्शन",
      categoryHeading: "श्रेणियाँ",
      showingHeading: "दिखा",
      size: "आकार",
      price: "कीमत",
      brand: "ब्रैंड",
      discount: "छूट",
      andMore: "और अधिक",
      color: "रंग",
      filter: "फिल्टर",
      allFilter: "सभी फिल्टर",
      applyAllFilters: "सभी फिल्टर लागू करें",
      clear: "साफ़",
      more: "अधिक",
      productFilters: "उत्पाद फ़िल्टर",
      noFilters: "कोई फ़िल्टर नहीं",
      showingProducts: "उत्पाद दिखाना",
      products: "उत्पादों",
      of: "का",
      noProductFound: "कोई उत्पाद नहीं मिला",
      noProducts: "कोई उत्पाद नहीं",
    },
    productDetails: {
      all: "सभी समीक्षा देखें",
      one: "एक",
      two: "दो",
      three: "तीन",
      four: "चार",
      five: "पाँच",
      soldBy: "द्वारा बेचा",
      outOfStock: "स्टॉक ख़त्म",
      addToWishList: "इच्छा सूची में जोड़ें",
      viewShop: "दृश्य -दुकान",
      chatWithUs: "हमारे साथ चैट करें",
      ratings: "रेटिंग",
      followers: "समर्थक",
      products: "उत्पादों",
      productDetails: "उत्पाद विवरण",
      productRatingAndReviews: "उत्पाद रेटिंग और रिव्यूज",
      similarProducts: "इसी तरह के प्रॉडक्ट्स",
      reviews: "रिव्यूज",
      removeFromWishlist: "विशलिस्ट से निकालें",
      share: "शेयर करना",
      off: "छूट",
      youSave: "आप बचाते हैं",
      placeOrderPinCode: "पिनकोड दर्ज करें",
      check: "जाँच करना",
      checkDelivery: "डिलीवरी की जाँच करें",
      inputLabel: "कृपया डिलीवरी और सेवाओं की जांच के लिए पिन कोड दर्ज करें",
      deliveryDateOne: "आमतौर पर 7 दिनों में डिलिवरी हो जाती है।",
      deliveryDateTwo: "डिलीवरी पर भुगतान उपलब्ध है।",
      deliveryDateThree: "परेशानी मुक्त 7 दिन वापसी",
    },
    myOrders: {
      filters: "फिल्टर",
      orderStatus: "ऑर्डर की स्थिति",
      onTheWay: "रास्ते में",
      delivered: "पहुंचा दिया",
      cancelled: "रद्द",
      returned: "लौटा हुआ",
      orderTime: "ऑर्डर का समय",
      last30Days: "पिछले 30 दिनों में",
      processing: "प्रसंस्करण",
      older: "पुराने",
      soldBy: "द्वारा बेचा",
      viewOrder: "आर्डर देखें",
      cancelOrder: "ऑर्डर रद्द",
      returnOrder: "वापसी अनुरोध",
      returnCancelOrder: "वापसी अनुरोध रद्द करें",
      address: "पता",
      paymentDetail: "भुगतान विवरण",
      COd: "सीओडी",
      transactionId: "लेन -देन आईडी",
      myOrders: "मेरे ऑर्डर",
      filter: "फिल्टर",
      openMainMenu: "मुख्य मेनू खोलें",
      orderedOn: "पर आदेश दिया",
      walletAmount: "प्रयुक्त बटुआ",
      orderAmount: "ऑर्डर करने की राशि",
      qty: "qty",
    },
    myProfile: {
      profile: "प्रोफ़ाइल",
      alert: "चेतावनी",
      ok: "ठीक",
      updateProfile: "अद्यतन",
      firstName: "पहला नाम",
      city: "शहर",
      email: "मेल पता",
      phone: "फ़ोन नंबर",
      state: "राज्य",
      address: "पता",
      dialing_code: "कंट्री कोड",
      contactMessage: "फ़ोन नंबर बदलने के लिए समर्थन संपर्क करें",
      personalInfo: "व्यक्तिगत जानकारी",
      accountInfo: "खाता संबंधी जानकारी",
      alert: "ओटीपी सत्यापन",
      message: "OTP को आपके मोबाइल नंबर पर भेजा गया है",
      emailMessage: "OTP आपके ईमेल पर भेजा गया है",
      otpError: "आपके द्वारा प्रदान किया गया ओटीपी अमान्य या OTP की अवधि समाप्त हो गया है",
      image: "अनुशंसित आकार 500x500px",
      phoneError: "फोन नंबर पहले से मौजूद है",
      emailError: "ईमेल पहले से ही मौजूद है",
      changePassword: "पासवर्ड बदलें",
      password: "पुराना पासवर्ड",
      newPass: "नया पासवर्ड",
      confirmPass: "पासवर्ड की पुष्टि कीजिये",
      confirmPassError: "पुष्टि करें पासवर्ड मेल नहीं खाता है",
    },
    providingFeatures: {
      features: {
        i: {
          heading: "सुरक्षित भुगतान",
          subHeading:
            "दुनिया के सबसे सुरक्षित भुगतान विधियों के साथ भुगतान करें",
        },
        ii: {
          heading: "   आत्मविश्वास",
          subHeading: "संरक्षण में आपकी खरीद और व्यक्तिगत डेटा शामिल है",
        },
        iii: {
          heading: "पैन इंडिया डिलीवरी",
          subHeading:
            "200+ से अधिक देशों और राज्य के लिए नि: शुल्क और तेजी से शिपिंग।",
        },
        iv: {
          heading: "हॉटलाइन",
          subHeading:
            "4141 456 789, 4125 666 888 पर अपने प्रश्न के लिए मदद करने के लिए टॉक करें",
        },
      },
    },
    cartDetails: {
      product: "उत्पाद",
      quantity: "मात्रा",
      total: "कुल योग",
      price: "कीमत",
      shopNow: "अभी खरीदें",
      noItems: "कार्ट में कोई सामग्री नहीं है",
      checkout: "चेक आउट",
      subtotal: "उप कुल योग",
      discount: "डिस्काउंट ऑर्डर",
      shipping: "शिपिंग",
      yourCart: "आपकी कार्ट",
      addOnlyTenProducts: "आप केवल दस उत्पाद जोड़ सकते हैं",
      action: "कार्य",
      ok: "ठीक है",
      nocartitem: "कार्ट में कोई आइटम नहीं",
      cartEmpty: "आपकी कार्ट खाली है।",
      wishlistEmpty: "अपकि इच्छा सूची खाली है।",
    },

    checkout: {
      alert: "चेतावनी",
      ok: "ठीक है",
      checkout: "चेक आउट",
      refresh: "रिफ्रेश",
      orderProcessing: "आपका ऑर्डर भुगतान प्रसंस्करण है",
      thankYouForOrder: "ऑर्डर के लिए धन्यवाद",
      orderNumberIs: "ऑर्डर नंबर है",
      transactionIdIs: "लेन -देन आईडी है",
      continueShopping: "खरीदारी जारी रखें",
      back: "पीछे",
      next: "आगे बढ़े",
      placeOrder: "ऑर्डर देना",
      payNow: "अब भुगतान करें",
      shippingAddress: "शिपिंग पता",
      paymentMethod: "भुगतान विधि",
      orderSummary: "ऑर्डर सारांश",
    },
    review: {
      flashSaleCouponMsg : "ध्यान दें: फ्लैश सेल उत्पादों के मामले में कूपन लागू नहीं होगा।",
      flashSaleWalletMsg : "ध्यान दें: फ्लैश सेल उत्पादों के मामले में वॉलेट लागू नहीं है।",
      to: "को",
      days: "दिन",
      minimumOrderValue: "न्यूनतम ऑर्डर मूल्य है",
      maimumDiscountIS: "अधिकतम छूट है",
      orderSummary: "ऑर्डर सारांश",
      productName: "प्रोडक्ट का नाम",
      originalPrice: "असली कीमत",
      margin: "अंतर",
      offerPrice: "रखी गयी क़ीमत",
      orderAmount: "ऑर्डर करने की राशि",
      offerDiscount: "उत्पाद छूट",
      bankDiscount: "बैंक ऑफर",
      subTotal: "उप कुल योग",
      Qty: "qty",
      productAmount: "उत्पाद की कीमत",
      deliveryFee: "वितरण शुल्क",
      marginPrice: "मार्जिन मूल्य",
      orderDiscount: "कूपन छूट",
      orderTotal: "कुल आर्डर",
      youSave: "आप बचाते हैं",
      chooseCoupon: "कूपन चुनें",
      remove: "निकालना",
      applyCoupon: "कूपन के लिए आवेदन करो",
      youSaveAdditional: "आप अतिरिक्त बचत करते हैं",
      shipping: "शिपिंग पता",
      paymentMethod: "भुगतान विधि",
      total: "कुल योग",
      errorMessage: "कृपया मान्य पता चुनें",
      margin: "मार्जिन राशि दर्ज करें",
      message: "कूपन छूट की गणना करें",
      orderPlaceMessage: "आपका ऑर्डर प्रसंस्करण है ...",
      paymentMessage: "अपने कार्ड के साथ भुगतान करें ..",
      applyCoupen: "कूपन कोड लागू करें",
      sellingMessage: "उत्पाद को फिर से शुरू करना?",
      marginMessage: "अपना मार्जिन जोड़ने के लिए हां पर क्लिक करें",
      yes: "हाँ",
      close: "बंद करना",
      enterMargin: "मार्जिन राशि दर्ज करें",
      addMargin: "मार्जिन जोड़ें",
      marginError1: "कृपया मार्जिन पर दर्ज करें",
      marginError2: "कुल राशि का",
      couponApply: "कूपन एप्लाइड",
      valid: "तक मान्य",
      totalAmount: "कुल राशि",
      paymentdetails: "भुगतान विवरण",
      reviewOrder: "अपने आर्डर का पुनरीक्षण करे",
      online: "ऑनलाइन",
      deliveryServices: "वितरण सेवाओं का चयन करें",
      freeDeliveryMessage: "ऊपर के आदेशों पर",
      freeDeliveryNotMessage: "पात्र नहीं है",
      freeDelivery: "नि: शुल्क डिलिवरी",
      serviceSelectMessage: "कृपया वितरण सेवा का चयन करें",
      marginError: "मार्जिन राशि से कम या उससे कम होनी चाहिए",
    },

    reasons: {
      returnReasonOne: "दोष का टुकड़ा।",
      returnReasonTwo: "गलत उत्पाद प्राप्त किया।",
      returnReasonThree: "आवश्यक नहीं।",
      cancelReasonOne:
        "मैं उत्पाद की गुणवत्ता के मुद्दों के कारण रद्द करना चाहता हूं",
      cancelReasonTwo: "उत्पाद के लिए मूल्य कम हो गया है",
      cancelReasonThree: "अपेक्षित डिलीवरी का समय बहुत लंबा है",
      cancelReasonFour: "मैंने उत्पाद को कहीं और खरीदा है",
      cancelReasonFive: "मैं ऑर्डर के लिए पता बदलना चाहता हूं",
      cancelReasonSix: "मैंने अपना निर्णय बदल लिया है",
      cancelReasonSeven: "मैं अपने ऑर्डर को प्रीपेड में बदलना चाहता हूं",
      cancelReasonEight: "मैं अपना फोन नंबर बदलना चाहता हूं",
    },
    wishList: {
      addToCart: "कार्ट में जोड़ें",
      product: "उत्पाद",
      offerPrice: "रखी गयी क़ीमत",
      price: "कीमत",
      yourWishlist: "मेरी इच्छा सूची",
      addOnlyTenProducts: "आप केवल दस उत्पाद जोड़ सकते हैं",
    },
    attributeModal: {
      price: "कीमत",
      chooseOptions: "विकल्प चुनें",
      noProducts: "विशलिस्ट में कोई आइटम नहीं हैं",
      shopNow: "अभी खरीदें",
      addToCart: "कार्ट में जोड़ें",
      alert: "चेतावनी",
      ok: "ठीक",
    },
    chats: {
      chats: "चैट",
      noChats: "आपके पास कोई चैट नहीं है",
      noThanks: "जी नहीं, धन्यवाद",
      upgradeNow: "अभी अपग्रेड करें",
      makeAOffer: "एक प्रस्ताव देना",
      acceptOffer: "प्रस्ताव स्वीकार करें",
      am: "पूर्वाह्न",
      pm: "बजे",
      addToCart: "कार्ट में जोड़ें",
    },
    login: {
      loginWithPhone: "फोन के साथ लॉगिन करें",
      loginWithEmail: "ईमेल के साथ लॉगिन करें",
      login: "लॉग इन करें",
      newUser: "फ़ैशनलियो में नया? खाता बनाएँ",
      sendOtp: "OTP भेजें",
      signUp: "साइन अप फॉर्म",
      signUpButton: "साइन अप करें",
      or: "या",
      resendOtp: "OTP फिर से भेजे",
      forgotPassword: "पासवर्ड भूल गए",
      enterPhone: "अपना फोन नंबर डालें",
      dialingCode: "डायलिंग कोड",
      alreadyAccount: "क्या आपके पास पहले से एक खाता मौजूद है? लॉगिन करें",
      verify: "सत्यापित करना",
      signUp: "साइन अप करें",
      enterTheOtp: "कृपया ओटीपी दर्ज करें",
      password: "पासवर्ड दर्ज करे",
      emailPhone: "ईमेल/मोबाइल नंबर",
      email: "ईमेल दर्ज करें",
      name: "अपना पूरा नाम भरें",
      codeError: "कृपया देश डायलिंग कोड चुनें",
      phoneError: "कृपया मान्य मोबाइल नंबर दर्ज करें",
      termsError: "कृपया नियम और शर्तें स्वीकार करें।",
      termCondition: "मैं सभी नियमों और शर्तों को स्वीकार करता हूं।",
      termConditionOne: "मुझे सब स्वीकार है",
      termConditionTwo: "नियम और शर्तों",
      nameError: "नाम फ़ील्ड की आवश्यकता है",
      forgotMessage:
        "कृपया अपना पंजीकृत फोन दर्ज करें और हम आपको एक ओटीपी भेजेंगे",
    },
    pageNotFound: {
      oops: "उफ़! पेज नहीं मिला",
      unAvailable:
        "   जिस पृष्ठ को आप एक्सेस करने की कोशिश कर रहे हैं, वह हटा दिया गया हो सकता है, बदल गया है, या अनुपलब्ध है।",
      goToHome: "   मुखपृष्ठ प्र जाएं",
      tryExploring: "खोजने की कोशिश करें",
    },
    vendorDetails: {
      myFollowing: "मेरे फालोइंग",
      vendorDetails: "विक्रेता विवरण",
    },
    brands: {
      allbrands: "ब्रांडों द्वारा दुकान",
    },
    profileMenu: {
      profile: "प्रोफ़ाइल",
      myBankDetails: "मेरे बैंक विवरण",
      myEarnings: "मेरी कमाई",
      myReviews: "मेरी रिव्यूज",
      deleteAccount: "खाता हटा दो",
      myChats: "मेरी चैट",
      myOrders: "मेरे आर्डर",
      myCart: "मेरी कार्ट",
      myFollowing: "मेरे फालोइंग",
      manageAddress: "पता प्रबंधित करें",
      logout: "लॉग आउट",
    },
    deletePopup: {
      confirmToDelete: "पुष्टि करना",
      delAccount: "खाता हटा दो",
      delMsg:
        "आपके खाते को हटाकर निम्नलिखित जानकारी हटा दी जाएगी और इसे पूर्ववत नहीं किया जाएगा",
      msgOne: "व्यक्तिगत विवरण",
      msgTwo: "विक्रेता प्रोफ़ाइल (यदि उपलब्ध हो)",
      msgThree: "व्यक्तिगत दस्तावेज",
      msgFour: "पते",
      msgFive: "बैंक विवरण",
      delConfirm: "कृपया अपने खाते को हटाने के लिए CONFTION पर क्लिक करें?",
    },
    reviewTr: {
      title: "मेरी रिव्यूज",
      titleEarn: "मेरी कमाई",
      viewRec: "रसीद देखें",
      TransactionId: "लेन -देन आईडी",
      walletMsg: "अपना रेफ़रल कोड जनरेट करने के लिए पहला ऑर्डर पूरा करें और रेफ़रल आय शुरू करें।",
      totalEarn: "कुल कमाई",
      walletBalance: "वॉलेट बैलेंस",
      inviteMsg: "अपने दोस्त को FashionLeo के लिए आमंत्रित करें और उनके पहले सफल ऑर्डर पर कैशबैक पाएं। अधिक जानकारी के लिए नियम और शर्तें पढ़ें।",
      refferalCode: "रेफरल कोड",

    },
    chatPopUp: {
      actualPrice: "वास्तविक कीमत",
      makeAOffer: "एक प्रस्ताव बनाओ",
      am: "पूर्वाह्न",
      pm: "पीएम",
      acceptOffer: "प्रस्ताव स्वीकार करें",
      send: "भेजना",
      addToCart: "कार्ट में जोड़ें",
    },
    sortForm: {
      newArrivals: "नवागन्तुक",
      mostPopular: "सबसे लोकप्रिय",
      priceLowToHigh: "कीमतों का उतार - चढ़ाव)",
      priceHighToLow: "मूल्य (उच्च से निम्न",
      ratingHighToLow: "रेटिंग (उच्च से निम्न)",
      sort: "क्रम से लगाना",
    },
    myFollowing: {
      ratings: "रेटिंग",
      followings: "फॉलोइंग",
      products: "उत्पाद",
      allProducts: "सभी प्रोडक्ट",
      showing: "दिखा रहा है",
      follow: "अनुसरण करना",
      following: "अगले",
    },
    bankDetails: {
      title: "बैंक विवरण",
      bankName: "बैंक का नाम",
      accountNumber: "खाता संख्या",
      confirmAccountNumber: "खाता संख्या की पुष्टि करें",
      swiftCode: "स्विफ्ट कोड / रूटिंग संख्या",
      accountHolderName: "खाता धारक का नाम",
      bankCode: "बैंक का सांकेतिक अंक",
      branchCode: "शाखा क्र्मांक",
      accountType: "खाते का प्रकार",
      submit: "जमा करना",
      bankNameError: "बैंक का नाम आवश्यक है।",
      NameError: "खाता धारक का नाम आवश्यक है।",
      AccountError: "खाता संख्या आवश्यक है।",
      accountError: "खाता संख्या 6 से अधिक होनी चाहिए।",
      confirmAccountError: "खाता संख्या की पुष्टि करें।",
      ifscError: "स्विफ्ट कोड / रूटिंग नंबर की आवश्यकता है।",
      bankCodeError: "बैंक कोड की आवश्यकता है।",
      branchCodeError: "शाखा कोड की आवश्यकता है।",
      accountTypeError: "खाता प्रकार की आवश्यकता है।",
    },
    home: {
      recentProducts: "ताजा आगमन",
      messageText: "अपना संदेश टाइप करें",
      button: { viewAll: "सभी को देखें" },
      categories: { title: "श्रेणियाँ" },
      brand: { title: "ब्रांडों द्वारा खरीदारी करें" },
      fCollection: { title: "विशेष रुप से प्रदर्शित प्रोडक्टस" },
      flashSale: { title: "तेज़ बिक्री", left: "बाएं" },
      trProducts: { title: "ट्रेंडिंग प्रोडक्ट्स" },
      topProducts: { title: "शीर्ष उत्पाद" },
      nearbySellers: { title: "पास के विक्रेता" },
      recentViewProduct: {title: "हालिया दृश्य उत्पाद"},
      providingFeatures: {
        i: {
          main: "मुफ्त शिपिंग और वापसी",
          secondary: "पात्र आदेशों पर उपलब्ध है",
        },
        ii: {
          main: "मनी गारंटी",
          secondary: "30 दिन मनी बैक गारंटी",
        },
        iii: {
          main: "ऑनलाइन समर्थन",
          secondary: "हम दिन पर ऑनलाइन 24/7 का समर्थन करते हैं",
        },
        iv: {
          main: "सुरक्षित भुगतान",
          secondary: "सभी भुगतान सुरक्षित और विश्वसनीय हैं",
        },
      },
    },
    affiliate: {
      headingRegister: " Affiliate Dashboard",
      phone: "Phone",
      firstName: "Name",
      lastName: "Last Name",
      emailAddress: "Email Address",
      terms: " I have read and agree to the website terms and conditions",
      register: "Register",
      password: "Password",
      amount: "Amount"
    },
    affiliateDashboard: {
      dashboard: "Dashboard",
      commission: "Commission",
      visits: "Visits",
      payment: "Payment",
      link: "Link Generator",
      settings: "Settings",
      generateLinks: "Generate Link",
      directBank: "Direct bank/wire transfer",
      paymentInfo: "Payment info",
      status: "All status",
      rowsPerpage: "rows",
      urlTitle: "Your Referral URL is :",
      commissionHeading: "Recent Commissions",
    },
    affiliatePages: {
      recenteVisit: "Recent Visits ( View all )",
      affiliate: "Affiliate",
      totalEarn: "Total earnings",
      totalpaid: "Total paid",
      totalpaid: "Total refunded",
      balance: "Balance",
      commitssionRate: "Commission Rate",
      conversionRate: "Conversion rate",
      visits: "Visits",
      visitsToday: "Visits Today",
      date: "Date",
      product: "Product",
      orderNo: "Order No.",
      commissionPrice: "Commission Price",
      status: "Status",
    },
  },
};
export default hi;