import { Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { matchPath, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AppConfig from "../../../appConfig";
import SupporChat from "../../../Pages/supportChat/SupporChat";
import ApiService from "../../../services/apiService";
import Footer2 from "./Footer2";
import toast, { Toaster } from "react-hot-toast";
import { Button, Dialog, DialogContent, DialogContentText } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import apiService from "../../../services/apiService/apiService";
import playStoreIcon from '../../../assets/images/playStore.webp'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import twitterIcon from '../../../assets/images/twitter.png'

export default function Footer(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const user_id = sessionStorage.getItem("user_id");
  const UserData = JSON.parse(localStorage.getItem("UserData"));
  const cmsData = useSelector(({ allCategories }) => allCategories.cmsData);
  const field = useSelector(({ allCategories }) => allCategories.categories);
  const { t } = useTranslation();
  const [openChatModel, setOpenChatModel] = useState(false);
  const [contactNumberArray, setContactNumberArray] = useState([]);
  const [contactPersonArray, setContactPersonArray] = useState([]);
  const [emailArray, setEmailArray] = useState([]);
  const currenttheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(currenttheme);
  const [addressArray, setAddressArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [thankYou, setthankYou] = useState(false)
  const [instaLink, setInstaLink] = useState()
  const [twitterLink, setTwitterLink] = useState()
  const [facebookLink, setFacebookLink] = useState()
  const [youtubeLink, setYoutubeLink] = useState()
  const [linkedinLink, setLinkedinLink] = useState()

  const handleClose = () => {
    setOpen(false);
    setthankYou(false)
  };

  useEffect(() => {
    // below api will give us  contact us data
    // let tempAddress = []
    // let tempcontact = []
    // let tempContactP = []
    // let tempemail= []
    // ApiService.contactUsData().then((res) => {
    //   res.data?.map((ele, key) => {
    //     if (ele.attr_key == "Contact Number") {
    //       tempcontact.push(ele.attr_value);
    //     } else if (ele.attr_key == "Contact Person") {
    //       tempContactP.push(ele.attr_value);
    //     } else if (ele.attr_key == "Email") {
    //       tempemail.push(ele.attr_value);
    //     } else if (ele.attr_key == "Address") {
    //       tempAddress.push(ele.attr_value);
    //     }
    //     setContactNumberArray(tempcontact);
    //     setAddressArray(tempAddress);
    //     setEmailArray(tempemail);
    //     setContactPersonArray(tempContactP);
    //   });
    // });
    ApiService.contactUsData().then((res) => {
      // there can be more than one contact nbumber,Contact Person ,email,address so we will be pushing all contact number in one array and will map it while rendering
      res.data?.map((ele, key) => {
        if (ele.attr_key == "instagram Link") {
          setInstaLink(ele.attr_value)
        }
        if (ele.attr_key == "twitter Link") {
          setTwitterLink(ele.attr_value)
        }
        if (ele.attr_key == "FaceBook Link") {
          setFacebookLink(ele.attr_value)
        }
        if (ele.attr_key == "youtube_link") {
          setYoutubeLink(ele.attr_value)
        }
        if (ele.attr_key == "linkedin_link") {
          setLinkedinLink(ele.attr_value)
        }


        if (ele.attr_key == "Contact Number") {
          setContactNumberArray((contactNumberArray) => [
            ...contactNumberArray,
            ele.attr_value,
          ]);

        } else if (ele.attr_key == "Contact Person") {
          setContactPersonArray((contactPersonArray) => [
            ...contactPersonArray,
            ele.attr_value,
          ]);
        } else if (ele.attr_key == "Email") {
          setEmailArray((emailArray) => [...emailArray, ele.attr_value]);
        } else if (ele.attr_key == "Address") {
          setAddressArray((addressArray) => [...addressArray, ele.attr_value]);
        }
      });
    });
  }, []);

  const handleAffiliate = () => {
    apiService.affiliateRequest().then((response) => {
      if (response.code === 200) {
        setthankYou(true)
        toast.success(response.data.message);
        localStorage.setItem("UserData", JSON.stringify(response.data.data))

      } else {
        toast.error(response.data.message);
      }
    });
  };
  useEffect(() => {

  }, [thankYou])

  return pathname.includes("cart") ||
    pathname.includes("checkOut") ||
    pathname.includes("login") ||
    pathname.includes("shop") ||
    pathname.includes("wishlist") ||
    pathname.includes("product-details") ? (
    <Footer2 setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} />
  ) : pathname == "/my-chats" ||
    ![...Object.values(AppConfig.routes), "/"]
      .map((path, key) => {
        return matchPath(pathname, {
          path,
          exact: true,
        })?.url;
      })
      .includes(pathname) ? null : (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {thankYou ? <div className="p-5">
              <div className="text-center">
                <CheckCircleIcon color="success" fontSize="large" />
              </div>
              <div className="text-center text-green-500 text-2xl my-3">
                Thank You!!
              </div>
              <div className="text-center">Your request has been registered and it is awaiting administrator's approval!</div>
            </div> : <div>
              <div className="text-center">
                You're just one step away from becoming an affiliate!{" "}
              </div>
              <div className="text-center my-4">
                <Button onClick={handleAffiliate} className="border mx-2" autoFocus>
                  Submit
                </Button>
                <Button onClick={handleClose} className="border">Cancel</Button>
              </div>
            </div>}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div
        className={`grid grid-flow-row bg-background dark:bg-darkBackground text-primary ${props.mobile ? "shadow-none" : "shadow-xl"
          }`}
        style={{ color: "black !important" }}
      >
        <div
          className="grid grid-cols-1 lg:justify-items-center text-black gap-6 pt-2 sm:mx-10 lg:mx-0 sm:grid-cols-2 
                          lg:grid-cols-4 sm:justify-items-start ml-0"
        >
          <div className=" mx-10  ">
            <Typography
              variant={"h6"}
              className="text-black  dark:text-fontWhite pt-3 pl-3 pr-3"
            >
              {t("footer.quickShop")}
            </Typography>
            <List
              sx={{
                border: "2px",
              }}
              className="grid grid-cols-2 sm:block  text-nowrap justify-center"
            >
              {field.length === 0
                ? [1, 2, 3, 4, 5].map((ele, key) => (
                  <Skeleton variant="text" key={key} className="my-2" />
                ))
                : field.slice(0, 5).map((ele, key) => (
                  <React.Fragment key={key}>
                    <ListItem
                      alignItems="flex-start"
                      className="-mb-3 text-black  dark:text-fontWhite "
                      key={key}
                    >
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              className="text-black dark:text-fontWhite"
                            >
                              {ele.name}
                            </Typography>
                          </React.Fragment>
                        }
                        onClick={() =>
                          history.push({
                            pathname: `/shop/category-product/${ele.slug}/${ele.id}`,
                            state: { id: ele.id, type: "Categories" },
                          })
                        }
                        className="font-thin  cursor-pointer"
                        classes={{
                          secondary: "text-14 list-item-text-primary ",
                        }}
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
            </List>
          </div>
          <div className="mx-10">
            <Typography
              variant={"h6"}
              className="pt-3 pl-3  pr-3 text-black  dark:text-fontWhite"
            >
              {t("footer.informations")}
            </Typography>
            <List
              className="grid grid-cols-1  align-start justify-items-start text-nowrap  mt-0"
              sx={{
                border: "2px",
              }}
            >
              {cmsData.length === 0
                ? [1, 2, 3].map((ele, key) => (
                  <Skeleton variant="text" key={key} className="my-2 w-40" />
                ))
                : cmsData.slice(0, 3).map((info, key) => (
                  <ListItem
                    alignItems="flex-start"
                    className="-mb-3 text-dark"
                    key={key}
                  >
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            className=" cursor-pointer text-black  dark:text-fontWhite"
                            variant="body2"
                            onClick={() =>
                              history.push({
                                pathname: `/cms/${info.slug}`,
                                state: { type: info.slug },
                              })
                            }
                          >
                            {info.title}
                          </Typography>

                        </React.Fragment>
                      }
                      className="font-thin  "
                      classes={{
                        secondary: "text-14 list-item-text-primary ",
                      }}
                    />
                  </ListItem>
                ))}
            </List>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    className=" cursor-pointer text-black mx-4 dark:text-fontWhite"
                    variant="body2"
                    onClick={() => {
                      if (UserData) {
                        if (UserData.is_affiliate === 0) {
                          setOpen(true);
                        }
                        else {
                          history.push({
                            pathname: "/affiliate-dashboard/",
                          });
                        }
                      } else {
                        history.push({
                          pathname: "/affiliate-dashboard/",
                        });
                      }
                    }}
                  >
                    {t("footer.BecameAffiliate")}
                  </Typography>
                </React.Fragment>
              }
              className="font-thin  "
              classes={{
                secondary: "text-14 list-item-text-primary ",
              }}
            />

            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    className=" cursor-pointer text-black mx-4 dark:text-fontWhite"
                    variant="body2"
                    onClick={() => {
                      history.push({
                        pathname: "/SignUpSeller",
                      });
                    }}
                  >
                    {t("footer.BecameSeller")}
                  </Typography>
                </React.Fragment>
              }
              className="font-thin  "
              classes={{
                secondary: "text-14 list-item-text-primary py-2",
              }}
            />

            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    className=" cursor-pointer text-black mx-4 dark:text-fontWhite"
                    variant="body2"
                    onClick={() => {
                      history.push({
                        pathname: "/faq",
                      });
                    }}
                  >
                    {t("footer.faq")}
                  </Typography>
                </React.Fragment>
              }
              className="font-thin  "
              classes={{
                secondary: "text-14 list-item-text-primary ",
              }}
            />

          </div>
          <div className=" mx-10  ">
            <Typography
              variant={"h6"}
              className="pt-3 pl-3 pr-3 text-nowrap text-black  dark:text-fontWhite"
            >
              {t("footer.customerService")}
            </Typography>

            <List
              className="grid grid-cols-1 sm:block text-nowrap items-start justify-center"
              sx={{
                border: "2px",
              }}
            >
              {cmsData.length === 0
                ? [1, 2, 3].map((ele, key) => (
                  <Skeleton variant="text" key={key} className="my-2" />
                ))
                : cmsData.slice(3).map((info, key) =>
                  info.slug === "support" ? (
                    user_id !== "null" ? (
                      <ListItem
                        alignItems="flex-start"
                        className="-mb-3 text-dark"
                        key={key}
                      >
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                className=" cursor-pointer text-black dark:text-fontWhite"
                                variant="body2"
                                onClick={() => {
                                  // if (key == 2) {
                                  setOpenChatModel(true);
                                  // }
                                  // else {
                                  //   history.push({
                                  //     pathname: `/cms/${info.slug}`,
                                  //     state: { type: info.slug },
                                  //   })
                                  // }
                                }}
                              >
                                {info.title}
                              </Typography>


                            </React.Fragment>
                          }
                          className="font-thin  "
                          classes={{
                            secondary: "text-14 list-item-text-primary ",
                          }}
                        />
                      </ListItem>
                    ) : (
                      ""
                    )
                  ) : (
                    <ListItem
                      alignItems="flex-start"
                      className="-mb-3 text-dark"
                      key={key}
                    >
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              className=" cursor-pointer text-black dark:text-fontWhite"
                              variant="body2"
                              onClick={() => {
                                if (key == 2) {
                                  setOpenChatModel(true);
                                } else {
                                  history.push({
                                    pathname: `/cms/${info.slug}`,
                                    state: { type: info.slug },
                                  });
                                }
                              }}
                            >
                              {info.title}
                            </Typography>
                          </React.Fragment>
                        }
                        className="font-thin  "
                        classes={{
                          secondary: "text-14 list-item-text-primary ",
                        }}
                      />
                    </ListItem>
                  )
                )}
              {/* <Typography
                className="px-4 py-3 cursor-pointer text-sm text-black dark:text-fontWhite"
                onClick={() => {
                  history.push('/shipping-policy')
                }}
              >
                Shipping policy
              </Typography> */}
              <Typography
                className="px-4 py-3 cursor-pointer text-sm text-black dark:text-fontWhite"
                onClick={() => {
                  setOpenChatModel(true);
                }}
              >
                {t("footer.supportChat")}
              </Typography>
            </List>

            <div className="ml-4 mr-5">
              <div style={{ color: "#000", paddingBottom: "6px" }}>EXPERIENCE FASHIONLEO APP ON MOBILE</div>
              <a href={AppConfig.playStoreUrl}>
                <div className="flex items-center border rounded-md text-white py-2 pl-3 pr-5 min-w-max" style={{ background: "#262626" }}>
                  <img src={playStoreIcon} className="h-10 w-10" />
                  <div className="pl-3">
                    <div>GET IT ON</div>
                    <div className="text-lg">Google Play</div>
                  </div>
                </div>
              </a>

              <div style={{ color: "#000" }} className="pt-4 pb-1">KEEP IN TOUCH</div>
              <div className="flex items-center p-1" style={{ color: "#555555" }}>
                <a href={`https://${facebookLink}`} target="_blank"><FacebookIcon /></a>
                <a href={`https://${twitterLink}`} target="_blank">
                  {/* <CloseIcon style={{ margin: "0px 8px" }} /> */}
                  <img src={twitterIcon} className="w-5 h-5 mx-2" />
                </a>
                <a href={`https://${instaLink}`} target="_blank"><InstagramIcon /></a>
                <a href={`https://${youtubeLink}`} target="_blank"><YouTubeIcon style={{ margin: "0px 8px" }} /></a>
                <a href={`https://${linkedinLink}`} target="_blank"><LinkedInIcon /></a>
              </div>
            </div>
          </div>

          <div className=" mx-10 ">
            <Typography
              variant={"h6"}
              className="pt-3 pl-3 pr-3 lg:pl-0 lg:pr-0 text-black dark:text-fontWhite"
            >
              {t("footer.contactUs")}
            </Typography>
            <List
              className="grid grid-cols-1  lg:block mt-2 mx-4 lg:mx-0 text-nowrap items-start justify-center"
              sx={{
                border: "2px",
              }}
            >
              <ListItemText
                secondary={
                  contactNumberArray.length === 0 ? (
                    [1, 2, 3].map((ele, key) => (
                      <Skeleton variant="text" key={key} className="my-1" />
                    ))
                  ) : (
                    <div className="lg:pb-1">
                      {contactNumberArray.map((ele, key) => (
                        <React.Fragment key={key}>

                          <Typography
                            // sx={{ display: "inline" }}
                            component="span"
                            className=" text-black block dark:text-fontWhite"
                            variant="body1"
                          // onClick={() =>
                          //   history.push({
                          //     pathname: `/cms/${info.slug}`,
                          //     state: { type: info.slug },
                          //   })
                          // }
                          >
                            <CallIcon
                              className="mr-2 bg-transparent text-black dark:text-fontWhite"
                              key={key}
                            /> <a
                              href={`tel:${ele}`}
                              className="hover:text-black hover:no-underline"
                            >
                              {" "}
                              {ele}
                            </a>
                          </Typography>
                        </React.Fragment>
                      ))}
                    </div>
                  )
                }
                className="font-thin  "
                classes={{
                  secondary: "text-14 list-item-text-primary ",
                }}
              />

              <ListItemText
                secondary={
                  <div className="pb-1">
                    {contactPersonArray.map((ele, key) => (
                      <Typography
                        key={key}
                        // sx={{ display: "inline" }}
                        component="span"
                        className=" cursor-pointer text-black dark:text-fontWhite "
                        variant="body2"
                      // onClick={() =>
                      //   history.push({
                      //     pathname: `/cms/${info.slug}`,
                      //     state: { type: info.slug },
                      //   })
                      // }
                      >
                        <a
                          href={`tel:${ele}`}
                          className="hover:text-black hover:no-underline"
                        >
                          {" "}
                          {ele}
                        </a>
                      </Typography>
                    ))}
                  </div>
                }
                className="font-thin  "
                classes={{
                  secondary: "text-14 list-item-text-primary ",
                }}
              />

              <ListItemText
                secondary={
                  <div className="pb-1">
                    {emailArray.map((ele, key) => (
                      <React.Fragment key={key}>
                        <MailIcon className="mr-3 text-black dark:text-fontWhite bg-transparent" />
                        <Typography
                          // sx={{ display: "inline" }}
                          component="span"
                          className=" cursor-pointer text-black dark:text-fontWhite"
                          variant="body2"
                        // onClick={() =>
                        //   history.push({
                        //     pathname: `/cms/${info.slug}`,
                        //     state: { type: info.slug },
                        //   })
                        // }
                        >
                          <a
                            href={`mailto:${ele}`}
                            className="hover:text-black hover:no-underline"
                          >
                            {" "}
                            {ele}
                          </a>
                        </Typography>
                      </React.Fragment>
                    ))}
                  </div>
                }
                className="font-thin  "
                classes={{
                  secondary: "text-14 list-item-text-primary ",
                }}
              />
              <div className="pb-1 w-full h-full"></div>
              <ListItemText
                secondary={
                  <React.Fragment>
                    {addressArray.map((ele, key) => (
                      <div className="flex" key={key}>
                        <LocationOnIcon className="mr-3 mb-8 text-black dark:text-fontWhite bg-transparent" />
                        <Typography
                          // sx={{ display: "inline" }}
                          component="span"
                          className=" cursor-pointer text-black dark:text-fontWhite text-wrap pb-5"
                          variant="body2"
                          onClick={() => {
                            window.open(
                              `https://maps.google.com/maps?q=${ele}&t=&z=13&ie=UTF8&iwloc=&output=embed`
                            );
                          }}
                        // onClick={() =>
                        //   history.push({
                        //     pathname: `/cms/${info.slug}`,
                        //     state: { type: info.slug },
                        //   })
                        // }
                        >
                          {ele}
                        </Typography>
                      </div>
                    ))}
                  </React.Fragment>
                }
                className="font-thin  "
                classes={{
                  secondary: "text-14 list-item-text-primary ",
                }}
              />
            </List>
          </div>
        </div>
        <div className="grid sm:mb-5 mb-8 bg-black ">
          <Divider className=" bg-primary" />
        </div>
      </div>

      {openChatModel && (
        <SupporChat
          openChatModel={openChatModel}
          setOpenChatModel={setOpenChatModel}
        />
      )}
    </React.Fragment>
  );
}
