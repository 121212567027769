import React, { useEffect } from 'react'
import AffiliateRegister from '../AffiliateRegister'
import AffiliateDashboard from '../AffiliateDashboard'
import ApiService from '../../../services/apiService'

const Affiliate = () => {
    const user_id = sessionStorage.getItem("user_id");

    useEffect(() => {
      ApiService.getProfile().then((res) => {
        if (res.code === 200) {
          localStorage.setItem("UserData", JSON.stringify(res.data.data.user))
        }
      });
    }, [])
  return (
    <div>
       {user_id !== null ? <AffiliateDashboard/> : <AffiliateRegister/>}
    </div>
  )
}

export default Affiliate