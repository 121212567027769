import React from "react";
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";

export default function SelectReason({reason, cancelReason, error, handleChange}) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <InputLabel className="text-fontWhite" id="demo-simple-select-outlined-label">
          {t("cancelOrder.reasonForCancel")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-error-label"
          id="demo-simple-select-outlined"
          value={cancelReason}
          required

          name="reason"
          onChange={handleChange}
          className=' bg-loginDark text-fontWhite p-0 text-left'
        >
          {reason.map((ele) => (
            <MenuItem value={ele.id} className="-my-2 bg-primary text-fontWhite">{ele.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </React.Fragment>
  );
}