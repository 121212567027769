import React from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";

const Notification = ({ foreground, setForeground }) => {

  const history = useHistory()
  return toast.success(
    <div
      onClick={() => {
        if (foreground.data.action == "order_details") {
          history.push(
            "/my-orders/" + foreground.data.other_id
          );
        } else if (foreground.data.action == "message") {
          history.push({
            pathname: `/my-chats`,
            state: {
              chatId: foreground.data.other_id,
              productId: foreground.data.other_data.product_id,
              productName:
                foreground.data.other_data.product_name,
            },
          });

        } else if (
          foreground.data.action == "product_details"
        ) {
          history.push({
            pathname: `/product-details/${foreground.data.other_data.slug}/${foreground.data.other_id}`,
          });

        } else if (foreground.data.action == "cart") {
          history.push({
            pathname: `/cart-details`,
          });

        } else if (foreground.data.action == "payouts") {

        }
        setForeground(null)
      }}
      className="w-52 h-auto p-1 rounded-xl cursor-pointer items-start flex flex-col justify-center">
      <h1 className="text-gray text-sm">{foreground.notification.title}</h1>

      <p className="text-gray">{foreground.notification.body}</p>
    </div>,

    {
      duration: 4000,
      style: { backgroundColor: "#F1E5AC" },
    }
  );
};

export default Notification;
