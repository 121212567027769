import { getToken, getMessaging, isSupported, onMessage } from "@firebase/messaging";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../src/assets/css/style.css";
import backimage from "./assets/images/NewBackground.jpg";
import Message from "./core-components/Message";
import { ActionTypes } from "./redux/contants/ActionType";
import store from "./redux/store";
import Routing from "./Routing";
import ApiService from "./services/apiService";
import "./styles/Custom.css";
import "./styles/output.css";
import apiService from "./services/apiService/apiService";
import http from "./utils/http";
import { messaging } from "./services/Firebase/firebase";
import TagManager from "react-gtm-module";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "sans-serif"
    ].join(",")
  }
});

const tagManagerArgs = {
  dataLayer: {
    algoliaDisplayedObjectIDs: ['001'],
    algoliaUserToken: 'sandbox_1',
    algoliaIndexName: 'instant_search',
  },
  gtmId: 'G-472FY1SFS7',
};

function App() {
  const [foreground, setForeground] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const currenttheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(currenttheme);
  const { innerWidth, innerHeight } = window;
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const user_id = sessionStorage.getItem('user_id')
  // seting font family dynamically

  useEffect(() => {
    store.dispatch({
      type: ActionTypes.SET_IMAGE,
      payload: backimage,
    });
    setTimeout(() => {

      let font = new FontFace("loadedFont", "url(../../assets/fonts/Poppins-Regular.woff')");
      font.load().then(function (loadedFont) {
        // const ele = document.getElementsByTagName('*')
        // console.log("body error",ele)
        // document.querySelector('head').style.fontFamily=loadedFont
        document.body.style.fontFamily = loadedFont.family
        document.head.style.fontFamily = loadedFont.family
        const typography = document.querySelectorAll('.MuiTypography-root')
        const typographyArray = [...typography]

        typographyArray.forEach((ele) => {
          ele.style.fontFamily = loadedFont.family
        })
        const span = document.querySelectorAll('.span')
        const spanArray = [...span]
        spanArray.forEach((ele) => {
          ele.style.fontFamily = loadedFont.family
        })

      }).catch(function (error) {
        // error occurred

      });
    }, 0);
    ApiService.getBrandList().then((res) => {
      if (res.code === 200) {
        // setAllData(res.data.data);
        store.dispatch({
          type: ActionTypes.ADD_ALL_DATA,
          payload: res.data.data,
        });
      }
    });
  }, [])
  useEffect(() => {


    if (currenttheme == null || currenttheme == "null") {
      setCurrentTheme("white");
      localStorage.setItem("theme", "white");
    }
    if (currentTheme == "dark") {
      const root = window.document.documentElement;
      root.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else if (currentTheme == "white") {
      const root = window.document.documentElement;
      root.classList.remove("dark");
      localStorage.setItem("theme", "white");
    }
  }, [currentTheme]);
  // setting css for components which css we cant access directly

  useEffect(async () => {
    // branch library is used for sharing product or category or page url
    // branch.init(
    //   "key_test_lf7VVSzdoKDSZi3Shzkuqpjmtxe3wI2C",
    //   function (err, data) { }
    // );
    // branch.data(function (err, data) { });

    const prefix_id = window.sessionStorage.getItem("prefix_id");
    const user_id = sessionStorage.getItem("user_id");
    if (prefix_id == null || prefix_id == "null" || prefix_id == undefined) {
      apiService.getConfig().then((res) => { });
    }
    let data;


    // console.log('Requesting permission...');
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(messaging, { vapidKey: 'BFicVpiQ-A51XbYcPn6GfZKR2MOaaW-IQujjIZEf1aOVIjfPG7hgQTJ1dBP0-h-25p19lvG6D0-pgZeyRwoO36E' }).then((currentToken) => {
            if (currentToken) {
              if (
                user_id !== null &&
                user_id !== "null" &&
                user_id !== undefined
              ) {
                let formData = new FormData();
                formData.append("token", currentToken);
                formData.append("topic", `${prefix_id}_user_id_${user_id}`);
                // formData.append("topic", `${prefix_id}_fashionleo_customer`);

                http.post("/user/subscribe", formData).then((response) => {

                });
              }

              onMessage(messaging, (payload) => {
                setForeground(payload);
                // ...
              });
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
          });
        } else {

        }
      })
    }
    else {
      console.log('Firebase not supported this browser');
    }

    return data;
  }, [setTokenFound]);

  TagManager.initialize(tagManagerArgs)

  return (
    <>
      <Helmet>
        <title>Fashion Leo</title>
        <meta name="description" content="All types data"></meta>
      </Helmet>

      <ThemeProvider theme={theme}>
        <SkeletonTheme baseColor={themes === '' ? localStorage.getItem('theme') !== 'dark' ? "#D3D3D3" : "#355863" : themes !== 'dark' ? "#009acc" : "#355863"} highlightColor="#efefef">
          <div
            // style={{ width: innerWidth > 1000 ? "" : innerWidth, }}
            className={`${innerWidth > 1000 ? "lg:w-screen" : "w-full"} bg-background dark:bg-darkBackground `}
          >

            <Routing
              setCurrentTheme={setCurrentTheme}
              currentTheme={currentTheme}
              foreground={foreground !== null ? foreground : null}
              setForeground={setForeground}
            />
            <Message />
          </div>

        </SkeletonTheme>
      </ThemeProvider>
    </>
  );
}
export default App;
