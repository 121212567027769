import React, { useEffect, useState } from "react";
import TextFieldFormsy from "../../core-components/TextFieldFormsy";
import { useTranslation } from "react-i18next";
import apiService from "../../services/apiService/apiService";
import Checkbox from "@mui/material/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import { isEmpty } from "lodash";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useHistory } from "react-router-dom";

const AffiliateRegister = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const data = JSON.parse(localStorage.getItem("UserData"));
  const [error, setError] = useState();
  const [showOtp, setShowOtp] = useState();
  const [confirmMsg, setConfirmMsg] = useState(false)
  const history = useHistory()

  const [form, setForm] = useState({
    phone: "",
    name: "",
    dialing_code: "+91",
    email: "",
    password: "",
    otp: "",
  });

  useEffect(() => {
    if (data) {
      setForm({
        ...form,
        phone: data?.phone,
        email: data?.email,
      });
    }
  }, []);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    // let errorData = {};
    // for (const key in form) {
    //   if (form[key] === "") {
    //     errorData = { ...errorData, [key]: `Please Fill the ${key} value` };
    //   }
    // }
    // setError(errorData);
    // if (isEmpty(errorData)) {
    const _register = new FormData();
    _register.append("name", form.name);
    _register.append("dialing_code", form.dialing_code);
    _register.append("email", form.email);
    _register.append("phone", form.phone);
    _register.append("password", form.password);
    _register.append("affiliate", "true");
    apiService.signUP(_register).then((response) => {
      if (response.code === 200) {
        toast.success("Success");
        setShowOtp(true)
      }
    });
    // } else {
    //   toast.error("Please select ");
    // }
  };


  const handleVerifyOtp = () => {
    const _form = new FormData()
    _form.append("phone", form.phone);
    _form.append("dialing_code", "+91");
    _form.append("otp", form.otp);
    apiService.verifyOtp(_form).then((res) => {
      if (res.code === 200) {
        setConfirmMsg(true)
        localStorage.setItem("UserData", JSON.stringify(res.data.data.user))
      } else {
        
      }
    });
  }

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <Dialog
        open={confirmMsg}
        onClose={() => setConfirmMsg(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <div className="text-base text-center">Registration done succefully</div>
            <div className="flex flex-col justify-center py-3">
              <Button className="border border-fontDark px-4 rounded-md p-1 mx-3" onClick={() => {
                setConfirmMsg(false)
                window.location.reload()
              }}>
                Close
              </Button>
              <Button className="border border-fontDark px-4 rounded-md p-1" onClick={() => {
                setConfirmMsg(false)
                history.push('/home')
                window.location.reload()
              }}>
                ok
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            theme: {
              primary: "red",
            },
          },
        }}
      />
      <div className="py-10">
        <div className="ml-32 p-5">
          <div className="text-3xl  font-semibold text-black">
            {t("affiliate.headingRegister")}
          </div>
        </div>
        <div className="md:w-1/3 w-11/12  mx-auto">
          <div className="text-2xl my-2">Register</div>
          <div className="bgSlate100 affiliate bg-gray-300 rounded-md p-5">
            <TextFieldFormsy
              label={t("affiliate.firstName")}
              name="name"
              value={form?.name}
              serverError={error?.name}
              onChange={handleChange}
              className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite bg-whiteBackground"
              variant="outlined"
            />
            {/* <TextFieldFormsy
              label={t("affiliate.lastName")}
              name="lastName"
              value={form?.lastName}
              serverError={error?.lastName}
              sx={{ background: "white" }}
              onChange={handleChange}
              className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite bg-whiteBackground"
              variant="outlined"
            /> */}
            <TextFieldFormsy
              label={t("affiliate.phone")}
              name="phone"
              value={form?.phone}
              serverError={error?.phone}
              onChange={handleChange}
              className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite  bg-gray-50"
              variant="outlined"
            />
            <TextFieldFormsy
              label={t("affiliate.emailAddress")}
              name="email"
              value={form?.email}
              serverError={error?.email}
              onChange={handleChange}
              className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite bg-fontWhite"
              variant="outlined"
            />
            <TextFieldFormsy
              label={t("affiliate.password")}
              name="password"
              serverError={error?.password}
              value={form?.password}
              onChange={handleChange}
              className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite bg-fontWhite"
              variant="outlined"
            />

            {showOtp &&
              <TextFieldFormsy
                className="w-11/12 mx-auto my-3 affiliate-formField text-fontWhite bg-fontWhite"
                type="password"
                name="otp"
                label={t("login.enterTheOtp")}
                serverError={error?.otp}
                value={form.otp}
                onChange={handleChange}
                validations={{
                  minLength: 4,
                }}
                validationErrors={{
                  minLength: "Min character length is 4",
                }}
                variant="outlined"
              />
            }

            <div className="flex">
              {/* <Checkbox
                // checked={checked}
                // onChange={(e) => handleChangeCheck(e)}
                inputProps={{ "aria-label": "controlled" }}
                onClick={(e) => handleChangeCheck(e)}
              /> */}
              <input type="checkbox" onClick={(e) => handleChangeCheck(e)} className="p-2" />

              <div className="leading-2 pl-2"> {t("affiliate.terms")} </div>
            </div>
            <div className="text-center my-2 mt-4">
              {showOtp ?
                <button
                  disabled={checked ? false : true}
                  onClick={() => {
                    handleVerifyOtp()
                    // setForm({
                    //   phone: "",
                    //   name: "",
                    //   email: "",
                    //   password: "",
                    // })
                  }
                  }
                  className="mx-auto w-44 p-2 rounded-xl bg-yellow-600 "
                >
                  verfyOtp
                </button>
                :
                <button
                  onClick={() => {
                    handleSubmit()
                  }
                  }
                  className="mx-auto w-44 p-2 rounded-xl bg-yellow-600 "
                >
                  {t("affiliate.register")}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AffiliateRegister;
