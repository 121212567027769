import { AppBar, Toolbar } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppConfig from "../../appConfig";
import animationData from "../../assets/animations/check-mark.json";
import animationDataEmptyCart from '../../assets/animations/EmptyCart.json';
import animationDataSadFace from '../../assets/animations/SadFace.json';
import { ActionTypes } from "../../redux/contants/ActionType";
import store from "../../redux/store";
import ApiService from "../../services/apiService";
import AddressForm from "./AddressForm";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { showMessage } from "../../redux/reducers/messageSlice";

const theme = createTheme();
// const stripePromise = loadStripe(AppConfig.stripePaymentKey);
export default function Checkout() {
  const themes = useSelector(({ allCategories }) => allCategories.theme);
  const appearance = {
    theme: themes === '' ? localStorage.getItem('theme') === 'dark' ? "stripe" : 'night' : themes === 'dark' ? "stripe" : 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: themes === '' ? localStorage.getItem('theme') === 'dark' ? "#355863" : "#fff" : themes === 'dark' ? "#355863" : '#fff',
      colorPrimary: '#EFC078',
      colorPrimaryText: '#1A1B25',
      colorText: '#597593',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#727F96',
      colorIconTab: 'red',
      colorLogo: 'dark'
    },
  };

  const [stripePromise, setStripePromise] = React.useState();
  const history = useHistory();
  const [confirmOrder, setConfirmOrder] = React.useState();
  const [clientSecret, setClientSecret] = React.useState();
  const [paymentMethodData, setPaymentMethodData] = React.useState();
  const user_id = sessionStorage.getItem("user_id");
  const apiUrl = AppConfig.apiUrlCCAvenue

  const [data, setData] = React.useState({
    addressId: "",
    address: "",
    name: "",
    payment_method: "",
    service_type: "",
  });

  React.useEffect(() => {
    setData({
      ...data, payment_method: paymentMethodData
    })
  }, [paymentMethodData])

  const [orderDetails, setOrderDetails] = React.useState();
  const [paymentFaildData, setPaymentFailedData] = useState(null);
  const [isOrderSuccessful, setIsOrderSuccessful] = useState(false)
  const [progress, setProgress] = React.useState(0);
  const [cartCount, setCartCount] = React.useState(0);
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const [countryError, setCountryError] = useState();
  const { innerHeight } = window;
  const [alldata, setAllData] = useState()
  const [walletAmount, setWalletAmount] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [deliveryError, setDeliveryError] = useState(false);
  const sessionPinCode = sessionStorage.getItem('pincode')

  const steps = [t("checkout.shippingAddress"), t("review.paymentdetails"), t("review.reviewOrder")];
  React.useEffect(() => {
    window.scrollTo(0, 0);

    getCartCount();
  }, []);

  const getCartCount = () => {
    ApiService.getCartCount().then(res => {
      if (res.code === 200) {
        setAddressLoad(false)
        setCartCount(res.data.data);
      }
    })
  }
  const handlechange = (id, address, name, pincode) => {
    if (address == "delete") {
      if (id == data.addressId) {
        setData({
          ...data,
          ["addressId"]: "",
          ["address"]: "",
          ["name"]: "",
          ['pincode']: ""
        });
      }
    } else {
      setData({
        ...data,
        ["addressId"]: id,
        ["address"]: address,
        ["name"]: name,
        ['pincode']: pincode
      });
    }
  };
  const handlePaymentDetails = (method) => {
    setData({ ...data, ["service_type"]: method });
  };
  const handlePlaceOrder = (data) => {
    setOrderDetails(data);
  };
  const handleClickShow = (data) => {
    setShow(data);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm handleReceiveProps={handlechange} addressLoad={setAddressLoad} />;
      case 1:
        return (
          <PaymentForm
            data={data}
            // method={data.payment_method}
            method={setPaymentMethodData}
            handleError={setDeliveryError}
            handleReceiveProps={handlePaymentDetails}
          />
        );
      case 2:
        return showProgress ? (
          <div className="text-center  h-64">
            {" "}
            <CircularProgress
              variant="determinate"
              value={progress}
              className="text-center  my-16"
              size={100}
              sx={{ height: 300, width: 300, color: "#597593" }}
            />{" "}
          </div>
        ) : (

          <Review
            data={data}
            loading={(load) => { setloading(load) }}
            handleShow={handleClickShow}
            error={(data) => setCountryError(data)}
            handleReceiveProps={handlePlaceOrder}
            method={paymentMethodData}
            setAllData={setAllData}
            setWalletAmount={setWalletAmount}
            setTotalAmount={setTotalAmount}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [addressLoad, setAddressLoad] = useState(true);
  const [apiValidation, setApiValidation] = useState(false)
  const dispatch = useDispatch()

  const AddressValidationApi = () => {

    let _form = new FormData();
    _form.append("pincode", sessionPinCode)
    _form.append('vendor_id', user_id)

    ApiService.checkCourierService(_form).then((res) => {
      if (res.data.pricing !== null) {
        if (res.data.pricing.status === 200) {
          if (!data.payment_method) {
            store.dispatch(
              showMessage({
                message: "Now You Can Pay",
                variant: "success",
              })
            )
          }
          setActiveStep(activeStep + 1);
        }
        else if (res.data.pricing.status === 404) {
          store.dispatch(
            showMessage({
              message: res.data.pricing.message,
              variant: "error",
            })
          );
        }
        else {
          console.log('error courier api');
        }
      }
    });
  }

  // below function will help going forward in stepper
  const handleNext = () => {
    // console.log("data---next", data);
    // if (activeStep === 1 && data.service_type === "") {
    //   alert(t("review.serviceSelectMessage"))
    // } else {
    // AddressValidationApi()
    // if (apiValidation === true) {
    //   console.log("afsfsdfsfsfsdfs");
    // }
    // }
    if (data.addressId !== '') {
      setActiveStep(activeStep + 1);
    } else {
      alert(t("review.serviceSelectMessage"))
    }
  };

  // below function will help going backward in stepper
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const [paymentModal, setShowPaymentModal] = React.useState(false);
  const [paymentableId, setPaymentableId] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [showProgress, setShowProgress] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentFaildError, setPaymentFailedError] = useState("");
  const [buttonHide, setButtonHide] = useState(false);
  const [Paymentloading, setPaymentLoading] = useState(true);
  const [orderId, setOrderId] = React.useState();
  const [product] = React.useState({
    amount: orderDetails?.original_price * 100,
    currency: "US$",
    payment_method_types: "card",
  });

  // function to close the paymentModal
  const handlePaymentModalClose = (paymentInitialted) => {
    // close the paymentModal
    setShowPaymentModal(false);
    setButtonHide(false)
    // we can call handlePaymentModalClose function in two condition one is when we have clicked on pay now and payment is initiated and second one is we just open the window and close it without clickin on pay now button
    // if user has intiatted then only we will start loader so when i will call handlepaymentModalClose function on pay now click i will send paymentInitialted = "paymentInitiated" so it will close the window and loader will shown
    if (paymentInitialted == "paymentInitiated") {
      setShowProgress(true);

      // and when user just open and close the window i will send nothing so loader will not get true
    } else {
      setShowProgress(false);
    }
    setIsLoading(false);
  };
  const overWriteCss = () => {
    // over writing mui placeholder class

    // using set time out so that the component on which we are tring to add class gets render first because we can only add class to component which is present in dom
    setTimeout(() => {
      // over writing menu class
      const paperElement = document.querySelectorAll('.css-126nd9x-MuiPaper-root')
      const paperElementArray = [...paperElement]
      paperElementArray.forEach((ele) => {
        ele.classList.add('dark:bg-darkBackground')
        // ele.classList.add('text-fontDark')
      })

      const stepperText = document.querySelectorAll('.css-1hngkjo')
      const stepperTextArray = [...stepperText]
      stepperTextArray.forEach((ele) => {
        // ele.classList.add('dark:bg-darkBackground')
        ele.classList.add('dark:text-fontWhite')
      })
      const stepperTextActive = document.querySelectorAll('.css-farnub.Mui-active')
      const stepperTextActiveArray = [...stepperTextActive]
      stepperTextActiveArray.forEach((ele) => {
        // ele.classList.add('dark:bg-darkBackground')
        ele.classList.add('dark:text-primary')
      })

      const stepperTextCompleted = document.querySelectorAll('.css-farnub.Mui-completed')
      const stepperTextCompletedArray = [...stepperTextCompleted]
      stepperTextCompletedArray.forEach((ele) => {
        // ele.classList.add('dark:bg-darkBackground')
        ele.classList.add('dark:text-primary')
      })


      const radioButton = document.querySelectorAll('.css-hyxlzm')
      const radioButtonArray = [...radioButton]
      radioButtonArray.forEach((ele) => {
        // ele.classList.add('dark:bg-darkBackground')
        ele.classList.add('dark:text-fontWhite')
      })

    }, 0);

  }
  React.useEffect(() => {

    overWriteCss()
  }, [activeStep, confirmOrder])

  const placeOrder = () => {
    console.log(orderDetails, walletAmount)
    setShowProgress(true);
    setIsLoading(true);
    let _form = new FormData();
    let razoId;
    // if (orderDetails.margin_price == 'null' || orderDetails.margin_price == null || orderDetails.margin_price == undefined || orderDetails.margin_price == 'undefined') {

    // }
    // else {
    if (orderDetails?.is_margin === true) {
      _form.append('margin_price', orderDetails.margin_price)
    }
    // }
    // _form.append(
    //   "total_price",
    //   orderDetails?.total_price !== "NAN" ? orderDetails?.total_price : ""
    // );
    _form.append("total_price", orderDetails?.original_price)
    _form.append("discount_price", orderDetails?.discount_price);
    _form.append(
      "coupon_detail",
      typeof orderDetails?.coupon_data?.coupon_detail === "undefined"
        ? ""
        : JSON.stringify(orderDetails?.coupon_data?.coupon_detail)
    );
    _form.append(
      "coupon_code",
      typeof orderDetails?.coupon_data?.coupon_detail?.coupon_code === "undefined"
        ? ""
        : orderDetails?.coupon_data?.coupon_detail?.coupon_code
    );
    _form.append('delivery_charges', typeof data.service_type === 'string' ? 0 : data.service_type.service_amount)
    _form.append('delivery_request', typeof data.service_type === 'string' ? null : JSON.stringify(data.service_type))
    _form.append("original_price", orderDetails?.original_price);
    _form.append("reselling", orderDetails?.is_margin === true ? "Yes" : "No");
    _form.append("payment_method", data.payment_method);
    _form.append("addressid", data.addressId);
    _form.append("currency", orderDetails.currency_id);
    _form.append("wallet_amount", walletAmount ? walletAmount : 0);

    if (sessionStorage.getItem(AppConfig.localReferalKey)) {
      _form.append("affiliate_id", sessionStorage.getItem(AppConfig.localReferalKey))
    }

    // firstly we will call create order or place order api which will give us paymentable id
    ApiService.placeOrder(_form).then((res) => {
      if (res.code === 200) {
        // setButtonHide(false);

        let _form = new FormData();

        res.data.data.saveorder.map((item, index) => {
          // setPaymentableId(res.data.data.saveorder.id);
          _form.append(`order_id[${index}]`, item.id);
        })

        if (paymentMethodData === "COD" || totalAmount == 0) {

          ApiService.getOrderCheckout(_form).then((res) => {
            setShowProgress(false);
            setConfirmOrder(res.data.data.oderdata);

            store.dispatch(
              showMessage({ message: res?.data?.message, variant: "success" })
            );

            store.dispatch({
              type: ActionTypes.ADD_USER_CART_COUNT,
              payload: 0,
            });
            setIsLoading(false);
            // handleNext();
            history.push(AppConfig.routes.myOrders)
          });
          // }, 6000);
        } else {
          setTimeout(() => {
            clearInterval(interval)
            history.push(AppConfig.routes.myOrders)
          }, 200000)

          window.open(`${apiUrl}/ccavenue/${res.data.data.order_checkout_id}`)

          const interval = setInterval(() => {
            ApiService.checkPayment(res.data.data.order_checkout_id).then((res) => {
              if (res.code === 200 && res.data.status == true) {
                ApiService.getOrderCheckout(_form).then((res) => {
                  setShowProgress(false);
                  setConfirmOrder(res.data.data.oderdata);

                  store.dispatch(
                    showMessage({ message: res?.data?.message, variant: "success" })
                  );

                  store.dispatch({
                    type: ActionTypes.ADD_USER_CART_COUNT,
                    payload: 0,
                  });
                  setIsLoading(false);
                  // handleNext();
                  history.push(AppConfig.routes.myOrders)
                  clearInterval(interval)
                });
              }
            }).catch((er) => {
              console.log(er, "error pay");
            })
          }, 3000);

          // ApiService.stripePayment(formData).then((res) => {
          //   if (res.code === 200) {
          //     setClientSecret(res.data.paymentIntent);
          //     setIsOrderSuccessful(true)
          //     window.gtag("event", "purchase", {
          //       transaction_id: confirmOrder?.transaction_id || "",
          //       value: orderDetails?.total_price,
          //       currency: AppConfig.currencyFormat,
          //       affiliate_name: sessionStorage.getItem(AppConfig.localReferalKey) || "",
          //       coupon: orderDetails?.coupon_detail?.name || "",
          //       items: JSON.stringify(alldata) || '[]'
          //     });
          //     // setStripePromise(loadStripe(res.data.publishableKey));
          //     // now stripe api has runned succesfully now we have to open stripe payment window so we will do setShowPaymentModal(true )
          //     setShowPaymentModal(true);
          //     setTimeout(() => {
          //       setPaymentLoading(false);
          //     }, 800);
          //   }
          // });
        }
      } else {
        setButtonHide(false);
        setErrorMessage(res.errors);
        setOpenModal(false);
        setloading(false)
      }
    });

  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsEmptyCart = {
    loop: true,
    autoplay: true,
    animationData: animationDataEmptyCart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsSadFace = {
    loop: true,
    autoplay: true,
    animationData: animationDataSadFace,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
  };
  const [paymentPopup, setPaymentPopup] = useState();
  const checkOrderStatus = () => {
    // ApiService.stripePaymentConfirmation(paymentableId).then((res) => {
    //   if (res.code == 200) {
    //     setConfirmOrder(res.data.data);
    //     setIsLoading(false);
    //     setPaymentFailedData(null)
    //     handleNext();
    //     store.dispatch({
    //       type: ActionTypes.ADD_USER_CART_COUNT,
    //       payload: 0,
    //     });
    //   } else {
    //     setActiveStep(2);
    //     setPaymentFailedData(res);
    //   }
    // });
  }
  return (
    <ThemeProvider theme={theme} className='dark:bg-DarkBackground'>
      <Dialog
        open={paymentModal}
        paymentPopup={paymentPopup}
        setPaymentPopup={setPaymentPopup}
        onClose={handlePaymentModalClose}
        fullWidth
      >
        <AppBar position="static" elevation={1} className='font-bold bg-button dark:text-fontWhite  text-primary'>
          <Toolbar className="flex w-full">
            <Typography component={'span'} variant={'body2'} className='font-bold bg-button dark:text-fontWhite  text-white'>
              {t("review.paymentMessage")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className="bg-login dark:bg-darkBackground">
          {/* this element is imported from stripe its need stripePromise which is intentid generated from stripePayment api and in options it need publishable id which is also generated from stripePayment api  */}
          {/* <Elements stripe={stripePromise} options={options} >
            <CheckoutForm
              loading={Paymentloading}
              currency_type={orderDetails?.currency_type}
              setIsLoading={setIsLoading}
              totalAmount={orderDetails?.total_price}
              setConfirmOrder={setConfirmOrder}
              handleNext={handleNext}
              handlePaymentModalClose={handlePaymentModalClose}
              paymentFailedError={(data) => {
                setActiveStep(2);
                setPaymentFailedData(data);

              }}
              // now we will pass PaymentableId because we will need it for order confirmation api in Checkout form component
              paymentableId={paymentableId}
            />
          </Elements> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
      >
        <AppBar
          position="static"
          elevation={1}
          className="bg-primary text-white"
        >
          <Toolbar className="flex w-full">
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 text-xl font-semibold"
            >
              {t("checkout.alert")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <Typography className="py-4 px-2 text-center">
              {errorMessage}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="py-2 px-4 bg-button text-white"
            onClick={() => handleModalClose()}
          >
            {t("checkout.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      {isOrderSuccessful && alldata.length > 0 && <script>
        {`
        
       
        `}
      </script>}
      <Container component="main" className='dark:bg-DarkBackground' maxWidth="xl" style={{ minHeight: innerHeight - 180 }} >
        <Paper
          variant="outlined"
          className='dark:bg-lightDarkBackground'
          sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }}

        >
          <Typography className="text-fontDark dark:text-fontWhite" component="h1" variant="h4" align="center">
            {/* {t("checkout.checkout")} */}
            {activeStep === 0 ? t("checkout.shippingAddress") : activeStep === 1 ? t("review.paymentdetails") : t("review.reviewOrder")}
          </Typography>
          {/* Below component is a mui stepper depending on the different step different component will get render like address form payment form  */}
          <Stepper activeStep={activeStep} sx={{ pt: 5, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className=''>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {isLoading && (
              <div className=" justify-center mt-16 text-center items-center block">
                <Typography variant="h6" gutterBottom className="px-2 text-center dark:text-fontWhite">
                  {t("review.orderPlaceMessage")}.
                </Typography>
                <CircularProgress className='mt-10' style={{ color: '#597593' }} />
              </div>
            )}

            {
              paymentFaildData !== null ?
                <React.Fragment>
                  <div className="my-16">
                    {/* <Lottie options={defaultOptionsSadFace} height={50} width={50} className='-mt-2' /> */}
                    <Lottie options={defaultOptions} height={100} width={100} />
                    {/* <Typography variant="h5" className="text-center dark:text-fontWhite" gutterBottom>
                      {paymentFaildData.message}
                    </Typography> */}
                    <Typography variant="h5" className="text-center dark:text-fontWhite" gutterBottom>
                      {t("checkout.thankYouForOrder")}
                    </Typography>
                    <Typography variant="h5" className="text-center dark:text-fontWhite" gutterBottom>
                      {t("checkout.orderProcessing")}
                    </Typography>
                    <Button
                      variant="contained"
                      className="text-center bg-button text-white justify-center flex m-0 md:m-auto float-center"
                      onClick={() => checkOrderStatus()}
                    >
                      {t("checkout.refresh")}
                    </Button>

                    {/* <Button
                      className={`bg-button text-right justify-end float-right text-white ${buttonHide ? 'opacity-20' : ''}`}
                      variant="contained"
                      onClick={() => { handleBack(); setPaymentFailedError(''); setCountryError({ ...countryError, show: false }) }}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {t("checkout.back")}
                    </Button> */}
                  </div>
                </React.Fragment>
                :
                activeStep === steps.length ? (
                  <React.Fragment>
                    <div >
                      <Lottie options={defaultOptions} height={100} width={100} />
                      <Typography variant="h5" className="text-center dark:text-fontWhite" gutterBottom>
                        {t("checkout.thankYouForOrder")}
                      </Typography>
                      <div className="flex-1 text-center">
                        <Typography variant="subtitle1" className="justify-all dark:text-fontWhite px-6">
                          {t("checkout.orderNumberIs")}{" "}
                          <span className="text-lg dark:text-fontWhitefont-bold">
                            {confirmOrder.order_number}
                          </span>
                          .
                        </Typography>
                        <Typography variant="subtitle1" className="justify-all dark:text-fontWhite px-6">
                          {t("checkout.transactionIdIs")}{" "}
                          <span className="text-lg dark:text-fontWhite font-bold">
                            {confirmOrder.transaction_id}
                          </span>
                          .
                        </Typography>
                      </div>
                      <div className="text-center mt-4">
                        <Button
                          variant="contained"
                          className="text-center bg-button text-white float-center"
                          onClick={() => history.push(AppConfig.routes.home)}
                        >
                          {t("checkout.continueShopping")}
                        </Button>

                      </div>                </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>

                    {addressLoad ?
                      <div className="text-center  h-72">
                        <CircularProgress className='mt-10' style={{ color: '#597593' }} />
                      </div>
                      : cartCount > 0 ? getStepContent(activeStep) :
                        <React.Fragment>
                          <Lottie options={defaultOptionsEmptyCart} height={200} width={200} />
                          <div className="d-flex justify-content-center my-20">
                            <div> <Typography variant={"body1"} component={'span'} className='text-2xl  text-gray-400 font-bold'>{t('cartDetails.cartEmpty')}</Typography></div>
                            <div>
                              <Lottie options={defaultOptionsSadFace} height={50} width={50} className='-mt-2' />
                            </div>
                          </div>

                        </React.Fragment>
                    }

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {activeStep !== 0 && (
                        <Button
                          className={`bg-button text-white ${buttonHide ? 'opacity-20' : ''}`}
                          variant="contained"
                          onClick={() => { handleBack(); setPaymentFailedError(''); setDeliveryError(false); setCountryError({ ...countryError, show: false }) }}
                          sx={{ mt: 3, ml: 1 }}
                        >
                          {t("checkout.back")}
                        </Button>
                      )}

                      {show ? (
                        ""
                      ) : (
                        <React.Fragment>
                          {!loading && cartCount > 0 ? activeStep !== steps.length - 1 ? (
                            <Button
                              className={`bg-button text-white ${deliveryError ? 'opacity-40' : ''}`}
                              variant="contained"
                              onClick={() => {

                                activeStep === steps.length - 1
                                  ? placeOrder()
                                  : handleNext()

                              }
                              }
                              sx={{ mt: 3, ml: 1 }}
                              disabled={showProgress || deliveryError ? true : false}
                            >

                              {t("checkout.next")}
                            </Button>
                          ) : data.payment_method === "COD" ? (
                            <Button
                              className={`bg-button text-white ${buttonHide ? 'opacity-20' : ''}`}
                              variant="contained"
                              onClick={() => {
                                activeStep === steps.length - 1
                                  ? placeOrder()
                                  : handleNext()
                                setButtonHide(true);
                              }
                              }
                              sx={{ mt: 3, ml: 1 }}
                              disabled={showProgress ? true : false}
                            >
                              {t("checkout.placeOrder")}
                            </Button>
                          ) : (
                            <Button
                              className={`bg-button text-white ${buttonHide ? 'opacity-20' : ''} ${paymentFaildError || countryError?.show ? 'hidden' : 'block'}`}
                              variant="contained"
                              onClick={() => {
                                activeStep === steps.length - 1
                                  ? placeOrder()
                                  : handleNext()
                                setButtonHide(true);
                              }
                              }
                              sx={{ mt: 3, ml: 1 }}
                              disabled={showProgress ? true : false}
                            >
                              {t("checkout.payNow")}
                            </Button>
                          )
                            : ""
                          }

                        </React.Fragment>
                      )}
                    </Box>
                  </React.Fragment>
                )}
          </React.Fragment>
        </Paper>
      </Container >
    </ThemeProvider >
  );
}
