import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import '../../../styles/App.css'

export default function SliderCategories(props) {
  const params = useLocation();

  const [current, setCurrent] = useState(null);
  const [ID, setId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [megaMenu, setMegaMenu] = useState(false)
  const [categoryName, setCategoryName] = useState([])


  // console.log(props.removeHeader, "props----");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  useEffect(() => {
    if (params?.state?.id === '') {
      setCurrent(null);
    } else {
      setCurrent(params?.state?.id);
    }
  })

  return (
    <React.Fragment>
      <div className="flex p-0 m-0 group">
        <div className="flex justify-evenly">
          <Tabs
            value={props.value}
            aria-label="basic tabs example"
          >
            {
              props.field.length === 0 ?
                <div className='flex items-center'>
                  {[1, 2, 3, 4].map((ele, key) => (<Skeleton variant='text' className="w-24 mr-2" height={25} key={key} />))}
                </div>
                :
                props.field.slice(0, 4).map((categories, index) => (

                  <div key={index}
                    className={
                      (current === categories.id && "text-primary border-b-2 border-primary") +
                      "tabMenu text-xs pt-4 pb-7 mt-3 lg:mt-2 md:mt-4  font-bold text-muted  dark:text-fontWhite lg:mb-0 -mb-2 lg:text-sm mx-2 tracking-wider w-min text-nowrap hover:text-primary active:text-primary uppercase  cursor-pointer"
                    }

                    onClick={() => {
                      setCurrent(categories.id);
                      setId(null);
                      history.push({
                        pathname: `/shop/category-product/${categories.slug}/${categories.id}`,
                        state: {
                          id: categories.id,
                          name: categories.name,
                          type: "Categories",
                        },
                      });
                      props.onChange(props.field.indexOf(categories));
                    }}
                    onMouseEnter={() => setCategoryName(categories)}
                  >
                    {categories.name}
                  </div>
                ))}
            <div className="flex flex-col md:justify-center lg:justify-start">
              {
                props.field.length === 0 ?
                  <div className='flex'>
                    {[1].map((ele, key) => (<Skeleton variant='text' className="w-24 mr-2" height={25} key={key} />))}
                  </div> :
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      sx={(ID && { borderBottom: '2px solid' })}
                      className={(ID && "text-primary rounded-none border-b-2 border-primary ") + " text-xs py-2 mt-3 lg:mt-4 md:mt-2  font-bold text-muted  dark:text-fontWhite lg:mb-0 md:mb-0 -mb-2 lg:text-sm mx-2 tracking-wider w-min text-nowrap hover:text-primary active:text-primary uppercase  cursor-pointer"}
                    >
                      More
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}

                    >
                      {props.field.slice(4, props.field.length).map((categories, index) => {
                        return <MenuItem key={index} className="dark:bg-darkBackground text-sm dark:text-whiteBackground -my-2 py-3 dark:hover:bg-lightDarkBackground"
                          onClick={() => {

                            setId(categories.id);
                            history.push({
                              pathname: `/shop/category-product/${categories.slug}/${categories.id}`,
                              state: {
                                id: categories.id,
                                name: categories.name,
                                type: "Categories",
                              },
                            });
                            props.onChange(props.field.indexOf(categories));
                            handleClose();
                          }}>
                          {categories.name}
                        </MenuItem>
                      })}
                    </Menu>
                  </>
              }
            </div>
          </Tabs>
        </div>

        <div className="hidden group-hover:block mt-10">
          <div className={props.removeHeader ?
            'mt-10 border border-t-2 px-7 pt-2  pb-7 gap-2 bg-white z-100 fixed top-20 w-3/4 left-28 text-sm leading-6 grid grid-cols-4' :
            'border border-t-2 px-7 pt-2  pb-7 gap-2 bg-white z-100 fixed top-20 w-3/4 left-28 text-sm leading-6 grid grid-cols-4'}>
            {categoryName?.childrens?.map((categories, index) => (
              <div className="pb-2 cursor-pointer">
                <div
                  onClick={() => {
                    setCurrent(categories.id);
                    setId(null);
                    history.push({
                      pathname: `/shop/category-product/${categories.name}/${categories.id}`,
                      state: {
                        sub_id: categories.id,
                        id: categoryName?.id,
                        name: categories.name,
                        type: "Categories",
                      },
                    });
                    props.onChange(props.field.indexOf(categories));
                  }}>
                  <div className="font-medium pb-1" style={{ color: "#009acc" }}>{categories.name}</div>
                </div>

                {categories?.childrens.length > 0 &&
                  categories?.childrens?.map((child, index) => (
                    <div
                      onClick={() => {
                        setCurrent(categories.id);
                        setId(null);
                        history.push({
                          pathname: `/shop/category-product/${child.slug}/${child.id}`,
                          state: {
                            sub_id: child.id,
                            id: categoryName?.id,
                            name: categories.name,
                            type: "Categories",
                          },
                        });
                        props.onChange(props.field.indexOf(categories));
                      }}>
                      <div>{child.name}</div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
