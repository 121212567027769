import React from 'react'
import AccountMenu from './AccountMenu'
import { useTranslation } from 'react-i18next'
import Filter from './Filter';
import { useState } from 'react';

const VisitClicks = () => {
  const { t } = useTranslation();
  const [rowPerPage, setRowperPage] = useState(20)
  const [visitClicks, setVisitClicks] = useState([])
  const type = [
    {id:'Pending', name:'Pending'},
    {id:'Processing', name:'Processing'},
    {id:'Cancelled', name:"Cancelled"},
    {id:'Delivered', name:'Delivered'}
  ]
  const [statusValue, setStatusvalue] = useState();

  const onPropsReceiveChange = (event, value) => {
    setStatusvalue(value);
  };

  return (
    <div>
    <div className="ml-32 mt-10 p-5">
      <div className="text-3xl font-semibold text-black">{t('affiliateDashboard.visits')}</div>
    </div>
    <div className="md:w-2/3 w-11/12 mx-auto">
      <AccountMenu />    
      <div className="my-10 ">
          <div className="text-2xl font-semibold text-black">
            {t("affiliateDashboard.visits")}
          </div>
        </div>  
        <Filter
          statusValue={statusValue}
          onPropsReceiveChange={onPropsReceiveChange}
          type={type}
          setRowperPage={setRowperPage}
          rowPerPage={rowPerPage}
        />
        <div className="relative  overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs border h-16 text-gray-700 bg-white bg-transparent py-5 uppercase  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="">
                <div className="w-full text-center bg-blue-100 py-2"> Date </div>
                </th>
                <th scope="col" className="">
                <div className="w-full text-center bg-blue-100 py-2"> Link </div>
                </th>
                <th scope="col" className="">
                <div className="w-full text-center bg-blue-100 py-2"> Origin </div>
                 </th>
                <th scope="col" className="">
                <div className="w-full text-center bg-blue-100 py-4"></div>
                </th>
              </tr>
            </thead>
            <tbody>
            {visitClicks?.length > 0 ? (
                visitClicks?.map(() => {
                  return (
                    <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-3 border-r py-4 font-medium whitespace-nowrap dark:text-white"
                      >
                        10 May 2023
                      </th>
                      <td className="pl-6 border-r py-4">
                        https://hemalstore.com?ref=18
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="flex justify-between">
                          <div className="my-auto border-r">N/A</div>
                        </div>
                      </td>
                      <td className="px-4 border-l py-4">
                        <div className="border-l-1 mx-2 px-8 py-2 text-fontWhite bg-red-600">
                          Converted
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="bg-white border-1 border-gray-500 dark:bg-gray-800 dark:border-gray-700">
                  <td colspan="4" className="pl-6 rounded-md py-4 ">
                    <div className=" rounded py-2  text-center">
                      Sorry! No item Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>      
    </div>
  </div>
  )
}

export default VisitClicks