
// import firebase from "firebase";
// import "firebase/messaging";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// var getToken;
// var onMessageListener;
// // Before initialising our app we will be checking if firebase is suported in our browser or not so that our site doesnot get crash
// if (firebase.messaging.isSupported()) {
//   // firebase.messaging.isSupported function is for checking browser support for firebase
//   const firebaseConfig = {
//     apiKey: "AIzaSyBfJTSKua0ehloNDY-gLE5raooMM5m3a_A",
//     authDomain: "nily2022-516b9.firebaseapp.com",
//     projectId: "nily2022-516b9",
//     storageBucket: "nily2022-516b9.appspot.com",
//     messagingSenderId: "261489286975",
//     appId: "1:261489286975:web:4b36756bea4227cdc3fd8a",
//     measurementId: "G-QBZFGS6DGM"
//   };
//   // firebaseConfig are our configration for firebase
//   // below initilizing firebase app
//   firebase.initializeApp(firebaseConfig);

//   // below initializing firebase messaging using firebase.messaging function
//   const messaging = firebase.messaging();
//   const publicKey =
//     'BCYB0awFSMf6ePYCq7S4na6fQfxAEDz3IFc4mAYosDPS-9o4yA029inOvRVSjb53SQoNjK7i7Cs-Hod5KaQYwMw'


//   // Below function is for getting token so that we can send this token to our backend and start getting push Notification
//   getToken = async (token) => {
//     console.log("inside gettoken in firebase file")
//     let currentToken = "";
//     try {
//       currentToken = await messaging.getToken({ vapidKey: publicKey });
//       if (currentToken) {
//         token(true);
//       } else {
//         token(false);
//       }
//     } catch (error) {
//       console.log("An arror to get current token", error);
//     }
//     return currentToken;
//   };

//   // Below function is the function for getting for ground messages
//   onMessageListener = () =>
//     new Promise((resolve) => {
//       messaging.onMessage((payload) => {
//         resolve(payload);
//       });
//     });

// }
// export { getToken, onMessageListener };
// export default firebase;

// // we will be using both getToken and onMessageListener function in useEffect of app.js file


// new Comment -----

import { getMessaging } from "@firebase/messaging";
import { initializeApp } from "firebase/app";
// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
let app;

const firebaseConfig = {
    apiKey: "AIzaSyCvugufpsVIKrTWBW5z5VUvipcrIH336XY",
    authDomain: "leo-fashion.firebaseapp.com",
    projectId: "leo-fashion",
    storageBucket: "leo-fashion.appspot.com",
    messagingSenderId: "92946170865",
    appId: "1:92946170865:web:da0c9acd3476136a73fa50",
    measurementId: "G-RSGZXVJCG9"
};

app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
